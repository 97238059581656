import * as React from 'react';

export interface ISMSSettingsProps {
}

export default class SMSSettings extends React.Component<ISMSSettingsProps> {
  public render() {
    return (
      <div>
            <div className="inner-title">
                <h3>SMS Settings</h3>
                <div className="path_navigation">
                    <ul>
                        <li><a href="security.html">Settings</a></li>
                        <li><span>SMS Settings</span></li>
                    </ul>
                </div>
            </div>

            <div className="white_panel form_pading">
                <form action="">

                    <div className="row">

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="blue_label">User Name</label>
                                <input type="text" className="form-control"  placeholder="Enter User Name" />
                            </div>
                            <div className="form-group">
                                <label className="blue_label">Password</label>
                                <input type="text" className="form-control"  placeholder="Enter Password" />
                            </div>
                            <div className="form-group">
                                <label className="blue_label">Sender</label>
                                <input type="text" className="form-control"  placeholder="Enter Sender" />
                            </div>
                            <div className="form-group">
                                <label className="blue_label">URL</label>
                                <input type="text" className="form-control"  placeholder="Enter URL" />
                            </div>
                        </div>

                    </div>


                    <button className="btn-submit" type="submit">Save</button>




                </form>
            </div>
      </div>
    );
  }
}
