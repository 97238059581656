import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import SimpleReactValidator from "simple-react-validator";
import VendorForm from "../../components/vendor/vendorForm";
import Select, { ValueType } from "react-select";
import { AppState } from "../../state/types";
import { vendorCreate } from "../../state/vendors/actions";
import { toast } from "react-toastify";


interface DispatchProps {
  vendorCreate: (vendro: {
    businessName: string;
    category: string;
    email: string;
    contactEmail: string;
    phoneNumber: string;
    GroupNames: string[];
    firstName: string;
    lastName: string;
    facebookURL: string;
    twitterURL: string;
    instagramURL: string;
    paymentType: string[];
    Notifications: string[];
    location: string;
    city: string;
    state: string;
    zipcode: string;
    aboutVendor: string;
    emailSystemEmbedCode: string;
    country: string;
    websiteLink: string;
    profileImageURL: any;
    bannerImageUrl: any;
    tags: any;
  }) => void;
}

interface StateProps {
  isLoading: boolean;
  success: boolean;
  hasErrored: boolean;
  response: any;
}

// interface Props {}
interface State {
  businessName: string;
  category: ValueType<{ value: string; label: string }>[];
  payment: ValueType<{ value: string; label: string }>[];
  country: ValueType<{ value: string; label: string }>;
  state: ValueType<{ value: string; label: string }>[];
  firstName: string;
  lastName: string;
  city: string;
  email: string;
  contactEmail: string;
  facebookURL: string;
  instagramURL: string;
  location: string;
  phoneNumber: string;
  twitterURL: string;
  websiteURL: string;
  zipcode: string;
  about: string;
  embed: string;
  profileImageURL: any;
  bannerImageUrl: any;
  notifications: Map<string, string>;
}

type Props = DispatchProps & StateProps & RouteComponentProps;
export class VendorCreate extends Component<Props, State> {
  private validator: any;

  state = {
    isUpdate: false,
    businessName: "",
    category: null as any,
    payment: null as any,
    country: null as any,
    state: null as any,
    firstName: "",
    lastName: "",
    city: "",
    email: "",
    contactEmail: "",
    facebookURL: "",
    instagramURL: "",
    location: "",
    phoneNumber: "",
    twitterURL: "",
    websiteURL: "",
    zipcode: "",
    about: "",
    embed: "",
    profileImageURL:"",
    bannerImageUrl:"",
    notifications: new Map()
  };

  constructor(props: Props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this,  validators: {
      phoneNumber: {
        message: 'The :attribute must be a valid phone Number',
        rule: (val:any, params:any, validator:any) => {
          return validator.helpers.testRegex(val,/^((\([0-9]{3}\))|[0-9]{3})[\s\-][\0-9]{3}[-][0-9]{4}$/i) && params.indexOf(val) === -1
        },
        required: true
      }
    } });
  }
  componentDidMount() {
    document.title = "New Vendor | FestFrwrd - The Futr of FSTIVLS";
  }
  componentDidUpdate(prevProps: Props) {
    if (prevProps.response !== this.props.response && this.props.success) { 
      this.props.history.goBack();
      // toast.update(1, {
      //   render: this.props.response.data.message,
      //   type: toast.TYPE.SUCCESS,
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   onClose:() =>{
      //     this.props.history.goBack();
      //   }
      // });    
    }
    if (
      prevProps.response !== this.props.response &&
      this.props.hasErrored
    ) {      
      toast.error((this.props.response.error == null) ? this.props.response.message : this.props.response.error); 
      // toast.update(1, {
      //   render: (this.props.response.error == null) ? this.props.response.message : this.props.response.error,
      //   type: toast.TYPE.ERROR,
      //   autoClose: 2000,
      //   hideProgressBar: false
      // });
    }
  }

  setTextInput = <T extends keyof State>(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const newState = {
      [event.currentTarget.name]: event.currentTarget.value
    };
    this.setState(newState as { [P in T]: State[P] });
    if(event.currentTarget.name === 'email'){
      this.setState({
        ['contactEmail']: event.currentTarget.value
      } as Pick<State, keyof State>);
    }
  };

  handleSelectChange = <T extends keyof State>(
    selectedOption: ValueType<{ value: string; label: string }>,
    name: string
  ) => {
    const newState = {
      [name]: selectedOption
    };
    this.setState(newState as { [P in T]: State[P] }, () =>
      console.log(this.state)
    );
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const item = e.target.id;
    const name = e.target.name;
    const isChecked = e.target.checked;
    if (!isChecked) {
      this.setState((state, props) => {
        state.notifications.delete(item);
        return {
          notifications: state.notifications
        };
      });
    } else {
      this.setState((state, props) => {
        state.notifications.set(item, name);
        return {
          notifications: state.notifications
        };
      });
    }
    console.log(this.state.notifications);
  };
  private setProfileFileInput = (value: any) => {
    this.setState({ profileImageURL: value });
  };
  private setBanerFileInput = (value: any) => {
    this.setState({ bannerImageUrl: value });
  };
  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.props.isLoading) {
      return;
    }
    if (this.validator.allValid()) {
      const paymentType = this.state.payment.map((item: any) => item["value"]);
      const category = this.state.category.map((item: any) => item["value"]);
      this.props.vendorCreate({
        category: category,
        paymentType: paymentType,
        aboutVendor: this.state.about,
        Notifications: [...this.state.notifications.values()],
        city: this.state.city,
        country: this.state.country ? this.state.country.value : "",
        email: this.state.email,
        contactEmail: this.state.contactEmail,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phoneNumber: this.state.phoneNumber,
        GroupNames: ["Festival Vendors"],
        state: this.state.state ? this.state.state.value : "",
        businessName: this.state.businessName,
        facebookURL: this.state.facebookURL,
        instagramURL: this.state.instagramURL,
        location: this.state.location,
        twitterURL: this.state.twitterURL,
        websiteLink: this.state.websiteURL,
        zipcode: this.state.zipcode,   
        emailSystemEmbedCode: this.state.embed,
        profileImageURL:this.state.profileImageURL,
        bannerImageUrl:this.state.bannerImageUrl,
        tags: {
          opeationalStatus: '',
          invitationStatus: '',
          activeSince: ''
        },
      });
    } else {
      toast.dismiss(2);
      toast.warn("Please fill in all required fields!",{toastId:2});
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
        <div>
          <div className="inner-title">
            <h3>ADD VENDOR</h3>
            <div className="path_navigation">
              <ul>
                <li>
                  <NavLink to="/FestivalVendorManagement">VENDOR Management</NavLink>
                </li>
                <li>
                  <NavLink to="/FestivalVendorManagement/list">VIEW VENDORS</NavLink>
                </li>
                <li>
                  <span>ADD VENDOR</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="white_panel form_pading">
            <VendorForm
              isUpdate={this.state.isUpdate}
              businessName={this.state.businessName}
              category={this.state.category}
              firstName={this.state.firstName}
              lastName={this.state.lastName}
              city={this.state.city}
              email={this.state.email}
              contactEmail={this.state.contactEmail}
              facebookURL={this.state.facebookURL}
              instagramURL={this.state.instagramURL}
              location={this.state.location}
              phoneNumber={this.state.phoneNumber}
              twitterURL={this.state.twitterURL}
              websiteURL={this.state.websiteURL}
              zipcode={this.state.zipcode}
              payment={this.state.payment}
              country={this.state.country}
              about={this.state.about}
              embed={this.state.embed}
              profileImageURL= {this.state.profileImageURL}
              bannerImageUrl= {this.state.bannerImageUrl}
              state={this.state.state}
              notifications={this.state.notifications}
              setTextInput={this.setTextInput}
              handleSelectChange={this.handleSelectChange}
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}              
              setProfileFileInput={this.setProfileFileInput}
              setBanerFileInput={this.setBanerFileInput}
              validator={this.validator}
            />
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  response: state.vendors.VendorCreate.response,
  isLoading: state.vendors.VendorCreate.isLoading,
  success: state.vendors.VendorCreate.success,
  hasErrored: state.vendors.VendorCreate.hasErrored
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, Action>
): DispatchProps => ({
  vendorCreate: ({
    businessName,
    category,
    email,
    contactEmail,
    phoneNumber,
    GroupNames,
    firstName,
    lastName,
    facebookURL,
    twitterURL,
    websiteLink,
    instagramURL,
    paymentType,
    Notifications,
    location,
    city,
    state,
    zipcode,
    aboutVendor,
    emailSystemEmbedCode,
    country,
    profileImageURL,
    bannerImageUrl,
    tags
  }) =>
    dispatch(
      vendorCreate({
        businessName,
        category,
        email,
        contactEmail,
        phoneNumber,
        GroupNames,
        firstName,
        lastName,
        facebookURL,
        twitterURL,
        instagramURL,
        websiteLink,
        paymentType,
        Notifications,
        location,
        city,
        state,
        zipcode,
        aboutVendor,
        emailSystemEmbedCode,
        country,
        profileImageURL,
        bannerImageUrl,
        tags
      })
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(VendorCreate);
