import { combineReducers } from "redux";
import { loadingBarReducer } from "react-redux-loading-bar";
import { reducers as sessionReducers } from "./session/reducers";
import userMyProfileCreateReducers from "./userMyProfileCreate/reducer";
import userChangePinCreateReducers from "./userChangePinCreate/reducer";
import userFunctionCreateReducers from "./userFunctionCreate/reducer";
import userFunctionEditReducers from "./userFunctionEdit/reducer";
import userFunctionsListReducers from "./userFunctionList/reducer";
import userGroupsReducer from "./userGroups/reducer";
import userManagementReducer from "./userManagement/reducer";
import userFunctionDeleteReducer from "./userFunctionDelete/reducer";
import forgetPinEmailReducer from "./forgotPin/reducer";
import festivalOwnerReducer from "./festivalOwners/reducer";
import vendorsReducer from "./vendors/reducer";
import profilesReducer from "./profiles/reducer";
import festivalsReducer from "./festivals/reducer";

export const reducers = combineReducers({
  session: sessionReducers,
  loadingBar: loadingBarReducer,
  userChangePinsCreate: userChangePinCreateReducers,
  userMyProfileCreate: userMyProfileCreateReducers,
  userFunctionsCreate: userFunctionCreateReducers,
  userFunctionsList: userFunctionsListReducers,
  userFunctionsEdit: userFunctionEditReducers,
  userFunctionDelete: userFunctionDeleteReducer,
  userGroups: userGroupsReducer,
  userManagement: userManagementReducer,
  forgotPin: forgetPinEmailReducer,
  festivalOwner: festivalOwnerReducer,
  vendors: vendorsReducer,
  profiles: profilesReducer,
  festivals: festivalsReducer,
});
