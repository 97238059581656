import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { Dispatch, Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { emailSignIn, emailSignInError } from "../../state/session/actions";
import { AppState } from "./../../state/types";
import { Profile } from "./../../model/Profile";
import eye from "../../assets/images/eye.svg";
import eye_d from "../../assets/images/eye_d.svg";

interface StateProps {
  profile?: Object;
  token?: string;
  error?: any;
}

interface OwnProps {}

interface DispatchProps {
  signIn: (email: string, password: string) => void;
  emailSignInError: (status: boolean) => void;
}

type Props = DispatchProps & StateProps & OwnProps & RouteComponentProps;

interface State {}

class SignInForm extends Component<Props, State> {
  public state = { email: "", password: "", isView: false };
  private validator: any;
  private isvalid: any;
  constructor(props: any) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.isvalid = true;
  }

  componentDidMount() {
    document.title = "Login | FestFrwrd - The Futr of FSTIVLS";
    this.props.emailSignInError(false);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.token !== this.props.token) {
      this.props.history.replace("/FestivalManagement");
    }
  }

  private handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.signIn(this.state.email, this.state.password);
  };

  public submitForm(e: any) {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.handleSubmit(e);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  public viewPassword() {
    this.setState({ isView: !this.state.isView });
  }
  public render() {
    const { email, password } = this.state;
    if (this.props.token) {
      return <Redirect to={"/security"} />;
    }
    return (
      <div>
        {this.props.error ? (
          <div className="invalid_login">
            <p>
              Invalid credentials entered. <br />
              <span>{this.props.error.error}</span>
            </p>
          </div>
        ) : null}

        <form onSubmit={(event) => this.submitForm(event)}>
          <div className="form-group">
            <label>
              Username <span className="required">*</span>
            </label>
            <div className="icon-input user-icon">
              <input
                value={email}
                name="mail"
                maxLength={45}
                onChange={(event) => this.setStateWithEvent(event, "email")}
                type="email"
                className="form-control"
                placeholder="Enter Username"
              />
              {this.validator.message(
                "username",
                this.state.email,
                "required|email"
              )}
            </div>
          </div>
          <div className="form-group">
            <label>
              PIN <span className="required">*</span>
            </label>
            <div className="icon-input password-icon">
              {this.state.isView == true ? (
                <span>
                  <input
                    value={password}
                    name="password"
                    onChange={(event) =>
                      this.setStateWithEvent(event, "password")
                    }
                    type="text"
                    minLength={6}
                    className={
                      this.state.password !== ""
                        ? "form-control password_control password_control_view"
                        : "form-control password_control"
                    }
                    placeholder="&#8728;&#8728;&#8728;&#8728;&#8728;&#8728;"
                  />
                  <a className="eyeoverlap" onClick={() => this.viewPassword()}>
                    <img src={eye} />
                  </a>
                </span>
              ) : (
                <span>
                  <input
                    value={password}
                    name="password"
                    onChange={(event) =>
                      this.setStateWithEvent(event, "password")
                    }
                    type="password"
                    minLength={6}
                    className="form-control password_control"
                    placeholder="&#8728;&#8728;&#8728;&#8728;&#8728;&#8728;"
                  />
                  <a className="eyeoverlap" onClick={() => this.viewPassword()}>
                    <img src={eye_d} />
                  </a>
                </span>
              )}

              {this.validator.message(
                "pin",
                this.state.password,
                "required|string|min:6"
              )}
            </div>
          </div>
          <button type="submit" className="login_btn">
            Submit
          </button>
        </form>
      </div>
    );
  }
  private setStateWithEvent(event: any, columnType: string): void {
    this.setState(SignInForm.propKey(columnType, (event.target as any).value));
  }
  private static propKey(propertyName: string, value: any): object {
    return { [propertyName]: value };
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => ({
  profile: state.session.profile,
  token: state.session.token,
  error: state.session.error,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, Action>
): DispatchProps => ({
  signIn: (email, password) => dispatch(emailSignIn(email, password)),
  emailSignInError: (status) => dispatch(emailSignInError(status)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignInForm)
);
