import * as React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { onLogout,onUpdateToken } from '../../state/session/actions';
import { AppState } from '../../state/types';
import { Dropdown } from 'react-bootstrap';
import userImg  from '../../assets/images/user.png';
import { NavLink,Redirect } from 'react-router-dom';

import user from "../../assets/images/user.png";
export interface INavbarProps {
}

interface StateProps {
  profile: any,
}

interface DispatchProps {
  logout: () => void,
  updateToken: () => void,
}


type Props = DispatchProps & INavbarProps & StateProps

class Navbar extends React.Component<Props> {
  private intervalId:any;
  componentDidMount() {
     this.intervalId = setInterval(() => this.props.updateToken(), 10 * 60 * 1000);
  }

  componentWillUnmount() {
      clearInterval(this.intervalId);
  }

  public render() {
    return (     
      <header>
         { this.props.profile && this.props.profile.firstName !== undefined ? (
        <div className="row">
          <div className="col-md-6">
            <div className="text-logo">
              <h1><span>FEST</span>FRWRD</h1>
            </div>
          </div>
          <div className="col-md-6 text-right" style={{ justifyContent: "flex-end" }}>
            {/* <p>Hello! {this.props.profile && this.props.profile.firstName}</p>
            <div className="user-widget" onClick={() => this.props.logout()}>
              LogOut
            </div> */}
            <Dropdown className="user_dropdown">
              <Dropdown.Toggle  id="dropdown-basic">
                <div  >
                {(this.props.profile.profileImageURL != '') ? (
                  <img src={this.props.profile.profileImageURL} />
                ): ( <img src={user} />)}
                   
                  <i  className="capitalize">{this.props.profile && this.props.profile.firstName}</i>
                </div> 
              </Dropdown.Toggle>
 
              <Dropdown.Menu> 
              <Dropdown.Item className="zero" > <NavLink to="/change-pin" className="dropdown-item" >CHANGE PIN</NavLink></Dropdown.Item>
              <Dropdown.Item className="zero"  >  <NavLink to="/my-profile" className="dropdown-item" >MY PROFILE</NavLink></Dropdown.Item>
              <Dropdown.Item className="zero"  >  <a className="dropdown-item" onClick={() => this.props.logout()}>LOGOUT</a></Dropdown.Item>
               
               
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
         ) : (
          <Redirect to="/login"/>
        )  }
      </header>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  profile: state.session.profile,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>) => ({
  logout: () => dispatch(onLogout()),
  updateToken: () => dispatch(onUpdateToken())
})

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
