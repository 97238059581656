import * as React from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import SimpleReactValidator from "simple-react-validator";
import { AppState } from "../../state/types";
import { userGroupList } from "../../state/userGroups/actions";
import { userManagementCreate } from "../../state/userManagement/actions";
import UserManagementForm from "../../components/Security/UserManagementForm";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";

export interface IUserManagementCreateProps {}

interface StateProps {
  userGroups: any;
  createResponse: boolean;
  isLoading: boolean;
  success: boolean;
  hasErrored: boolean;
  response: any;
}

interface DispatchProps {
  getAllUserGroups: () => void;
  userManagementCreate: (
    email: string,
    contactEmail: string,
    phoneNumber: string,
    firstName: string,
    lastName: string,
    userGroup: any[],
    profilePicbase: string
  ) => void;
}

interface State {
  firstName: string;
  lastName: string;
  email: string;
  contactEmail: string;
  phoneNumber: string;
  userGroup: string;
  profilePic: any;  
  checkedItems: Map<string, string>;
  profilePicbase:any;
}

interface Input {
  groupName: string;
  groupDescription: string;
}
interface GState extends Input {
  checkedItems: Map<string, string>;
}

type Props = DispatchProps & GState & StateProps & RouteComponentProps;

class UserManagementCreate extends React.Component<Props, State> {
  public state = {
    isUpdate: false,
    firstName: "",
    lastName: "",
    email: "",
    contactEmail: "",
    phoneNumber: "",
    userGroup: "",
    profilePic: null as any,
    checkedItems: new Map(),
    profilePicbase:""
  };
  private validator: any;
  constructor(props: any) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this,  validators: {
      phoneNumber: {
        message: 'The :attribute must be a valid phone Number',
        rule: (val:any, params:any, validator:any) => {
          return validator.helpers.testRegex(val,/^((\([0-9]{3}\))|[0-9]{3})[\s\-][\0-9]{3}[-][0-9]{4}$/i) && params.indexOf(val) === -1
        },
        required: true
      }
    } });
    this.handleChange = this.handleChange.bind(this);
    this.setTextInput = this.setTextInput.bind(this);
    this.setFileInput = this.setFileInput.bind(this);
  }
  componentDidMount() {
    document.title = "New User | FestFrwrd - The Futr of FSTIVLS";
    this.props.getAllUserGroups();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.response !== this.props.response && this.props.success) { 
      this.props.history.goBack();
      // toast.update(1, {
      //   render: this.props.response.data.message,
      //   type: toast.TYPE.SUCCESS,
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   onClose:() =>{
      //     this.props.history.goBack();
      //   }
      // });    
    }
    if (
      prevProps.response !== this.props.response &&
      this.props.hasErrored
    ) {   
      toast.error((this.props.response.error == null) ? this.props.response.message : this.props.response.error);    
      // toast.update(1, {
      //   render: (this.props.response.error == null) ? this.props.response.message : this.props.response.error,
      //   type: toast.TYPE.ERROR,
      //   autoClose: 2000,
      //   hideProgressBar: false
      // });
    }
  }
  private deleteFileInput = (event: any) => {
    this.setState({ profilePicbase: "" });
  };
  private setTextInput = (event: any) => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    } as Pick<State, keyof State>);
    if(event.currentTarget.name === 'email'){
      this.setState({
        ['contactEmail']: event.currentTarget.value
      } as Pick<State, keyof State>);
    }
  };
  getBase64(file:any, cb:any) {
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = function () {
          cb(reader.result)
      };
      reader.onerror = function (error) {
          console.log('Error: ', error);
      };
  }
  private setFileInput = (event: React.FormEvent<HTMLInputElement>) => { 
    let files = event.currentTarget.files;
    this.getBase64(files, (result:any) => {
      this.setState({ profilePicbase: result });
    });
  };
  setRadioButton = (event: React.SyntheticEvent<HTMLInputElement, Event>) => {
    this.setState({ userGroup: event.currentTarget.value });
  };
  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const item = e.target.id;
    const name = e.target.name;
    const isChecked = e.target.checked;
    if (!isChecked) {
      this.setState((state, props) => {
        state.checkedItems.delete(item);
        return {
          checkedItems: state.checkedItems
        };
      });
    } else {
      this.setState((state, props) => {
        state.checkedItems.set(item, name);
        return {
          checkedItems: state.checkedItems
        };
      });
    }
  }
  handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (this.validator.allValid()) {
      let keys = [...this.state.checkedItems.keys()];
      let values = [...this.state.checkedItems.values()];
      let _userGroups:any = [];
      let userGroups = keys.map((key, index) => (
          _userGroups.push({
            id: key,
            name: values[index]
          })
      ));
      this.props.userManagementCreate(
        this.state.email,
        this.state.contactEmail,
        this.state.phoneNumber,
        this.state.firstName,
        this.state.lastName,
        _userGroups,
        this.state.profilePicbase,
      );
    } else {
      toast.dismiss(2);
      toast.warn("Please fill in all required fields!",{toastId:2});
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  public render() {
    if (this.props.userGroups.length === 0) {
      return <p>Loading</p>;
    }
    return (
      <div>
        <div className="inner-title">
          <h3>Add USER MANAGEMENT</h3>
          <div className="path_navigation">
            <ul>
              <li>
                <NavLink to="/security">SECURITY Management</NavLink>
              </li>
              <li>
                <NavLink to="/security/UserManagement">USER MANAGEMENT</NavLink>
              </li>
              <li>
                <span>Create User</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="white_panel form_pading">
          <UserManagementForm
            isUpdate={this.state.isUpdate}
            email={this.state.email}
            contactEmail={this.state.contactEmail}
            firstName={this.state.firstName}
            handleSubmit={this.handleSubmit}
            lastName={this.state.lastName}
            phoneNumber={this.state.phoneNumber}
            profilePic={this.state.profilePic}
            setRadioButton={this.setRadioButton}
            setTextInput={this.setTextInput}            
            setFileInput={this.setFileInput}   
            deleteFileInput={this.deleteFileInput}         
            profilePicbase={this.state.profilePicbase}
            userGroup={this.state.userGroup}
            userGroups={this.props.userGroups}
            checkedItems={this.state.checkedItems}
            handleChange={this.handleChange}
            validator={this.validator}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userGroups: state.userGroups.UserGroupList.success,
  response: state.userManagement.UserManagementCreate.response,
  createResponse: state.userManagement.UserManagementCreate.success,
  isLoading: state.userManagement.UserManagementCreate.isLoading,
  success: state.userManagement.UserManagementCreate.success,
  hasErrored: state.userManagement.UserManagementCreate.hasErrored
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, Action>
): DispatchProps => ({
  getAllUserGroups: () => dispatch(userGroupList()),
  userManagementCreate: (email,contactEmail, phoneNumber, firstName, lastName, userGroup,profilePicbase) =>
    dispatch(
      userManagementCreate(email,contactEmail, phoneNumber, firstName, lastName, userGroup, profilePicbase)
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagementCreate);
