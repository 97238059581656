import * as ActionTypes from "./actionTypes";
import { GetAllUserFunctionsActionTypes, UserFunctionListState } from "./types";

const initialState: UserFunctionListState = {
  isLoading: false,
  success: [],
  hasErrored: false
};

export default (
  state = initialState,
  action: GetAllUserFunctionsActionTypes
): UserFunctionListState => {
  switch (action.type) {
    case ActionTypes.USER_GET_ALL_FUNCTIONS:
      return { ...state, isLoading: true, success: [], hasErrored: false };

    case ActionTypes.USER_GET_ALL_FUNCTIONS_SUCCESS:
      return { ...state, success: action.payload, isLoading: false };

    case ActionTypes.USER_GET_ALL_FUNCTIONS_ERROR:
      return { ...state, isLoading: false, hasErrored: true };

    case ActionTypes.USER_DELETE_SINGLE_USER_FUNCTION:
      const success = state.success.filter(
        item => item.documentID !== action.payload
      );
      return { ...state, success };

    case ActionTypes.USER_GET_ALL_FUNCTIONS_RESET:
      return { ...initialState };

    default:
      return state;
  }
};
