export const FESTIVAL_OWNER_CREATE = "FESTIVAL_OWNER_CREATE";
export const FESTIVAL_OWNER_CREATE_SUCCESS = "FESTIVAL_OWNER_CREATE_SUCCESS";
export const FESTIVAL_OWNER_CREATE_ERROR = "FESTIVAL_OWNER_CREATE_ERROR";
export const FESTIVAL_OWNER_EDIT = "FESTIVAL_OWNER_EDIT";
export const FESTIVAL_OWNER_EDIT_SUCCESS = "FESTIVAL_OWNER_EDIT_SUCCESS";
export const FESTIVAL_OWNER_EDIT_ERROR = "FESTIVAL_OWNER_EDIT_ERROR";
export const FESTIVAL_OWNER_DELETE = "FESTIVAL_OWNER_DELETE";
export const FESTIVAL_OWNER_DELETE_SUCCESS = "FESTIVAL_OWNER_DELETE_SUCCESS";
export const FESTIVAL_OWNER_DELETE_ERROR = "FESTIVAL_OWNER_DELETE_ERROR";
export const FESTIVAL_OWNER_LIST = "FESTIVAL_OWNER_LIST";
export const FESTIVAL_OWNER_LIST_SUCCESS = "FESTIVAL_OWNER_LIST_SUCCESS";
export const FESTIVAL_OWNER_LIST_ERROR = "FESTIVAL_OWNER_LIST_ERROR";
export const FESTIVAL_OWNER_DELETE_SINGLE = "FESTIVAL_OWNER_DELETE_SINGLE";
export const FESTIVAL_OWNER_SEND_INVITE = "FESTIVAL_OWNER_SEND_INVITE";
export const FESTIVAL_OWNER_SEND_INVITE_SUCCESS =
  "FESTIVAL_OWNER_SEND_INVITE_SUCCESS";
export const FESTIVAL_OWNER_SEND_INVITE_ERROR =
  "FESTIVAL_OWNER_SEND_INVITE_ERROR";
export const FESTIVAL_OWNER_EDIT_SINGLE = "FESTIVAL_OWNER_EDIT_SINGLE";
