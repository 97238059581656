import * as React from "react";
import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { toast } from "react-toastify";
import { withRouter, RouteComponentProps } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { userChangePinCreate } from "../../state/userChangePinCreate/actions";
import UserChangePin from "../../components/profile/UserChangePin";
import { AppState } from "../../state/types";

interface IUserChangePinCreateProps {}

interface StateProps {
  isLoading: boolean;
  success: number;
  hasErrored: boolean;
  profile: any;
}

interface DispatchProps {
  userChangePinCreate: (oldPin: string, newPin: string,confirmPin: string,email:string) => void;
}

interface IUserChangePinCreateState {
  oldPin: string;
  newPin: string;
  confirmPin: string;
  email: string;
}

type Props = DispatchProps &
  IUserChangePinCreateProps &
  StateProps &
  RouteComponentProps;

class UserChangePinCreate extends React.Component<
  Props,
  IUserChangePinCreateState
> {
  private validator: any;
  public state = {
    oldPin: "",
    newPin: "",
    confirmPin: "",
    email: "",
  };
  constructor(props: Props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    console.log(this.props.profile);

  }
  componentDidMount() {
    document.title = "Change PIN | FestFrwrd - The Futr of FSTIVLS";
  }
  componentDidUpdate(prevProps: Props, prevState: IUserChangePinCreateState) {

    if (prevProps.success !== this.props.success && this.props.success) {
     // toast.dismiss(1);
     // toast.success("Success: User Pin Updated successfully");
      let url = "";
      if(this.props.profile.GroupNames.includes("Administrators") === true){
         url = "/FestivalManagement";
      }else{
         url = "/dashboard";
      }
      this.props.history.push(url);
    } 

    if (
      prevProps.hasErrored !== this.props.hasErrored &&
      this.props.hasErrored
    ) {
      toast.dismiss(1);
      toast.error("Failed to Pin Updated! please try again!");
    }
  }

  setTextInput = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value} as Pick<IUserChangePinCreateState, keyof IUserChangePinCreateState>);
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
  

    event.preventDefault();
    if (this.validator.allValid()) {
      this.setState({ ["email"]: this.props.profile.email },() => {
          this.props.userChangePinCreate(
            this.state.oldPin,
            this.state.newPin,
            this.state.confirmPin,
            this.state.email 
          );
      });
      
  } else {
      toast.dismiss(2);
      toast.warn("Please fill in all required fields!",{toastId:2});
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  public render() {
    return (
      <div>
        <UserChangePin
            handleSubmit={e => this.handleSubmit(e)}
            setTextInput={e => this.setTextInput(e)}
            oldPin={this.state.oldPin}
            newPin={this.state.newPin}
            confirmPin={this.state.confirmPin}
            validator={this.validator}  
            />

      
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  profile: state.session.profile,
  isLoading: state.userChangePinsCreate.isLoading,
  success: state.userChangePinsCreate.success,
  hasErrored: state.userChangePinsCreate.hasErrored
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, Action>
): DispatchProps => ({
  userChangePinCreate: (oldpin, newpin,confirmpin,email ) =>
    dispatch(userChangePinCreate(oldpin, newpin,confirmpin,email ))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserChangePinCreate);
