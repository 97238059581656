import React from "react";
import axios, { AxiosResponse } from "axios";
import { Axios } from './../config/Axios';
import { UserFunction } from "../state/userFunctionList/types";
var randomstring = require("randomstring");


interface signInResponse {
  data: {
    status: boolean;
    token?: string;
    uid?: string;
    data?: Object;
    error?: string;
  };
}

interface createUserFunctionResponse {
  data: {
    status: boolean;
    error?: string;
  };
}
interface createUserGroupResponse {
  data: {
    status: boolean;
    error?: string;
  };
}

interface createUserResponse {
  data: {
    status: boolean;
    error?: string;
  };
}
interface getAllFunctionsResponse {
  data: {
    message: string;
    status?: boolean;
    results?: Array<UserFunction>;
    error?: string;
  };
}
interface getAllGroupsResponse {
  data: {
    message?: string;
    results?: any;
    error?: string;
  };
}
interface getAllUsersResponse {
  data: {
    message?: string;
    results?: any;
    error?: string;
  };
}

class Api {
  signIn = async (params: {
    email: string;
    password: string;
  }): Promise<signInResponse["data"]> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: signInResponse = await Axios.post("auth/login", {
          email: params.email,
          password: params.password
        });
        if (response.data.status) {
          resolve(response.data);
        } else {
          reject(response.data.error);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };
  updateToken = async (): Promise<signInResponse["data"]> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: signInResponse = await Axios.post("auth/updateToken");
        if (response.data.status) {
          resolve(response.data);
        } else {
          reject(response.data.error);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  forgotPassword = async (params: { email: string }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: signInResponse = await Axios.post("auth/forgetpassword", {
          email: params.email
        });
        if (response.data.status) {
          resolve(response.data);
        } else {
          reject(response.data.error);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  resetPassword = async (params: {
    token: string;
    password: string;
  }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: signInResponse = await Axios.post("auth/resetPin", {
          token: params.token,
          password: params.password
        });
        if (response.data.status) {
          resolve(response.data);
        } else {
          reject(response.data.error);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };
  resetPIN = async (params: {
    newPin: any;
    email: string;
  }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: signInResponse = await Axios.post("auth/userResetPin", {
          newPin: params.newPin,
          email: params.email
        });
        if (response.data.status) {
          resolve(response.data);
        } else {
          reject(response.data.error);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };
  createUserFunction = async (params: {
    name: string;
    description: string;
  }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: createUserFunctionResponse = await Axios.post(
          "functions/",
          { name: params.name, description: params.description }
        );
        if (response.data.status) {
          resolve(response);
        } else {
          reject(response);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  editUserFunction = async (params: {
    name: string;
    description: string;
    functionId: string;
  }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: createUserFunctionResponse = await Axios.put(
          `functions/${params.functionId}`,
          { name: params.name, description: params.description }
        );
        console.log(response);
        if (response.data.status) {
          resolve(response);
        } else {
          reject(response);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  deleteUserFunction = async (functionId: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: createUserFunctionResponse = await Axios.delete(
          `functions/${functionId}`
        );
        console.log(response);
        if (response.data.status) {
          resolve(response);
        } else {
          reject(response);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  createGroupFunction = async (params: {
    name: string;
    description: string;
    functionslist: any;
  }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: createUserGroupResponse = await Axios.post("groups/", {
          name: params.name,
          description: params.description,
          _functions: params.functionslist
        });
        console.log(response);
        if (response.data.status) {
          resolve(response);
        } else {
          reject(response);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  editGroupFunction = async (params: {
    name: string;
    description: string;
    functionslist: any;
    groupId: string;
  }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: createUserGroupResponse = await Axios.put(
          `groups/${params.groupId}`,
          {
            name: params.name,
            description: params.description,
            _functions: params.functionslist
          }
        );
        console.log(response);
        if (response.data.status) {
          resolve(response);
        } else {
          reject(response);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  deleteGroupFunction = async (params: { groupId: string }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: createUserGroupResponse = await Axios.delete(
          `groups/${params.groupId}`
        );
        console.log(response);
        if (response.data.status) {
          resolve(response);
        } else {
          reject(response);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  createUser = async (params: {
    email: string;
    contactEmail: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    _groupId: any[];
    profilePicbase: string;
  }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: createUserResponse = await Axios.post("users/", {
          email: params.email,
          contactEmail: params.contactEmail,
          phoneNumber: params.phoneNumber,
          firstName: params.firstName,
          lastName: params.lastName,
          _groupId: params._groupId,
          profilePicbase: params.profilePicbase,
        });
        if (response.data.status) {
          resolve(response);
        } else {
          reject(response);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  editUser = async (params: {
    email: string;
    contactEmail: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    _groupId: any[];
    profilePicbase: string;
    userId: string;
    tags: any;
  }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let password: string = randomstring.generate(6);
        console.log("password=" + password);
        let response: createUserResponse = await Axios.put(
          `users/${params.userId}`,
          {
            email: params.email,
            contactEmail: params.contactEmail,
            phoneNumber: params.phoneNumber,
            firstName: params.firstName,
            lastName: params.lastName,
            profilePicbase: params.profilePicbase,
            _groupId: params._groupId,
            tags: params.tags
          }
        );
        console.log(response);
        if (response.data.status) {
          resolve(response);
        } else {
          reject(response);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  deleteUser = async (params: { userId: string }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: createUserResponse = await Axios.delete(
          `users/${params.userId}`
        );
        console.log(response);
        if (response.data.status) {
          resolve(response);
        } else {
          reject(response);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  getGroupById = async (params: { id: string }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: any = await Axios.get("groups/get/" + params.id);
        console.log(response);
        if (response.data.status) {
          resolve(response);
        } else {
          reject(response);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  getFunctionById = async (params: { id: string }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: any = await Axios.get("functions/get/" + params.id);
        console.log(response);
        if (response.data.status) {
          resolve(response.data.data);
        } else {
          reject(response.data.error);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };
  getAllUserFunctionsList = async (
    limit: number,
    page: number,
    search: string,
    sort: string
  ): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let url = "functions";
        if (sort != "") url += "/" + sort;
        if (search != "") {
          url += "?s=" + search + "&limit=" + limit + "&page=" + page;
        } else {
          url += "?limit=" + limit + "&page=" + page;
        }

        let response: getAllFunctionsResponse = await Axios.get(url);
        if (response.data.message === "success") {
          resolve(response.data.results);
        } else {
          reject(response.data.error);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };
  getAllFunctions = async (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: getAllFunctionsResponse = await Axios.get("functions");
        console.log(response);
        if (response.data.message === "success") {
          resolve(response.data.results);
        } else {
          reject(response.data.error);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };
  getAllGroupsByList = async (
    limit: number,
    page: number,
    search: string,
    sort: string
  ): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let url = "groups";
        if (sort != "") url += "/" + sort;
        if (search != "") {
          url += "?s=" + search + "&limit=" + limit + "&page=" + page;
        } else {
          url += "?limit=" + limit + "&page=" + page;
        }
        let response: getAllGroupsResponse = await Axios.get(url);
        console.log(response);
        if (response.data.message === "success") {
          resolve(response.data.results);
        } else {
          reject(response.data.error);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };
  getAllGroups = async (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: getAllGroupsResponse = await Axios.get("groups");
        console.log(response);
        if (response.data.message === "success") {
          resolve(response.data.results);
        } else {
          reject(response.data.error);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };
  getAllUsersList = async (
    limit: number,
    page: number,
    search: string,
    sort: string
  ): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let url = "users";
        if (sort != "") url += "/" + sort;
        if (search != "") {
          url += "?s=" + search + "&limit=" + limit + "&page=" + page;
        } else {
          url += "?limit=" + limit + "&page=" + page;
        }
        let response: getAllUsersResponse = await Axios.get(url);
        console.log(response);
        if (response.data.message === "success") {
          resolve(response.data.results);
        } else {
          reject(response.data.error);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };
  getAllUsers = async (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: getAllUsersResponse = await Axios.get("users");
        console.log(response);
        if (response.data.message === "success") {
          resolve(response.data.results);
        } else {
          reject(response.data.error);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };
  updateUserPin = async (params: {
    oldpin: string;
    newpin: string;
    confirmpin: string;
    email: string;
  }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: createUserFunctionResponse = await Axios.post(
          "auth/changePassword/",
          {
            oldPassword: params.oldpin,
            newPassword: params.newpin,
            confirmNewPassword: params.confirmpin,
            email: params.email
          }
        );
        console.log(response);
        if (response.data.status) {
          resolve(response);
        } else {
          reject(response);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };
  updateUserMyProfile = async (params: {
    token: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    profilePic: string;
  }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: createUserFunctionResponse = await Axios.post(
          "auth/updateUserPRofile/",
          {
            token: params.token,
            firstName: params.firstName,
            lastName: params.lastName,
            email: params.email,
            phoneNumber: params.phoneNumber,
            profilePic: params.profilePic
          }
        );
        console.log(response);
        if (response.data.status) {
          resolve(response.data.status);
        } else {
          reject(response.data.error);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  createFestivalOwner = async (params: {
    firstName: string;
    lastName: string;
    email: string;
    contactEmail: string;
    // password: string;
    phoneNumber: string;
    GroupNames: string[];
    address: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    Notifications: string[];
    profileImageURL: any;
    tags: any;
  }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let password: string = randomstring.generate(6);
        let response: createUserFunctionResponse = await Axios.post("owners/", {
          firstName: params.firstName,
          lastName: params.lastName,
          email: params.email,
          contactEmail: params.contactEmail,
          password: password,
          phoneNumber: params.phoneNumber,
          GroupNames: params.GroupNames,
          address: params.address,
          city: params.city,
          state: params.state,
          zipcode: params.zipcode,
          country: params.country,
          Notifications: params.Notifications,
          opeationalStatus: "New",
          _groupId: "",
          profileImageURL: params.profileImageURL,
          tags:  params.tags,
        });
        console.log(response);
        if (response.data.status) {
          resolve(response);
        } else {
          reject(response);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  editFestivalOwner = async (params: {
    ownerId: string;
    firstName: string;
    lastName: string;
    email: string;
    contactEmail: string;
    // password: string;
    phoneNumber: string;
    GroupNames: string[];
    address: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    Notifications: string[];
    profileImageURL: any;
    tags: any;
  }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: createUserFunctionResponse = await Axios.put(
          `owners/${params.ownerId}`,
          {
            firstName: params.firstName,
            lastName: params.lastName,
            email: params.email,
            contactEmail: params.contactEmail,
            phoneNumber: params.phoneNumber,
            GroupNames: params.GroupNames,
            address: params.address,
            city: params.city,
            state: params.state,
            zipcode: params.zipcode,
            country: params.country,
            Notifications: params.Notifications,
            profileImageURL: params.profileImageURL,
            tags: params.tags,
          }
        );
        console.log(response);
        if (response.data.status) {
          resolve(response);
        } else {
          reject(response);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  deleteFestivalOwner = async (params: { ownerId: string }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: createUserGroupResponse = await Axios.delete(
          `owners/${params.ownerId}`
        );
        console.log(response);
        if (response.data.status) {
          resolve(response);
        } else {
          reject(response);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  getAllOwnersByList = async (
    limit: number,
    page: number,
    search: string,
    sort: string
  ): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let url = "owners";
        if (sort != "") url += "/" + sort;
        if (search != "") {
          url += "?s=" + search + "&limit=" + limit + "&page=" + page;
        } else {
          url += "?limit=" + limit + "&page=" + page;
        }
        let response: getAllGroupsResponse = await Axios.get(url);
        console.log(response);
        if (response.data.message === "success") {
          resolve(response.data.results);
        } else {
          reject(response.data.error);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  getAllOwners = async (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: getAllGroupsResponse = await Axios.get("owners");
        console.log(response);
        if (response.data.message === "success") {
          resolve(response.data.results);
        } else {
          reject(response.data.error);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  ownersSendInvite = async (params: { email: string,contactEmail:string,name: string,groupscount:number }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: createUserGroupResponse = await Axios.post(
          "owners/sendinvite",
          {
            email: params.email,
            contactEmail: params.contactEmail,
            name: params.name,
            groupscount: params.groupscount,
          }
        );
        if (response.data.status) {
          resolve(response.data.status);
        } else {
          reject(response.data.error);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  ownersUpdateInvite = async (params: {
    uid: string;
    pin: string;
    invitationStatus: string;
  }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: createUserGroupResponse = await Axios.post(
          "owners/updateinvite",
          {
            uid: params.uid,
            pin: params.pin,
            opeationalStatus: params.invitationStatus,
            date: new Date()
          }
        );
        console.log(response);
        if (response.data.status) {
          resolve(response.data.status);
        } else {
          reject(response.data.error);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  createVendor = async (params: {
    businessName: string;
    category: string;
    email: string;
    contactEmail: string;
    phoneNumber: string;
    GroupNames: string[];
    firstName: string;
    lastName: string;
    facebookURL: string;
    twitterURL: string;
    instagramURL: string;
    paymentType: string[];
    Notifications: string[];
    location: string;
    city: string;
    state: string;
    zipcode: string;
    aboutVendor: string;
    emailSystemEmbedCode: string;
    websiteLink: string;
    country: string;
    profileImageURL: any;
    bannerImageUrl: any;
    tags: any;
  }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let password: string = randomstring.generate(6);
        let response: createUserFunctionResponse = await Axios.post(
          "vendors/",
          {
            businessName: params.businessName,
            category: params.category,
            email: params.email,
            contactEmail: params.contactEmail,
            phoneNumber: params.phoneNumber,
            firstName: params.firstName,
            lastName: params.lastName,
            facebookURL: params.facebookURL,
            twitterURL: params.twitterURL,
            instagramURL: params.instagramURL,
            paymentType: params.paymentType,
            Notifications: params.Notifications,
            location: params.location,
            city: params.city,
            state: params.state,
            zipcode: params.zipcode,
            aboutVendor: params.aboutVendor,
            emailSystemEmbedCode: params.emailSystemEmbedCode,
            GroupNames: params.GroupNames,
            _groupId: "",
            websiteLink: params.websiteLink,
            country: params.country,
            invitationStatus: "New",
            activeSince: "",
            profileImageURL:  params.profileImageURL,
            bannerImageUrl:  params.bannerImageUrl,
            tags:  params.tags,
          }
        );
        console.log(response);
        if (response.data.status) {
          resolve(response);
        } else {
          reject(response);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  editVendor = async (params: {
    vendorId: string;
    tags: any;
    businessName: string;
    category: string;
    email: string;
    contactEmail: string;
    phoneNumber: string;
    GroupNames: string[];
    firstName: string;
    lastName: string;
    facebookURL: string;
    twitterURL: string;
    instagramURL: string;
    paymentType: string[];
    Notifications: string[];
    location: string;
    city: string;
    state: string;
    zipcode: string;
    aboutVendor: string;
    emailSystemEmbedCode: string;
    websiteLink: string;
    country: string;    
    profileImageURL: any;
    bannerImageUrl: any;
  }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try { 
        let response: createUserFunctionResponse = await Axios.put(
          `vendors/${params.vendorId}`,
          { 
            tags: params.tags,
            businessName: params.businessName,
            category: params.category,
            email: params.email,
            contactEmail: params.contactEmail,
            phoneNumber: params.phoneNumber,
            firstName: params.firstName,
            lastName: params.lastName,
            facebookURL: params.facebookURL,
            twitterURL: params.twitterURL,
            instagramURL: params.instagramURL,
            paymentType: params.paymentType,
            Notifications: params.Notifications,
            location: params.location,
            city: params.city,
            state: params.state,
            zipcode: params.zipcode,
            aboutVendor: params.aboutVendor,
            emailSystemEmbedCode: params.emailSystemEmbedCode,
            GroupNames: params.GroupNames,
            _groupId: "",
            websiteLink: params.websiteLink,
            country: params.country,
            profileImageURL:  params.profileImageURL,
            bannerImageUrl:  params.bannerImageUrl,
          }
        );
        console.log(response);
        if (response.data.status) {
          resolve(response);
        } else {
          reject(response);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  getAllVendorsByList = async (
    limit: number,
    page: number,
    search: string,
    sort: string
  ): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let url = "vendors";
        if (sort != "") url += "/" + sort;
        if (search != "") {
          url += "?s=" + search + "&limit=" + limit + "&page=" + page;
        } else {
          url += "?limit=" + limit + "&page=" + page;
        }
        let response: getAllGroupsResponse = await Axios.get(url);
        console.log(response);
        if (response.data.message === "success") {
          resolve(response.data.results);
        } else {
          reject(response.data.error);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  getAllVendors = async (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: getAllGroupsResponse = await Axios.get("vendors");
        console.log(response);
        if (response.data.message === "success") {
          resolve(response.data.results);
        } else {
          reject(response.data.error);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  deleteVendor = async (params: { vendorId: string }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: createUserGroupResponse = await Axios.delete(
          `vendors/${params.vendorId}`
        );
        console.log(response);
        if (response.data.status) {
          resolve(response);
        } else {
          reject(response);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  vendorsSendInvite = async (params: { email: string,contactEmail:string,name: string,groupscount:number }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: createUserGroupResponse = await Axios.post(
          "vendors/sendinvite",
          {
            email: params.email,
            contactEmail: params.contactEmail,
            name: params.name,
            groupscount: params.groupscount,
          }
        );
        console.log(response);
        if (response.data.status) {
          resolve(response.data.status);
        } else {
          reject(response.data.error);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  vendorsUpdateInvite = async (params: {
    uid: string;
    pin: string;
    invitationStatus: string;
  }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: createUserGroupResponse = await Axios.post(
          "vendors/updateinvite",
          {
            uid: params.uid,
            pin: params.pin,
            invitationStatus: params.invitationStatus,
            date: new Date()
          }
        );
        console.log(response);
        if (response.data.status) {
          resolve(response.data.status);
        } else {
          reject(response.data.error);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };
  editProfile = async (params: {
    token: string;
    businessName: string;
    category: string;
    email: string;
    contactEmail: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    facebookURL: string;
    twitterURL: string;
    GroupNames:string;
    instagramURL: string;
    paymentType: string[];
    Notifications: string[];
    location: string;
    city: string;
    state: string;
    zipcode: string;
    aboutVendor: string;
    emailSystemEmbedCode: string;
    websiteLink: string;
    country: string;
    profileImageURL: any;
    bannerImageUrl: any;
  }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: createUserFunctionResponse = await Axios.post(
          `auth/updateUserPRofile`,
          {
            token:params.token,
            businessName: params.businessName,
            category: params.category,
            email: params.email,
            contactEmail: params.contactEmail,
            GroupNames:params.GroupNames,
            phoneNumber: params.phoneNumber,
            firstName: params.firstName,
            lastName: params.lastName,
            facebookURL: params.facebookURL,
            twitterURL: params.twitterURL,
            instagramURL: params.instagramURL,
            paymentType: params.paymentType,
            Notifications: params.Notifications,
            location: params.location,
            city: params.city,
            state: params.state,
            zipcode: params.zipcode,
            aboutVendor: params.aboutVendor,
            emailSystemEmbedCode: params.emailSystemEmbedCode,
            profileImageURL: params.profileImageURL,
            _groupId: "",
            websiteLink: params.websiteLink,
            country: params.country,
            bannerImageUrl: params.bannerImageUrl,
          }
        );
        console.log(response);
        if (response) {
          resolve(response);
        } else {
          reject(response);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };

  createFestival = async (params: {
    festivalName: string,
    category: string,
    address: string,
    city: string,
    state: string,
    country: string,
    zipcode: string,
    startDate: string,
    endDate: string,
    price: string,
    festivalDescription: string,
    festivalOwner: string,
    contactNumber: string,
    websiteURL: string,
    facebookURL: string,
    twitterURL: string,
    instagramURL: string,
    otherSocialLink: string,
    Amenities : any,
    Vendors: any,
    Schedule: any,
    timeandSchedule: any,
    _festivalImages: any,
    publishingStatus: any
  }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let password: string = randomstring.generate(6);
        let response: createUserFunctionResponse = await Axios.post(
          "festivals/",
          {
            festivalName: params.festivalName,
            category: params.category,
            address: params.address,
            city: params.city,
            state: params.state,
            country: params.country,
            zipcode: params.zipcode,
            startDate: params.startDate,
            endDate: params.endDate,
            price: params.price,
            festivalDescription: params.festivalDescription,
            festivalOwner: params.festivalOwner,
            contactNumber: params.contactNumber,
            websiteURL: params.websiteURL,
            facebookURL: params.facebookURL,
            twitterURL: params.twitterURL,
            instagramURL: params.instagramURL,
            otherSocialLink: params.otherSocialLink,
            Amenities : params.Amenities,
            Vendors: params.Vendors,
            Schedule: params.Schedule,
            timeandSchedule: params.timeandSchedule,
            _festivalImages: params._festivalImages,
            publishingStatus: params.publishingStatus
          }
        );
        console.log(response);
        if (response.data.status) {
          resolve(response);
        } else {
          reject(response);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };
  editFestival = async (params: {
    festivalId: string,
    festivalName: string,
    category: string,
    address: string,
    city: string,
    state: string,
    country: string,
    zipcode: string,
    startDate: string,
    endDate: string,
    price: string,
    festivalDescription: string,
    festivalOwner: string,
    contactNumber: string,
    websiteURL: string,
    facebookURL: string,
    twitterURL: string,
    instagramURL: string,
    otherSocialLink: string,
    Amenities : any,
    Vendors: any,
    Schedule: any,
    timeandSchedule: any,
    _festivalImages: any,
    publishingStatus: any,
  }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let password: string = randomstring.generate(6);
        let response: createUserFunctionResponse = await Axios.put(
          `festivals/${params.festivalId}`,
          {
            festivalName: params.festivalName,
            category: params.category,
            address: params.address,
            city: params.city,
            state: params.state,
            country: params.country,
            zipcode: params.zipcode,
            startDate: params.startDate,
            endDate: params.endDate,
            price: params.price,
            festivalDescription: params.festivalDescription,
            festivalOwner: params.festivalOwner,
            contactNumber: params.contactNumber,
            websiteURL: params.websiteURL,
            facebookURL: params.facebookURL,
            twitterURL: params.twitterURL,
            instagramURL: params.instagramURL,
            otherSocialLink: params.otherSocialLink,
            Amenities : params.Amenities,
            Vendors: params.Vendors,
            Schedule: params.Schedule,
            timeandSchedule: params.timeandSchedule,
            _festivalImages: params._festivalImages,
            publishingStatus: params.publishingStatus,
          }
        );
        console.log(response);
        if (response.data.status) {
          resolve(response);
        } else {
          reject(response);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };
  deleteFestival = async (festivalId: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: createUserFunctionResponse = await Axios.delete(
          `festivals/${festivalId}`
        );
        console.log(response);
        if (response.data.status) {
          resolve(response);
        } else {
          reject(response);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };
  getVendorsAndOwners = async (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await Axios.get("getVendorsAndOwners");
        console.log(response);
        if (response.data.message === "success") {
          resolve(response.data.results);
        } else {
          reject(response.data.error);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };
  getAllFestivalsByList = async (
    limit: number,
    page: number,
    search: string,
    sort: string,
    filter: string,
    type: any,
  ): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let url = "festivals/"+filter;
        if (sort != "") url += "/" + sort;
        if (search != "") {
          url += "?s=" + search + "&limit=" + limit + "&page=" + page;
        } else {
          url += "?limit=" + limit + "&page=" + page;
        }
        if(type === undefined){
          url +="&type=all"
        }else{
          url +="&type="+type
        }
        let response = await Axios.get(url);
        console.log(response);
        if (response.data.message === "success") {
          resolve(response.data.results);
        } else {
          reject(response.data.error);
        }
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  };


}

export default new Api();
