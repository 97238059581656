export const FESTIVAL_CREATE = "FESTIVAL_CREATE";
export const FESTIVAL_CREATE_SUCCESS = "FESTIVAL_CREATE_SUCCESS";
export const FESTIVAL_CREATE_ERROR = "FESTIVAL_CREATE_ERROR";
export const FESTIVAL_EDIT = "FESTIVAL_EDIT";
export const FESTIVAL_EDIT_SUCCESS = "FESTIVAL_EDIT_SUCCESS";
export const FESTIVAL_EDIT_ERROR = "FESTIVAL_EDIT_ERROR";
export const FESTIVAL_DELETE = "FESTIVAL_DELETE";
export const FESTIVAL_DELETE_SUCCESS = "FESTIVAL_DELETE_SUCCESS";
export const FESTIVAL_DELETE_ERROR = "FESTIVAL_DELETE_ERROR";
export const FESTIVAL_LIST = "FESTIVAL_LIST";
export const FESTIVAL_LIST_SUCCESS = "FESTIVAL_LIST_SUCCESS";
export const FESTIVAL_LIST_ERROR = "FESTIVAL_LIST_ERROR";
export const FESTIVAL_DELETE_SINGLE = "FESTIVAL_DELETE_SINGLE";
