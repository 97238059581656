export const VENDOR_CREATE = "VENDOR_CREATE";
export const VENDOR_CREATE_SUCCESS = "VENDOR_CREATE_SUCCESS";
export const VENDOR_CREATE_ERROR = "VENDOR_CREATE_ERROR";
export const VENDOR_EDIT = "VENDOR_EDIT";
export const VENDOR_EDIT_SUCCESS = "VENDOR_EDIT_SUCCESS";
export const VENDOR_EDIT_ERROR = "VENDOR_EDIT_ERROR";
export const VENDOR_DELETE = "VENDOR_DELETE";
export const VENDOR_DELETE_SUCCESS = "VENDOR_DELETE_SUCCESS";
export const VENDOR_DELETE_ERROR = "VENDOR_DELETE_ERROR";
export const VENDOR_LIST = "VENDOR_LIST";
export const VENDOR_LIST_SUCCESS = "VENDOR_LIST_SUCCESS";
export const VENDOR_LIST_ERROR = "VENDOR_LIST_ERROR";
export const VENDOR_DELETE_SINGLE = "VENDOR_DELETE_SINGLE";
export const VENDOR_SEND_INVITE = "VENDOR_SEND_INVITE";
export const VENDOR_SEND_INVITE_SUCCESS = "VENDOR_SEND_INVITE_SUCCESS";
export const VENDOR_SEND_INVITE_ERROR = "VENDOR_SEND_INVITE_ERROR";
export const VENDOR_EDIT_SINGLE = "VENDOR_EDIT_SINGLE";
