import * as React from "react";
import { DatePickerComponent,TimePickerComponent  } from '@syncfusion/ej2-react-calendars';
import Select, { ValueType } from "react-select";
 
import closeBtn from "../../assets/images/close-button.svg";
import BannerDefault from "../../assets/images/upload.svg";
import arrowgreen from "../../assets/images/arrow-green.png";
import { Link } from "react-router-dom";
import StarRatingComponent from 'react-star-rating-component';
import facebook from "../../assets/images/facebook.svg";
import twitter from "../../assets/images/twitter.svg";
import instagram from "../../assets/images/instagram.svg";
import topicons from "../../assets/images/top-icons.png";

import headerp from "../../assets/images/headerp.png";
import top_v_btn from "../../assets/images/top_v_btn.png";
import * as _ from "lodash";
import { Dropdown, Modal, Tabs, Tab } from "react-bootstrap";
import { CheckBox } from "../../styled-components/checkboxes/index";
import moment from "moment";


export interface IFestivalCreateProps {
  festivalName: string;
  category?: ValueType<{ value: string; label: string }>;
  payment?: ValueType<{ value: string; label: string }>;
  country?: ValueType<{ value: string; label: string }>;
  state?: ValueType<{ value: string; label: string }>;
  city: string;
  address: string;
  zipcode: string;
  startDate: string;
  endDate: string;
  price: string;
  festivalDescription: string;
  contactNumber: string;
  websiteURL: string;
  facebookURL: string;
  twitterURL: string;
  instagramURL: string;
  otherSocialLink: string;  
  festivalOwner: ValueType<{ value: string; label: string }>;
  vendorOwnersList: any;
  ScheduleFormTitle: string;
  ScheduleFormIndex: any;
  ScheduleFormStartTime: string;
  ScheduleFormEndTime: string;
  ScheduleFormDescription: string;
  ScheduleFormVenue: string;
  scheduleModal: boolean;
  handleScheduleShow: any;
  handleScheduleClose: any;
  handleScheduleEdit: any;
  handleScheduleDelete: any;
  validator: any;
  validatorSchedule: any;
  amenitieOptions: any;
  Amenities: any[];
  checkedAmenities: Map<string, any>;
  checkedVendors: Map<string, any>;
  Vendors: any[];
  Schedule: any[];
  timeandSchedule: any;
  bannerImageUrl: any;
  _festivalImages: string[];
  setStateFromChild: any;
  handleAmenitiesDelete: (id:any) => void;
  handleAmenitiesChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleVendorsChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleVendorsDelete: (id:any) => void;  
  setTextInput: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleSelectChange: (selectedOption: ValueType<{ value: string; label: string }>,name: string) => void;
  handleSubmit: (e: React.FormEvent<HTMLButtonElement>) => void;
  handleSave: (e: React.FormEvent<HTMLButtonElement>) => void;
  handleScheduleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  setBanerFileInput: (e: any) => void;
  _handleBannerImageChange: (e: any) => void;
  handleImageDelete: (e: any) => void;
}

interface IFestivalGroupCreateState {
  checkedAmenities: Map<string, any>;
  checkedVendors: Map<string, any>;
  categorySelected: any;
  paymentSelect: any;
  stateSelected: any;
  _festivalOwners: any;
  countrySelected: any;
  amenitiesModal: any;
  vendorsModal: any;
  startDateModal: any;
  endDateModal: any;
  bannerImage: any;
  bannerImageUrl: any;
}



export default class FestivalView extends React.Component<
  IFestivalCreateProps,
  IFestivalGroupCreateState
> {
  public _owners:any = [];
  public _vendors:any = [];
  public _amenitiesSelectedkeys:any = [];

  constructor(props: any) {
    super(props);
    this.state = {
      checkedAmenities: new Map(),
      checkedVendors: new Map(),
      categorySelected: this.props.category,
      _festivalOwners: this.props.festivalOwner,
      paymentSelect: null,
      stateSelected: this.props.state,
      countrySelected: this.props.country,
      amenitiesModal: false,
      vendorsModal: false,
      startDateModal: null,
      endDateModal: null,
      bannerImage: '',
      bannerImageUrl: '',
    };
    
    if(this.props.vendorOwnersList.length > 0){
      this.props.vendorOwnersList.map((item:any) => {
        if(item.Type.includes("Festival Owners") == true && item.Type.includes("Administrators") == false){
          let obj = { value: item.documentID, label: item.document.firstName+" "+item.document.lastName };
           this._owners.push(obj);  
        }
        if(item.Type.includes("Administrators") == false && item.Type.includes("Festival Vendors") == true ){
          let _obj = { id: item.documentID, label: item.document.businessName,img: item.document.profileImageURL };   
          this._vendors.push(_obj);  
        } 
      });
    }
  }
  componentDidMount() {
    document.title = "View Festival | FestFrwrd - The Futr of FSTIVLS";
  }
  handlestartDateChange = (date: any) => {   
    if(date != null)
    this.props.setStateFromChild("startDate",date.value.toLocaleDateString('en-US'));
  };
  handleendDateChange = (date: any) => {
    if(date != null)
    this.props.setStateFromChild("endDate",date.value.toLocaleDateString('en-US'));
  };
  
  public setDatesArray = (startDate:any, endDate:any) => {
    if(startDate == '' || endDate == ''){
      return [];
    }
    var current = moment(startDate,'MM/DD/YYYY');
    var dates = [];
    while (current.isBefore(endDate)) {
      dates.push(current.format('MM-DD-YYYY'));
      current.add(1, 'days');
    }
    dates.push(moment(endDate,'MM/DD/YYYY').format('MM-DD-YYYY'));
    return dates;
  
  }
  getDates = () => {
    if(this.props.timeandSchedule._dates !== undefined){
      return Object.keys(this.props.timeandSchedule._dates).sort();
    }
    return [];
  }
  handlestartDateSdlChange = (date: any) => { 
    if(date != null)
    this.props.setStateFromChild("ScheduleFormStartTime",date.value.toLocaleTimeString('en-US',{ hour: '2-digit', minute: '2-digit' }));
  };
  handleShendDateSdlChange = (date: any) => {
    if(date != null)
    this.props.setStateFromChild("ScheduleFormEndTime",date.value.toLocaleTimeString('en-US',{ hour: '2-digit', minute: '2-digit' }));
  };

  handlePaymentChange = (paymentSelect: any) => {
    this.setState({ paymentSelect });
  };
  handleStateChange = (stateSelected: any) => {
    this.setState({ stateSelected });
  };
  handleCountryChange = (countrySelected: any) => {
    this.setState({ countrySelected });
  };
  handleCategoryChange = (categorySelected: any) => {
    this.setState({ categorySelected });
  };
  getLocation = () => {
    let location = [];
    if(this.props.address != ''){
      location.push(this.props.address);
    }
    if(this.props.city != ''){
      location.push(this.props.city);
    }
    
    if(this.state.stateSelected != null){
      location.push(this.state.stateSelected.label);
    }
    if(this.props.zipcode != ''){
      if(this.state.countrySelected != null){
        location.push(this.props.zipcode+" "+this.state.countrySelected.value);
      }else{
        location.push(this.props.zipcode);
      }
     
    }
   

    return location.join(", ");
  }

  public handleAmenitiesClose = () => {
    this.setState({ amenitiesModal: false });
  };
  public handleAmenitiesShow = () => {
    this.setState({ amenitiesModal: true});
  };
  public handleVendorsClose = () => {
    this.setState({ vendorsModal: false });
  };
  public handleVendorsShow = () => {
    this.setState({ vendorsModal: true });
  };

  public render() {
    const {
      paymentSelect,
      stateSelected,
      countrySelected,
      categorySelected
    } = this.state;

    let $bannerPreview = null;
    if (this.props._festivalImages.length > 0) {
      this.props._festivalImages.slice(0, this.props._festivalImages.length).map((item:any)=>{
        if(item.isnew !== undefined && item.isnew == true){
        $bannerPreview = (<img src={ item.thmb} />);
        }else{
          $bannerPreview = (<img src={ item.main} />);
        }
      });
    } else {
      $bannerPreview = (<div className="no_banner_img"><img src={BannerDefault} /><span>Upload</span></div>);
    }

    
    return (
        <div>
        
            <form action="">
                <div className="row">
                    <div className="col-md-7">
                        {/* Festival overview section */}
                        <div className="box festival_overview">
                            <h4>Overview</h4>
                            <div className="form-group">
                                <label className="blue_label">Festival Name </label>
                                <p className="input_data">{this.props.festivalName}</p>
                            </div>
                            <div className="form-group select-group">
                                <label className="blue_label">Festival Category </label>
                                <p className="input_data">{(this.state.categorySelected != null ) ? this.state.categorySelected.label : ''}</p>
                            </div>
                            <div className="form-group">
                                <label className="blue_label">Address </label>
                                <p className="input_data">{this.props.address}</p>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                    <label className="blue_label">City </label>
                                    <p className="input_data">{this.props.city}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group select-group">
                                    <label className="blue_label">State </label>
                                    <p className="input_data">{(this.state.stateSelected != null ) ? this.state.stateSelected.label : ''}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                    <label className="blue_label">Zip </label>
                                    <p className="input_data">{this.props.zipcode}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group select-group">
                                    <label className="blue_label">Country </label>
                                    <p className="input_data">{(this.state.countrySelected != null ) ? this.state.countrySelected.label : ''}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group label_block">
                                    <label className="blue_label">Start Date </label>
                                    <p className="input_data">{this.props.startDate}</p>                                   
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group label_block">
                                    <label className="blue_label">End Date </label>
                                    <p className="input_data">{this.props.endDate}</p>
                                    
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                    <label className="blue_label">Price </label>
                                    <p className="input_data">{this.props.price}</p>
                                    
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="blue_label">Festival Description </label>
                                <p className="input_data">{this.props.festivalDescription}</p>
                            </div>

                            <div className="form-group select-group">
                                <label className="blue_label">Festival Owner </label>
                                <p className="input_data">{this.state._festivalOwners != null ? this.state._festivalOwners.label : null   }</p>
                            </div>
                            <div className="form-group">
                                <label className="blue_label">Contact Number </label>
                                <p className="input_data">{this.props.contactNumber}</p>
                            </div>
                            
                        </div>
                         {/* Festival overview section ends */}

                         <div className="box">
                              {/* Festival Vendors section */}
                                <div className="form-group">
                                <label className="banner_upload">
                                    
                                    
                                    <div className="banner_img">
                                    {$bannerPreview}
                                    </div>
                                </label>
                                
                                </div>

                                <div className="banner_images_list">
                                {this.props._festivalImages.map((item:any,index:any) => (
                                    <div className="banner_item">
                                    <img src={item.thmb} />
                                    </div>
                                ))}
                                </div>
                         </div>
                         
                         
                          {/* Festival Amenities section */}
                        <div className="box festival_overview">
                            <h4>Amenities</h4>
                             <div className="amenites-list">                        
                             {this.props.Amenities.map((item:any) => (
                                 <div className="amenites-item">
                                    <div className="amenites-item-inner">
                                     <img src={require(`../../assets/images/${item.icon}`)} alt=""/>
                                     <h5>{item.name}</h5>
                                     <p>{item.description}</p>
                                   </div>
                                 </div>
                              ))}

                            </div>
                        </div>
                         {/* Festival Amenities section ends*/}

                         {/* Festival social  links section */}
                        <div className="box festival_social">
                            <h4>Social Links</h4>
                            <div className="form-group">
                                <label className="blue_label">Website </label>
                                <p className="input_data">{this.props.websiteURL}</p>
                            </div> 
                            <div className="form-group">
                                <label className="blue_label">Twitter </label>
                                <p className="input_data">{this.props.twitterURL}</p>
                            </div> 
                            <div className="form-group">
                                <label className="blue_label">Facebook </label>
                                <p className="input_data">{this.props.facebookURL}</p>
                            </div> 
                            <div className="form-group">
                                <label className="blue_label">Instagram </label>
                                <p className="input_data">{this.props.instagramURL}</p>
                            </div> 
                            <div className="form-group">
                                <label className="blue_label">Other Social Link </label>
                                <p className="input_data">{this.props.otherSocialLink}</p>
                            </div> 
                        </div>
                         {/* Festival social links section ends*/}

                         {/* Festival Vendors section */}
                        <div className="box festival_overview">
                            <h4>Vendors</h4>
                             <div className="vendor-list">
                             {this.props.Vendors.map((item:any) => (
                                <div className="vendor-item">
                                    <div className="vendor-item-inner">
                                    <img src={item.profileImageURL} alt=""/>
                                     <div className="vendor_content">
                                        <div>
                                            <span>Vendor Name</span>
                                            <p className="capitalize">{item.businessName}</p>
                                        </div>
                                        <div>
                                            <span>Category</span>
                                            <p className="capitalize">{item.category.join()}</p>
                                        </div>
                                        <div>
                                            <span>Payment Type</span>
                                            <p className="capitalize">{item.paymentType.join()}</p>
                                        </div>
                                     </div>
                                   </div>
                                 </div>

                             ))}
                            </div>
                        </div>
                         {/* Festival Vendors section ends*/}

                        

                    </div>
                    <div className="col-md-5">
                       

                    <div className="mobile_preview">
                             <p className="preview_title">MOBILE PREVIEW</p>
                            <div className="mobile_preview_inner">
                            <img className="topicons" src={topicons}/>
                                <div className="mobile_preview_container">
                                  <div className="mobile_banner" >
                                    {this.props._festivalImages.length > 0 ? (
                                              $bannerPreview
                                    ):(
                                      <img src={""} alt={""}/>
                                    )}
                                   
                                    <div className="mobile_banner_title">
                                      <h2>{this.props.festivalName !== '' ? this.props.festivalName : "Festival name will be here"}</h2>
                                    </div>
                                  </div>
                                  <div className="mobile_tabs">
                                    <Tabs id="mobile_tabs" defaultActiveKey="overview" >
                                      <Tab eventKey="overview" title="Overview">
                                         <div className="mobile_location border_bottom_preview">
                                             <label className="blue_label">LOCATION </label>
                                             <h4>{this.getLocation() !== '' ? this.getLocation()
                                               : "Festival Location Will Be Here"}</h4>
                                         </div>
                                        
                                         <div className="mobile_overview_info border_bottom_preview">
                                            <div className="mobile_overview_item">
                                                <label className="blue_label">DATE </label>
                                                  <p>{this.props.startDate}</p>
                                            </div>
                                            <div className="mobile_overview_item ">
                                                <label className="blue_label">Time </label>
                                                {/* <p>{this.props.endDate}</p> */}
                                            </div>
                                            <div className="mobile_overview_item ">
                                                <label className="blue_label">PRICE </label>
                                                <p>{this.props.price}</p>
                                            </div>
                                         </div>
                                         <div className="mobile_overview_info border_bottom_preview">
                                            <p className="truncate-overflow">{this.props.festivalDescription}</p>
                                         </div>
                                         
                                         <div className="mobile_amenites">
                                          {this.props.Amenities.map((item:any) => (
                                              <div className="amenites-item">
                                                  <div className="amenites-item-left">
                                                     <img src={require(`../../assets/images/${item.icon}`)} alt=""/>
                                                  </div>
                                                  <div className="amenites-item-right">
                                                  <h5>{item.name}</h5>
                                                  <p>{item.description}</p>
                                                </div>
                                                <div className="amenites-item-right">
                                                     <img src={arrowgreen} alt=""/>
                                                  </div>
                                              </div>
                                            ))}
                                         </div>
                                         <div className="overview_footer ">
                                             <button className="btn-submit"> 
                                              <Link className="buttonlink" to={this.props.websiteURL} >
                                              Event Website
                                                </Link>
                                              </button>
                                             <Link to={this.props.facebookURL} >
                                                <img src={facebook} />
                                              </Link>
                                              <Link to={this.props.twitterURL} >
                                                <img src={twitter} />
                                              </Link>
                                              <Link to={this.props.instagramURL} >
                                                <img src={instagram} />
                                              </Link>
                                         </div>
                                      </Tab>
                                      <Tab eventKey="vendors" title="Vendors">
                                      <img src={top_v_btn}/>
                                        <div className="mobile_vendors_check_list">
                                          {this.props.Vendors != null && this.props.Vendors.map((item:any) => (
                                              <div className="vendor_list_item ">
                                                
                                                <div className="vendor_img">
                                                {(item.profileImageURL != "") ? (
                                                    <img  src={item.profileImageURL} alt={""}></img>
                                                  ): <img  alt={""}></img>}
                                                </div>

                                                <div className="vendor_details">
                                                   <h3>{item.businessName}</h3>
                                                   <div className="rating">
                                                      <StarRatingComponent 
                                                      name="rate1" 
                                                      starCount={5}
                                                      value={4}
                                                      starColor={'#58B5C9'}
                                                      emptyStarColor={'#e2e2e2'}
                                                      />
                                                      <p>97 Ratings</p>
                                                    </div>
                                                   <p className="capitalize">{item.category.join()}</p>
                                                   <p className="capitalize">$ {item.paymentType.join()}</p>
                                                </div>
                                                
                                                  
                                              
                                              </div>
                                            ))}
                                        </div>
                                      </Tab>
                                      <Tab eventKey="schedule" title="Schedule">
                                          <img src={headerp}/>
                                          {this.getDates().map((value:any, index:any) => (
                                          <div>
                                          {this.props.timeandSchedule._dates[value].schedules.map((item:any,index:Number) => (
                                              <div>
                                              <p className="time">{value}</p> 
                                            <table className="mobile_schedule">
                                              <tr>
                                                <td> <span className="time"> <p>{ moment(new Date(item.StartTime)).format("LT") }</p></span></td>
                                                <td>
                                                  <p>{item.title}</p>
                                                  <span>{item.Venue}</span>
                                                </td>
                                                <td>
                                                  <span className="more_icon"></span>
                                                </td>
                                              </tr>
                                            </table>  
                                            </div>                                          
                                        ))}
                                          </div>
                                        )
                                        )}
                                      </Tab>
                                   
                                    </Tabs>
                                  </div>
                                  </div>
                            </div>
                        </div>
                   </div>
                </div>
            </form>
        
        
        <Modal
          className="custom_modal_1"
          show={this.state.amenitiesModal}
          onHide={() => this.handleAmenitiesClose()}
        >
          <Modal.Header>
            <Modal.Title>Amenities</Modal.Title>
            <a className="modalClose" onClick={() => this.handleAmenitiesClose()}>
              <img src={closeBtn}></img>
            </a>
          </Modal.Header>
          <Modal.Body>
             <div className="amenites_check_list">
                 <React.Fragment>
                    {this.props.amenitieOptions.map((item:any) => (
                      <div className="checkBoxButton ">
                        <CheckBox
                          id={item.id}
                          name={item.label}
                          checked={this.props.checkedAmenities.get(item.id)}
                          onChange={this.props.handleAmenitiesChange} 
                        />
                        <label
                          className="checkboxlabel"
                          htmlFor={item.id}
                          key={item.id}
                        >
                          {item.label}
                        </label>
                      </div>
                    ))}
                  </React.Fragment>
             </div>
          </Modal.Body>
          <Modal.Footer>
              <span>{this.props.checkedAmenities.size} Selected</span>
              {this.props.checkedAmenities.size> 0 ? (
                <a className="btn-submit" onClick={() => this.handleAmenitiesClose()}>Submit</a>
              ): (
                <a className="btn-submit disabled" onClick={() => this.handleAmenitiesClose()}>Submit</a>
              )}
          </Modal.Footer>
        </Modal>

        <Modal
          className="custom_modal_1"
          show={this.state.vendorsModal}
          onHide={() => this.handleVendorsClose()}
        >
          <Modal.Header>
            <Modal.Title>Vendors</Modal.Title>
            <div className="icon-input search-icon">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Vendor Business name or Name"
              />
            </div>
            <a className="modalClose" onClick={() => this.handleVendorsClose()}>
              <img src={closeBtn}></img>
            </a>
          </Modal.Header>
          <Modal.Body>
             <div className="vendors_check_list">
                 <React.Fragment>
                    {this._vendors.map((item:any) => (
                      <div className="checkBoxButton ">
                        <CheckBox
                          id={item.id}
                          name={item.label}
                          checked={this.props.checkedVendors.get(item.id)}
                          onChange={this.props.handleVendorsChange}
                        />
                        <label
                          className="checkboxlabel capitalize"
                          htmlFor={item.id}
                          key={item.id}
                        >
                          <img  src={item.img}></img>
                          {item.label}
                        </label>
                      </div>
                    ))}
                  </React.Fragment>
             </div>
          </Modal.Body>
          <Modal.Footer>
              <span>{this.props.checkedVendors.size} Selected</span>
              {this.props.checkedVendors.size> 0 ? (
                <a className="btn-submit" onClick={() => this.handleVendorsClose()}>Add to Festival</a>

              ): (
                <a className="btn-submit disabled" onClick={() => this.handleVendorsClose()}>Add to Festival</a>
              )}
             
          </Modal.Footer>
        </Modal>

        <Modal
          className="custom_modal_1"
          show={this.props.scheduleModal}
          onHide={() => this.props.handleScheduleClose()}
        >
           <form action="" onSubmit={this.props.handleScheduleSubmit}>

          <Modal.Header>
            <Modal.Title>Add Schedule</Modal.Title>
            <a className="modalClose" onClick={() => this.props.handleScheduleClose()}>
              <img src={closeBtn}></img>
            </a>
          </Modal.Header>
          <Modal.Body>
              <div className="form-group">
                  <label className="blue_label">Title </label>
                  <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Title"
                      name={"ScheduleFormTitle"}                                          
                      onChange={this.props.setTextInput}
                      value={this.props.ScheduleFormTitle}
                  />
                  {this.props.validatorSchedule.message(
                    "Title",
                    this.props.ScheduleFormTitle,
                    "required"
                  )}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="blue_label">Start Time </label>
                    {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Select Time"
                        name={"ScheduleFormStartTime"}                                          
                        change={this.props.setTextInput}
                        value={this.props.ScheduleFormStartTime}
                    /> */}
                    <TimePickerComponent value={new Date(this.props.startDate+" "+this.props.ScheduleFormStartTime)} change={this.handlestartDateSdlChange}  placeholder="Select Time" />
                     {this.props.validatorSchedule.message(
                        "StartTime",
                        this.props.ScheduleFormStartTime,
                        "required"
                      )}

                  </div>
                </div>
                <div className="col-md-6">
                <div className="form-group">
                    <label className="blue_label">End Time </label>
                    <TimePickerComponent strictMode={true}  value={new Date(this.props.startDate+" "+this.props.ScheduleFormEndTime)} change={this.handleShendDateSdlChange}  placeholder="Select Time" />
                    {this.props.validatorSchedule.message(
                        "EndTime",
                        this.props.ScheduleFormEndTime,
                        "required"
                      )}
                    {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Select Time"
                        name={"ScheduleFormEndTime"}                                          
                       // onChange={this.props.setTextInput}
                       // value={this.props.ScheduleFormEndTime}
                    />
                    {this.props.validatorSchedule.message(
                        "EndTime",
                        this.props.ScheduleFormEndTime,
                        "required"
                      )} */}
                  </div>
                </div>
              </div>

              <div className="form-group">
                  <label className="blue_label">Description </label>
                  <textarea  className="form-control"
                   name={"ScheduleFormDescription"}                                          
                   onChange={this.props.setTextInput}
                   defaultValue={this.props.ScheduleFormDescription}                  
                   ></textarea>
                   {this.props.validatorSchedule.message(
                        "Description",
                        this.props.ScheduleFormDescription,
                        "required"
                      )}
              </div>

              <div className="form-group">
                  <label className="blue_label">Venue </label>
                  <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Venue"
                      name={"ScheduleFormVenue"}                                          
                      onChange={this.props.setTextInput}
                      value={this.props.ScheduleFormVenue}
                  />
                  {this.props.validatorSchedule.message(
                        "Venue",
                        this.props.ScheduleFormVenue,
                        "required"
                      )}
              </div>
          </Modal.Body>
          <Modal.Footer>
          <button className="btn-submit" type="submit"> Submit </button>
          </Modal.Footer>
          </form>
        </Modal>


      </div>
    );
  }
}
