import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Action } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { ThunkDispatch } from "redux-thunk";
import SimpleReactValidator from "simple-react-validator";
import { NavLink } from "react-router-dom";
import { userFunctionCreate } from "../../state/userFunctionCreate/actions";
import UserFunctionForm from "../../components/Security/UserFunctionForm";
import { userFunctionEdit } from "../../state/userFunctionEdit/actions";
import { AppState } from "../../state/types";

interface IUserFunctionCreateProps {}

interface StateProps {
  isLoading: boolean;
  success: boolean;
  hasErrored: boolean;
  response: any;
}

interface DispatchProps {
  userFunctionEdit: (
    name: string,
    description: string,
    functionId: string
  ) => void;
}

interface IUserFunctionCreateState {
  functionName: string;
  functionDescription: string;
}

type Props = DispatchProps &
  IUserFunctionCreateProps &
  RouteComponentProps &
  StateProps;

class UserFunctionEdit extends React.Component<
  Props,
  IUserFunctionCreateState
> {
  private validator: any;
  state = {
    functionName: "",
    functionDescription: "",
    isUpdate: true
  };
  constructor(props: Props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    if(!this.props.location.state){
      this.props.history.push("/dashboard");
    }else{
      this.state = {
        functionName: this.props.location.state.name,
        functionDescription: this.props.location.state.description,
        isUpdate: true
      };
    }
  }
  componentDidMount() {
    document.title = "Edit Function | FestFrwrd - The Futr of FSTIVLS";
  }
  componentDidUpdate(prevProps: Props, prevState: IUserFunctionCreateState) {
    if (prevProps.response !== this.props.response && this.props.success) { 
      this.props.history.goBack();
      // toast.update(1, {
      //   render: this.props.response.data.message,
      //   type: toast.TYPE.SUCCESS,
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   onClose:() =>{
      //     this.props.history.goBack();
      //   }
      // });    
    }
    if (
      prevProps.hasErrored !== this.props.hasErrored &&
      this.props.hasErrored
    ) {   
      toast.error((this.props.response.error == null) ? this.props.response.message : this.props.response.error);    
      // toast.update(1, {
      //   render: (this.props.response.error == null) ? this.props.response.message : this.props.response.error,
      //   type: toast.TYPE.ERROR,
      //   autoClose: 2000,
      //   hideProgressBar: false
      // });
    }
  }

  setTextInput = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    } as Pick<IUserFunctionCreateState, keyof IUserFunctionCreateState>);
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (this.validator.allValid()) {
      this.props.userFunctionEdit(
        this.state.functionName,
        this.state.functionDescription,
        this.props.location.state.id
      );
    } else {
      toast.dismiss(2);
      toast.warn("Please fill in all required fields!",{toastId:2});
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  public render() {
    return (
        <div>
          <div className="inner-title">
            <h3>Edit FUNCTION</h3>
            <div className="path_navigation">
              <ul>
                <li>
                  <NavLink to="/security">SECURITY Management</NavLink>
                </li>
                <li>
                  <NavLink to="/security/UserFunctionsManagement">VIEW USER FUNCTIONS</NavLink>
                </li>
                <li>
                  <span>Edit FUNCTION</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="white_panel form_pading">
            <UserFunctionForm
             isUpdate={this.state.isUpdate}
              handleSubmit={e => this.handleSubmit(e)}
              setTextInput={e => this.setTextInput(e)}
              functionName={this.state.functionName}
              functionDescription={this.state.functionDescription}
              validator={this.validator}
            />
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  response: state.userFunctionsEdit.response,
  isLoading: state.userFunctionsEdit.isLoading,
  success: state.userFunctionsEdit.success,
  hasErrored: state.userFunctionsEdit.hasErrored
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, Action>
): DispatchProps => ({
  userFunctionEdit: (name, description, functionId) =>
    dispatch(userFunctionEdit(name, description, functionId))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserFunctionEdit);
