import * as ActionTypes from "./actionTypes";
import { ProfileState, ProfileCreateActionTypes } from "./types";

const initialState: ProfileState = {
  ProfileEdit: {
    isLoading: false,
    hasErrored: false,
    success: false
  }
};

export default (
  state = initialState,
  action: ProfileCreateActionTypes
): ProfileState => {
  switch (action.type) {
    
    case ActionTypes.PROFILE_EDIT_SUCCESS:
      return {
        ...state,
        ProfileEdit: {
          ...state.ProfileEdit,
          success: action.payload,
          isLoading: false
        }
      };

    case ActionTypes.PROFILE_EDIT_ERROR:
      return {
        ...state,
        ProfileEdit: {
          ...state.ProfileEdit,
          isLoading: false,
          hasErrored: true
        }
      };

    case ActionTypes.PROFILE_EDIT_SINGLE:
      return {
        ...state,
        ProfileEdit: {
          ...state.ProfileEdit,
          success: action.payload,
          isLoading: false
        }
      };

    default:
      return state;
  }
};
