import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, RouteComponentProps,Link } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { getVendorsAndOwners } from "../../state/userManagement/actions";
import * as _ from "lodash";
import SimpleReactValidator from "simple-react-validator";
import FestivalForm from "../../components/Festivals/festivalCreate";
import { ValueType } from "react-select";
import { AppState } from "../../state/types";
import { festivalCreate } from "../../state/festivals/actions";
import { amenitieOptions } from "./../../constants/amenitieOptions";
import arrayMove from 'array-move';
import { toast } from "react-toastify";
import moment from "moment";



interface DispatchProps {
  getVendorsAndOwners: () => void;
  festivalCreate: (vendro: {
    festivalName: string;
    category: string;
    address: string;
    city: string;
    state: string;
    country: string;
    zipcode: string;
    startDate: string;
    endDate: string;
    price: string;
    festivalDescription: string;
    festivalOwner: string;
    contactNumber: string;
    websiteURL: string;
    facebookURL: string;
    twitterURL: string;
    instagramURL: string;
    otherSocialLink: string;
    Amenities : any;
    Vendors: any;
    Schedule: any;
    timeandSchedule: any;
    _festivalImages: any;
    publishingStatus: any;
  }) => void;
}
interface StateAmenities {
  checkedAmenities: Map<string, string>;
}
interface StateVendors{
  checkedVendors: Map<string, string>;
}
interface StateProps {
  vendorOwnersList:  any;
  checkedAmenities: Map<string, string>;
  checkedVendors: Map<string, string>;
  isLoading: boolean;
  success: boolean;
  hasErrored: boolean;
  response: any;
}

// interface Props {}
interface State {
  festivalName: string;
  category?: ValueType<{ value: string; label: string }>;
  payment?: ValueType<{ value: string; label: string }>;
  country?: ValueType<{ value: string; label: string }>;
  state?: ValueType<{ value: string; label: string }>;
  city: string;
  address: string;
  zipcode: string;
  startDate: string;
  endDate: string;
  price: string;
  festivalDescription: string;
  festivalOwner: ValueType<{ value: string; label: string }>;
  contactNumber: string;
  websiteURL: string;
  facebookURL: string;
  twitterURL: string;
  instagramURL: string;
  otherSocialLink: string;
  Amenities : any[];
  Vendors: any;
  Schedule: any,
  timeandSchedule: any,
  scheduleModal: any,  
  scheduleModalPopUp: any,  
  ScheduleFormIndex: any,
  ScheduleFormTitle: string,
  ScheduleFormStartTime: string,
  ScheduleFormEndTime: string,
  ScheduleFormDescription:string,
  ScheduleFormVenue: string,
  checkedAmenities:  Map<string, string>;
  checkedVendors:  Map<string, string>;
  bannerImageUrl: any;
  _festivalImages: string[];
  activeSchedule: any;
  commonStartTime: any;
  commonEndTime: any;
}

type Props = DispatchProps & StateProps & StateAmenities & StateVendors& RouteComponentProps;
export class FestivalCreate extends Component<Props, State> {
  private validator: any;
  private validatorSchedule: any;
  private _ownersList: any;
  public _owners:any = [];
  public _vendors:any = [];
  state = {
    festivalName: "",
    category: null as any,
    address: "",
    city: "",
    state: null as any,
    country: null as any,
    zipcode: "",
    startDate: "",
    endDate:  "",
    price: "",
    festivalDescription: "",
    festivalOwner:  null as any,
    contactNumber: "",
    websiteURL: "",
    facebookURL: "",
    twitterURL: "",
    instagramURL: "",
    otherSocialLink: "",
    Amenities : [],
    Vendors: [],
    Schedule: [],
    timeandSchedule: {
      isPerDay:false,
      _dates:{}
    },
    checkedAmenities: new Map(),
    checkedVendors: new Map(),
    ScheduleFormIndex: -1,
    ScheduleFormTitle: "",
    ScheduleFormStartTime: "",
    ScheduleFormEndTime: "",
    ScheduleFormDescription:"",
    ScheduleFormVenue: "",
    scheduleModal: false,
    scheduleModalPopUp: false,
    bannerImageUrl: [],
    _festivalImages: [],
    activeSchedule: '',
    commonStartTime: '',
    commonEndTime: ''
  };

  constructor(props: Props) {
    super(props);  
    this.validator = new SimpleReactValidator({ autoForceUpdate: this,  validators: {
      phoneNumber: {
        message: 'The :attribute must be a valid phone Number',
        rule: (val:any, params:any, validator:any) => {
          return validator.helpers.testRegex(val,/^((\([0-9]{3}\))|[0-9]{3})[\s\-][\0-9]{3}[-][0-9]{4}$/i) && params.indexOf(val) === -1
        },
        required: true
      }
    } });
    this.validatorSchedule = new SimpleReactValidator({ autoForceUpdate: this });
    this.handleAmenitiesChange = this.handleAmenitiesChange.bind(this);
    this.handleAmenitiesDelete = this.handleAmenitiesDelete.bind(this);
    this.pushToAmenities = this.pushToAmenities.bind(this);
    this.handleVendorsChange = this.handleVendorsChange.bind(this);
    this.handleVendorsDelete = this.handleVendorsDelete.bind(this);
    this.pushToVendors = this.pushToVendors.bind(this);
    this.setBanerFileInput = this.setBanerFileInput.bind(this);
    this._handleBannerImageChange = this._handleBannerImageChange.bind(this);
    this.handleImageDelete = this.handleImageDelete.bind(this);
    this.setIsPerDay = this.setIsPerDay.bind(this);
    this.handleShStartDateTimeSdlChange = this.handleShStartDateTimeSdlChange.bind(this);
    this.handleShendDateTimeSdlChange = this.handleShendDateTimeSdlChange.bind(this);
    this.handleShCommonDateTimeSdlChange = this.handleShCommonDateTimeSdlChange.bind(this);
    this.setSchedulesActive = this.setSchedulesActive.bind(this);
    this.handleScheduleSubClose = this.handleScheduleSubClose.bind(this);
    this.handleScheduleSubShow = this.handleScheduleSubShow.bind(this);
  }
  componentDidMount() {
    document.title = "New Festival | FestFrwrd - The Futr of FSTIVLS";
  }
  componentWillMount() {
    this.props.getVendorsAndOwners();
  }
  componentDidUpdate(prevProps: Props) {
    console.log("new State: ",this.state);
    if (prevProps.response !== this.props.response && this.props.success) { 
      toast.dismiss(1);     
        var hashval = window.location.hash;
        var f = 'active';
        if(hashval != ''){
          hashval = hashval.replace("#","");
          f = hashval;
        }
        this.props.history.push('/FestivalManagement#'+f); 

      
      // toast.update(1, {
      //   render: this.props.response.data.message,
      //   type: toast.TYPE.SUCCESS,
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   onClose:() =>{
      //     this.props.history.goBack();
      //   }
      // });    
    }
    if (
      prevProps.response !== this.props.response &&
      this.props.hasErrored
    ) {      
    
      toast.error((this.props.response.error == null) ? this.props.response.message : this.props.response.error);      

    }
    if(prevProps.vendorOwnersList !== this.props.vendorOwnersList){
      this.pushToVendors();
    }
  }
  setTextInput = <T extends keyof State>(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const newState = {
      [event.currentTarget.name]: event.currentTarget.value
    };
    this.setState(newState as { [P in T]: State[P] });
  };
  setStateFromChild  = <T extends keyof State> (name:string,value:any) => {
    if(this.isDatesValidSchedules(name,value) == true){
      const newState = {
        [name]: value
      };
      this.setState(newState as { [P in T]: State[P] },()=>{
        this.reDrawArray();
      });
    }
  }
  handleSelectChange = <T extends keyof State>(
    selectedOption: ValueType<{ value: string; label: string }>,
    name: string
  ) => {
    const newState = {
      [name]: selectedOption
    };
    this.setState(newState as { [P in T]: State[P] }, () =>
      console.log(this.state)
    );
  };
  pushToAmenities = <T extends keyof State>() => {
    let _amenities:any = [];
    let keys = [...this.state.checkedAmenities.keys()];
    keys.map((key, index) => {    
      var n = _.find(amenitieOptions, {id:key});
      _amenities.push(n);
    });
    const newState = {
      Amenities: _amenities
    };
    this.setState(newState as { [P in T]: State[P] }, () =>
      console.log(this.state)
    );
  }
  getSchedulesActive = () => {
    return this.state.activeSchedule;
  }
  setSchedulesActive = (date:any) => {
     this.setState({ activeSchedule: date });
  }
  isValidSchedules = () => {
    try {  
      var obj:any =  this.state.timeandSchedule;
      var dates = this.state.timeandSchedule._dates;
      for (var key in dates) { 
        if (obj._dates.hasOwnProperty(key)) {
            if(obj._dates[key].startTime == '' || obj._dates[key].endTime == ''){              
              return false;
            }            
        }
      }
      return true;
    } catch (error) {
      return false;
    }
  }
  reDrawArray = ()=>{
    let dates_ = this.getDatesBetween(this.state.startDate,this.state.endDate);
    let _obj:any = {};
    let times:any = this.state.timeandSchedule;
    _obj.isPerDay = times.isPerDay;
    _obj._dates = {};
    for(let _d of dates_){
      if(times._dates[_d] == undefined){ 
        if(times.isPerDay == false){    
          _obj._dates[_d] = { 
            startTime:this.state.commonStartTime,
            endTime:this.state.commonEndTime,
            schedules:[]
          };
        }else{
          _obj._dates[_d] = { 
            startTime:'',
            endTime:'',
            schedules:[]
          };
        }       
      }else{
        _obj._dates[_d] = times._dates[_d];
      }
     
    }
    this.setState({timeandSchedule:_obj});

  }
  getDatesBetween = (startDate:any, endDate:any) => {
    if(startDate == '' || endDate == ''){
      return [];
    }
    var current = moment(startDate,'MM/DD/YYYY');
    var dates = [];
    while (current.isBefore(endDate)) {
      dates.push(current.format('MM-DD-YYYY'));
      current.add(1, 'days');
    }
    dates.push(moment(endDate,'MM/DD/YYYY').format('MM-DD-YYYY'));
    return dates;
  
  }
  isDatesValidSchedules = (name:any,value:any) => {
    // try {
       if(this.state.startDate == undefined || this.state.endDate == undefined){
         return true;
       } 
       if(name == 'startDate'  && this.state.startDate !== undefined){
         var newStartDate:any = moment(moment(value).format('MM-DD-YYYY'),'MM-DD-YYYY');
         var endDteState = moment(moment(this.state.endDate).format('MM-DD-YYYY'),'MM-DD-YYYY');      
         if(endDteState.isBefore(newStartDate) == true  && !endDteState.isSame(newStartDate)){
          toast.dismiss(3);
          toast.error("The End date should be greater than Start date.",{toastId:3});
           return false;
         }
         var _dates = this.getDatesBetween(this.state.startDate,value);
         var obj:any =  this.state.timeandSchedule;
         if(_dates.length > 0){
           for(let _d of _dates){
             if(obj._dates[_d] !== undefined && obj._dates[_d].schedules !== undefined && obj._dates[_d].schedules.length > 0){
               toast.dismiss(3);
               toast.error("This date can not be changed as an Event has been scheduled outside of this date range. Please either revise your selected date below or the Event(s) scheduled during your selected date range.!",{toastId:3});
               return false;
             }
           }
         }
       }
       if(name == 'endDate' && this.state.endDate !== undefined){
         var newEndDate:any = moment(moment(value).format('MM-DD-YYYY'),'MM-DD-YYYY');
         var startDate = moment(moment(this.state.startDate).format('MM-DD-YYYY'),'MM-DD-YYYY');      
         if(startDate.isBefore(newEndDate) == false && !startDate.isSame(newEndDate)){
          toast.dismiss(3);
          toast.error("The End date should be greater than Start date.",{toastId:3});
           return false;
         }
         var _dates = this.getDatesBetween(value,this.state.endDate);
         var obj:any =  this.state.timeandSchedule;
         if(_dates.length > 0){
           for(let _d of _dates){
             if(obj._dates[_d] !== undefined && obj._dates[_d].schedules !== undefined && obj._dates[_d].schedules.length > 0){
               toast.dismiss(3);
               toast.error("This date can not be changed as an Event has been scheduled outside of this date range. Please either revise your selected date below or the Event(s) scheduled during your selected date range.!",{toastId:3});
               return false;
             }
           }
         }
       }
       return true;
   }
  /********Schedules Start Time Set Per Day*/
  handleShStartDateTimeSdlChange = (date:any,key:any,p:any) => {
    if(date != null && date.value != null ){    
      var value = date.value.toLocaleTimeString('en-US',{ hour: '2-digit', minute: '2-digit' });
      var obj:any =  this.state.timeandSchedule;
      obj._dates[key].startTime = value;
      this.setState({
        timeandSchedule: obj
      },()=>{
        if(this.isValidTimeSchedules() === false){ 
          var obj:any =  this.state.timeandSchedule;
          obj._dates[key].startTime = p;
          this.setState({
            timeandSchedule: obj
          });
        }
      });
    }
  }
   /********Schedules End Time Set */
  handleShendDateTimeSdlChange = (date:any,key:any,p:any) => { 
    if(date != null && date.value != null ){    
      var value = date.value.toLocaleTimeString('en-US',{ hour: '2-digit', minute: '2-digit' });
      var obj:any =  this.state.timeandSchedule;
      obj._dates[key].endTime = value;
      this.setState({
        timeandSchedule: obj
      },()=>{
        if(this.isValidTimeSchedules() === false){ 
          var obj:any =  this.state.timeandSchedule;
          obj._dates[key].endTime = p;
          this.setState({
            timeandSchedule: obj
          });
        }
      });
    }
  }
  /********Schedules Coommon Time Set */
  handleShCommonDateTimeSdlChange = (date:any,type:any) => {
   if(type == "start"){
      if(date != null && date.value !== null){    
        var value = date.value.toLocaleTimeString('en-US',{ hour: '2-digit', minute: '2-digit' });
        var obj:any =  this.state.timeandSchedule;
        var bkobj:any =   Object.assign({}, this.state.timeandSchedule);
        var bkcs = this.state.commonStartTime;
        var dates = Object.keys(this.state.timeandSchedule._dates).sort();
        for(let _d of dates){
          obj._dates[_d].startTime = value;
        }
        this.setState({
          commonStartTime: value,
          timeandSchedule: obj
        },()=>{
          if(this.isValidTimeSchedules() === false){
            this.setState({
              commonStartTime: bkcs,
              timeandSchedule: bkobj
            });
          }
        });
      }
    } else{
      var value = date.value.toLocaleTimeString('en-US',{ hour: '2-digit', minute: '2-digit' });
      var obj:any =  this.state.timeandSchedule;
      var bkobj:any =   Object.assign({}, this.state.timeandSchedule);
      var bkce = this.state.commonEndTime;
      var dates = Object.keys(this.state.timeandSchedule._dates).sort();
      for(let _d of dates){
        obj._dates[_d].endTime = value;
      }
      this.setState({
        commonEndTime: value,
        timeandSchedule: obj
      },()=>{
        if(this.isValidTimeSchedules() === false){
          this.setState({
            commonEndTime: bkce,
            timeandSchedule: bkobj
          });
        }
      });
    }
    
  }
    /********Schedules time verifivcation Time Set */

  isValidTimeSchedules = () => { 
    try { 
      var mag = ""; 
      var obj:any =  this.state.timeandSchedule;
      var dates = Object.keys(this.state.timeandSchedule._dates).sort();
      var result = true;
      for (var key of dates) {  
        if (obj._dates.hasOwnProperty(key)) {  
            if(obj._dates[key].schedules !== undefined && obj._dates[key].schedules.length > 0){
              
                for(let single of obj._dates[key].schedules){
                  var rootStart:any = moment(moment(key+" "+obj._dates[key].startTime).format('hh:mm A'),'hh:mm A');
                  var cStartTime = moment(moment(single.StartTime).format('hh:mm A'),'hh:mm A');
                
                  if(rootStart.isBefore(cStartTime) == false){
                    mag = ("This time can not be changed as an Event has been scheduled during this window. Please either revise your selected time below or the Events scheduled during your selected time range. ");
                    result = false;  
                  }

                  
                  var rootEndTime:any = moment(moment(key+" "+obj._dates[key].endTime).format('hh:mm A'),'hh:mm A');
                  var cEndTime = moment(moment(single.EndTime).format('hh:mm A'),'hh:mm A');
                
                  if(rootEndTime.isBefore(cEndTime) == true){
                    mag = ("This time can not be changed as an Event has been scheduled during this window. Please either revise your selected time below or the Events scheduled during your selected time range. ");
                    result = false;  
                  }
                }
            }            
        }
      }
      if(result == false)
      toast.error(mag);
      return result;
    } catch (error) {
      console.log(error);
      return false;
    }

  }
  setDatesArray = () => {
    if(this.state.startDate == '' || this.state.endDate == ''){
      return false;
    }
    var current = moment(this.state.startDate,'MM/DD/YYYY');
    var _current = moment(this.state.startDate,'MM/DD/YYYY');
    var dates = [];
    
    var obj:any = this.state.timeandSchedule;
    while (current.isBefore(this.state.endDate)) {
      if(!obj._dates.hasOwnProperty(current.format('MM-DD-YYYY'))){
        obj._dates[current.format('MM-DD-YYYY')] = {
          startTime:'',
          endTime:'',
          schedules:[]
        }
      }      
      current.add(1, 'days');
    }
    if(!obj._dates.hasOwnProperty(moment(this.state.endDate,'MM/DD/YYYY').format('MM-DD-YYYY'))){
      obj._dates[moment(this.state.endDate,'MM/DD/YYYY').format('MM-DD-YYYY')] = {
        startTime:'',
        endTime:'',
        schedules:[]
      }
    }


   
  this.setState((state, props) => {
    this.setSchedulesActive(_current.format('MM-DD-YYYY'));
    return {
      timeandSchedule: obj
    };
  });
  }
 
  setIsPerDay(e: React.ChangeEvent<HTMLInputElement>) {
     this.setDatesArray();
    const isChecked = e.target.checked;
    let obj:any = this.state.timeandSchedule;
    obj.isPerDay = isChecked;
    this.setState({ timeandSchedule: obj },()=>{
      if(isChecked === true){
          var obj:any =  this.state.timeandSchedule;
          var dates:any = Object.keys(this.state.timeandSchedule._dates).sort();
          var _startTime:any = obj._dates[dates[0]];
          var startTime = _startTime.startTime;
          var _endTime:any = obj._dates[dates[0]];
          var endTime = _endTime.endTime;        
          for(let _d of dates){
            obj._dates[_d].startTime = startTime;
            obj._dates[_d].endTime = endTime;
          }
          this.setState( {
              commonStartTime: startTime,
              commonEndTime: endTime,
              timeandSchedule: obj
          });
      } 
    });
  }
  handleScheduleSubmit = (e: React.FormEvent<HTMLFormElement>) => { 
    e.preventDefault();
    if (this.validatorSchedule.allValid()) { 
      var _obj:any =  this.state.timeandSchedule;
      let _list:any = _obj._dates[this.state.activeSchedule].schedules; 
      let index = _list.length;
      if(this.state.ScheduleFormIndex >= 0){
        index = this.state.ScheduleFormIndex;
      }
      let obj = { 
        index: index,
        title: this.state.ScheduleFormTitle,
        StartTime:this.state.ScheduleFormStartTime,
        EndTime: this.state.ScheduleFormEndTime,
        Description: this.state.ScheduleFormDescription,
        Venue: this.state.ScheduleFormVenue
      };
      if(this.state.ScheduleFormIndex >= 0){
        _list[this.state.ScheduleFormIndex] = obj;
      }else{
        _list.push(obj);
      }
      _obj._dates[this.state.activeSchedule].schedules = _list;
      
      this.setState({ timeandSchedule: _obj},()=>{
        console.log(this.state)
        this.handleScheduleClose();
        this.handleScheduleReset();
      });
     }
    else {
      this.validatorSchedule.showMessages();
      this.forceUpdate();
    }
  }
  handleScheduleReset = () => {
    this.setState({ ScheduleFormTitle: "" },()=>{
      this.setState({ ScheduleFormIndex: -1});
      this.setState({ ScheduleFormStartTime: "" });
      this.setState({ ScheduleFormEndTime: "" });
      this.setState({ ScheduleFormDescription: "" });
      this.setState({ ScheduleFormVenue: "" });
      this.validatorSchedule.hideMessages();
    });
  }
  handleScheduleEdit = (item:any,index:any) => { 
    this.setState({ ScheduleFormTitle: item.title },()=>{     
      this.setState({ ScheduleFormStartTime: item.StartTime });
      this.setState({ ScheduleFormEndTime: item.EndTime });
      this.setState({ ScheduleFormDescription: item.Description });
      this.setState({ ScheduleFormVenue: item.Venue });
      this.setState({ ScheduleFormIndex: item.index },()=>{
        this.setState({ scheduleModal: true });
      });
     
    });
    this.validatorSchedule.hideMessages();
  };
  handleScheduleDelete = (item:any,index:any) => { 
    var _obj:any =  this.state.timeandSchedule;
    let _list:any = _obj._dates[this.state.activeSchedule].schedules; 
    _list.splice(index, 1);
    _obj._dates[this.state.activeSchedule].schedules = _list;
    this.setState({ timeandSchedule: _obj });
  }

  handleScheduleClose = () => {
    this.setState({ scheduleModal: false });
    this.validatorSchedule.hideMessages();
  };
  handleScheduleShow = () => {
    this.handleScheduleReset();
    this.setState({ scheduleModal: true });
  };

  handleScheduleSubClose = () => {
    this.setState({ scheduleModalPopUp: false });
  };
  handleScheduleSubShow = () => {
    this.setState({ scheduleModal: false });
    this.setState({ scheduleModalPopUp: true });
  };
  
  handleAmenitiesChange(e: React.ChangeEvent<HTMLInputElement>) { 
    const item = e.target.id;
    const name = e.target.name;
    const isChecked = e.target.checked;
    if (!isChecked) {
      this.setState((state, props) => {
        state.checkedAmenities.delete(item);
        this.pushToAmenities();
        return {
          checkedAmenities: state.checkedAmenities
        };
      });
    } else {
      this.setState((state, props) => {
        state.checkedAmenities.set(item, name);
        this.pushToAmenities();
        return {
          checkedAmenities: state.checkedAmenities
        };
      });
    }

  }
  handleAmenitiesDelete = (item: any) =>{    
    this.setState((state, props) => {
      state.checkedAmenities.delete(item);
      this.pushToAmenities();
      return {
        checkedAmenities: state.checkedAmenities
      };
    });
  } 
  pushToVendors = <T extends keyof State>() => {
    if(this.props.vendorOwnersList.length > 0){
      this.props.vendorOwnersList.map((item:any) => {
        if(item.Type.includes("Festival Owners") == true){
          let obj = { value: item.documentID, label: item.document.firstName+" "+item.document.lastName };
           this._owners.push(obj);  
        }
        if(item.Type.includes("Festival Vendors") == true){
          let obj = { 
            documentID: item.documentID,
            businessName: item.document.businessName,
            firstName: item.document.firstName,
            lastName: item.document.lastName,
            category: item.document.category,
            email: item.document.email,
            paymentType: item.document.paymentType,
            profileImageURL: item.document.profileImageURL
            };
            if(obj !== undefined)
          this._vendors.push(obj);  
        } 
      });
    }
    console.log( this._vendors);
    let _vendors:any = [];
    let keys = [...this.state.checkedVendors.keys()];
    console.log(keys);
    keys.map((key, index) => {    
      var n = _.find(this._vendors, {documentID:key});
      if(n !== undefined)
      _vendors.push(n);
    });
    const newState = {
      Vendors: _vendors
    };
    this.setState(newState as { [P in T]: State[P] }, () =>
      console.log(this.state)
    );
  }
  handleVendorsChange(e: React.ChangeEvent<HTMLInputElement>) { 
    const item = e.target.id;
    const name = e.target.name;
    const isChecked = e.target.checked;
    if (!isChecked) {
      this.setState((state, props) => {
        state.checkedVendors.delete(item);
        this.pushToVendors();
        return {
          checkedVendors: state.checkedVendors
        };
      });
    } else {
      this.setState((state, props) => {
        state.checkedVendors.set(item, name);
        this.pushToVendors();
        return {
          checkedVendors: state.checkedVendors
        };
      });
    }

  }
  handleVendorsDelete = (item: any) =>{    
    this.setState((state, props) => {
      state.checkedVendors.delete(item);
      this.pushToVendors();
      return {
        checkedVendors: state.checkedVendors
      };
    });
  }
  setBanerFileInput = (item: any) =>{    
    // this.setState((state, props) => {
    //   state.checkedVendors.delete(item);
    //   this.pushToVendors();
    //   return {
    //     checkedVendors: state.checkedVendors
    //   };
    // });
  }
  handleSubmit = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (this.props.isLoading) {
      return;
    }
    if (this.validator.allValid()) {
      if(this.isValidSchedules() === false || this.isValidTimeSchedules() === false){
        toast.error("Please fill festival schedule times");   
        return false;
      }
      // const paymentType = this.state.payment.map((item: any) => item["value"]);
      // const category = this.state.category.map((item: any) => item["value"]);
      this.props.festivalCreate({
        festivalName: this.state.festivalName,
        category: this.state.category,
        address: this.state.address,
        city: this.state.city,
        state: this.state.state,
        country: this.state.country,
        zipcode: this.state.zipcode,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        price: this.state.price,
        festivalDescription: this.state.festivalDescription,
        festivalOwner: this.state.festivalOwner,
        contactNumber: this.state.contactNumber,
        websiteURL: this.state.websiteURL,
        facebookURL: this.state.facebookURL,
        twitterURL: this.state.twitterURL,
        instagramURL: this.state.instagramURL,
        otherSocialLink: this.state.otherSocialLink,
        Amenities : this.state.Amenities,
        Vendors: this.state.Vendors,
        Schedule: this.state.Schedule,
        timeandSchedule: this.state.timeandSchedule,
        _festivalImages: this.state._festivalImages,
        publishingStatus: 'published',
      });
    } else {
      toast.dismiss(2);
      toast.warn("Please fill in all required fields!",{toastId:2});
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  handleSave = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (this.props.isLoading) {
      return;
    }
    this.validator.hideMessages();
      this.props.festivalCreate({
        festivalName: this.state.festivalName,
        category: this.state.category,
        address: this.state.address,
        city: this.state.city,
        state: this.state.state,
        country: this.state.country,
        zipcode: this.state.zipcode,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        price: this.state.price,
        festivalDescription: this.state.festivalDescription,
        festivalOwner: this.state.festivalOwner,
        contactNumber: this.state.contactNumber,
        websiteURL: this.state.websiteURL,
        facebookURL: this.state.facebookURL,
        twitterURL: this.state.twitterURL,
        instagramURL: this.state.instagramURL,
        otherSocialLink: this.state.otherSocialLink,
        Amenities : this.state.Amenities,
        Vendors: this.state.Vendors,
        Schedule: this.state.Schedule,
        timeandSchedule: this.state.timeandSchedule,
        _festivalImages: this.state._festivalImages,
        publishingStatus: 'unpublished',
      });
  };
  _handleBannerImageChange(e:any) {
    e.preventDefault();
    var files = e.target.files;  
    for (var i = 0; i < files.length; i++) {
        var file = files[i];
        if (!file.type.match('image')) continue;
        var picReader = new FileReader();
        picReader.addEventListener("load",  (event:any) => {
            var picFile = event.target;
            var _iObj:any = this.state._festivalImages;
            _iObj.push(picFile.result );
            this.setState({_festivalImages:_iObj});
        });
        picReader.readAsDataURL(file);
    }
    
  }
 
  handleImageDelete = (index:any) => {
    let obj = this.state._festivalImages;
    obj.splice(index, 1);
    this.setState({ _festivalImages: obj },()=>{
      console.log(this.state);
    });
  }
  onSortEnd = ({oldIndex, newIndex}:any) => {
    this.setState(({_festivalImages}) => ({
      _festivalImages: arrayMove(_festivalImages, oldIndex, newIndex),
    }));
  };
  render() {
    if (this.props.vendorOwnersList.length === 0) {
      
      return <p>Loading.....
      </p>;
    }
    return ( 
        <div> 
          <div className="white_panel form_pading festival_module">
            <div className="festival_header">
                  <div className="row">
                      <div className="col-md-4">
                        <Link onClick={()=> window.history.go(-1)} to={""} className="btn-back">Back </Link>
                      </div>
                      <div className="col-md-4">
                        <h3>Add New Festival</h3>
                      </div>
                      <div className="col-md-4">
                        <div className="action_buttons text-right ">
                            <button className="btn-bordered"  onClick={(e)=>this.handleSave(e)} >Save</button>

                            <button className="btn-submit"  onClick={(e)=>this.handleSubmit(e)}>Publish Festival</button>
                        </div>
                      </div>
                  </div>
            </div>

            <FestivalForm
              festivalName= {this.state.festivalName}
              category={this.state.category}
              address={this.state.address}
              city={this.state.city}
              state={this.state.state}
              country={this.state.country}
              zipcode={this.state.zipcode}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              price={this.state.price}
              festivalDescription={this.state.festivalDescription}
              festivalOwner={this.state.festivalOwner}
              vendorOwnersList={this.props.vendorOwnersList}
              contactNumber={this.state.contactNumber}
              websiteURL={this.state.websiteURL}
              facebookURL={this.state.facebookURL}
              twitterURL={this.state.twitterURL}
              instagramURL={this.state.instagramURL}
              otherSocialLink={this.state.otherSocialLink}
              setTextInput={this.setTextInput}
              handleSelectChange={this.handleSelectChange}        
              handleSubmit={this.handleSubmit}
              handleSave={this.handleSave}
              amenitieOptions={amenitieOptions}
              checkedAmenities={this.state.checkedAmenities}
              handleAmenitiesChange={this.handleAmenitiesChange}
              Amenities={this.state.Amenities}
              handleAmenitiesDelete={this.handleAmenitiesDelete}
              checkedVendors={this.state.checkedVendors}
              handleVendorsChange={this.handleVendorsChange}
              Vendors={this.state.Vendors}
              handleVendorsDelete={this.handleVendorsDelete}
              validator={this.validator}
              ScheduleFormIndex={this.state.ScheduleFormIndex}
              ScheduleFormTitle={this.state.ScheduleFormTitle}
              ScheduleFormStartTime={this.state.ScheduleFormStartTime}
              ScheduleFormEndTime={this.state.ScheduleFormEndTime}
              ScheduleFormDescription={this.state.ScheduleFormDescription}
              ScheduleFormVenue={this.state.ScheduleFormVenue}
              Schedule={this.state.Schedule}
              timeandSchedule={this.state.timeandSchedule}
              scheduleModal={this.state.scheduleModal}
              scheduleModalPopUp={this.state.scheduleModalPopUp}
              handleScheduleShow={this.handleScheduleShow}
              handleScheduleSubClose={this.handleScheduleSubClose}
              handleScheduleSubShow={this.handleScheduleSubShow}
              handleScheduleClose={this.handleScheduleClose}
              handleScheduleEdit={this.handleScheduleEdit}
              handleScheduleDelete={this.handleScheduleDelete}
              validatorSchedule={this.validatorSchedule}
              handleScheduleSubmit={this.handleScheduleSubmit}
              bannerImageUrl = {this.state.bannerImageUrl}
              setBanerFileInput = {this.setBanerFileInput}
              setStateFromChild={this.setStateFromChild}
              _festivalImages={this.state._festivalImages}
              _handleBannerImageChange={this._handleBannerImageChange}
              handleImageDelete={this.handleImageDelete}
              onSortEnd={this.onSortEnd}
              setDatesArray={this.setDatesArray}
              setIsPerDay={this.setIsPerDay}
              handleShStartDateTimeSdlChange={this.handleShStartDateTimeSdlChange}
              handleShendDateTimeSdlChange={this.handleShendDateTimeSdlChange}
              getSchedulesActive={this.getSchedulesActive}
              setSchedulesActive={this.setSchedulesActive}
              isValidSchedules={this.isValidSchedules}
              commonStartTime={this.state.commonStartTime}
              commonEndTime={this.state.commonEndTime}
              handleShCommonDateTimeSdlChange={this.handleShCommonDateTimeSdlChange}
            />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorOwnersList: state.userManagement.GetVendorsAndOwners.success,
  isLoading: state.festivals.FestivalCreate.isLoading,
  success: state.festivals.FestivalCreate.success,
  hasErrored: state.festivals.FestivalCreate.hasErrored,
  response: state.festivals.FestivalCreate.response
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, Action>
): DispatchProps => ({
  getVendorsAndOwners: () => dispatch(getVendorsAndOwners()),
  festivalCreate: ({
    festivalName,
    category,
    address,
    city,
    state,
    country,
    zipcode,
    startDate,
    endDate,
    price,
    festivalDescription,
    festivalOwner,
    contactNumber,
    websiteURL,
    facebookURL,
    twitterURL,
    instagramURL,
    otherSocialLink,
    Amenities,
    Vendors,
    Schedule,
    timeandSchedule,
    _festivalImages,
    publishingStatus,
  }) =>
    dispatch(
      festivalCreate({
        festivalName,
        category,
        address,
        city,
        state,
        country,
        zipcode,
        startDate,
        endDate,
        price,
        festivalDescription,
        festivalOwner,
        contactNumber,
        websiteURL,
        facebookURL,
        twitterURL,
        instagramURL,
        otherSocialLink,
        Amenities,
        Vendors,
        Schedule,
        timeandSchedule,
        _festivalImages,
        publishingStatus,
      })
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(FestivalCreate);
