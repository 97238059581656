import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer,Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingBar from "react-redux-loading-bar";
import { store, persistor } from "./state/store";
import { PersistGate } from "redux-persist/integration/react";
//import "./index.css";
import "./assets/css/style.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "babel-polyfill";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <div className="fixed"><LoadingBar showFastActions style={{ backgroundColor: '#58B5C9' }}/></div>   
      <App />
      <ToastContainer position={"top-center"} transition={Zoom} />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
