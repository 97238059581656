import React, { useState, useEffect } from "react";
import logo from "../../assets/images/logo_2x.png";
import sadface from "../../assets/images/sad-face.svg";
import { NavLink, useParams,useHistory } from "react-router-dom";
import Api from "../../Api";
import { toast } from "react-toastify";

export const OptoutInvitation = () => {
  const params: {
    uid?: string;
    temppin?: string;
    GroupNames?: string;
  } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [isFirstTime, setIsFirstTime] = useState(true);
  const optoutVendorInvite = (uid: string, pin: string) => {
    Api.vendorsUpdateInvite({ uid, pin, invitationStatus: "optout" })
      .then(res => {
        setIsLoading(false);
            if (res) {
          setSuccess(true);
        } else {
          setError(true);
        }
        console.log(res);
      })
      .catch(err => {
        toast.error("Action already taken!", {
          onClose:() =>{
            history.push("/login");
          }
        });
        setError(true);
        console.log(err);
      });
  };

  const optoutOwnerInvite = (uid: string, pin: string) => {
    Api.ownersUpdateInvite({ uid, pin, invitationStatus: "optout" })
      .then(res => {
        setIsLoading(false);
            if (res) {
          setSuccess(true);
        } else {
          setError(true);
        }
        console.log(res);
      })
      .catch(err => {
        toast.error("Action already taken!", {
          onClose:() =>{
            history.push("/login");
          }
        });
        setError(true);
        console.log(err);
      });
  };

  useEffect(() => {
    if (isFirstTime) {
      setIsFirstTime(false);
    let uid = params.uid ? params.uid : "";
    let pin = params.temppin ? params.temppin : "";
    const GroupNames = params.GroupNames ? params.GroupNames : "";
    setIsLoading(true);
    if (GroupNames.includes("Festival Vendors") === true) {
      optoutVendorInvite(uid, pin);
    }

    if (GroupNames.includes("Festival Owners") === true) {
      optoutOwnerInvite(uid, pin);
    }
  }
  }, [success, isLoading, error,isFirstTime]);

  return (
    <div>
      <div className="non--login">
      {isLoading == false  &&  success == true   ? (
        <div className="login-form alert-messages">
          <div className="logo_img">
            <img src={logo} alt="F" />
            <h1>
              <span>FEST</span>FRWRD
            </h1>
          </div>

          <div className="login-form--inner text-center">
            <img src={sadface} alt="" />
            <h2>We’re really sorry to see you go!</h2>
            <p className="text-center">
              We’ll respect your decision to not receive communications from us,
              but if you ever have a change of heart feel free to email us at{" "}
              <a href="mailto:contact@festfrwrd.com">contact@festfrwrd.com</a>.
            </p>
          </div>
        </div>
        ): null}
      </div>
      <div className="footer">Copyright. All rights reserved 2019</div>
    </div>
  );
};
