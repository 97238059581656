import * as ActionTypes from "./actionTypes"
import { UserFunctionCreateState, UserFunctionCreateActionTypes } from "./types"

const initialState: UserFunctionCreateState = {
	isLoading: false,
	hasErrored: false,
	success: false,
	response: null,
}

export default (state = initialState, action: UserFunctionCreateActionTypes): UserFunctionCreateState => {
	switch (action.type) {

		case ActionTypes.USER_FUNCTION_CREATE:
			return { ...state, isLoading: true, success: false, hasErrored: false, response:null }

		case ActionTypes.USER_FUNCTION_CREATE_SUCCESS:
			return { ...state, isLoading: false, success: true, hasErrored: false, response:action.payload }

		case ActionTypes.USER_FUNCTION_CREATE_ERROR:
			return { ...state, isLoading: false, success: false,  hasErrored: true, response:action.payload }

		default:
			return state
	}
}
