import * as ActionTypes from "./actionTypes";
import { Dispatch } from "redux";
import Api from "../../Api";

const userFunctionEditLoading = () => ({
  type: ActionTypes.USER_FUNCTION_EDIT
});

const userFunctionEditSuccess = (payload: any) => ({
  type: ActionTypes.USER_FUNCTION_EDIT_SUCCESS,
  payload
});

const userFunctionEditError = (payload: any) => ({
  type: ActionTypes.USER_FUNCTION_EDIT_ERROR,
  payload
});

export const userFunctionEdit = (
  name: string,
  description: string,
  functionId: string
) => async (dispatch: Dispatch) => {
  try {
    dispatch(userFunctionEditLoading());
    let userFunctionEditResponse = await Api.editUserFunction({
      name,
      description,
      functionId
    });
    dispatch(userFunctionEditSuccess(userFunctionEditResponse));
  } catch (error) {
    dispatch(userFunctionEditError(error));
  }
};
