import * as ActionTypes from "./actionTypes"
import { UserMyProfileCreateState, UserMyProfileCreateActionTypes } from "./types"

const initialState: UserMyProfileCreateState = {
	isLoading: false,
	hasErrored: false,
	success: Math.random()
}

export default (state = initialState, action: UserMyProfileCreateActionTypes): UserMyProfileCreateState => {
	switch (action.type) {

		case ActionTypes.USER_MY_PROFILE:
			return { ...state, isLoading: true, success: Math.random(), hasErrored: false }

		case ActionTypes.USER_MY_PROFILE_SUCCESS:
			return { ...state, success: Math.random(), isLoading: false }

		case ActionTypes.USER_MY_PROFILE_ERROR:
			return { ...state, isLoading: false, hasErrored: true }

		default:
			return state
	}
}
