import * as React from "react";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrashAlt,
  faEnvelopeOpenText,
  faStar
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import { Dropdown, Modal } from "react-bootstrap";
import { AppState } from "../../state/types";
import {
  festivalOwnerListAll,
  festivalOwnerDelete,
  festivalOwnerSendInvite
} from "../../state/festivalOwners/actions";
import closeBtn from "../../assets/images/close-button.svg";
import userImg from "../../assets/images/user.png";
import { CheckBox } from "../../styled-components/checkboxes";

import sortUp from "../../assets/images/sort_up.svg";
import sortDown from "../../assets/images/sort_down.svg";

import arrowRight from "../../assets/images/right-arrow.svg";
import arrowRightDis from "../../assets/images/right-arrow-dis.svg";
import arrowLeft from "../../assets/images/left-arrow.svg";
import arrowLeftDis from "../../assets/images/left-arrow-dis.svg";
import sortUpActive from "../../assets/images/sort_up_active.svg";
import { stateOptions } from "./../../constants/stateOptions";

const checkboxes = [
  {
    name: "sms",
    key: "sms",
    label: "SMS"
  },
  {
    name: "email",
    key: "email",
    label: "Email"
  }
];

interface DispatchProps {
  getAllowners: (
    limit: number,
    page: number,
    search: string,
    sort: string
  ) => void;
  deleteowner: (ownerId: string) => void;
  sendInvite: (email: string,contactEmail: string,name: string,groupscount:number) => void;
}

interface StateProps {
  isLoading: boolean;
  hasErrored: boolean;
  success: Array<any>;
  isSendInviteLoading: boolean;
  hasSendInviteErrored: boolean;
  sendInviteSuccess: boolean;
  delSuccess: boolean;
  delResponse: any;
  hasDeleteErrored: boolean;
}

interface IOwnerListProps {}

interface IState {
  limit: number;
  page: number;
  search: string;
  sort: string;
  sort_type: string;
  inviteModalShow: boolean;
  selectedOwner: any;
  isSendInviteLoading: boolean;
}

type Props = StateProps & IOwnerListProps & DispatchProps;

class OwnerList extends React.Component<Props> {
  private prevSort = "firstNameSort";
  public totalCount = 0;
  private prevSortInit = "asc";
  public state = {
    limit: 10,
    page: 0,
    search: "",
    sort: "asc",
    sort_type: "firstNameSort",
    inviteModalShow: false,
    deleteModalShow: false,
    selectedOwner: null as any,
    isSendInviteLoading: false
  };
  constructor(props: Props) {
    super(props);
    this.getData();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.delResponse !== prevProps.delResponse &&this.props.delSuccess) {
      this.handleDeleteClose();  
      this.getData();  
      // toast.update(1, {
      //   render: this.props.delResponse.message,
      //   type: toast.TYPE.SUCCESS,
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   onClose:() =>{
      //     this.getData();
      //   }
      // });
    }
    if (this.props.delResponse !== prevProps.delResponse &&this.props.hasDeleteErrored) {
      this.handleDeleteClose();   
      toast.error((this.props.delResponse.error == null) ? this.props.delResponse.message : this.props.delResponse.error);  
      // toast.update(1, {
      //   render:  (this.props.delResponse.error == null) ? this.props.delResponse.message : this.props.delResponse.error,
      //   type: toast.TYPE.ERROR,
      //   autoClose: 2000,
      //   hideProgressBar: false
      // });
    }
    if (
      prevProps.sendInviteSuccess !== this.props.sendInviteSuccess &&
      this.props.sendInviteSuccess
    ) {
      this.setState({
        inviteModalShow: false,
        selectedOwner: null,
        isSendInviteLoading: false
      },()=>{
        //toast.success("Sent Invite successfull!"); 
      });
    }
    if (
      prevProps.hasSendInviteErrored !== this.props.hasSendInviteErrored &&
      this.props.hasSendInviteErrored
    ) {
      this.setState({
        inviteModalShow: false,
        selectedOwner: null,
        isSendInviteLoading: false
      },()=>{
        toast.error("Sent Invite unsuccessfull!"); 
      });
    }
  }

  private getData() {
    if (this.prevSort != "") {
      this.props.getAllowners(
        this.state.limit,
        this.state.page,
        this.state.search,
        this.state.sort_type + "_" + this.state.sort
      );
    } else {
      this.props.getAllowners(
        this.state.limit,
        this.state.page,
        this.state.search,
        ""
      );
    }
  }

  private setTextInput = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState(
      { [event.currentTarget.name]: event.currentTarget.value },
      () => {
        this.getData();
      }
    );
  };
  private setSelectInput = (event: React.FormEvent<HTMLSelectElement>) => {
    this.setState({ ["limit"]: event.currentTarget.value }, () => {
      this.setState({ ["page"]: 0 }, () => {
        this.getData();
      });
    });
  };
  private setPage = (value: any) => {
    let page = this.state.page;
    page = value == "inc" ? page + 1 : page - 1;
    this.setState({ ["page"]: page }, () => {
      this.getData();
    });
  };
  private setSort = (type: string, value: string) => {
    value = value == "asc" ? "desc" : "asc";
    if (this.prevSort != type) {
      this.prevSort = type;
      value = "asc";
    }
    this.setState({ ["sort_type"]: type }, () => {
      this.setState({ ["sort"]: value }, () => {
        this.getData();
      });
    });
  };
  getArrow = () => {
    return (((this.state.page+1) * this.state.limit) >= this.totalCount) ? arrowRightDis : arrowRight;
  }
  setCount = (count:number) => {
    this.totalCount = count;
  }
  getCountString = () => {
    var str = "";
    if(this.totalCount > ((this.state.page+1) * this.state.limit)){
       str = (this.state.page * this.state.limit)+1+"-"+((this.state.page+1) * this.state.limit) +" of "+ this.totalCount;
    }else{
       str = (this.state.page * this.state.limit)+1+"-"+this.totalCount +" of "+ this.totalCount;
    }
    return str;
  }
  handleDelete = () => {
    this.props.deleteowner(this.state.selectedOwner.documentID);
    this.handleClose();
    this.handleDeleteClose();
  };

  public handleClose = () => {
    this.setState({ inviteModalShow: false, selectedOwner: null });
  };
  getRoles(profile:any){
    const groups = [];
    if(profile.GroupNames.includes("Administrators") === true ){
      groups.push("Administrators");
    }
    if(profile.GroupNames.includes("Festival Vendors") === true && profile.invitationStatus == "accepted"){
      groups.push("Festival Vendors");
    }
    if(profile.GroupNames.includes("Festival Owners") === true && profile.opeationalStatus == "accepted"){
      groups.push("Festival Owners");
    }
    return groups.length;
  }
  handleSendInvite = () => {
    this.setState({ isSendInviteLoading: true });
    const groupscount:number = this.getRoles(this.state.selectedOwner.document);
    this.props.sendInvite(
        this.state.selectedOwner.document.email as string,
        this.state.selectedOwner.document.contactEmail as string,
        this.state.selectedOwner.document.firstName as string, 
        groupscount as number
      );
  };

  public handleShow = (item: any) => {
    this.setState({ inviteModalShow: true, selectedOwner: item });
  };

  public handleDeleteClose = () => {
    this.setState({ deleteModalShow: false, selectedOwner: null });
  };
  public handleDeleteShow = (ownerId: string) => {
    this.setState({ deleteModalShow: true, selectedOwner: ownerId }, () => {
      console.log(this.state.selectedOwner);
    });
  };
  getLocation = () => {
    let location = [];
    if(this.state.selectedOwner.document.address != '' && this.state.selectedOwner.document.address != undefined ){
      location.push(this.state.selectedOwner.document.address);
    }else{
      if(this.state.selectedOwner.document.location != '' && this.state.selectedOwner.document.location != undefined ){
        location.push(this.state.selectedOwner.document.location);
      }
    }
    if(this.state.selectedOwner.document.city != ''){
      location.push(this.state.selectedOwner.document.city);
    }

    if(this.state.selectedOwner.document.state != ''){
      var statedata:any = stateOptions.filter((option:any) => option.value === this.state.selectedOwner.document.state);
      location.push(statedata[0].label);
    }
    if(this.state.selectedOwner.document.zipcode != ''){
      if(this.state.selectedOwner.document.country != null){
        location.push(this.state.selectedOwner.document.zipcode+" "+this.state.selectedOwner.document.country);
      }else{
        location.push(this.state.selectedOwner.document.zipcode);
      }     
    }
    return location.join(", ");
  }
  public render() {
    if (this.props.isLoading) {
      return <p>Loading</p>;
    }

    if (this.props.hasErrored) {
      return <p>error</p>;
    }

    return (
        <div>
          <div className="inner-title">
            <h3>VIEW FESTIVAL OWNER</h3>
            <div className="path_navigation">
              <ul>
                <li>
                  <NavLink to="/FestivalOwnerManagement">FESTIVAL OWNER Management</NavLink>
                </li>
                <li>
                  <span>VIEW FESTIVAL OWNER</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="table_data">
            <div className="table_header">
              <div className="icon-input search-icon">
                <input
                  type="text"
                  className="form-control"
                  name={"search"}
                  value={this.state.search}
                  onChange={event => this.setTextInput(event)}
                  placeholder="Search for Festival Owner"
                />
              </div>
              <NavLink to="/FestivalOwnerManagement/list/new" className="btn-addnew">
                Add New <span>+</span>
              </NavLink>
            </div>
            <table className="custom_table">
              <thead>
                <tr>
                  <th>
                    First Name{" "}
                    <a
                      onClick={event =>
                        this.setSort("firstNameSort", this.state.sort)
                      }
                    >
                      {this.state.sort_type == "firstNameSort" ? (
                        <img src={this.state.sort == "asc" ? sortUpActive : sortDown} />
                      ) : (
                        <img src={sortUp} />
                      )}
                    </a>
                  </th>
                  <th>
                    Last Name{" "}
                    <a
                      onClick={event =>
                        this.setSort("lastNameSort", this.state.sort)
                      }
                    >
                      {this.state.sort_type == "lastNameSort" ? (
                        <img src={this.state.sort == "asc" ? sortUpActive : sortDown} />
                      ) : (
                        <img src={sortUp} />
                      )}
                    </a>
                  </th>
                  <th>
                    Email ADDRESS{" "}
                    <a
                      onClick={event => this.setSort("email", this.state.sort)}
                    >
                      {this.state.sort_type == "email" ? (
                        <img src={this.state.sort == "asc" ? sortUpActive : sortDown} />
                      ) : (
                        <img src={sortUp} />
                      )}
                    </a>
                  </th>
                  <th>
                    OPERATIONAL STATUS{" "}
                    <a
                      onClick={event =>
                        this.setSort("opeationalStatus", this.state.sort)
                      }
                    >
                      {this.state.sort_type == "opeationalStatus" ? (
                        <img src={this.state.sort == "asc" ? sortUpActive : sortDown} />
                      ) : (
                        <img src={sortUp} />
                      )}
                    </a>
                  </th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {this.props.success.length == 0 && !this.props.isLoading ? (
                  <tr className="">
                    <td colSpan={5} className="nodata">
                      No data found!
                    </td>
                  </tr>
                ) : null}
                {this.props.success.map(item => (
                  <tr key={item.documentID}>{this.setCount(item.count)}
                    <td  className="capitalize">{item.document.firstName}</td>
                    <td  className="capitalize">{item.document.lastName}</td>
                    <td>{item.document.email}</td>
                    <td className="capitalizeNew">
                    <span className={item.document.opeationalStatus}>
                        {item.document.opeationalStatus}
                      </span>
                    </td>
                    <td>
                      <Dropdown drop="left" className="table_menu">
                        <Dropdown.Toggle id="dropdown-basic">
                          <span className="more_icon"></span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                        {item.document.opeationalStatus != 'accepted' ? (
                          <div>
                            <a className="spinv" onClick={() => this.handleShow(item)}>
                              {" "}
                              <FontAwesomeIcon icon={faEnvelopeOpenText} /> 
                              {(item.document.opeationalStatus == 'optout' || item.document.opeationalStatus == 'pending' || item.document.opeationalStatus == 'rejected') ? ("Resend Invite") : "Send Invite"}
                            </a>
                          
                            </div>
                            ) : null} 
                          <div role="presentation">
                            <Link
                              to={{
                                pathname: "/FestivalOwnerManagement/list/edit",
                                state: {
                                  item: item
                                }
                              }}
                            >
                              <FontAwesomeIcon icon={faEdit} />{" "}
                              <i className="fa fa-eye"></i> View/Edit
                            </Link>
                          </div>
                          {/* <div role="presentation">
                          <Link
                              to={{
                                pathname: "/reviews"
                              }}
                            >
                              <FontAwesomeIcon icon={faStar} /> Ratings
                              </Link>
                          </div> */}
                          <div role="presentation">
                            <a onClick={e => this.handleDeleteShow(item)}>
                              {" "}
                              <FontAwesomeIcon icon={faTrashAlt} /> Delete
                            </a>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={6}>
                    <div className="d-flex flex-row-reverse bd-highlight">
                      <div className="p-2 bd-highlight">
                        <button
                          className="paginationbtn"
                          disabled={this.state.page == 0}
                          onClick={event => this.setPage("dec")}
                        >
                          {" "}
                          &nbsp;&nbsp;&nbsp;
                          <img style={{width:'15px'}} src={(this.state.page == 0) ? arrowLeftDis : arrowLeft }/>{" "}
                          &nbsp;&nbsp;&nbsp;
                        </button>
                        <button
                          className="paginationbtn"
                          disabled={(((this.state.page+1) * this.state.limit) >= this.totalCount)} 
                          onClick={event => this.setPage("inc")}
                        >
                          {" "}
                          &nbsp;&nbsp;&nbsp;
                          <img style={{ width: "15px" }} src={this.getArrow()} />
                          &nbsp;&nbsp;&nbsp;
                        </button>
                      </div>
                      <div className="p-2 bd-highlight">{this.getCountString()}</div>
                      <div className="p-2 bd-highlight">
                        Rows per page &nbsp;&nbsp;&nbsp;
                        <select
                          name={"offset"}
                          onChange={event => this.setSelectInput(event)}
                          style={{ border: "0px" }}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Invite Modal */}
          <Modal
            className="custom_modal"
            show={this.state.inviteModalShow}
            onHide={() => this.handleClose()}
          >
            {/* <Modal.Header>
            <Modal.Title>Send Invitation to Vendor</Modal.Title>
           
          </Modal.Header> */}
            <Modal.Body>
              <div className="invitationBox">
                <a className="modalClose" onClick={() => this.handleClose()}>
                  <img src={closeBtn}></img>
                </a>
                <div className="user-deatils-box">
                  {this.state.selectedOwner && this.state.selectedOwner.document.profileImageURL != '' ? (
                        <div className="user-img">
                          <img src={this.state.selectedOwner.document.profileImageURL} alt="" />
                        </div>

                  ) :null}
                  <div className="user-deatils">
                    <h4>
                      {this.state.selectedOwner &&
                        this.state.selectedOwner.document.firstName+" "+this.state.selectedOwner.document.lastName}
                    </h4>
                    {/* <p>Britt’s Bragtastic Burger Em…</p> */}
                  </div>
                </div>
                {this.state.selectedOwner && this.state.selectedOwner.document.contactEmail != '' && this.state.selectedOwner.document.contactEmail != undefined ? (
                    <div className="form-group">
                      <label className="blue_label"> CONTACT EMAIL ADDRESS </label>
                      <p>
                        {this.state.selectedOwner &&
                          this.state.selectedOwner.document.contactEmail}
                      </p>
                    </div>
                ) : (
                  <div className="form-group">
                    <label className="blue_label"> EMAIL ADDRESS </label>
                    <p>
                      {this.state.selectedOwner &&
                        this.state.selectedOwner.document.email}
                    </p>
                  </div>
                )}
                <div className="form-group">
                  <label className="blue_label"> PHONE NUMBER </label>
                  <p>
                    {this.state.selectedOwner &&
                      this.state.selectedOwner.document.phoneNumber}
                  </p>
                </div>
                <div className="form-group">
                  <label className="blue_label"> MAILING ADDRESS </label>
                  <p>
                    {this.state.selectedOwner &&
                      this.getLocation()}
                  </p>
                </div>
                <div className="form-group">
                  <label className="blue_label"> ALERT NOTIFICATIONS ON</label>
                  <div>
                    <React.Fragment>
                      {checkboxes.map(item => (
                        <div className="checkBoxButton checkBoxButton_inline">
                          <CheckBox
                            id={item.key}
                            name={item.name}
                            checked={
                              this.state.selectedOwner &&
                              this.state.selectedOwner.document.Notifications.includes(
                                item.name
                              )
                            }
                            onChange={() => null}
                          />
                          <label
                            className="checkboxlabel"
                            htmlFor={item.key}
                            key={item.key}
                          >
                            {item.label}
                          </label>
                        </div>
                      ))}
                    </React.Fragment>
                  </div>
                </div>
                <button
                  disabled={this.state.isSendInviteLoading}
                  className="btn-submit"
                  onClick={() => this.handleSendInvite()}
                  type="submit"
                >
                  {this.state.isSendInviteLoading
                    ? "Sending.."
                    : "Send Invite"}
                </button>
                <button
                  className="btn-bordered"
                  onClick={() => this.handleClose()}
                  type="submit"
                >
                  Cancel
                </button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            className="custom_modal"
            show={this.state.deleteModalShow}
            onHide={() => this.handleDeleteClose()}
          >
            {/* <Modal.Header>
           
          </Modal.Header> */}
            <Modal.Body>
              <div className="invitationBox">
                <a
                  className="modalClose"
                  onClick={() => this.handleDeleteClose()}
                >
                  <img src={closeBtn}></img>
                </a>
                <div className="delete-msg">
                  <h4>Are you sure you want delete this Festival Owner?</h4>
                </div>
                <div className="user-deatils-box">
                {this.state.selectedOwner && this.state.selectedOwner.document.profileImageURL !== undefined && this.state.selectedOwner.document.profileImageURL != '' ? (
                        <div className="user-img">
                          <img src={this.state.selectedOwner.document.profileImageURL} alt="" />
                        </div>

                  ) :null}
                  <div className="user-deatils">
                    {this.state.selectedOwner ? (
                      <h4>
                        {this.state.selectedOwner.document.firstName}{" "}
                        {this.state.selectedOwner.document.lastName}
                      </h4>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <button
                  className="btn-submit"
                  onClick={() => this.handleDelete()}
                  type="submit"
                >
                  Delete
                </button>
                <button
                  className="btn-bordered"
                  onClick={() => this.handleDeleteClose()}
                  type="submit"
                >
                  Cancel
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.festivalOwner.FestivalOwnerList.isLoading,
  hasErrored: state.festivalOwner.FestivalOwnerList.hasErrored,
  success: state.festivalOwner.FestivalOwnerList.success,
  delSuccess: state.festivalOwner.FestivalOwnerDelete.success,
  delResponse: state.festivalOwner.FestivalOwnerDelete.response,
  hasDeleteErrored: state.festivalOwner.FestivalOwnerDelete.hasErrored,
  isSendInviteLoading: state.festivalOwner.FestivalOwnerSendInvite.isLoading,
  hasSendInviteErrored: state.festivalOwner.FestivalOwnerSendInvite.hasErrored,
  sendInviteSuccess: state.festivalOwner.FestivalOwnerSendInvite.success
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, Action>
): DispatchProps => ({
  getAllowners: (limit, page, search, sort) =>
    dispatch(festivalOwnerListAll(limit, page, search, sort)),
  deleteowner: (ownerId: string) => dispatch(festivalOwnerDelete(ownerId)),
  sendInvite: (email: string,contactEmail: string,name: string,groupscount: number) => dispatch(festivalOwnerSendInvite(email,contactEmail,name,groupscount))
});

export default connect(mapStateToProps, mapDispatchToProps)(OwnerList);
