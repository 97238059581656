export const VendorCategories:any = [
    { value: "Non-profit", label: "Non-profit" },
    { value: "Civic Group", label: "Civic Group" },
    { value: "Artist - Candles", label: "Artist - Candles" },
    { value: "Artist - Clay", label: "Artist - Clay" },
    { value: "Artist - Culinary Arts", label: "Artist - Culinary Arts" },
    { value: "Artist - Digital", label: "Artist - Digital" },
    { value: "Artist - Fiber", label: "Artist - Fiber" },
    { value: "Artist - Folk Art", label: "Artist - Folk Art" },
    { value: "Artist - Glass", label: "Artist - Glass" },
    { value: "Artist - Jewelry", label: "Artist - Jewelry" },
    { value: "Artist - Leather", label: "Artist - Leather" },
    { value: "Artist - Living plants", label: "Artist - Living plants" },
    { value: "Artist - Metal", label: "Artist - Metal" },
    { value: "Artist - Mixed media", label: "Artist - Mixed media" },
    { value: "Artist - Paintings", label: "Artist - Paintings" },
    { value: "Artist - Photography", label: "Artist - Photography" },
    { value: "Artist - Soaps/Lotions/Balms", label: "Artist - Soaps/Lotions/Balms" },
    { value: "Artist - Wood", label: "Artist - Wood" },
    { value: "Artist - Other", label: "Artist - Other" },
    { value: "Food - American", label: "Food - American" },
    { value: "Food - Asian", label: "Food - Asian" },
    { value: "Food - Italian", label: "Food - Italian" },
    { value: "Food - African", label: "Food - African" },
    { value: "Food - Indian", label: "Food - Indian" },
    { value: "Food - Caribbean", label: "Food - Caribbean" },
    { value: "Food - Mediterranean", label: "Food - Mediterranean" },
    { value: "Food - Eastern European", label: "Food - Eastern European" },
    { value: "Food - French", label: "Food - French" },
    { value: "Food - Spanish", label: "Food - Spanish" },
    { value: "Food - Dessert", label: "Food - Dessert" },
    { value: "Food - Specialty", label: "Food - Specialty" },
    { value: "Children - Inflatable Ride", label: "Children - Inflatable Ride" },
    { value: "Children - Bungee or trampoline jump", label: "Children - Bungee or trampoline jump" },
    { value: "Children - Face painting", label: "Children - Face painting" },
    { value: "Children - Dand or special material art", label: "Children - Dand or special material art" },
    { value: "Children - Animal handling or ride", label: "Children - Animal handling or ride" },
    { value: "Children - Carnival ride", label: "Children - Carnival ride" },
    { value: "Children -Other", label: "Children -Other" },
    { value: "Entertainer - Music", label: "Entertainer - Music" },
    { value: "Entertainer - Dance", label: "Entertainer - Dance" },
    { value: "Entertainer - Comedy/improv", label: "Entertainer - Comedy/improv" },
    { value: "Entertainer - Theater/acting", label: "Entertainer - Theater/acting" },
    { value: "Entertainer - Other", label: "Entertainer - Other" },
]