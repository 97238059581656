import * as React from 'react';

export interface IAppSettingsProps {
}

export default class AppSettings extends React.Component<IAppSettingsProps> {
  public render() {
    return (
      <div>
            <div className="inner-title">
                <h3>App Settings</h3>
                <div className="path_navigation">
                    <ul>
                        <li><a href="security.html">Settings</a></li>
                        <li><span>App Settings</span></li>
                    </ul>
                </div>
            </div>

            <div className="white_panel form_pading">
                <form action="">

                    <div className="row">

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="blue_label">App Name</label>
                                <input type="text" className="form-control"  placeholder="Enter App Name Name" />
                            </div>
                            <div className="form-group">
                                <label className="blue_label">Default Language</label>
                                <select name="" id="" className="form-control">
                                    <option value="English">English</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="blue_label">Register Default Role</label>
                                <select name="" id="" className="form-control">
                                    <option value="English">State Govt Dept</option>
                                </select>
                            </div>
                           
                        </div>

                    </div>


                    <button className="btn-submit" type="submit">Save</button>




                </form>
            </div>
      </div>
    );
  }
}
