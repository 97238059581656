import React, { useState } from "react";
import logo from "../../assets/images/logo_2x.png";
import { NavLink } from "react-router-dom";

export const ForgotPinConfirm = () => (
  <div>
    <div className="non--login">
      <div className="login-form">
        <div className="logo_img">
          <img src={logo} alt="F" />
          <h1>
            <span>FEST</span>FRWRD
          </h1>
        </div>

        <div className="login-form--inner">
          <h2>Reset Pin</h2>
          <p className="text-center">
            If the email address entered is registered with us, your temporary
            pin will be emailed to you shortly.
          </p>
        </div>
      </div>
    </div>
    <div className="footer">Copyright. All rights reserved 2019</div>
  </div>
);
