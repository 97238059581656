import * as React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import closeBtn from "../../assets/images/close-button.svg";
import { CheckBox } from '../../styled-components/checkboxes';
import userImg  from '../../assets/images/user.png';
import deleteIcon from "../../assets/images/delete.svg";
import StarRatingComponent from 'react-star-rating-component';

export interface IVendorReviewsProps {
}
interface IUserGroupCreateState {
    DeleteModalShow: any
}


export default class VendorReviews extends React.Component<IVendorReviewsProps,IUserGroupCreateState> {

 constructor(props: any) {
        super(props);

        this.state = {
            DeleteModalShow: false,
        }

    }
  
    public handleDeleteClose = () => {
        this.setState({DeleteModalShow: false});
    };
    public handleDeleteShow = () => {
        this.setState({DeleteModalShow: true});
    };

  public render() {

    
    return (
      <div>
            <div className="inner-title">
                <h3>MANAGE REVIEWS</h3>
                <div className="path_navigation">
                    <ul>
                        <li><NavLink to="/FestivalVendorManagement" className="btn-addnew">VENDOR Management</NavLink></li>
                        <li><NavLink to="/FestivalVendorManagement/list" className="btn-addnew">VIEW VENDORS</NavLink></li>
                        <li><span>MANAGE REVIEWS</span></li>
                    </ul>
                </div>
            </div>

            <div className="table_data">
               <div className="reviews-list">
                   <h4>Year 2019</h4>
                   <div className="reviews-list-inner">
                        <h3>Reviews <span>Action</span></h3>
                        <div className="review_item">
                            <p className="review-user">
                                JOHN DOE
                                <span className="review-festival">North Georgia Arts Festival</span>
                            </p>
                            <StarRatingComponent 
                            name="rate1" 
                            starCount={5}
                            value={4}
                            starColor={'#58B5C9'}
                            emptyStarColor={'#e2e2e2'}
                            />
                            <p className="review-des">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                            <a className="review_delete"><img src={deleteIcon} /></a>
                        </div>

                        <div className="review_item">
                            <p className="review-user">
                                JOHN DOE
                                <span className="review-festival">North Georgia Arts Festival</span>
                            </p>
                            <StarRatingComponent 
                            name="rate2" 
                            starCount={5}
                            value={4}
                            starColor={'#58B5C9'}
                            emptyStarColor={'#e2e2e2'}
                            />
                            <p className="review-des">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                            <a className="review_delete"><img src={deleteIcon} /></a>
                        </div>
                   </div>

                   <h4>Year 2019</h4>
                   <div className="reviews-list-inner">
                        <h3>Reviews <span>Action</span></h3>
                        <div className="review_item">
                            <p className="review-user">
                                JOHN DOE
                                <span className="review-festival">North Georgia Arts Festival</span>
                            </p>
                            <StarRatingComponent 
                            name="rate1" 
                            starCount={5}
                            value={4}
                            starColor={'#58B5C9'}
                            emptyStarColor={'#e2e2e2'}
                            />
                            <p className="review-des">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                            <a className="review_delete"><img src={deleteIcon} /></a>
                        </div>

                        <div className="review_item">
                            <p className="review-user">
                                JOHN DOE
                                <span className="review-festival">North Georgia Arts Festival</span>
                            </p>
                            <StarRatingComponent 
                            name="rate2" 
                            starCount={5}
                            value={4}
                            starColor={'#58B5C9'}
                            emptyStarColor={'#e2e2e2'}
                            />
                            <p className="review-des">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                            <a className="review_delete"><img src={deleteIcon} /></a>
                        </div>
                   </div>
               </div>
               
            </div>
            

            <Modal className="custom_modal" show={this.state.DeleteModalShow} onHide={() => this.handleDeleteClose()}>
                <Modal.Header>
                    <Modal.Title>Delete Vendor</Modal.Title>
                    <a className="modalClose" onClick={() => this.handleDeleteClose()}><img src={closeBtn}></img></a>
                </Modal.Header>
                <Modal.Body>
                    <div className="invitationBox">
                        <div className="delete-msg">
                            <h4>Are you sure you want delete this user?</h4>
                        </div>
                        <div className="user-deatils-box">
                            <div className="user-img">
                                <img src={userImg} alt=""/>
                            </div>
                            <div className="user-deatils">
                                <h4>Naah Lamax</h4>
                                <p>Britt’s Bragtastic Burger Em…</p>
                            </div>
                        </div>
                       
                       
                        <button className="btn-submit" onClick={() => this.handleDeleteClose()} type="submit">Cancel</button>
                        <button className="btn-bordered" onClick={() => this.handleDeleteClose()} type="submit">Delete</button>
                    </div>
                </Modal.Body>
            </Modal>
      </div>
    );
  }
}
