import * as React from "react";
import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { userGroupCreate } from "../../state/userGroups/actions";
import { getAllUserFunctions } from "../../state/userFunctionList/actions";
import { CheckBox } from "../../styled-components/checkboxes/index";
import { AppState } from "../../state/types";
import { UserFunction } from "../../state/userFunctionList/types";
import SimpleReactValidator from "simple-react-validator";
import UserGroupForm from "../../components/Security/UserGroupForm";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";

export interface IUserGroupCreateProps {}

interface StateProps {
  response: any;
  userFunctions: Array<UserFunction>;
  isLoading: boolean;
  success: boolean;
  hasErrored: boolean;
}

interface IUserGroupCreateState {
  groupName: string;
  groupDescription: string;
  checkedItems: Map<string, string>;
  isUpdate: boolean;
}

interface DispatchProps {
  userGroupCreate: (
    name: string,
    description: string,
    functionslist: any
  ) => void;
  getAllUserFunctions: () => any;
}

interface Input {
  groupName: string;
  groupDescription: string;
}

interface State extends Input {
  checkedItems: Map<string, string>;
}

type Props = DispatchProps &
  IUserGroupCreateProps &
  StateProps &
  State &
  RouteComponentProps;

class UserGroupCreate extends React.Component<Props, IUserGroupCreateState> {
  private validator: any;
  constructor(props: any) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      isUpdate: false,
      groupName: "",
      groupDescription: "",
      checkedItems: new Map()
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = () => {
    document.title = "New Group | FestFrwrd - The Futr of FSTIVLS";
    this.props.getAllUserFunctions();
  };

  componentDidUpdate(prevProps: Props, prevState: IUserGroupCreateState) {
    if (prevProps.response !== this.props.response && this.props.success) { 
      this.props.history.goBack();
      // toast.update(1, {
      //   render: this.props.response.data.message,
      //   type: toast.TYPE.SUCCESS,
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   onClose:() =>{
      //     this.props.history.goBack();
      //   }
      // });    
    }
    if (
      prevProps.response !== this.props.response &&
      this.props.hasErrored
    ) {      
      toast.error((this.props.response.error == null) ? this.props.response.message : this.props.response.error); 
      // toast.update(1, {
      //   render: (this.props.response.error == null) ? this.props.response.message : this.props.response.error,
      //   type: toast.TYPE.ERROR,
      //   autoClose: 2000,
      //   hideProgressBar: false
      // });
    }
  }

  setTextInput = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    } as Pick<Input, keyof Input>);
  };

  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const item = e.target.id;
    const name = e.target.name;
    const isChecked = e.target.checked;
    if (!isChecked) {
      this.setState((state, props) => {
        state.checkedItems.delete(item);
        return {
          checkedItems: state.checkedItems
        };
      });
    } else {
      this.setState((state, props) => {
        state.checkedItems.set(item, name);
        return {
          checkedItems: state.checkedItems
        };
      });
    }
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      console.log(this.state.checkedItems);
      let keys = [...this.state.checkedItems.keys()];
      let values = [...this.state.checkedItems.values()];
      let userFunctions = keys.map((key, index) => ({
        id: key,
        name: values[index]
      }));
      this.props.userGroupCreate(
        this.state.groupName,
        this.state.groupDescription,
        userFunctions
      );
    } else {
      toast.dismiss(2);
      toast.warn("Please fill in all required fields!",{toastId:2});
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  public render() {
    return (
        <div>
          <div className="inner-title">
            <h3>ADD USER GROUP</h3>
            <div className="path_navigation">
              <ul>
                <li>
                  <NavLink to="/security">SECURITY Management</NavLink>
                </li>
                <li>
                  <NavLink to="/security/UserGroupsManagement">VIEW USER GROUPS</NavLink>
                </li>
                <li>
                  <span>ADD GROUP</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="white_panel form_pading">
            <UserGroupForm
              isUpdate={this.state.isUpdate}
              handleSubmit={this.handleSubmit}
              groupName={this.state.groupName}
              groupDescription={this.state.groupDescription}
              checkedItems={this.state.checkedItems}
              handleChange={this.handleChange}
              setTextInput={this.setTextInput}
              userFunctions={this.props.userFunctions}
              validator={this.validator}
            />
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  response: state.userGroups.UserGroupCreate.response,
  userFunctions: state.userFunctionsList.success,
  isLoading: state.userGroups.UserGroupCreate.isLoading,
  success: state.userGroups.UserGroupCreate.success,
  hasErrored: state.userGroups.UserGroupCreate.hasErrored
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, Action>
): DispatchProps => ({
  userGroupCreate: (name, description, functionslist) =>
    dispatch(userGroupCreate(name, description, functionslist)),
  getAllUserFunctions: () => dispatch(getAllUserFunctions())
});

export default connect(mapStateToProps, mapDispatchToProps)(UserGroupCreate);
