import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import { reducers } from "./reducers";
import storage from "redux-persist/lib/storage";
//import { logger } from "redux-logger";
const persistConfig = {
  key: "root",
  storage,
  timeout: 3600,
  whitelist: ["session"] // Only persist session data
};

const middlewareEnhancer = applyMiddleware(thunk);
const composedEnhancers = compose(middlewareEnhancer);
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(persistedReducer, composedEnhancers);
export const persistor = persistStore(store);
