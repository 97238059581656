import * as React from "react";
import { DatePickerComponent,TimePickerComponent, DateTimePickerComponent  } from '@syncfusion/ej2-react-calendars';
import Select, { ValueType } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';

import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import closeBtn from "../../assets/images/close-button.svg";
import BannerDefault from "../../assets/images/upload.svg";
import arrowgreen from "../../assets/images/arrow-green.png";
import { Link } from "react-router-dom";
import headerp from "../../assets/images/headerp.png";
import top_v_btn from "../../assets/images/top_v_btn.png";
import facebook from "../../assets/images/facebook.svg";
import facebookDis from "../../assets/images/facebook_dis.svg";
import twitter from "../../assets/images/twitter.svg";
import twitterDis from "../../assets/images/twitter_dis.svg";
import instagram from "../../assets/images/instagram.svg";
import instagramDis from "../../assets/images/instagram_dis.svg";
import StarRatingComponent from 'react-star-rating-component';
import topicons from "../../assets/images/top-icons.png";

import InputMask from 'react-input-mask';

import { festivalsCategory } from "../../constants/festivalsCategory";
import { stateOptions } from "../../constants/stateOptions";
import { countryOptions } from "../../constants/countryOptions";

import * as _ from "lodash";
import { Dropdown, Modal, Tabs, Tab } from "react-bootstrap";
import { CheckBox } from "../../styled-components/checkboxes/index";
import moment from "moment";

export interface IFestivalCreateProps {
  festivalName: string;
  category?: ValueType<{ value: string; label: string }>;
  payment?: ValueType<{ value: string; label: string }>;
  country?: ValueType<{ value: string; label: string }>;
  state?: ValueType<{ value: string; label: string }>;
  city: string;
  address: string;
  zipcode: string;
  startDate: string;
  endDate: string;
  price: string;
  festivalDescription: string;
  contactNumber: string;
  websiteURL: string;
  facebookURL: string;
  twitterURL: string;
  instagramURL: string;
  otherSocialLink: string;  
  festivalOwner: ValueType<{ value: string; label: string }>;
  vendorOwnersList: any;
  ScheduleFormTitle: string;
  ScheduleFormIndex: any;
  ScheduleFormStartTime: string;
  ScheduleFormEndTime: string;
  ScheduleFormDescription: string;
  ScheduleFormVenue: string;
  scheduleModal: boolean;
  scheduleModalPopUp: boolean;
  handleScheduleShow: any;
  handleScheduleClose: any;
  handleScheduleSubShow: any;
  handleScheduleSubClose: any;
  handleScheduleEdit: any;
  handleScheduleDelete: any;
  validator: any;
  validatorSchedule: any;
  amenitieOptions: any;
  Amenities: any[];
  checkedAmenities: Map<string, any>;
  checkedVendors: Map<string, any>;
  Vendors: any[];
  Schedule: any[];
  timeandSchedule: any;
  bannerImageUrl: any;
  _festivalImages: string[];
  setStateFromChild: any;
  commonStartTime: any;
  commonEndTime: any;
  handleAmenitiesDelete: (id:any) => void;
  handleAmenitiesChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleVendorsChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleVendorsDelete: (id:any) => void;  
  setTextInput: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleSelectChange: (selectedOption: ValueType<{ value: string; label: string }>,name: string) => void;
  handleSubmit: (e: React.FormEvent<HTMLButtonElement>) => void;
  handleSave: (e: React.FormEvent<HTMLButtonElement>) => void;
  handleScheduleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  setBanerFileInput: (e: any) => void;
  _handleBannerImageChange: (e: any) => void;
  handleImageDelete: (e: any) => void;  
  onSortEnd: (e: any) => void;
  setDatesArray: () => void;
  setIsPerDay: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleShStartDateTimeSdlChange: (e: any,v:any,p:any) => void;
  handleShendDateTimeSdlChange: (e: any,v:any,p:any) => void;
  getSchedulesActive: () => any;
  isValidSchedules: () => any;
  setSchedulesActive: (e: any) => void;
  handleShCommonDateTimeSdlChange: (e: any,type:any) => void;
}

interface IFestivalGroupCreateState {
  checkedAmenities: Map<string, any>;
  checkedVendors: Map<string, any>;
  categorySelected: any;
  paymentSelect: any;
  stateSelected: any;
  countrySelected: any;
  amenitiesModal: any;
  vendorsModal: any;
  startDateModal: any;
  endDateModal: any;
  bannerImage: any;
  bannerImageUrl: any;
  scheduleModalPopUpDisplay: boolean;
}



export default class FestivalForm extends React.Component<
  IFestivalCreateProps,
  IFestivalGroupCreateState
> {
  public _owners:any = [];
  public _vendors:any = [];
  public _amenitiesSelectedkeys:any = [];

  constructor(props: any) {
    super(props);
    this.state = {
      checkedAmenities: new Map(),
      checkedVendors: new Map(),
      categorySelected: null,
      paymentSelect: null,
      stateSelected: this.props.state,
      countrySelected: this.props.country,
      amenitiesModal: false,
      vendorsModal: false,
      startDateModal: null,
      endDateModal: null,
      bannerImage: '',
      bannerImageUrl: '',
      scheduleModalPopUpDisplay: false
    };
    
    if(this.props.vendorOwnersList.length > 0){
      this.props.vendorOwnersList.map((item:any) => {
        if(item.Type.includes("Festival Owners") == true){
          let obj = { value: item.documentID, label: item.document.firstName+" "+item.document.lastName };
           this._owners.push(obj);  
        }
        if(item.Type.includes("Festival Vendors") == true ){
 
          let _obj = { id: item.documentID, label: item.document.businessName,img: item.document.profileImageURL };   
          this._vendors.push(_obj);  
        } 
      });
    }
  }
  
  handlestartDateChange = (date: any) => {   
    if(date != null && date.value != null){
      this.props.setStateFromChild("startDate",date.value.toLocaleDateString('en-US'));
      this.props.setDatesArray();
    }
  };
  handleendDateChange = (date: any) => {
    if(date != null && date.value != null){
      this.props.setStateFromChild("endDate",date.value.toLocaleDateString('en-US'));
      this.props.setDatesArray();
    }
  };
  handlestartDateSdlChange = (date: any) => {     
    if(date != null && date.value != null)
    this.props.setStateFromChild("ScheduleFormStartTime",date.value);
  };
  handleShendDateSdlChange = (date: any) => {
    if(date != null && date.value != null)
    this.props.setStateFromChild("ScheduleFormEndTime",date.value);
  };
  handleDisplyScheduleSubClose = () => {
    this.setState({ scheduleModalPopUpDisplay: false });
  };
  handleDisplyScheduleSubOpen = () => {
    this.setState({ scheduleModalPopUpDisplay: true });
  };
  handlePaymentChange = (paymentSelect: any) => {
    this.setState({ paymentSelect });
  };
  handleStateChange = (stateSelected: any) => {
    this.setState({ stateSelected });
  };
  handleCountryChange = (countrySelected: any) => {
    this.setState({ countrySelected });
  };
  handleCategoryChange = (categorySelected: any) => {
    this.setState({ categorySelected });
  };
  public setDatesArray = (startDate:any, endDate:any) => {
    if(startDate == '' || endDate == ''){
      return [];
    }
    var current = moment(startDate,'MM/DD/YYYY');
    var dates = [];
    while (current.isBefore(endDate)) {
      dates.push(current.format('MM-DD-YYYY'));
      current.add(1, 'days');
    }
    dates.push(moment(endDate,'MM/DD/YYYY').format('MM-DD-YYYY'));
    return dates;
  
  }
  getDates = () => {
    if(this.props.timeandSchedule._dates !== undefined){
      return Object.keys(this.props.timeandSchedule._dates).sort();
    }
    return [];
  }
  getLocation = () => {
    let location = [];
    if(this.props.address != ''){
      location.push(this.props.address);
    }
    if(this.props.city != ''){
      location.push(this.props.city);
    }
    
    if(this.state.stateSelected != null){
      location.push(this.state.stateSelected.label);
    }
    if(this.props.zipcode != ''){
      if(this.state.countrySelected != null){
        location.push(this.props.zipcode+" "+this.state.countrySelected.value);
      }else{
        location.push(this.props.zipcode);
      }
     
    }
   

    return location.join(", ");
  }
  public handleAmenitiesClose = () => {
    this.setState({ amenitiesModal: false });
  };
  public handleAmenitiesShow = () => {
    this.setState({ amenitiesModal: true});
  };
  public handleVendorsClose = () => {
    this.setState({ vendorsModal: false });
  };
  public handleVendorsShow = () => {
    this.setState({ vendorsModal: true });
  };
  getbgImag(img:any){
    return  {background: 'url('+img+') no-repeat center center'};
  }
  public render() {
    const {
      paymentSelect,
      stateSelected,
      countrySelected,
      categorySelected
    } = this.state;

    let $bannerPreview = null;
    if (this.props._festivalImages.length > 0) {
      this.props._festivalImages.map((item:any,index:any)=>{
        if(index == 0){
          if(item.isnew !== undefined && item.isnew == true){
            $bannerPreview = (<img src={ item.thmb} alt={""}/>);
            }else{
              $bannerPreview = (<img src={ item.main} alt={""}/>);
            }
        }
      });
    } else {
      //$bannerPreview = (<div className="no_banner_img"><img src={BannerDefault} alt={""}/><span>Upload</span></div>);
    }
    const SortableItem = SortableElement(({value}:any,{index}:any) => (
      <div className="Showcase__style__stylizedItem Showcase__style__gridItem banner_item" data-index={index} style={this.getbgImag(value.split("@@@")[0])}>
           <a className="delete_img" onMouseDownCapture={(e) => {this.props.handleImageDelete(value.split("@@@")[1]);  }}></a>
         </div>
   ));
   
   const SortableList = SortableContainer(({items}:any) => { 
     return (
      <div className="Showcase__Content__root">
        <div className="Showcase__Content__wrapper">
          <div className="Showcase__style__list Showcase__style__stylizedList Showcase__style__grid">
            {items.map((value:any, index:any) => (
              <SortableItem key={`item-${index}`} index={index} value={value.thmb+"@@@"+index} />
            ))}
          </div>
        </div>
       </div>
     );
   });
    return (
        <div>

            <form action="">
                <div className="row">
                    <div className="col-md-7">
                        {/* Festival overview section */}
                        <div className="box festival_overview">
                            <h4>Overview</h4>
                            <div className="form-group">
                                <label className="blue_label">Festival Name <span className="reduired">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Festival Name"
                                    name={"festivalName"}
                                    value={this.props.festivalName}
                                    onChange={this.props.setTextInput}
                                />
                                {this.props.validator.message(
                                  "festivalName",
                                  this.props.festivalName,
                                  "required"
                                )}
                            </div>
                            <div className="form-group select-group">
                                <label className="blue_label">Festival Category <span className="reduired">*</span></label>
                                <Select
                                    name={"category"}
                                    value={this.props.category}
                                    onChange={(
                                      selectedOption: ValueType<{ value: string; label: string }>
                                    ) => this.props.handleSelectChange(selectedOption, "category")}
                                    options={festivalsCategory}
                                    
                                  />
                                  {this.props.validator.message(
                                    "category",
                                    this.props.category,
                                    "required"
                                  )}
                            </div>
                            <div className="form-group">
                                <label className="blue_label">Address <span className="reduired">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Address"
                                    name={"address"}
                                    value={this.props.address}
                                    onChange={this.props.setTextInput}
                                />
                                {this.props.validator.message(
                                  "address",
                                  this.props.address,
                                  "required"
                                )}
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                    <label className="blue_label">City <span className="reduired">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter City"
                                        name={"city"}
                                        value={this.props.city}
                                        onChange={this.props.setTextInput}
                                    />
                                    {this.props.validator.message(
                                      "city",
                                      this.props.city,
                                      "required"
                                    )}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group select-group">
                                    <label className="blue_label">State <span className="reduired">*</span></label>
                                    <Select
                                        options={stateOptions}
                                        value={this.props.state}
                                        onChange={(
                                        selectedOption: ValueType<{
                                            value: string;
                                            label: string;
                                        }>
                                        ) => {
                                          this.props.handleSelectChange(selectedOption, "state")
                                          this.handleStateChange(selectedOption);

                                        }
                                        }

                                    />
                                    {this.props.validator.message(
                                      "state",
                                      this.props.state,
                                      "required"
                                    )}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                    <label className="blue_label">Zip <span className="reduired">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Zip code"
                                        name={"zipcode"}
                                        value={this.props.zipcode}
                                        onChange={this.props.setTextInput}
                                    />
                                      {this.props.validator.message(
                                          "zipcode",
                                          this.props.zipcode,
                                          "required|numeric"
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-8">
                                    <div className="form-group select-group">
                                    <label className="blue_label">Country <span className="reduired">*</span></label>
                                    <Select
                                        value={this.props.country}
                                        onChange={(selectedOption: ValueType<{value: string;label: string;}>) =>{
                                          this.props.handleSelectChange(selectedOption, "country");
                                          this.handleCountryChange(selectedOption);
                                        }}
                                        options={countryOptions}
                                    />
                                     {this.props.validator.message(
                                          "Country",
                                          this.props.country,
                                          "required"
                                        )}
                                    </div>
                                </div>
                            </div>

                          
                            <div className="row">
                              <div className="col-md-12">
                              <label className="blue_label">Date & Time</label>
                              <p></p>
                              <p></p>
                            </div>

                                <div className="col-md-4">
                                    <div className="form-group label_block">
                                    <label className="blue_label">Start Date <span className="reduired ">*</span></label>                                   
                                    <DatePickerComponent  cssClass="e-custom-style"  value={ new Date(this.props.startDate) }  change={this.handlestartDateChange}   placeholder='Select Start Date' format='MMM d, yyyy'></DatePickerComponent>
                                    {this.props.validator.message(
                                        "startDate",
                                        this.props.startDate,
                                        "required"
                                      )}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className={(this.props.startDate == undefined || this.props.startDate == '' || this.props.startDate == null) ? "form-group label_block  disabled-link" : "form-group label_block"}>
                                    <label className="blue_label">End Date <span className="reduired">*</span></label>
                                    <DatePickerComponent  cssClass="e-custom-style"  value={ new Date(this.props.endDate) }  min={new Date(this.props.startDate)} change={this.handleendDateChange}  placeholder='Select End Date' format='MMM d, yyyy'></DatePickerComponent>
                                    {this.props.validator.message(
                                        "endDate",
                                        this.props.endDate,
                                        "required"
                                      )}
                                    </div>
                                </div>
                                <div className="col-md-4">                          
                                  <a className={(this.props.startDate != '' && this.props.endDate != '' && this.props.startDate !== null && this.props.startDate !== undefined && this.props.endDate !== null && this.props.endDate !== undefined ) ? ' btn-submit right_align ' : 'btn-submit right_align disabled-link' } onClick={() => this.props.handleScheduleSubShow()}>Edit Festival & Event Schedules</a>
                                </div> 
                            </div>

                          
                            <div className="form-group">
                                <label className="blue_label">Festival Description <span className="reduired">*</span></label>
                                <textarea
                                    className="form-control"
                                    placeholder="Enter Festival Description"
                                    name={"festivalDescription"}
                                    defaultValue={this.props.festivalDescription}
                                    onChange={this.props.setTextInput}
                                ></textarea>
                                {this.props.validator.message(
                                  "festivalDescription",
                                  this.props.festivalDescription,
                                  "required"
                                )}
                            </div>

                            <div className="row">
                              <div className="col-md-8">
                                <div className="form-group select-group">
                                    <label className="blue_label">Festival Owner <span className="reduired">*</span></label>
                                    <Select
                                        name={"festivalOwner"}
                                        value={this.props.festivalOwner}
                                        onChange={(
                                          selectedOption: ValueType<{ value: string; label: string }>
                                        ) => this.props.handleSelectChange(selectedOption, "festivalOwner")}
                                        options={this._owners}
                                        
                                      />
                                      {this.props.validator.message(
                                      "festivalOwner",
                                      this.props.festivalOwner,
                                      "required"
                                    )}
                                </div>
                              
                              </div>
                              <div className="col-md-4"> <div className="form-group ">
                                      <div className="form-group">
                                      <label className="blue_label">Price <span className="reduired">*</span></label>
                                      <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Select Price"
                                          name={"price"}
                                          value={this.props.price}
                                          onChange={this.props.setTextInput}
                                      />
                                      {this.props.validator.message(
                                          "price",
                                          this.props.price,
                                          "required"
                                        )}
                                      </div>
                                  </div>
                            </div>
                           </div>
                            <div className="form-group">
                                <label className="blue_label">Contact Number <span className="reduired">*</span></label>
                                <InputMask placeholder="Ex: (202) 456-1111" className="form-control" mask="(999) 999-9999"  name={"contactNumber"} value={this.props.contactNumber} onChange={this.props.setTextInput}  />

                               
                                {this.props.validator.message(
                                  "contactNumber",
                                  this.props.contactNumber,
                                  "required|contactNumber"
                                )}
                            </div>
                            
                        </div>
                         {/* Festival overview section ends */}
                         
                         
                          {/* Festival Amenities section */}
                        <div className="box festival_overview">
                            <h4>Amenities</h4>
                            <a className="btn-bordered right_align" onClick={() => this.handleAmenitiesShow()}>Add Item +</a>
                             <div className="amenites-list">                        
                             {this.props.Amenities.map((item:any) => (
                                 <div className="amenites-item">
                                    <div className="amenites-item-inner">
                                     <a className="delete-icon"  onClick={() => this.props.handleAmenitiesDelete(item.id)}></a>
                                     <img src={require(`../../assets/images/${item.icon}`)} alt=""/>
                                     <h5>{item.name}</h5>
                                     <p>{item.description}</p>
                                   </div>
                                 </div>
                              ))}

                            </div>
                        </div>
                         {/* Festival Amenities section ends*/}

                         {/* Festival social  links section */}
                        <div className="box festival_social">
                            <h4>Social Links</h4>
                            <div className="form-group">
                                <label className="blue_label">Website</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Website Url"
                                    name={"websiteURL"}
                                    value={this.props.websiteURL}
                                    onChange={this.props.setTextInput}
                                />
                                {this.props.validator.message(
                                  "websiteUrl",
                                  this.props.websiteURL,
                                  "url"
                                )}
                            </div> 
                            <div className="form-group">
                                <label className="blue_label">Twitter</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Twitter Url"
                                    name={"twitterURL"}
                                    value={this.props.twitterURL}
                                    onChange={this.props.setTextInput}
                                />
                                {this.props.validator.message(
                                  "twitterUrl",
                                  this.props.twitterURL,
                                  "url"
                                )}
                            </div> 
                            <div className="form-group">
                                <label className="blue_label">Facebook</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Facebook Url"
                                    name={"facebookURL"}
                                    value={this.props.facebookURL}
                                    onChange={this.props.setTextInput}
                                />
                                {this.props.validator.message(
                                  "facebookUrl",
                                  this.props.facebookURL,
                                  "url"
                                )}
                            </div> 
                            <div className="form-group">
                                <label className="blue_label">Instagram </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Instagram Url"
                                    name={"instagramURL"}
                                    value={this.props.instagramURL}
                                    onChange={this.props.setTextInput}
                                />
                                 {this.props.validator.message(
                                  "instagramUrl",
                                  this.props.instagramURL,
                                  "url"
                                )}
                            </div> 
                            <div className="form-group">
                                <label className="blue_label">Other Social Link </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Social Link Url"
                                    name={"otherSocialLink"}
                                    value={this.props.otherSocialLink}
                                    onChange={this.props.setTextInput}
                                />
                                 {this.props.validator.message(
                                  "otherSocialLink",
                                  this.props.otherSocialLink,
                                  "url"
                                )}
                            </div> 
                        </div>
                         {/* Festival social links section ends*/}

                         {/* Festival Vendors section */}
                        <div className="box festival_overview">
                            <h4>Vendors</h4>
                            <a className="btn-bordered right_align" onClick={() => this.handleVendorsShow()}>Add Vendor +</a>
                             <div className="vendor-list">
                             {this.props.Vendors != null && this.props.Vendors.map((item:any) => (
                                <div className="vendor-item">
                                    <div className="vendor-item-inner">
                                    <img src={item.profileImageURL} alt=""/>
                                     <div className="vendor_content">
                                        <a className="delete-icon"  onClick={() => this.props.handleVendorsDelete(item.documentID)}></a>
                                        <div>
                                            <span>Vendor Name</span>
                                            <p className="capitalize">{item.businessName}</p>
                                        </div>
                                        <div>
                                            <span>Category</span>
                                            <p className="capitalize">{item.category.join()}</p>
                                        </div>
                                        <div>
                                            <span>Payment Type</span>
                                            <p className="capitalize">{item.paymentType.join()}</p>
                                        </div>
                                     </div>
                                   </div>
                                 </div>

                             ))}
                            </div>
                        </div>
                         {/* Festival Vendors section ends*/}

                         {/* Festival Vendors section */}
                       

                    </div>
                    <div className="col-md-5 festival_edit">
                        {/* Festival Vendors section */}
                        <div className="form-group">
                          <h4>Upload Banner Images <span className="reduired">*</span> </h4>
                          <i className="smallhint"><span className="reduired">*</span> Aspect ratio: 1.6:1 | Min Width:480px, Height: 300px</i>

                          <label className="banner_upload">
                            <div className="banner_img">
                              {$bannerPreview}
                            </div>
                          </label>
                         
                        </div>


                        

                        <SortableList   axis="xy"   items={this.props._festivalImages} onSortEnd={this.props.onSortEnd} />
                        <div className="form-group">
                          <label className="banner_upload banner_upload_edit">
                            <input
                              type="file"
                              multiple
                              className="form-control"
                              placeholder="Upload"  onChange={(e)=>this.props._handleBannerImageChange(e)}
                            />
                            <div className="banner_img">
                            <div className="no_banner_img onedit"><img src={BannerDefault} /><span>Upload</span></div>
                            </div>
                          </label>
                          {this.props.validator.message(
                                  "BannerImages",
                                  this.props._festivalImages,
                                  "required"
                                )}
                        </div>
                        <div className="mobile_preview">
                             <p className="preview_title">MOBILE PREVIEW</p>
                            <div className="mobile_preview_inner">
                            <img className="topicons" src={topicons}/>
                                <div className="mobile_preview_container">
                                  <div className="mobile_banner" >
                                    {this.props._festivalImages.length > 0 ? (
                                              $bannerPreview
                                    ):(
                                      <img src={""} alt={""}/>
                                    )}
                                   
                                    <div className="mobile_banner_title">
                                      <h2>{this.props.festivalName !== '' ? this.props.festivalName : "Festival name will be here"}</h2>
                                    </div>
                                  </div>
                                  <div className="mobile_tabs">
                                    <Tabs id="mobile_tabs" defaultActiveKey="overview" >
                                      <Tab eventKey="overview" title="Overview">
                                         <div className="mobile_location border_bottom_preview">
                                             <label className="blue_label">LOCATION </label>
                                             <h4>{this.getLocation() !== '' ? this.getLocation()
                                               : "Festival Location Will Be Here"}</h4>
                                         </div>
                                        
                                         <div className="mobile_overview_info border_bottom_preview">
                                            <div className="mobile_overview_item dsp">
                                                <label className="blue_label">DATE </label>
                                                <p>{(this.props.endDate != '') ? this.props.startDate+" - "+this.props.endDate: ''}</p>
                                            </div>
                                            <div className="mobile_overview_item dsp">
                                                <label className="blue_label">Time </label>
                                                <a className="time small"   onClick={() => this.handleDisplyScheduleSubOpen()}>See Times</a>

                                            </div>
                                            <div className="mobile_overview_item dsp">
                                                <label className="blue_label">PRICE </label>
                                                <p>{this.props.price}</p>
                                            </div>
                                         </div>
                                         <div className="mobile_overview_info border_bottom_preview">
                                            <p className="truncate-overflow">{this.props.festivalDescription}</p>
                                         </div>
                                         
                                         <div className="mobile_amenites">
                                          {this.props.Amenities.map((item:any) => (
                                              <div className="amenites-item">
                                                  <div className="amenites-item-left">
                                                     <img src={require(`../../assets/images/${item.icon}`)} alt=""/>
                                                  </div>
                                                  <div className="amenites-item-right">
                                                  <h5>{item.name}</h5>
                                                  <p>{item.description}</p>
                                                </div>
                                                <div className="amenites-item-right">
                                                     <img src={arrowgreen} alt=""/>
                                                  </div>
                                              </div>
                                            ))}
                                         </div>
                                         <div className="overview_footer ">
                                             <button className={(this.props.websiteURL == '') ? 'disabled-link social btn-submit' : 'btn-submit'}> 
                                              <Link className="buttonlink" to={this.props.websiteURL} >
                                              Event Website
                                                </Link>
                                              </button>
                                             <Link className={(this.props.facebookURL == '') ? 'disabled-link' : ''} to={this.props.facebookURL} >
                                                <img src={(this.props.facebookURL == '') ? facebookDis : facebook} />
                                              </Link>
                                              <Link className={(this.props.twitterURL == '') ? 'disabled-link' : ''} to={this.props.twitterURL} >
                                              <img src={(this.props.twitterURL == '') ? twitterDis : twitter} />
                                              </Link>
                                              <Link className={(this.props.instagramURL == '') ? 'disabled-link' : ''} to={this.props.instagramURL} >
                                                <img src={(this.props.instagramURL == '') ? instagramDis : instagram} />
                                              </Link>
                                         </div>
                                      </Tab>
                                      <Tab eventKey="vendors" title="Vendors">
                                      <img src={top_v_btn}/>
                                        <div className="mobile_vendors_check_list">
                                          {this.props.Vendors != null && this.props.Vendors.map((item:any) => (
                                              <div className="vendor_list_item ">
                                                
                                                <div className="vendor_img">
                                                {(item.profileImageURL != "") ? (
                                                    <img  src={item.profileImageURL} alt={""}></img>
                                                  ): <img  alt={""}></img>}
                                                </div>

                                                <div className="vendor_details">
                                                   <h3>{item.businessName}</h3>
                                                   <div className="rating">
                                                      <StarRatingComponent 
                                                      name="rate1" 
                                                      starCount={5}
                                                      value={4}
                                                      starColor={'#58B5C9'}
                                                      emptyStarColor={'#e2e2e2'}
                                                      />
                                                      <p>97 Ratings</p>
                                                    </div>
                                                   <p className="capitalize">{item.category.join()}</p>
                                                   <p className="capitalize">$ {item.paymentType.join()}</p>
                                                </div>
                                                
                                                  
                                              
                                              </div>
                                            ))}
                                        </div>
                                      </Tab>
                                      <Tab eventKey="schedule" title="Schedule">
                                          <img src={headerp}/>
                                          {this.getDates().map((value:any, index:any) => (
                                          <div>
                                          {this.props.timeandSchedule._dates[value].schedules.map((item:any,index:Number) => ( 
                                            <div>
                                               <p className="time">{value}</p>

                                            <table className="mobile_schedule">
                                              <tr>
                                                <td> <span className="time"> <p>{ moment(new Date(item.StartTime)).format("LT") }</p></span></td>
                                                <td>
                                                  <p>{item.title}</p>
                                                  <span>{item.Venue}</span>
                                                </td>
                                                <td>
                                                  <span className="more_icon"></span>
                                                </td>
                                              </tr>
                                            </table>  
                                            </div>                                          
                                        ))}
                                          </div>
                                        )
                                        )}
                                      </Tab>
                                   
                                    </Tabs>
                                  </div>
                                  </div>
                            </div>
                        </div>
                   </div>
                </div>
                <div className="festival_header">
                  <div className="action_buttons text-right ">
                      <button className="btn-bordered"  onClick={(e)=>this.props.handleSave(e)} >Save</button>
                      <button className="btn-submit"  onClick={(e)=>this.props.handleSubmit(e)}>Publish Festival</button>
                  </div>
                </div>
            </form>
        
        
        <Modal
          className="custom_modal_1"
          show={this.state.amenitiesModal}
          onHide={() => this.handleAmenitiesClose()}
        >
          <Modal.Header>
            <Modal.Title>Amenities</Modal.Title>
            <a className="modalClose" onClick={() => this.handleAmenitiesClose()}>
              <img src={closeBtn}></img>
            </a>
          </Modal.Header>
          <Modal.Body>
             <div className="amenites_check_list">
                 <React.Fragment>
                    {this.props.amenitieOptions.map((item:any) => (
                      <div className="checkBoxButton ">
                        <CheckBox
                          id={item.id}
                          name={item.label}
                          checked={this.props.checkedAmenities.get(item.id)}
                          onChange={this.props.handleAmenitiesChange} 
                        />
                        <label
                          className="checkboxlabel"
                          htmlFor={item.id}
                          key={item.id}
                        >
                          {item.label}
                        </label>
                      </div>
                    ))}
                  </React.Fragment>
             </div>
          </Modal.Body>
          <Modal.Footer>
              <span>{this.props.checkedAmenities.size} Selected</span>
              {this.props.checkedAmenities.size> 0 ? (
                <a className="btn-submit" onClick={() => this.handleAmenitiesClose()}>Submit</a>
              ): (
                <a className="btn-submit disabled" onClick={() => this.handleAmenitiesClose()}>Submit</a>
              )}
          </Modal.Footer>
        </Modal>

        <Modal
          className="custom_modal_1"
          show={this.state.vendorsModal}
          onHide={() => this.handleVendorsClose()}
        >
          <Modal.Header>
            <Modal.Title>Vendors</Modal.Title>
            <div className="icon-input search-icon">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Vendor Business name or Name"
              />
            </div>
            <a className="modalClose" onClick={() => this.handleVendorsClose()}>
              <img src={closeBtn}></img>
            </a>
          </Modal.Header>
          <Modal.Body>
             <div className="vendors_check_list">
                 <React.Fragment>
                    {this._vendors.map((item:any) => (
                      <div className="checkBoxButton ">
                        <CheckBox
                          id={item.id}
                          name={item.label}
                          checked={this.props.checkedVendors.get(item.id)}
                          onChange={this.props.handleVendorsChange}
                        />
                        <label
                          className="checkboxlabel capitalize"
                          htmlFor={item.id}
                          key={item.id}
                        >
                          {(item.img != "") ? (
                            <img  src={item.img} alt={""}></img>
                          ): <img  alt={""}></img>}
                          {item.label}
                        </label>
                      </div>
                    ))}
                  </React.Fragment>
             </div>
          </Modal.Body>
          <Modal.Footer>
              <span>{this.props.checkedVendors.size} Selected</span>
              {this.props.checkedVendors.size> 0 ? (
                <a className="btn-submit" onClick={() => this.handleVendorsClose()}>Add to Festival</a>

              ): (
                <a className="btn-submit disabled" onClick={() => this.handleVendorsClose()}>Add to Festival</a>
              )}
             
          </Modal.Footer>
        </Modal>

        
        <Modal
          className="custom_modal_1 schedule_pop"
          show={this.props.scheduleModalPopUp}
          onHide={() => this.props.handleScheduleSubClose()}
        >
              <Modal.Header className="row">
                <Modal.Title className="col-md-12">Manage Festival & Event Schedules</Modal.Title>
                <a className="modalClose" onClick={() => this.props.handleScheduleSubClose()}>
                  <img src={closeBtn}></img>
                </a>
              </Modal.Header>
              <Modal.Body>
              <Tabs className="festival_nav" defaultActiveKey="FestivalSchedule" id="time-tab-schedule">
                  <Tab eventKey="FestivalSchedule" title="Festival Schedule">                    
                    <div className="row">
                      <div className="col-md-12">
                        <div className="checkBoxButton checkBoxButton_inline">
                            <CheckBox 
                            id={'isPerDay'}
                            name={'isPerDay'}
                            checked={this.props.timeandSchedule.isPerDay}
                            onChange={(e:any) => this.props.setIsPerDay(e)}
                            />
                            <label className="checkboxlabel"  htmlFor={'isPerDay'} key={'isPerDay'}>Create schedule per day</label>
                          </div>
                      </div>
                      {this.props.timeandSchedule.isPerDay === undefined ||  this.props.timeandSchedule.isPerDay === false ? (
                         <div className="col-md-12">  
                           <div className="row">
                            <div className="col-md-12">
                            <span className="datelabval">{moment(this.props.startDate,'MM/DD/YYYY').format("MM-DD-YYYY")} | {moment(this.props.endDate,'MM/DD/YYYY').format("MM-DD-YYYY")}</span>

                            </div>
                            <div className="col-md-4">
                                <div className="form-group label_block">
                                  <label className="blue_label">Start Time <span className="reduired ">*</span></label>                                   
                                  <TimePickerComponent  value={(this.props.commonStartTime != '') ? new Date(this.props.startDate+" " + this.props.commonStartTime ) : undefined}  min={new Date(this.props.startDate)} max={new Date(moment(this.props.startDate+" 11:30 PM" ).format("MM-DD-YYYY hh:mm A"))} change={(e) => this.props.handleShCommonDateTimeSdlChange(e,"start")} placeholder="Start Time" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group label_block">
                                  <label className="blue_label">End Time <span className="reduired">*</span></label>
                                  <TimePickerComponent  value={(this.props.commonEndTime != '') ? new Date(this.props.startDate+" " + this.props.commonEndTime ) : undefined}   min={new Date(this.props.startDate+" " + this.props.commonStartTime )} max={new Date(moment(this.props.startDate+" 11:30 PM" ).format("MM-DD-YYYY hh:mm A"))} change={(e) => this.props.handleShCommonDateTimeSdlChange(e,"end")}  placeholder="Start Time" />
                                </div>
                            </div>
                            <div className="col-md-1"></div>
                          </div>
  
                         
                         </div>  

                      ):(
                        <div className="col-md-12">    
                        {this.getDates().map((value:any, index:any) => (
                          <div className="row">
                            <div className="col-md-3">
                                <div className="form-group">
                                  <label className="blue_label">Date</label>  
                                  <p className="datelabval">{value}</p>                                   
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group label_block">
                                  <label className="blue_label">Start Time <span className="reduired ">*</span></label>                                   
                                  <TimePickerComponent  value={(this.props.timeandSchedule._dates[value].startTime != '') ? new Date(value+" " + this.props.timeandSchedule._dates[value].startTime ) : undefined}  min={new Date(value)} max={new Date(moment(value+" 11:30 PM" ).format("MM-DD-YYYY hh:mm A"))} change={(e) => this.props.handleShStartDateTimeSdlChange(e,value,this.props.timeandSchedule._dates[value].startTime)}  placeholder="Start Time" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group label_block">
                                  <label className="blue_label">End Time <span className="reduired">*</span></label>
                                  <TimePickerComponent value={(this.props.timeandSchedule._dates[value].endTime != '') ? new Date(value+" " + this.props.timeandSchedule._dates[value].endTime ) : undefined}  min={new Date(value+" " + this.props.timeandSchedule._dates[value].startTime )} max={new Date(moment(value+" 11:30 PM" ).format("MM-DD-YYYY hh:mm A"))} change={(e) => this.props.handleShendDateTimeSdlChange(e,value,this.props.timeandSchedule._dates[value].endTime)}  placeholder="End Time" />
                                </div>
                            </div>
                            <div className="col-md-1"></div>
                          </div>
  
                          ))                 
                        }                  
                        </div>

                      )}
                   
                      <div className="col-md-12 FestivalScheduleFooter"> 
                        <div className="row rightmzero">
                          <div className="col-md-8 footerInnerlabBox">
                              <span className="lable">Festival Date:</span>
                              <span className="datelabval">{moment(this.props.startDate,'MM/DD/YYYY').format("MM-DD-YYYY")} | {moment(this.props.endDate,'MM/DD/YYYY').format("MM-DD-YYYY")}</span>
                          </div>
                          <div className="col-md-4 pull-right">  
                             <button className="btn-submit" type="submit"  onClick={() => this.props.handleScheduleSubClose()}> Add </button>
                          </div>
                        </div>
                      </div> 
                    </div>         
                   </Tab>
                  <Tab eventKey="EventsSchedule" title="Events Schedule"> 
                      {this.props.isValidSchedules() === false ? (
                        <h6 className="error">Please add a Festival Schedule before adding Events.</h6>

                      ) : (
                      <div>
                        {this.props.scheduleModal === true ? (
                          <div className="col-md-12 sceduleForm">
                            <form className="white_panel scheduleModalform form_pading festival_module" action="" onSubmit={this.props.handleScheduleSubmit}>
                            <div className="col-md-12 zeropm"> <h4 className="zeropm">
                              {this.props.ScheduleFormIndex !== -1 ? 'Edit Schedule': 'Add Schedule'}

                            
                            </h4></div>
                            <div className="col-md-8 zeropm">
                                <span className="datelabval"><span className="glabel"> Schedule of </span>{this.props.getSchedulesActive()} </span>   
                                <span className="glabel"> From: </span><span className="datelabval small">{this.props.timeandSchedule._dates[this.props.getSchedulesActive()].startTime }</span>   
                                <span className="glabel"> To: </span> <span className="datelabval small">{this.props.timeandSchedule._dates[this.props.getSchedulesActive()].endTime }</span>   

                              </div>
                                <div className="form-group">
                                    <label className="blue_label">Title <span className="reduired">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Title"
                                        name={"ScheduleFormTitle"}                                          
                                        onChange={this.props.setTextInput}
                                        value={this.props.ScheduleFormTitle}
                                    />
                                    {this.props.validatorSchedule.message(
                                      "Title",
                                      this.props.ScheduleFormTitle,
                                      "required"
                                    )}
                                </div>
                                <div className="row form-group">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="blue_label">Start Time <span className="reduired">*</span></label>{}
                                      <TimePickerComponent  min={ new Date(this.props.getSchedulesActive() + " " +this.props.timeandSchedule._dates[this.props.getSchedulesActive()].startTime)} max={ new Date(this.props.getSchedulesActive() + " " +this.props.timeandSchedule._dates[this.props.getSchedulesActive()].endTime)} value={new Date(this.props.ScheduleFormStartTime)} change={this.handlestartDateSdlChange}  placeholder="Select Time" />
                                      {this.props.validatorSchedule.message(
                                          "StartTime",
                                          this.props.ScheduleFormStartTime,
                                          "required"
                                        )}

                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                  <div className="form-group">
                                      <label className="blue_label">End Time <span className="reduired">*</span></label>
                                      <TimePickerComponent     min={ new Date(this.props.getSchedulesActive() + " " +this.props.timeandSchedule._dates[this.props.getSchedulesActive()].startTime)} max={ new Date(this.props.getSchedulesActive() + " " +this.props.timeandSchedule._dates[this.props.getSchedulesActive()].endTime)} value={new Date (this.props.ScheduleFormEndTime)} change={this.handleShendDateSdlChange}  placeholder="Select Time" />
                                      {this.props.validatorSchedule.message( 
                                          "EndTime",
                                          this.props.ScheduleFormEndTime,
                                          "required"
                                        )}
                                    
                                    </div>
                                  </div> 
                                </div>

                                <div className="form-group">
                                    <label className="blue_label">Description <span className="reduired">*</span></label>
                                    <textarea  className="form-control"
                                    name={"ScheduleFormDescription"}                                          
                                    onChange={this.props.setTextInput}
                                    defaultValue={this.props.ScheduleFormDescription}                  
                                    ></textarea>
                                    {this.props.validatorSchedule.message(
                                          "Description",
                                          this.props.ScheduleFormDescription,
                                          "required"
                                        )}
                                </div>

                                <div className="form-group">
                                    <label className="blue_label">Venue <span className="reduired">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Venue"
                                        name={"ScheduleFormVenue"}                                          
                                        onChange={this.props.setTextInput}
                                        value={this.props.ScheduleFormVenue}
                                    />
                                    {this.props.validatorSchedule.message(
                                          "Venue",
                                          this.props.ScheduleFormVenue,
                                          "required"
                                        )}
                                </div>
                                {this.props.startDate === '' ? (<div className="srv-validation-message">The start date and end field are required to add Schedule.</div>) : (
                                  <button className="btn-submit" type="submit"> Submit </button>

                                )}
                                </form>
                          </div>
                      ) : (
                        <div className="row">
                          <div className="col-md-3 EventsScheduleleft">
                              <div className="dates">
                                  <span className="lable">Festival Date:</span>
                                  <span className="datelabval">{moment(this.props.startDate,'MM/DD/YYYY').format("MM-DD-YYYY")} | {moment(this.props.startDate,'MM/DD/YYYY').format("MM-DD-YYYY")}</span>
                              </div>
                              <div className="col-md-12 zeropm">
                              {this.setDatesArray(this.props.startDate,this.props.endDate).map((value:any, index:any) => (
                                  <a  onClick={() => this.props.setSchedulesActive(value)} className={(this.props.getSchedulesActive() == value) ? "active btn-bordered right_align" : "btn-bordered right_align"}>{value}</a>
                              ))}
                            </div>
                          </div>
                          <div className="col-md-9 EventsScheduleright" >
                            {(this.props.timeandSchedule._dates !== undefined && this.props.timeandSchedule._dates[this.props.getSchedulesActive()] !== undefined) ? (
                            <div className="row mt10">
                              <div className="col-md-8">
                                <span className="datelabval">{this.props.getSchedulesActive()} </span>   
                                <span className="glabel"> From: </span><span className="datelabval small">{this.props.timeandSchedule._dates[this.props.getSchedulesActive()].startTime }</span>   
                                <span className="glabel"> To: </span> <span className="datelabval small">{this.props.timeandSchedule._dates[this.props.getSchedulesActive()].endTime }</span>   

                              </div>
                              <div className="col-md-4">
                              <a  onClick={() => this.props.handleScheduleShow()}  className="btn-bordered right_align">Add New +</a>
                              
                        
                              </div>
                              <div className="schedule-list page_container">
                                  <table className="table">
                                    <tbody>
                                      {this.props.timeandSchedule._dates[this.props.getSchedulesActive()].schedules.map((item:any,index:Number) => (
                                          <tr>
                                              <td>
                                                  <p className="capitalize">{item.title}</p>
                                                  <span className="capitalize">{item.Venue}</span>
                                              </td>
                                              <td>
                                                  <span className="time"> <p>{ moment(new Date(item.StartTime)).format("LT") }</p></span>
                                              </td>
                                              <td>
                                              <Dropdown drop="left" className="table_menu">
                                                  <Dropdown.Toggle id="dropdown-basic">
                                                  <span className="more_icon"></span>
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu>

                                                  <div role="presentation">
                                                      <a  onClick={() => this.props.handleScheduleEdit(item,index)}>
                                                        <FontAwesomeIcon icon={faEdit} />{" "}
                                                        <i className="fa fa-eye"></i> Edit
                                                      </a>
                                                  </div>
                                                
                                                  <div role="presentation">
                                                  <a  onClick={() => this.props.handleScheduleDelete(item,index)}>
                                                      <FontAwesomeIcon icon={faTrashAlt} /> Delete
                                                      </a>
                                                  </div>
                                                  </Dropdown.Menu>
                                              </Dropdown>
                                              </td>
                                          </tr>
                                      ))}
                                  </tbody>
                                  </table>
                                
                              </div>
                            </div>
                            ) : null}
                          </div>
                    </div>
                      )}
                    </div>
                    )}


                  </Tab>
              </Tabs>
              </Modal.Body>
              {/* <Modal.Footer>
              </Modal.Footer> */}
        </Modal>

        <Modal
          className="custom_modal_1"
          show={this.state.scheduleModalPopUpDisplay}
          onHide={() => this.handleDisplyScheduleSubClose()}
          >
              <Modal.Header className="row">
                <Modal.Title className="col-md-12">Festival Schedule Times</Modal.Title>
                <a className="modalClose" onClick={() => this.handleDisplyScheduleSubClose()}>
                  <img src={closeBtn}></img>
                </a>
              </Modal.Header>
              <Modal.Body>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="checkBoxButton checkBoxButton_inline disabled">
                            <CheckBox 
                            id={'isPerDay'}
                            name={'isPerDay'}
                            disabled={true}
                            checked={this.props.timeandSchedule.isPerDay}
                            onChange={(e:any) => this.props.setIsPerDay(e)}
                            />
                            <label className="checkboxlabel"  htmlFor={'isPerDay'} key={'isPerDay'}>Create schedule per day</label>
                          </div>
                      </div>
                      {this.props.timeandSchedule.isPerDay === undefined ||  this.props.timeandSchedule.isPerDay === false ? (
                        <div className="col-md-12">  
                          <div className="row">
                            <div className="col-md-12">
                            <span className="datelabval">{moment(this.props.startDate,'MM/DD/YYYY').format("MM-DD-YYYY")} | {moment(this.props.endDate,'MM/DD/YYYY').format("MM-DD-YYYY")}</span>

                            </div>
                            <div className="col-md-5">
                                <div className="form-group label_block">
                                  <label className="blue_label">Start Time <span className="reduired ">*</span></label>  
                                      <span className="datelabval">{this.props.commonStartTime }</span>                                
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group label_block">
                                  <label className="blue_label">End Time <span className="reduired">*</span></label>
                                  <span className="datelabval">{this.props.commonEndTime }</span>       
                              </div>
                            </div>
                          </div>

                        
                        </div>  

                      ):(
                        <div className="col-md-12">    
                        {this.getDates().map((value:any, index:any) => (
                          <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                  <label className="blue_label">Date</label>  
                                  <p className="datelabval">{value}</p>                                   
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group label_block">
                                  <label className="blue_label">Start Time <span className="reduired ">*</span></label>       
                                  <span className="datelabval">{this.props.timeandSchedule._dates[value].startTime }</span>                             
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group label_block">
                                  <label className="blue_label">End Time <span className="reduired">*</span></label>
                                  <span className="datelabval">{this.props.timeandSchedule._dates[value].endTime }</span>  
                                </div>
                            </div>
                          </div>

                          ))                 
                        }                  
                        </div>

                      )}
                    </div> 
              </Modal.Body>
              {/* <Modal.Footer>
              </Modal.Footer> */}
          </Modal>


      </div>
    );
  }
}
