import * as React from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { RouteComponentProps, NavLink } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import SimpleReactValidator from "simple-react-validator";
import { AppState } from "../../state/types";
import { userGroupList } from "../../state/userGroups/actions";
import {
  userManagementCreate,
  userManagementEdit
} from "../../state/userManagement/actions";
import UserManagementForm from "../../components/Security/UserManagementForm";
import { toast } from "react-toastify";
import { onUpdateTokenNone } from '../../state/session/actions';

export interface IUserManagementCreateProps {}

interface StateProps {
  userGroups: any;
  createResponse: boolean;
  isLoading: boolean;
  success: boolean;
  hasErrored: boolean;
  checkedItems: Map<string, string>;
  response: any;
  rand: any;
  profile: any;
}

interface DispatchProps {
  getAllUserGroups: () => void;
  userManagementEdit: (
    email: string,
    contactEmail: string,
    phoneNumber: string,
    firstName: string,
    lastName: string,
    userGroup: any,
    userId: string,    
    profilePicbase: string,
    tags: any
  ) => void,  
  updateToken: () => void,
}
interface Input {
  groupName: string;
  groupDescription: string;
}
interface GState extends Input {
  checkedItems: Map<string, string>;
}

interface State {
  firstName: string;
  lastName: string;
  email: string;
  contactEmail: string;
  phoneNumber: string;
  userGroup: any;   
  checkedItems: Map<string, string>; 
  profilePic: any;
  profilePicbase: any;
}

type Props = DispatchProps &GState & StateProps & RouteComponentProps;

class UserManagementEdit extends React.Component<Props, State> {
  public state = {
    isUpdate: true,
    firstName: this.props.location.state.firstName,
    lastName: this.props.location.state.lastName,
    email: this.props.location.state.email,
    contactEmail: this.props.location.state.contactEmail,
    phoneNumber: this.props.location.state.phoneNumber,
    userGroup: this.props.location.state.groupId,    
    checkedItems: new Map(),
    profilePic: this.props.location.state.profilePic,
    profilePicbase: this.props.location.state.profilePic,
  };
  private validator: any;
  constructor(props: any) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this,  validators: {
      phoneNumber: {
        message: 'The :attribute must be a valid phone Number',
        rule: (val:any, params:any, validator:any) => {
          return validator.helpers.testRegex(val,/^((\([0-9]{3}\))|[0-9]{3})[\s\-][\0-9]{3}[-][0-9]{4}$/i) && params.indexOf(val) === -1
        },
        required: true
      }
    } });
    this.handleChange = this.handleChange.bind(this);

  }
  componentDidMount() {
    document.title = "Edit User | FestFrwrd - The Futr of FSTIVLS";
    this.props.getAllUserGroups();
    this.setState((state, props) => { 
      if( this.props.location.state.groupId){
        this.props.location.state.groupId.map(
          (item: { id: string; name: string }) => {
            state.checkedItems.set(item.id, item.name);
          }
        );
      }
      return {
        checkedItems: state.checkedItems
      };
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.response !== this.props.response && this.props.success) { 
      if(this.props.profile.profile.email == this.state.email){
        this.props.updateToken();
      }else{
        this.props.history.goBack();
      }     
    }
    if (prevProps.profile.token !== this.props.profile.token) {
      this.props.history.goBack();
    }
    if (
      prevProps.response !== this.props.response &&
      this.props.hasErrored
    ) {   
      toast.error((this.props.response.error == null) ? this.props.response.message : this.props.response.error);   
      // toast.update(1, {
      //   render: (this.props.response.error == null) ? this.props.response.message : this.props.response.error,
      //   type: toast.TYPE.ERROR,
      //   autoClose: 2000,
      //   hideProgressBar: false
      // });
    }   
  }
  private deleteFileInput = (event: any) => {
    this.setState({ profilePicbase: "" });
  };
  private setTextInput = (event: any) => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    } as Pick<State, keyof State>);
  };
  getBase64(file:any, cb:any) {
    let reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}
  private setFileInput = (event: React.FormEvent<HTMLInputElement>) => {
    console.log("ggg",event.currentTarget.files);
    let files = event.currentTarget.files;
    this.getBase64(files, (result:any) => {
        this.setState({ profilePicbase: result });
    });
  };

  setRadioButton = (event: React.SyntheticEvent<HTMLInputElement, Event>) => {
    console.log(event.currentTarget.value);
    this.setState({ userGroup: event.currentTarget.value });
  };
  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const item = e.target.id;
    const name = e.target.name;
    const isChecked = e.target.checked;
    if (!isChecked) {
      this.setState((state, props) => {
        state.checkedItems.delete(item);
        return {
          checkedItems: state.checkedItems
        };
      });
    } else {
      this.setState((state, props) => {
        state.checkedItems.set(item, name);
        return {
          checkedItems: state.checkedItems
        };
      });
    }
  }
  handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (this.validator.allValid()) {
      let keys = [...this.state.checkedItems.keys()];
      let values = [...this.state.checkedItems.values()];
      let _userGroups:any = [];
      let userGroups = keys.map((key, index) => (
          _userGroups.push({
            id: key,
            name: values[index]
          })
      ));
      this.props.userManagementEdit(
        this.state.email,
        this.state.contactEmail,
        this.state.phoneNumber,
        this.state.firstName,
        this.state.lastName,
        _userGroups,        
        this.state.profilePicbase,
        this.props.location.state.id,
        this.props.location.state.tags
      );
    } else {
      toast.dismiss(2);
      toast.warn("Please fill in all required fields!",{toastId:2});
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  public render() {
    if (this.props.userGroups.length === 0) {
      return <p>Loading</p>;
    }
    return (
        <div>
          <div className="inner-title">
            <h3>Edit USER MANAGEMENT</h3>
            <div className="path_navigation">
              <ul>
                <li>
                  <NavLink to="/security">SECURITY Management</NavLink>
                </li>
                <li>
                  <NavLink to="/security/UserManagement">USER MANAGEMENT</NavLink>
                </li>
                <li>
                  <span>Edit USER</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="white_panel form_pading">
            <UserManagementForm
              isUpdate={this.state.isUpdate}
              email={this.state.email}
              contactEmail={this.state.contactEmail}
              firstName={this.state.firstName}
              handleSubmit={this.handleSubmit}
              lastName={this.state.lastName}
              phoneNumber={this.state.phoneNumber}              
              profilePic={this.state.profilePic}
              setRadioButton={this.setRadioButton}
              setTextInput={this.setTextInput}
              setFileInput={this.setFileInput}
              deleteFileInput={this.deleteFileInput}
              profilePicbase={this.state.profilePicbase}
              userGroup={this.state.userGroup}
              userGroups={this.props.userGroups}              
              checkedItems={this.state.checkedItems}
              handleChange={this.handleChange}
              validator={this.validator}
            />
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  profile: state.session,
  userGroups: state.userGroups.UserGroupList.success,
  response: state.userManagement.UserManagementEdit.response,
  rand: state.userManagement.UserManagementEdit.rand,
  createResponse: state.userManagement.UserManagementEdit.success,
  isLoading: state.userManagement.UserManagementEdit.isLoading,
  success: state.userManagement.UserManagementEdit.success,
  hasErrored: state.userManagement.UserManagementEdit.hasErrored
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, Action>
): DispatchProps => ({
  getAllUserGroups: () => dispatch(userGroupList()),
  userManagementEdit: (
    email,
    contactEmail,
    phoneNumber,
    firstName,
    lastName,
    userGroup,
    profilePicbase,
    userId,
    tags
  ) =>
    dispatch(
      userManagementEdit(
        email,
        contactEmail,
        phoneNumber,
        firstName,
        lastName,
        userGroup,
        profilePicbase,
        userId,
        tags
      )
    ),
    updateToken: () => dispatch(onUpdateTokenNone())
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementEdit);
