import React, { useState, useEffect } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import logo from "../../assets/images/logo_2x.png";

function getParameterByName(name:any, url:any) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
export const PreAcceptingInvitation = () => {
  const params: {
    uid?: string;
    temppin?: string;
    GroupNames?: string;
  } = useParams();
var hasval = decodeURIComponent(window.location.hash);
hasval = hasval.replace('#','');
var groupnames = getParameterByName('GroupNames',hasval);
var _pin:any = getParameterByName('_pin',hasval);
var username = getParameterByName('username',hasval);
var name = getParameterByName('name',hasval);
var istmppin = false;
if(_pin.indexOf("**") > -1){
  istmppin = true;
}
  return (   
    <div>
      {groupnames == 'Festival Vendors' ? (
      <div className="non--login preemailform">
        <div className="login-form alert-messages">
          <div className="logo_img">
            <img src={logo} alt="F" />
            <h1>
              <span>FEST</span>FRWRD
            </h1>
          </div>

          <div className="login-form--inner text-center">
            <img alt="" />
            <h2>To Accept, Reject, or Opt-out of this invitation please click below.</h2>
            {/* <p className="text-left">
            <b>Hi, {name}</b>
            </p> */}
            <p className="text-left">
              <b>Once you’ve accepted our invitation use the credentials we’ve sent you to log into our Vendor portal and begin interacting with your potential customers today.</b>
            </p>
          {/* {istmppin == true ?(
              <p className="text-center">
              <div className="row">
                <div className="col-md-12 form-group">
                  <label className=''>USERNAME</label>
                  <p><b>{username}</b></p>
                  <p>Since you are an existing user, you may continue using your existing PIN</p>
                  <p><b>{_pin} </b></p>
                </div>
              </div>
            </p>
          ):(  <p className="text-left">
                <div className="row">
                  <div className="col-md-8 form-group">
                    <label className=''>USERNAME</label>
                    <p><b>{username}</b></p>
                  </div>
                  <div className="col-md-4 form-group">
                    <label className=''>PIN</label>
                    <p><b>{_pin}</b></p>
                  </div>
                </div>
              </p>)}
           */}
            <div className="text-center">
              <NavLink to={"/invitation-accepted/"+hasval}
                className="btn accept"
              >
              ACCEPT                
              </NavLink>&nbsp;
              <NavLink  to={"/invitation-rejected/"+hasval}
                className="btn reject"
              >
              REJECT                
              </NavLink>&nbsp;
              <NavLink  to={"/invitation-optout/"+hasval}
                className="btn optout1"
              >
              OPT OUT                
              </NavLink>
            </div>
          </div>
        </div>
      </div>
     ):(
      <div className="non--login preemailform">
      <div className="login-form alert-messages">
        <div className="logo_img">
          <img src={logo} alt="F" />
          <h1>
            <span>FEST</span>FRWRD
          </h1>
        </div>

        <div className="login-form--inner text-center">
          <img alt="" />
          <h2>To Accept, Reject, or Opt-out of this invitation please click below.</h2>
          {/* <p className="text-left">
            <b>Hi, {name}</b>
          </p> */}
          <p className="text-center">
            <b>Once you’ve accepted our invitation use the credentials we’ve sent you to login and start interacting with us today.</b>
          </p>
          {/* {istmppin == true ?(
              <p className="text-center">
              <div className="row">
                <div className="col-md-12 form-group">
                  <label className=''>USERNAME</label>
                  <p><b>{username}</b></p>
                  <p>Since you are an existing user, you may continue using your existing PIN</p>
                  <p><b>{_pin} </b></p>
                </div>
              </div>
            </p>
          ):(  <p className="text-left">
                <div className="row">
                  <div className="col-md-8 form-group">
                    <label className=''>USERNAME</label>
                    <p><b>{username}</b></p>
                  </div>
                  <div className="col-md-4 form-group">
                    <label className=''>PIN</label>
                    <p><b>{_pin}</b></p>
                  </div>
                </div>
              </p>)} */}
            <div className="text-center">
              <NavLink to={"/invitation-accepted/"+hasval}
                className="btn accept"
              >
              ACCEPT                
              </NavLink>&nbsp;
              <NavLink  to={"/invitation-rejected/"+hasval}
                className="btn reject"
              >
              REJECT                
              </NavLink>&nbsp;
              <NavLink  to={"/invitation-optout/"+hasval}
                className="btn optout1"
              >
              OPT OUT                
              </NavLink>
            </div>
        </div>
      </div>
    </div>
   
     )}
     <div className="footer">Copyright. All rights reserved 2019</div>
    </div>
  );
};
