import React from 'react';
import ProfileDefault from "../../assets/images/user.svg";
import InputMask from 'react-input-mask';

interface Props {
	handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void,
	firstName: string,
	lastName: string,
	email: string,
	contactEmail: string,
	phoneNumber: string,
	profilePic: any,
	profilePicbase: any,
	validator: any,	
	setFileInput: (event: React.FormEvent<HTMLInputElement>) => void;
	setTextInput: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	deleteFileInput: (event: React.FormEvent) => void;

} 


export default function UserMyProfile(props: Props) {
	let $profilePreview = null;
	if (props.profilePicbase != "") {
	  $profilePreview = (<img src={props.profilePicbase} />);
	}else{
	  $profilePreview = (<img src={ProfileDefault} />);
	}
	return (
		<div>
         

            <div className="white_panel form_pading">
			<form onSubmit={(e) => props.handleSubmit(e)}>
			<div className="row">
				<div className="col-md-6">
					<div className="form-group">
						<label className="blue_label">FIRST NAME <span className="required">*</span></label>
						<input type="text" required name={"firstName"} value={props.firstName} onChange={props.setTextInput}  className="form-control" placeholder="Enter First Name" />
						{props.validator.message(
							"firstName",
							props.firstName,
							"required"
						)}
					</div>
				</div>
				<div className="col-md-6">
					<div className="form-group">
						<label className="blue_label">LAST NAME <span className="required">*</span></label>
						<input type="text" required name={"lastName"} value={props.lastName} onChange={props.setTextInput}  className="form-control" placeholder="Enter Last Name" />
					
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-6">
					<div className="form-group">
						<label className="blue_label">EMAIL ADDRESS (USERNAME) <span className="required">*</span></label>
						<input type="text" required disabled name={"email"} value={props.email} onChange={props.setTextInput}  className="form-control" placeholder="Enter Email Address" />
						{props.validator.message(
						"email",
						props.email,
						"required|email"
						)}
					</div>
				</div>
				<div className="col-md-6">
					<div className="form-group">
						<label className="blue_label">PHONE NUMBER <span className="required">*</span></label>
						<InputMask className="form-control" placeholder="Ex: (202) 456-1111"  mask="(999) 999-9999"  name={"phoneNumber"} value={props.phoneNumber} onChange={event => props.setTextInput(event)}  />
						{props.validator.message(
								"phoneNumber",
								props.phoneNumber,
								"required|phoneNumber"
								)}
					</div>
				</div>
			</div>
			<div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="blue_label">
              CONTACT EMAIL ADDRESS <span className="required">*</span>
            </label>
            <input
              name={"contactEmail"}
              value={props.contactEmail}
              onChange={event => props.setTextInput(event)}
              type="text"
              maxLength={45} 
              className="form-control"
              placeholder="Enter Contact Email Address"
            />
            {props.validator.message("contactEmail", props.contactEmail, "required|email")}
          </div>
        </div>
      </div>
			<div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="blue_label">PROFILE PICTURE</label>
            <label className="userFileUpload">
              Choose Picture
              <input 
              type="file"
              name={"profilePic"}
              onChange={event => props.setFileInput(event)} 
              className="form-control" />
              <span>Upload</span>
            </label>
            
          </div>
		  <i className="smallhint"><span className="reduired">*</span> Aspect ratio: 1:1 | Min Width:150px, Height: 150px</i>

        </div>
        <div className="col-md-6">
          
            <div className="profileUploadedImg">
               
                <div className="profileUploadedImg_inner">
                {$profilePreview}
                </div>
                <a className="delete-icon"   onClick={event => props.deleteFileInput(event)} ></a>
            </div>
        </div>
      </div>
     
			<button className="btn-submit" type="submit">Update</button>
		</form>
		</div>
		</div>
	)
}
