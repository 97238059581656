import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import SimpleReactValidator from "simple-react-validator";
import VendorForm from "../../components/vendor/vendorForm";
import Select, { ValueType } from "react-select";
import { AppState } from "../../state/types";
import { vendorEdit } from "../../state/vendors/actions";
import { toast } from "react-toastify";
import { onUpdateTokenNone } from '../../state/session/actions';

import RightArrow from "../../assets/images/keyboard-right-arrow-button-white.svg";
import table from "../../assets/images/table.png";
import { stateOptions } from "./../../constants/stateOptions";
import { countryOptions } from "./../../constants/countryOptions";
import { Modal } from "react-bootstrap";
var moment = require('moment');



interface DispatchProps {
  vendorEdit: (vendor: {
    vendorId: string;
    tags: any;
    businessName: string;
    category: string;
    email: string;
    contactEmail: string;
    phoneNumber: string;
    GroupNames: string[];
    firstName: string;
    lastName: string;
    facebookURL: string;
    twitterURL: string;
    instagramURL: string;
    paymentType: string[];
    Notifications: string[];
    location: string;
    city: string;
    state: string;
    zipcode: string;
    aboutVendor: string;
    emailSystemEmbedCode: string;
    websiteLink: string;
    country: string;
    profileImageURL: any;
    bannerImageUrl: any;
  }) => void,  
  updateToken: () => void,
}

interface StateProps {
  isLoading: boolean;
  success: boolean;
  hasErrored: boolean;
  response: any;
  profile: any;
}

// interface Props {}
interface State {
  isUpdate: boolean;
  businessName: string;
  category: ValueType<{ value: string; label: string }>[];
  payment: ValueType<{ value: string; label: string }>[];
  country: ValueType<{ value: string; label: string }>;
  state: ValueType<{ value: string; label: string }>;
  firstName: string;
  lastName: string;
  city: string;
  email: string;
  contactEmail: string;
  facebookURL: string;
  instagramURL: string;
  location: string;
  phoneNumber: string;
  twitterURL: string;
  websiteURL: string;
  zipcode: string;
  about: string;
  embed: string;
  invitationStatus: string;
  invitationSentOn: string;
  activeSince: string;
  profileImageURL: any;
  bannerImageUrl: any;
  notifications: Map<string, string>;
}

type Props = DispatchProps & StateProps & RouteComponentProps;
class VendorEdit extends Component<Props, State> {
  private validator: any;
  private _EmailSubModalShow: boolean = false;
  private countrydata:any = countryOptions.filter((option:any) => option.value === this.props.location.state.item.document.country);
  private statedata:any = stateOptions.filter((option:any) => option.value === this.props.location.state.item.document.state);
  
  
  state = {
    isUpdate:true,
    businessName: this.props.location.state.item.document.businessName,
    category: this.props.location.state.item.document.category.map(
      (item: string) => ({
        value: item,
        label: item.charAt(0).toUpperCase() + item.slice(1)
      })
    ),
    payment: this.props.location.state.item.document.paymentType.map(
      (item: string) => ({
        value: item,
        label: item.charAt(0).toUpperCase() + item.slice(1)
      })
    ),
    country:
      this.props.location.state.item.document.country.length > 0
        ? {
            value: this.props.location.state.item.document.country,
            label: (this.countrydata.length > 0) ? this.countrydata[0].label :this.props.location.state.item.document.country
          }
        : null,
    state: this.props.location.state.item.document.state
      ? {
          value: this.props.location.state.item.document.state,
          label: (this.statedata.length > 0) ? this.statedata[0].label :this.props.location.state.item.document.state
        }
      : null,
    firstName: this.props.location.state.item.document.firstName,
    lastName: this.props.location.state.item.document.lastName,
    city: this.props.location.state.item.document.city,
    email: this.props.location.state.item.document.email,
    contactEmail: this.props.location.state.item.document.contactEmail,
    facebookURL: this.props.location.state.item.document.facebookURL,
    instagramURL: this.props.location.state.item.document.instagramURL,
    location: this.props.location.state.item.document.location,
    phoneNumber: this.props.location.state.item.document.phoneNumber,
    twitterURL: this.props.location.state.item.document.twitterURL,
    websiteURL: this.props.location.state.item.document.websiteLink,
    zipcode: this.props.location.state.item.document.zipcode,
    about: this.props.location.state.item.document.aboutVendor,
    invitationStatus: this.props.location.state.item.document.invitationStatus,
    invitationSentOn: this.props.location.state.item.document.invitationSentOn,
    activeSince: this.props.location.state.item.document.activeSince,
    embed: this.props.location.state.item.document.emailSystemEmbedCode,
    profileImageURL: this.props.location.state.item.document.profileImageURL,
    bannerImageUrl: this.props.location.state.item.document.bannerImageUrl,
    notifications: new Map(
      this.props.location.state.item.document.Notifications.map((i: string) => [
        i,
        i
      ]) as Map<string, string>
    )
  };

  constructor(props: Props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this,  validators: {
      phoneNumber: {
        message: 'The :attribute must be a valid phone Number',
        rule: (val:any, params:any, validator:any) => {
          return validator.helpers.testRegex(val,/^((\([0-9]{3}\))|[0-9]{3})[\s\-][\0-9]{3}[-][0-9]{4}$/i) && params.indexOf(val) === -1
        },
        required: true
      }
    } });
  }
  componentDidMount() {
    document.title = "Edit Vendor | FestFrwrd - The Futr of FSTIVLS";
  }
  componentDidUpdate(prevProps: Props) {
    if (prevProps.response !== this.props.response && this.props.success) { 
      if(this.props.profile.profile.email == this.state.email){
        this.props.updateToken();
      }else{
        this.props.history.goBack();
      }   
    }
    if (prevProps.profile.token !== this.props.profile.token) {
      this.props.history.goBack();
    }
    if (
      prevProps.response !== this.props.response &&
      this.props.hasErrored
    ) {  
      toast.error((this.props.response.error == null) ? this.props.response.message : this.props.response.error);  
      // toast.update(1, {
      //   render: (this.props.response.error == null) ? this.props.response.message : this.props.response.error,
      //   type: toast.TYPE.ERROR,
      //   autoClose: 2000,
      //   hideProgressBar: false
      // });
    }
  }
  EmailSubModalShow(){
    this._EmailSubModalShow = ! this. _EmailSubModalShow;
    return this._EmailSubModalShow;
  }
  setTextInput = <T extends keyof State>(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const newState = {
      [event.currentTarget.name]: event.currentTarget.value
    };
    this.setState(newState as { [P in T]: State[P] });
  };

  handleSelectChange = <T extends keyof State>(
    selectedOption: ValueType<{ value: string; label: string }>,
    name: string
  ) => {
    const newState = {
      [name]: selectedOption
    };
    this.setState(newState as { [P in T]: State[P] }, () =>
      console.log(this.state)
    );
  };
  private setProfileFileInput = (value: any) => {
    this.setState({ profileImageURL: value });
  };
  private setBanerFileInput = (value: any) => {
    this.setState({ bannerImageUrl: value });
  };
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const item = e.target.id;
    const name = e.target.name;
    const isChecked = e.target.checked;
    if (!isChecked) {
      this.setState((state, props) => {
        state.notifications.delete(item);
        return {
          notifications: state.notifications
        };
      });
    } else {
      this.setState((state, props) => {
        state.notifications.set(item, name);
        return {
          notifications: state.notifications
        };
      });
    }
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.props.isLoading) {
      return;
    }
    if (this.validator.allValid()) {
      let profileImageURL = undefined;
      if(this.state.profileImageURL != this.props.location.state.item.document.profileImageURL){
        profileImageURL = this.state.profileImageURL;
      }
      let bannerImageUrl = undefined;
      if(this.state.bannerImageUrl != this.props.location.state.item.document.bannerImageUrl){
        bannerImageUrl = this.state.bannerImageUrl;
      }
      const paymentType = this.state.payment.map((item: any) => item["value"]);
      const category = this.state.category.map((item: any) => item["value"]);
      this.props.vendorEdit({
        vendorId: this.props.location.state.item.documentID,      
        category: category,
        paymentType: paymentType,
        aboutVendor: this.state.about,
        Notifications: [...this.state.notifications.values()],
        city: this.state.city,
        country: this.state.country ? this.state.country.value : "",
        email: this.state.email,
        contactEmail: this.state.contactEmail,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phoneNumber: this.state.phoneNumber,
        GroupNames: ["Festival Vendors"],
        state: this.state.state ? this.state.state.value : "",
        businessName: this.state.businessName,
        facebookURL: this.state.facebookURL,
        instagramURL: this.state.instagramURL,
        location: this.state.location,
        twitterURL: this.state.twitterURL,
        websiteLink: this.state.websiteURL,
        zipcode: this.state.zipcode,
        emailSystemEmbedCode: this.state.embed,        
        profileImageURL:profileImageURL,
        bannerImageUrl:bannerImageUrl,
        tags: {
          opeationalStatus: this.props.location.state.item.opeationalStatus,
          invitationStatus: this.props.location.state.item.invitationStatus,
          activeSince: this.props.location.state.item.activeSince
        },
      });
    } else {
      toast.dismiss(2);
      toast.warn("Please fill in all required fields!",{toastId:2});
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
        <div>
          <div className="inner-title">
            <h3>EDIT VENDOR</h3>
            <div className="path_navigation">
              <ul>
                <li>
                  <NavLink to="/FestivalVendorManagement">VENDOR Management</NavLink>
                </li>
                <li>
                  <NavLink to="/FestivalVendorManagement/list">VIEW VENDORS</NavLink>
                </li>
                <li>
                  <span>EDIT VENDOR</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="white_panel form_pading">
            <VendorForm
              isUpdate={this.state.isUpdate}
              businessName={this.state.businessName}
              category={this.state.category}
              firstName={this.state.firstName}
              lastName={this.state.lastName}
              city={this.state.city}
              email={this.state.email}
              contactEmail={this.state.contactEmail}
              facebookURL={this.state.facebookURL}
              instagramURL={this.state.instagramURL}
              location={this.state.location}
              phoneNumber={this.state.phoneNumber}
              twitterURL={this.state.twitterURL}
              websiteURL={this.state.websiteURL}
              zipcode={this.state.zipcode}
              payment={this.state.payment}
              country={this.state.country}
              about={this.state.about}
              embed={this.state.embed}
              profileImageURL= {this.state.profileImageURL}
              bannerImageUrl= {this.state.bannerImageUrl}
              state={this.state.state}
              notifications={this.state.notifications}
              setTextInput={this.setTextInput}
              handleSelectChange={this.handleSelectChange}
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              setProfileFileInput={this.setProfileFileInput}
              setBanerFileInput={this.setBanerFileInput}
              validator={this.validator}
            />

            <div>
            <div className="invitation_history">
                <h3>INVITATION HISTORY</h3>
                <div className="invitation_history_inner">
                   <div className="history_item">
                      <label>Last Invitation Sent</label>
                      <p>{
                      (this.state.invitationSentOn !== undefined &&  this.state.invitationSentOn._seconds !== undefined ) ?
                      moment.tz( moment.unix(this.state.invitationSentOn._seconds,"MM/DD/YYYY, hh:mm A"), "America/New_York").format("MM/DD/YY, hh:mm A")+" EDT"
                         
                      : "-"} 
                      </p>
                   </div>
                   <div className="history_item">
                      <label>Current Status</label>
                      <p className="history_status">
                      {this.state.activeSince != '' &&  this.state.activeSince !== undefined && this.state.activeSince._seconds  !== undefined ? (
                        <span className="capitalizeNew">
                          <span className={this.state.invitationStatus}>{this.state.invitationStatus}</span>{" "} -{" "}  
                          {moment.tz( moment.unix(this.state.activeSince._seconds), "America/New_York").format("MM/DD/YY, hh:mm A")+" EDT" }

                          
                      
                       </span>
                      ): (
                      <span className="capitalizeNew">
                          <span className={this.state.invitationStatus}>{this.state.invitationStatus != '' ? this.state.invitationStatus : '-'}</span>

                       </span>

                      )
                      }
                      </p>
                   </div>
                </div>
            </div> 
            <div className="emailSubscribe d-none">
               <p>EMAIL SUBSCRIBERS</p>
               <div className="count">
                 27
               </div>
               <div className="arrow">
                    <a onClick={()=>{this.EmailSubModalShow()}} ><img src={RightArrow} alt=">"/></a>
               </div>
             </div>
             </div>

         

          </div>
          
          {/* <Modal
            className="custom_modal"
            show={this.EmailSubModalShow()}
            onHide={() => !this.EmailSubModalShow()}
          >
            <Modal.Body>
              <img src={table}/>
            </Modal.Body>
          </Modal> */}
        </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  profile: state.session,
  response: state.vendors.VendorEdit.response,
  isLoading: state.vendors.VendorEdit.isLoading,
  success: state.vendors.VendorEdit.success,
  hasErrored: state.vendors.VendorEdit.hasErrored
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, Action>
): DispatchProps => ({
  vendorEdit: ({
    vendorId,
    tags,
    businessName,
    category,
    email,
    contactEmail,
    phoneNumber,
    GroupNames,
    firstName,
    lastName,
    facebookURL,
    twitterURL,
    instagramURL,
    paymentType,
    Notifications,
    location,
    city,
    state,
    zipcode,
    aboutVendor,
    emailSystemEmbedCode,
    websiteLink,
    country,
    profileImageURL,
    bannerImageUrl
  }) =>
    dispatch(
      vendorEdit({
        vendorId,
        tags,
        businessName,
        category,
        email,
        contactEmail,
        phoneNumber,
        GroupNames,
        firstName,
        lastName,
        facebookURL,
        twitterURL,
        instagramURL,
        paymentType,
        Notifications,
        location,
        city,
        state,
        zipcode,
        aboutVendor,
        emailSystemEmbedCode,
        websiteLink,
        country,
        profileImageURL,
        bannerImageUrl
      })
    ),
    updateToken: () => dispatch(onUpdateTokenNone())
});

export default connect(mapStateToProps, mapDispatchToProps)(VendorEdit);
