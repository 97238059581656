import * as ActionTypes from "./actionTypes";
import { FestivalState, FestivalCreateActionTypes } from "./types";

const initialState: FestivalState = {
  FestivalCreate: {
    isLoading: false,
    hasErrored: false,
    success: false,
    response: null
  },
  FestivalList: {
    isLoading: false,
    hasErrored: false,
    success: []
  },
  FestivalEdit: {
    isLoading: false,
    hasErrored: false,
    success: false,
    response: null
  },
  FestivalDelete: {
    isLoading: false,
    hasErrored: false,
    success: false,
    response: null
  }
};

export default (
  state = initialState,
  action: FestivalCreateActionTypes
): FestivalState => {
  switch (action.type) {
    case ActionTypes.FESTIVAL_CREATE:
      return {
        ...state,
        FestivalCreate: {
          ...state.FestivalCreate,
          isLoading: true,
          success: false,
          hasErrored: false,
          response: null,
        }
      };

    case ActionTypes.FESTIVAL_CREATE_SUCCESS:
      return {
        ...state,
        FestivalCreate: {
          ...state.FestivalCreate,
          success: action.payload,
          isLoading: false,
          response: action.payload,
        }
      };

    case ActionTypes.FESTIVAL_CREATE_ERROR:
      return {
        ...state,
        FestivalCreate: {
          ...state.FestivalCreate,
          isLoading: false,
          hasErrored: true,
          response: action.payload,
        }
      };

    case ActionTypes.FESTIVAL_EDIT:
      return {
        ...state,
        FestivalEdit: {
          ...state.FestivalEdit,
          isLoading: true,
          success: false,
          hasErrored: false,
          response: action.payload,
        }
      };

    case ActionTypes.FESTIVAL_EDIT_SUCCESS:
      return {
        ...state,
        FestivalEdit: {
          ...state.FestivalEdit,
          success: true,
          isLoading: false,
          hasErrored: false,
          response: action.payload,
        }
      };

    case ActionTypes.FESTIVAL_EDIT_ERROR:
      return {
        ...state,
        FestivalEdit: {
          ...state.FestivalEdit,
          isLoading: false,
          success: false,
          hasErrored: true,
          response: action.payload,
        }
      };

    case ActionTypes.FESTIVAL_DELETE:
      return {
        ...state,
        FestivalDelete: {
          ...state.FestivalDelete,
          isLoading: true,
          success: false,
          hasErrored: false,
          response: action.payload,
        }
      };

    case ActionTypes.FESTIVAL_DELETE_SINGLE:
      const success = state.FestivalList.success.filter(
        item => item.documentID !== action.payload
      );
      return {
        ...state,
        FestivalList: {
          ...state.FestivalList,
          success: success
        }
      };

    case ActionTypes.FESTIVAL_DELETE_SUCCESS:
      return {
        ...state,
        FestivalDelete: {
          ...state.FestivalDelete,
          success: true,
          isLoading: false,
          response: action.payload,
        }
      };

    case ActionTypes.FESTIVAL_DELETE_ERROR:
      return {
        ...state,
        FestivalDelete: {
          ...state.FestivalDelete,
          isLoading: false,
          success: false,
          hasErrored: true,
          response: action.payload,
        }
      };

      case ActionTypes.FESTIVAL_LIST:
      return {
        ...state,
        FestivalList: {
          ...state.FestivalList,
          isLoading: true,
          success: [],
          hasErrored: false
        }
      };


      case ActionTypes.FESTIVAL_LIST_SUCCESS:
        return {
          ...state,
          FestivalList: {
            ...state.FestivalList,
            success: action.payload,
            isLoading: false
          }
        };

        case ActionTypes.FESTIVAL_LIST_ERROR:
          return {
            ...state,
            FestivalList: {
              ...state.FestivalList,
              isLoading: false,
              hasErrored: true
            }
          };


    default:
      return state;
  }
};
