import React, { Component } from "react";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import settings from "../../assets/images/settings.svg";
// import { useRoutes } from "hookrouter"; // TODO: figure out issues with IE 11 and Edge
import { BrowserRouter, Switch, Route } from "react-router-dom";
//import { routes } from "./constants/routes";
import { NotFoundPage } from "./components/NotFound";
import { LoginPage } from "./components/Login";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Dashboard from "./components/profile/Dashboard";
import SecurityDashboard from "./components/Security";
import FestivalDashboard from "./components/Festivals";
import UserFunction from "./components/Security/UserFunction";
import UserFunctionCreate from "./containers/Security/UserFunctionCreate";
import UserFunctionEdit from "./containers/Security/UserFunctionEdit";
import UserGroup from "./components/Security/UserGroup";
import UserManagement from "./components/Security/UserManagement";
import UserGroupCreate from "./containers/Security/UserGroupCreate";
import UserGroupEdit from "./containers/Security/UserGroupEdit";
import UserManagementCreate from "./containers/Security/UserManagementCreate";
import UserManagementEdit from "./containers/Security/UserManagementEdit";
import AppSettings from "./components/Settings/AppSettings";
import SMSSettings from "./components/Settings/SMSSetttings";
import EmailSettings from "./components/Settings/EmailSettings";
import Settings from "./components/Settings";
import VendorDashboard from "./components/vendor";
import VendorList from "./components/vendor/vendorList";
import VendorCreate from "./containers/Vendor/VendorCreate";
import OwnerDashboard from "./components/owner";
import OwnerList from "./components/owner/ownerList";
import OwnerCreate from "./containers/Owner/OwnerCreate";
import OwnerEdit from "./containers/Owner/OwnerEdit";
import PrivateRoute from "./components/PrivateRoute";
import Home from "./components/Home";
import { ForgotPin } from "./components/ForgotPin";
import { ForgotPinConfirm } from "./components/ForgotPin/ForgotPinConfirm";
import { TempPin } from "./components/ForgotPin/TempPin";
import { ResetPin } from "./components/ForgotPin/ResetPin";
//import UserMyProfile from "./containers/Security/UserMyProfileCreate";
import UserChangePinCreate from "./containers/Security/UserChangePinCreate";
import VendorReviews from "./components/vendor/vendorReviews";
import VendorEdit from "./containers/Vendor/VendorEdit";
import ProfileEdit from "./containers/Security/ProfileEdit";
import { AcceptingInvitation } from "./components/AlertScreens/AcceptingInvitation";
import { PreAcceptingInvitation } from "./components/AlertScreens/PreAcceptingInvitation";
import { RejectingInvitation } from "./components/AlertScreens/RejectingInvitation";
import { OptoutInvitation } from "./components/AlertScreens/OptoutInvitation";
import FestivalCreate from "./containers/Festivals/FestivalCreate";
import FestivalEdit from "./containers/Festivals/FestivalEdit";
import FestivalView from "./containers/Festivals/FestivalView";


const HomeScreen = () => {
  return (
    <div>
      <Navigation />
      <Footer />
    </div>
  );
};

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/forgot-pin" component={ForgotPin} />
            <Route
              exact
              path="/forgot-pin-confirm"
              component={ForgotPinConfirm}
            />
            <Route
              path="/temporary-pin/:token&tmp=:temppin"
              component={TempPin}
            />
            <Route exact path="/reset-pin" component={ResetPin} />

            <Route
              path="/invitation-accepted/:token&uid=:uid&tmp=:temppin&GroupNames=:GroupNames"
              component={AcceptingInvitation}
            />
            <Route
              path="/pre"
              component={PreAcceptingInvitation}
            />
            <Route
              path="/invitation-rejected/:token&uid=:uid&tmp=:temppin&GroupNames=:GroupNames"
              component={RejectingInvitation}
            />
            <Route
              path="/invitation-optout/:token&uid=:uid&tmp=:temppin&GroupNames=:GroupNames"
              component={OptoutInvitation}
            />

            <section className="page_container">  
              <Navbar />
              <Sidebar />
              <div className="main-content">
                <Switch>
                  <PrivateRoute>
                  <Route
                      exact
                      path="/dashboard"
                      component={Dashboard}
                    />
                    <Route
                      exact
                      path="/security"
                      component={SecurityDashboard}
                    />
                    <Route
                      exact
                      path="/FestivalManagement"
                      component={FestivalDashboard}
                    />
                     <Route
                      exact
                      path="/FestivalManagement/:type"
                      component={FestivalDashboard}
                    />
                    <Route
                      exact
                      path="/FestivalManagement/list/create"
                      component={FestivalCreate}
                    />
                    <Route
                      exact
                      path="/FestivalManagement/list/edit"
                      component={FestivalEdit}
                    />
                    <Route
                      exact
                      path="/FestivalManagement/list/view"
                      component={FestivalView}
                    />

                    <Route
                      exact
                      path="/security/UserFunctionsManagement"
                      component={UserFunction}
                    />
                    <Route
                      exact
                      path="/security/UserFunctionsManagement/new"
                      component={UserFunctionCreate}
                    />
                    <Route
                      exact
                      path="/security/UserFunctionsManagement/view_edit"
                      component={UserFunctionEdit}
                    />
                    <Route exact path="/security/UserGroupsManagement" component={UserGroup} />
                    <Route
                      exact
                      path="/security/UserGroupsManagement/new"
                      component={UserGroupCreate}
                    />
                    <Route
                      exact
                      path="/security/UserGroupsManagement/view_edit"
                      component={UserGroupEdit}
                    />
                    <Route
                      exact
                      path="/security/UserManagement"
                      component={UserManagement}
                    />
                    <Route
                      exact
                      path="/security/UserManagement/new"
                      component={UserManagementCreate}
                    />
                    <Route
                      exact
                      path="/security/UserManagement/view_edit"
                      component={UserManagementEdit}
                    />
                    <Route exact path="/settings" component={Settings} />
                    <Route exact path="/app-settings" component={AppSettings} />
                    <Route exact path="/sms-settings" component={SMSSettings} />
                    <Route
                      exact
                      path="/email-settings"
                      component={EmailSettings}
                    />

                    <Route exact path="/FestivalVendorManagement" component={VendorDashboard} />
                    <Route exact path="/FestivalVendorManagement/list" component={VendorList} />
                    <Route
                      exact
                      path="/FestivalVendorManagement/list/create"
                      component={VendorCreate}
                    />
                    <Route
                      exact
                      path="/FestivalVendorManagement/list/edit"
                      component={VendorEdit}
                    />
                    <Route
                      exact
                      path="/reviews"
                      component={VendorReviews}
                    />

                    <Route exact path="/FestivalOwnerManagement" component={OwnerDashboard} />
                    <Route exact path="/FestivalOwnerManagement/list" component={OwnerList} />
                    <Route exact path="/FestivalOwnerManagement/list/new" component={OwnerCreate} />
                    <Route
                      exact
                      path="/FestivalOwnerManagement/list/edit"
                      component={OwnerEdit}
                    />

                    <Route exact path="/my-profile" component={ProfileEdit} />

                    <Route
                      exact
                      path="/change-pin"
                      component={UserChangePinCreate}
                    />
                  </PrivateRoute>
                </Switch>
              </div>
            </section>
            <Route component={NotFoundPage} />

          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
