import * as React from "react";
import { Link } from "react-router-dom";
import vendor from "../../assets/images/stall2.svg";
import stallc from "../../assets/images/stallc.svg";


export interface IVendorDashboardProps {}

export default class VendorDashboard extends React.Component<
  IVendorDashboardProps
> {
  componentDidMount() {
    document.title = "Vendor Management | FestFrwrd - The Futr of FSTIVLS";
  }
  public render() {
    return (
        <div>
          <div className="inner-title">
            <h3>Vendor Management</h3>
          </div>

          <div className="icon_boxes_list">
            <Link to="/FestivalVendorManagement/list" className="icon_box">
              <img className="icon_color" src={stallc} alt="" />
              <img className="icon_white" src={vendor} alt="" />
              <p>
                View <br /> Vendors
              </p>
            </Link>
          </div>
        </div>
    );
  }
}
