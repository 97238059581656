import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { AppState } from "../../state/types";
import { resetPin } from "../../state/forgotPin/actions";
import { toast } from "react-toastify";

import logo from "../../assets/images/logo_2x.png";

export const ResetPin = () => {
  const validator = new SimpleReactValidator();
  const history = useHistory();
  const [pin, setPin] = useState("");
  const [pin2, setPin2] = useState("");
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state: AppState) => state.forgotPin.resetPin.isLoading
  );
  const success = useSelector(
    (state: AppState) => state.forgotPin.resetPin.success
  );
  const hasErrored = useSelector(
    (state: AppState) => state.forgotPin.resetPin.hasErrored
  );

  useEffect(() => {
    if (success !== null) {
      history.push("/dashboard");
      // toast.success("You have successfully reset the new Pin. The page will be redirected in 5 secs to the Dashboard.",{toastId:1,hideProgressBar:true,delay:5000, onClose:() =>{
      //   history.push("/dashboard");
      // }});
    }
    return () => {};
  }, [success]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "pin") {
      setPin(event.target.value);
    }
    if (event.target.name === "pin2") {
      setPin2(event.target.value);
    }
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validator.allValid() && pin === pin2) {
      dispatch(
        resetPin({ token: history.location.state.token, password: pin })
      );
    } else {
      toast.dismiss(1);
      if(pin !== pin2){
      
        toast.error("Pin and re-entered pin not matched!",{toastId:1,hideProgressBar:true});

      }else{
        toast.error("Pin and re-entered pin not valid!",{toastId:1,hideProgressBar:true});
      }
      validator.showMessages();
    }
  };

  return (
    <div>
      <div className="non--login">
        <div className="login-form">
          <div className="logo_img">
            <img src={logo} alt="F" />
            <h1>
              <span>FEST</span>FRWRD
            </h1>
          </div>

          <div className="login-form--inner">
            <h2>Set New Pin</h2>
            <form onSubmit={handleFormSubmit}>
              <div className="form-group">
                <label>SET PERMANENT PIN <span className="required">*</span></label>
                <div className="icon-input password-icon">
                  <input
                    name={"pin"}
                    type="password"
                    
                    maxLength={6}
                    className="form-control"
                    value={pin}
                    onChange={handleOnChange}
                  />
                  {validator.message("pin", pin, `required|integer|min:6|max:6}`)}
                </div>
              </div>
              <div className="form-group">
                <label>RE-ENTER PERMANENT PIN <span className="required">*</span></label>
                <div className="icon-input password-icon">
                  <input
                    name={"pin2"}
                    type="password"
                    
                    maxLength={6}
                    className="form-control"
                    value={pin2}
                    onChange={handleOnChange}
                  />
                  {validator.message("pin", pin2, `required|integer|min:6|max:6|in:${pin}`)}
                </div>
              </div>
              {validator.message("pins don't match", pin, "")}
              <button className="login_btn">Login</button>
            </form>
          </div>
        </div>
      </div>
      <div className="footer">Copyright. All rights reserved 2019</div>
    </div>
  );
};
