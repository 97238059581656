import * as React from 'react';

export interface IEmailSettingsProps {
}

export default class EmailSettings extends React.Component<IEmailSettingsProps> {
  public render() {
    return (
      <div>
            <div className="inner-title">
                <h3>Email Settings</h3>
                <div className="path_navigation">
                    <ul>
                        <li><a href="security.html">Settings</a></li>
                        <li><span>Email Settings</span></li>
                    </ul>
                </div>
            </div>

            <div className="white_panel form_pading">
                <form action="">

                    <div className="row">

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="blue_label">Protocal</label>
                                <select name="" id="" className="form-control">
                                    <option value="SMTP">SMTP</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="blue_label">From Name</label>
                                <input type="text" className="form-control"  placeholder="Enter From Name" />
                            </div>
                            <div className="form-group">
                                <label className="blue_label">From Address</label>
                                <input type="text" className="form-control"  placeholder="Enter From Address" />
                            </div>
                            <div className="form-group">
                                <label className="blue_label">SMTP Host</label>
                                <input type="text" className="form-control"  placeholder="Enter SMTP Host" />
                            </div>
                            <div className="form-group">
                                <label className="blue_label">SMTP Port</label>
                                <input type="text" className="form-control"  placeholder="Enter SMTP Port" />
                            </div>
                            <div className="form-group">
                                <label className="blue_label">SMTP Username</label>
                                <input type="text" className="form-control"  placeholder="Enter SMTP Username" />
                            </div>
                            <div className="form-group">
                                <label className="blue_label">SMTP Password</label>
                                <input type="text" className="form-control"  placeholder="Enter SMTP Password" />
                            </div>
                        </div>

                    </div>


                    <button className="btn-submit" type="submit">Save</button>




                </form>
            </div>
      </div>
    );
  }
}
