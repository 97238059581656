import React from "react";
import { NavLink } from "react-router-dom";
interface Props {
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  oldPin: string;
  newPin: string;
  confirmPin: string;
  setTextInput: (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  validator: any;
}
export default function UserChangePin(props: Props) {
  return (
    <div>
      <form onSubmit={e => props.handleSubmit(e)}>
        <div className="inner-title">
          <h3>CHANGE PIN</h3>
        </div>

        <div className="white_panel form_pading">
          <div className="back_container text-right">
            <button className="btn-submit" type="button">
              Back
            </button>
          </div>
          <div className="change_pin_form">
            <form>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group oldpin">
                    <label className="blue_label">OLD PIN </label>
                    <input
                      name={"oldPin"}
                      onChange={props.setTextInput}
                      type="password"
                      className="form-control"
                      placeholder="OOOOOO"
                    />
                    {props.validator.message(
                      "oldPin",
                      props.oldPin,
                      "required|string|min:6"
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="blue_label">NEW PIN </label>
                    <div className="new_pin">
                      <input
                        name={"newPin"}
                        value={props.newPin}
                        onChange={props.setTextInput}
                        type="password"
                        className="form-control"
                        placeholder="OOOOOO"
                      />
                      {props.validator.message(
                        "newPin",
                        props.newPin,
                        "required|string|min:6"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="blue_label">CONFIRM NEW PIN </label>
                    <div className="new_pin">
                      <input
                        name={"confirmPin"}
                        value={props.confirmPin}
                        onChange={props.setTextInput}
                        type="password"
                        className="form-control"
                        placeholder="OOOOOO"
                      />
                      {props.validator.message(
                        "confirmPin",
                        props.confirmPin,
                        `required|string|min:6|in:${props.newPin}`
                      )}
                      {/* {!this.props.validator.fieldValid('confirm_password') && 'Passwords need to match!'} */}
                    </div>
                  </div>
                </div>
              </div>
              <button className="btn-submit" type="submit">
                Update
              </button>
            </form>
          </div>
        </div>
      </form>
    </div>
  );
}
