export const USER_GROUP_CREATE = "USER_GROUP_CREATE";
export const USER_GROUP_CREATE_SUCCESS = "USER_GROUP_CREATE_SUCCESS";
export const USER_GROUP_CREATE_ERROR = "USER_GROUP_CREATE_ERROR";
export const USER_GROUP_EDIT = "USER_GROUP_EDIT";
export const USER_GROUP_EDIT_SUCCESS = "USER_GROUP_EDIT_SUCCESS";
export const USER_GROUP_EDIT_ERROR = "USER_GROUP_EDIT_ERROR";
export const USER_GROUP_DELETE = "USER_GROUP_DELETE";
export const USER_GROUP_DELETE_SUCCESS = "USER_GROUP_DELETE_SUCCESS";
export const USER_GROUP_DELETE_ERROR = "USER_GROUP_DELETE_ERROR";
export const USER_GROUP_LIST = "USER_GROUP_LIST";
export const USER_GROUP_LIST_SUCCESS = "USER_GROUP_LIST_SUCCESS";
export const USER_GROUP_LIST_ERROR = "USER_GROUP_LIST_ERROR";
export const USER_GROUP_DELETE_SINGLE = "USER_GROUP_DELETE_SINGLE";
