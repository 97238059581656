import React, { Component } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { connect } from "react-redux";
import { AppState } from "../../state/types";
import Authorization from "../../containers/Authorization";

interface OwnProps {}

interface StateProps {
  token?: string;
}

type Props = OwnProps & StateProps & RouteProps;
class PrivateRoute extends Component<Props> {
  render() {
    const { token, children, ...rest } = this.props;
    return (
      <Authorization {...this.props}>
      <Route
        {...rest}
        render={({ location }) =>
          token ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
      
      </Authorization>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  token: state.session.token
});

export default connect(mapStateToProps)(PrivateRoute);
