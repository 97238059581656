import * as React from 'react';
import { Link } from 'react-router-dom'
import { AppState } from "../../state/types";
import { connect } from "react-redux";

import user from "../../assets/images/growth.svg";
import userWhite from "../../assets/images/growth_white.svg";

import group from "../../assets/images/group.svg";
import groupWhite from "../../assets/images/group__white.svg";

import functions from "../../assets/images/cms.svg";
import fcuntionWhite from "../../assets/images/cms_white.svg";
import * as _ from "lodash";
import { routesConfig } from "./../../constants/routes";
export interface ISecurityDashboardProps {}

interface StateProps {
  token?: string;
  profile?: any;
}

type Props = ISecurityDashboardProps & StateProps;
class SecurityDashboard extends React.Component<Props> {
  public allRoutes: string[] =[];
   
  componentDidMount() {
    document.title = "Security Management | FestFrwrd - The Futr of FSTIVLS";
  }
  componentWillMount() {
    this.renderAdminRoutes();
  }
  renderAdminRoutes = () => {
    this.props.profile.group.map((item:any)=>{
     item._functions.map((func: { name: any }) =>{
     // this.props.profile.group._functions.map((func: { name: any }) =>{
      if(routesConfig[func.name]){
        for(let i of routesConfig[func.name]){
          this.allRoutes.push(i);
        }       
      }
    }) 
  })
    ;
    this.allRoutes = _.uniq(this.allRoutes);
  };
  protected hasPermission = (link:string[]) => { return true;
    return  _.find(this.allRoutes, function(rout) {
          return link.includes(rout);
      });
  }
  public render() {
    return (
      <div>
        <div className="inner-title">
                <h3>Security Management</h3>
            </div>

            <div className="icon_boxes_list">
               {this.hasPermission(["/security/UserManagement"]) ? (
                <Link to="/security/UserManagement" className="icon_box" href="user-management.html">
                    <img className="icon_color" src={user} alt="" />
                    <img className="icon_white" src={userWhite} alt="" />
                    <p>User <br /> Management</p>
                </Link>
              ) : null}
                {this.hasPermission(["/security/UserGroupsManagement"]) ? (
                <Link to="/security/UserGroupsManagement" className="icon_box" href="user-groups.html">
                    <img className="icon_color" src={group} alt="" />
                    <img className="icon_white" src={groupWhite} alt="" />
                    <p>View User <br /> Groups</p>
                </Link>
                ) : null}
                {this.hasPermission(["/security/UserFunctionsManagement"]) ? (
                <Link to="/security/UserFunctionsManagement" className="icon_box">
                    <img className="icon_color" src={functions} alt="" />
                    <img className="icon_white" src={fcuntionWhite} alt="" />
                    <p>View User <br /> Functions</p>
                </Link>
              ) : null}
            </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  token: state.session.token,
  profile: state.session.profile
});

export default connect(mapStateToProps)(SecurityDashboard);
