import { showLoading, hideLoading } from "react-redux-loading-bar";
import * as ActionTypes from "./actionTypes";
import { Dispatch } from "redux";
import Api from "../../Api";

const userGroupListSuccess = (payload: any) => ({
  type: ActionTypes.USER_GROUP_LIST_SUCCESS,
  payload
});

const userGroupListError = (payload: any) => ({
  type: ActionTypes.USER_GROUP_CREATE_ERROR,
  payload
});
export const userGroupListAll = (
  limit: number,
  page: number,
  search: string,
  sort: string
) => async (dispatch: Dispatch) => {
  try {
    dispatch(showLoading());
    let userGroupListResponse = await Api.getAllGroupsByList(
      limit,
      page,
      search,
      sort
    );
    dispatch(userGroupListSuccess(userGroupListResponse));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch(userGroupListError(error));
  }
};
export const userGroupList = () => async (dispatch: Dispatch) => {
  try {
    dispatch(showLoading());
    let userGroupListResponse = await Api.getAllGroups();
    dispatch(userGroupListSuccess(userGroupListResponse));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch(userGroupListError(error));
  }
};

const userGroupCreateLoading = () => ({
  type: ActionTypes.USER_GROUP_CREATE
});

const userGroupCreateSuccess = (payload: any) => ({
  type: ActionTypes.USER_GROUP_CREATE_SUCCESS,
  payload
});

const userGroupCreateError = (payload: any) => ({
  type: ActionTypes.USER_GROUP_CREATE_ERROR,
  payload
});

export const userGroupCreate = (
  name: string,
  description: string,
  functionslist: any
) => async (dispatch: Dispatch) => {
  try {
    dispatch(showLoading());
    dispatch(userGroupCreateLoading());
    let userGroupCreateResponse = await Api.createGroupFunction({
      name,
      description,
      functionslist
    });
    dispatch(userGroupCreateSuccess(userGroupCreateResponse));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(userGroupCreateError(error));
    dispatch(hideLoading());
  }
};

const userGroupEditLoading = () => ({
  type: ActionTypes.USER_GROUP_EDIT
});

const userGroupEditSuccess = (payload: any) => ({
  type: ActionTypes.USER_GROUP_EDIT_SUCCESS,
  payload
});

const userGroupEditError = (payload: any) => ({
  type: ActionTypes.USER_GROUP_EDIT_ERROR,
  payload
});

export const userGroupEdit = (
  name: string,
  description: string,
  functionslist: any,
  groupId: string
) => async (dispatch: Dispatch) => {
  try {
    dispatch(userGroupEditLoading());
    dispatch(showLoading());
    let userGroupEditResponse = await Api.editGroupFunction({
      name,
      description,
      functionslist,
      groupId
    });
    dispatch(userGroupEditSuccess(userGroupEditResponse));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch(userGroupEditError(error));
  }
};

const userGroupDeleteSingle = (groupId: string) => ({
  type: ActionTypes.USER_GROUP_DELETE_SINGLE,
  payload: groupId
});

const userGroupDeleteLoading = () => ({
  type: ActionTypes.USER_GROUP_DELETE
});

const userGroupDeleteSuccess = (payload: any) => ({
  type: ActionTypes.USER_GROUP_DELETE_SUCCESS,
  payload
});

const userGroupDeleteError = (payload: any) => ({
  type: ActionTypes.USER_GROUP_DELETE_ERROR,
  payload
});

export const userGroupDelete = (groupId: string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(userGroupDeleteLoading());
    let userGroupEditResponse = await Api.deleteGroupFunction({
      groupId
    });
    dispatch(userGroupDeleteSuccess(userGroupEditResponse));
    dispatch(userGroupDeleteSingle(groupId));
  } catch (error) {
    dispatch(userGroupDeleteError(error));
  }
};
