import * as React from "react";
import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { toast } from "react-toastify";
import { withRouter, RouteComponentProps } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { userFunctionCreate } from "../../state/userFunctionCreate/actions";
import UserFunctionForm from "../../components/Security/UserFunctionForm";
import { AppState } from "../../state/types";
import { NavLink } from "react-router-dom";

interface IUserFunctionCreateProps {}

interface StateProps {
  response: any;
  isLoading: boolean;
  success: boolean;
  hasErrored: boolean;
}

interface DispatchProps {
  userFunctionCreate: (name: string, description: string) => void;
}

interface IUserFunctionCreateState {
  functionName: string;
  functionDescription: string;
}

type Props = DispatchProps &
  IUserFunctionCreateProps &
  StateProps &
  RouteComponentProps;

class UserFunctionCreate extends React.Component<
  Props,
  IUserFunctionCreateState
> {
  private validator: any;
  public state = {
    functionName: "",
    functionDescription: "",
    isUpdate: false
  };
  constructor(props: Props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }
  componentDidMount() {
    document.title = "New Function | FestFrwrd - The Futr of FSTIVLS";
  }
  componentDidUpdate(prevProps: Props, prevState: IUserFunctionCreateState) { 
    if (prevProps.response !== this.props.response && this.props.success) { 
      this.props.history.goBack();
      // toast.update(1, {
      //   render: this.props.response.data.message,
      //   type: toast.TYPE.SUCCESS,
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   onClose:() =>{
      //     this.props.history.goBack();
      //   }
      // });    
    }
    if (
      prevProps.response !== this.props.response &&
      this.props.hasErrored
    ) {  
      toast.error((this.props.response.error == null) ? this.props.response.message : this.props.response.error);     
      // toast.update(1, {
      //   render: (this.props.response.error == null) ? this.props.response.message : this.props.response.error,
      //   type: toast.TYPE.ERROR,
      //   autoClose: 2000,
      //   hideProgressBar: false
      // });
    }
  }

  setTextInput = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    } as Pick<IUserFunctionCreateState, keyof IUserFunctionCreateState>);
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (this.validator.allValid()) {
      this.props.userFunctionCreate(
        this.state.functionName,
        this.state.functionDescription
      );
    } else {
      toast.dismiss(2);
      toast.warn("Please fill in all required fields!",{toastId:2});
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  public render() {
    if (this.props.isLoading) {      
      return <p>Loading..
      </p>;
    }
    return (
        <div>
          <div className="inner-title">
            <h3>ADD FUNCTION</h3>
            <div className="path_navigation">
              <ul>
                <li>
                  <NavLink to="/security">SECURITY Management</NavLink>
                </li>
                <li>
                  <NavLink to="/security/UserFunctionsManagement">VIEW USER FUNCTIONS</NavLink>
                </li>
                <li>
                  <span>ADD FUNCTION</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="white_panel form_pading">
            <UserFunctionForm
              isUpdate={this.state.isUpdate}
              handleSubmit={e => this.handleSubmit(e)}
              setTextInput={e => this.setTextInput(e)}
              functionName={this.state.functionName}
              functionDescription={this.state.functionDescription}
              validator={this.validator}
            />
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  response: state.userFunctionsCreate.response,
  isLoading: state.userFunctionsCreate.isLoading,
  success: state.userFunctionsCreate.success,
  hasErrored: state.userFunctionsCreate.hasErrored
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, Action>
): DispatchProps => ({
  userFunctionCreate: (name, description) =>
    dispatch(userFunctionCreate(name, description))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserFunctionCreate);
