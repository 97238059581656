import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AppState } from '../../state/types'
import { Redirect } from 'react-router'

interface StateProps {
	token?: string
}

type Props = StateProps

class Home extends Component<Props> {

	render() {
		const { token } = this.props
		return <Redirect to={token ? "/dashboard" : "/login"} />
	}
}

const mapStateToProps = (state: AppState) => ({
	token: state.session.token
})

export default connect(mapStateToProps)(Home)
