import * as ActionTypes from "./actionTypes";
import { UserGroupState, UserGroupCreateActionTypes } from "./types";

const initialState: UserGroupState = {
  UserGroupCreate: {
    isLoading: false,
    hasErrored: false,
    success: false,
    response: null
  },
  UserGroupList: {
    isLoading: false,
    hasErrored: false,
    success: [],
    response: null
  },
  UserGroupEdit: {
    isLoading: false,
    hasErrored: false,
    success: false,
    response: null
  },
  UserGroupDelete: {
    isLoading: false,
    hasErrored: false,
    success: false,
    response: null
  }
};

export default (
  state = initialState,
  action: UserGroupCreateActionTypes
): UserGroupState => {
  switch (action.type) {
    case ActionTypes.USER_GROUP_CREATE:
      return {
        ...state,
        UserGroupCreate: {
          ...state.UserGroupCreate,
          isLoading: true,
          success: false,
          hasErrored: false,
          response: null,
        }
      };

    case ActionTypes.USER_GROUP_CREATE_SUCCESS:
      return {
        ...state,
        UserGroupCreate: {
          ...state.UserGroupCreate,
          isLoading: false,
          success: true,
          hasErrored: false,
          response: action.payload
        }
      };

    case ActionTypes.USER_GROUP_CREATE_ERROR:
      return {
        ...state,
        UserGroupCreate: {
          ...state.UserGroupCreate,
          isLoading: false,
          success: false,
          hasErrored: true,
          response: action.payload
        }
      };

    case ActionTypes.USER_GROUP_EDIT:
      return {
        ...state,
        UserGroupEdit: {
          ...state.UserGroupEdit,
          isLoading: true,
          success: false,
          hasErrored: false
        }
      };

    case ActionTypes.USER_GROUP_EDIT_SUCCESS:
      return {
        ...state,
        UserGroupEdit: {
          ...state.UserGroupEdit,
          isLoading: false,
          success: true,
          hasErrored: false,
          response: action.payload
        }
      };

    case ActionTypes.USER_GROUP_EDIT_ERROR:
      return {
        ...state,
        UserGroupEdit: {
          ...state.UserGroupEdit,
          isLoading: false,
          success: false,
          hasErrored: true,
          response: action.payload
        }
      };

    case ActionTypes.USER_GROUP_DELETE:
      return {
        ...state,
        UserGroupDelete: {
          ...state.UserGroupDelete,
          isLoading: true,
          success: false,
          hasErrored: false
        }
      };

    case ActionTypes.USER_GROUP_DELETE_SINGLE:
      const success = state.UserGroupList.success.filter(
        item => item.documentID !== action.payload
      );
      return {
        ...state,
        UserGroupList: {
          ...state.UserGroupList,
          isLoading: false,
          success: success,
          hasErrored: false,
          response: action.payload
        }
      };

    case ActionTypes.USER_GROUP_DELETE_SUCCESS:
      return {
        ...state,
        UserGroupDelete: {
          ...state.UserGroupDelete,
          isLoading: false,
          success: action.payload,
          hasErrored: false,
          response: action.payload
        }
      };

    case ActionTypes.USER_GROUP_DELETE_ERROR:
      return {
        ...state,
        UserGroupDelete: {
          ...state.UserGroupDelete,
          isLoading: false,
          success: false,
          hasErrored: false,
          response: action.payload
        }
      };

    case ActionTypes.USER_GROUP_LIST:
      return {
        ...state,
        UserGroupList: {
          ...state.UserGroupList,
          isLoading: true,
          success: [],
          hasErrored: false
        }
      };

    case ActionTypes.USER_GROUP_LIST_SUCCESS:
      return {
        ...state,
        UserGroupList: {
          ...state.UserGroupList,
          success: action.payload,
          isLoading: false
        }
      };

    case ActionTypes.USER_GROUP_LIST:
      return {
        ...state,
        UserGroupList: {
          ...state.UserGroupList,
          isLoading: false,
          hasErrored: true
        }
      };

    default:
      return state;
  }
};
