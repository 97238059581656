import React, { Component } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { connect } from "react-redux";
import { AppState } from "../../state/types";
import NoAccess from "../../components/NoAccess";
import { routesConfig } from "./../../constants/routes";
import * as _ from "lodash";

interface OwnProps extends React.Props<Authorization> {}

interface StateProps {
  token?: string;
  profile?: any;
}

type Props = OwnProps & StateProps & RouteProps;
class Authorization extends Component<Props> {
  public allRoutes: string[] = [];
  constructor(props: Props) {
    super(props);
  }
  componentWillMount() {
    this.renderAdminRoutes();
  }
  renderAdminRoutes = () => {
    if (this.props.profile) {
      if (this.props.profile.group) {
        this.props.profile.group.map((item: any) => {
          if (item._functions) {
            item._functions.map((func: { name: any }) => {
              if (routesConfig[func.name]) {
                for (let i of routesConfig[func.name]) {
                  this.allRoutes.push(i);
                }
              }
              this.allRoutes = _.uniq(this.allRoutes);
            });
          }
        });
      }
    }
  };
  protected hasPermission = (link: string) => {
    return _.find(this.allRoutes, function(rout) {
      let _rout = link.split("/");
      return link.includes("/" + _rout[1]);
    });
  };

  render() {
    const { token, children, profile, ...rest } = this.props;
    if (this.props.profile) {
      if (
        this.props.profile.GroupNames.length > 0 &&
        this.props.profile.GroupNames.includes("Administrators") === true
      ) {
        if (this.props.location && this.props.location.pathname) {
          let check = this.hasPermission(this.props.location.pathname);
          if (check) {
            return <React.Fragment>{children}</React.Fragment>;
          }
        }
      } else if (
        this.props.profile.GroupNames.includes("Administrators") === false &&
        this.props.profile.GroupNames.includes("Festival Owners")
      ) {
        if (
          this.props.location &&
          (this.props.location.pathname.includes("/my-profile") ||
            this.props.location.pathname.includes("/change-pin") ||
            this.props.location.pathname.includes("/FestivalManagement") ||
            this.props.location.pathname.includes("/dashboard"))
        ) {
          if (
            this.props.location.pathname.includes(
              "/FestivalManagement/list/create"
            )
          ) {
            return <NoAccess />;
          }
          return <React.Fragment>{children}</React.Fragment>;
        }
      } else if (
        this.props.profile.GroupNames.includes("Administrators") === false &&
        this.props.profile.GroupNames.includes("Festival Vendors")
      ) {
        if (
          this.props.location &&
          (this.props.location.pathname.includes("/my-profile") ||
            this.props.location.pathname.includes("/change-pin") ||
            this.props.location.pathname.includes("/FestivalManagement") ||
            this.props.location.pathname.includes("/dashboard"))
        ) {
          if (
            this.props.location.pathname.includes(
              "/FestivalManagement/list/create"
            )
          ) {
            return <NoAccess />;
          }
          return <React.Fragment>{children}</React.Fragment>;
        }
      } else {
        if (
          this.props.location &&
          (this.props.location.pathname.includes("/my-profile") ||
            this.props.location.pathname.includes("/change-pin") ||
            this.props.location.pathname.includes("/dashboard"))
        ) {
          return <React.Fragment>{children}</React.Fragment>;
        }
      }
      // return <React.Fragment>{children}</React.Fragment>;
      return <NoAccess />;
    } else {
      return <Redirect to="/login" />;
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  token: state.session.token,
  profile: state.session.profile
});

export default connect(mapStateToProps)(Authorization);
