import { showLoading, hideLoading } from "react-redux-loading-bar";
import * as ActionTypes from "./actionTypes";
import { Dispatch } from "redux";
import Api from "../../Api";

const festivalListSuccess = (payload: any) => ({
  type: ActionTypes.FESTIVAL_LIST_SUCCESS,
  payload
});

const festivalListError = (payload: any) => ({
  type: ActionTypes.FESTIVAL_LIST_ERROR,
  payload
});

export const festivalListAll = (
  limit: number,
  page: number,
  search: string,
  sort: string,
  filter: string,
  type: any,
) => async (dispatch: Dispatch) => {
  try {
    dispatch(showLoading());
    let festivalListResponse = await Api.getAllFestivalsByList(
      limit,
      page,
      search,
      sort,
      filter,
      type,
    );
    dispatch(festivalListSuccess(festivalListResponse));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch(festivalListError(error));
  }
};

// export const festivalList = () => async (dispatch: Dispatch) => {
//   try {
//     dispatch(showLoading());
//     let festivalListResponse = await Api.getAllFestivals();
//     dispatch(festivalListSuccess(festivalListResponse));
//     dispatch(hideLoading());
//   } catch (error) {
//     dispatch(hideLoading());
//     dispatch(festivalListError(error));
//   }
// };

const festivalCreateSuccess = (payload: any) => ({
  type: ActionTypes.FESTIVAL_CREATE_SUCCESS,
  payload
});

const userGroupCreateError = (payload: any) => ({
  type: ActionTypes.FESTIVAL_CREATE_ERROR,
  payload
});

export const festivalCreate = ({
  festivalName,
  category,
  address,
  city,
  state,
  country,
  zipcode,
  startDate,
  endDate,
  price,
  festivalDescription,
  festivalOwner,
  contactNumber,
  websiteURL,
  facebookURL,
  twitterURL,
  instagramURL,
  otherSocialLink,
  Amenities,
  Vendors,
  Schedule,
  timeandSchedule,
  _festivalImages,
  publishingStatus,
}: {
  festivalName: string;
  category: string;
    address: string;
    city: string;
    state: string;
    country: string;
    zipcode: string;
    startDate: string;
    endDate: string;
    price: string;
    festivalDescription: string;
    festivalOwner: string;
    contactNumber: string;
    websiteURL: string;
    facebookURL: string;
    twitterURL: string;
    instagramURL: string;
    otherSocialLink: string;
    Amenities : any;
    Vendors: any;
    Schedule: any,
    timeandSchedule: any,
    _festivalImages: any,
    publishingStatus: any,
}) => async (dispatch: Dispatch) => {
  try {
    dispatch(showLoading());
    let festivalCreateResponse = await Api.createFestival({
      festivalName,
      category,
      address,
      city,
      state,
      country,
      zipcode,
      startDate,
      endDate,
      price,
      festivalDescription,
      festivalOwner,
      contactNumber,
      websiteURL,
      facebookURL,
      twitterURL,
      instagramURL,
      otherSocialLink,
      Amenities,
      Vendors,
      Schedule,
      timeandSchedule,
      _festivalImages,
      publishingStatus
    });
    dispatch(festivalCreateSuccess(festivalCreateResponse));
    dispatch(hideLoading());   
  } catch (error) {
    dispatch(userGroupCreateError(error));
    dispatch(hideLoading());
  }
};


const festivalEditSuccess = (payload: any) => ({
  type: ActionTypes.FESTIVAL_EDIT_SUCCESS,
  payload
});

const festivalEditError = (payload: any) => ({
  type: ActionTypes.FESTIVAL_EDIT_ERROR,
  payload
});

export const festivalEdit = ({
  festivalId,
  festivalName,
  category,
  address,
  city,
  state,
  country,
  zipcode,
  startDate,
  endDate,
  price,
  festivalDescription,
  festivalOwner,
  contactNumber,
  websiteURL,
  facebookURL,
  twitterURL,
  instagramURL,
  otherSocialLink,
  Amenities,
  Vendors,
  Schedule,
  timeandSchedule,
  _festivalImages,
  publishingStatus,
}:
{
  festivalId: string;
  festivalName: string;
  category: string;
    address: string;
    city: string;
    state: string;
    country: string;
    zipcode: string;
    startDate: string;
    endDate: string;
    price: string;
    festivalDescription: string;
    festivalOwner: string;
    contactNumber: string;
    websiteURL: string;
    facebookURL: string;
    twitterURL: string;
    instagramURL: string;
    otherSocialLink: string;
    Amenities : any;
    Vendors: any;
    Schedule: any;
    timeandSchedule: any;
    _festivalImages: any;
    publishingStatus: any;
}) => async (dispatch: Dispatch) => {
  try {
    dispatch(showLoading());
    let festivalEditResponse = await Api.editFestival({
      festivalId,
      festivalName,
      category,
      address,
      city,
      state,
      country,
      zipcode,
      startDate,
      endDate,
      price,
      festivalDescription,
      festivalOwner,
      contactNumber,
      websiteURL,
      facebookURL,
      twitterURL,
      instagramURL,
      otherSocialLink,
      Amenities,
      Vendors,
      Schedule,
      timeandSchedule,
      _festivalImages,
      publishingStatus
    });
    dispatch(festivalEditSuccess(festivalEditResponse));
    dispatch(hideLoading());
    
  } catch (error) {
    dispatch(hideLoading());
    dispatch(festivalEditError(error));
  }
};

const festivalDeleteSingle = (groupId: string) => ({
  type: ActionTypes.FESTIVAL_DELETE_SINGLE,
  payload: groupId
});

const festivalDeleteSuccess = (payload: any) => ({
  type: ActionTypes.FESTIVAL_DELETE_SUCCESS,
  payload
});

const festivalDeleteError = (payload: any) => ({
  type: ActionTypes.FESTIVAL_DELETE_ERROR,
  payload
});

export const festivalDelete = (festivalId: string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(showLoading());
    let festivalEditResponse = await Api.deleteFestival(festivalId);
    dispatch(festivalDeleteSuccess(festivalEditResponse));
    dispatch(festivalDeleteSingle(festivalId));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(festivalDeleteError(error));
    dispatch(hideLoading());
  }
};
