import * as ActionTypes from "./actionTypes";
import { showLoading, hideLoading } from "react-redux-loading-bar";

import { Dispatch } from "redux";
import Api from "../../Api";

const userFunctionCreateLoading = () => ({
  type: ActionTypes.USER_FUNCTION_CREATE
});

const userFunctionCreateSuccess = (payload: any) => ({
  type: ActionTypes.USER_FUNCTION_CREATE_SUCCESS,
  payload
});

const userFunctionCreateError = (payload: any) => ({
  type: ActionTypes.USER_FUNCTION_CREATE_ERROR,
  payload
});

export const userFunctionCreate = (name: string, description: string) => async (
  dispatch: Dispatch
) => {
  let userFunctionCreateResponse;
  try {
    dispatch(userFunctionCreateLoading());
    userFunctionCreateResponse = await Api.createUserFunction({
      name,
      description
    });
    dispatch(userFunctionCreateSuccess(userFunctionCreateResponse));
  } catch (error) {
    dispatch(userFunctionCreateError(error));
  }
};
