export const amenitieOptions:any = [
    {
        id: "FoodAvailable",
        icon: "icon-food.png",
        name: "Food Available",
        label: "Food Available",
        description: "Meals are available from a wide number of restaurant vendors and food trucks.",
    } ,
    {
        id: "FamilyFriendly",
        icon: "icon-family.png",
        name: "Family Friendly",
        label: "Family Friendly",
        description: "All exhibits and vendors have been verified for all ages. ",
    } ,
    {
        id: "DogsAllowed",
        icon: "icons-dogsallow.png",
        name: "Dogs Allowed",
        label: "Dogs Allowed",
        description: "All exhibits and vendors have been verified for all ages. ",
    }  ,
    {
        id: "OnsiteNavigation",
        icon: "ico-onsite.png",
        name: "On-site Navigation",
        label: "On-site Navigation",
        description: "Navigation assistance available here in FestFRWRD.",
    }      
]