
import * as React from "react";

import { NavLink, Link } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { showLoading, hideLoading  } from "react-redux-loading-bar";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrashAlt,
  faStar,
  faEnvelopeOpenText
} from "@fortawesome/free-solid-svg-icons";
import closeBtn from "../../assets/images/close-button.svg";
import { CheckBox } from "../../styled-components/checkboxes";
import userImg from "../../assets/images/user.png";
import { connect } from "react-redux";
import { AppState } from "../../state/types";
import {
  vendorListAll,
  vendorDelete,
  vendorSendInvite
} from "../../state/vendors/actions";
import arrowRight from "../../assets/images/right-arrow.svg";
import arrowRightDis from "../../assets/images/right-arrow-dis.svg";
import arrowLeft from "../../assets/images/left-arrow.svg";
import arrowLeftDis from "../../assets/images/left-arrow-dis.svg";
import sortUp from "../../assets/images/sort_up.svg";
import sortDown from "../../assets/images/sort_down.svg";
import sortUpActive from "../../assets/images/sort_up_active.svg";
import { stateOptions } from "./../../constants/stateOptions";

interface DispatchProps {
  getAllVendors: (
    limit: number,
    page: number,
    search: string,
    sort: string
  ) => void;
  deleteVendor: (vendorId: string) => void;
  sendInvite: (email: string,contactEmail: string,name: string,groupscount: number) => void;
}

interface StateProps {
  isLoading: boolean;
  hasErrored: boolean;
  success: Array<any>;
  isSendInviteLoading: boolean;
  hasSendInviteErrored: boolean;
  sendInviteSuccess: boolean;
  VendorDeleteSuccess: boolean;
  VendorDeleteResponse: any;
  VendorDeleteHasErrored: boolean;
}

export interface IVendorListProps {}
interface IUserGroupCreateState {
  limit: number;
  page: number;
  search: string;
  sort: string;
  sort_type: string;
  selectedVendor: any;
  checkedItems: Map<string, string>;
  InviteModalShow: any;
  DeleteModalShow: any;
  isSendInviteLoading: boolean;
}

type Props = DispatchProps & StateProps & IVendorListProps;

const checkboxes = [
  {
    name: "sms",
    key: "sms",
    label: "SMS"
  },
  {
    name: "email",
    key: "email",
    label: "Email"
  }
];

class VendorList extends React.Component<Props, IUserGroupCreateState> {
  private prevSort = "businessNameSort";
  public totalCount = 0;
  private prevSortInit = "asc";
  constructor(props: any) {
    super(props);
    this.state = {
      limit: 10,
      page: 0,
      search: "",
      sort: "asc",
      sort_type: "businessNameSort",
      selectedVendor: null as any,
      checkedItems: new Map(),
      InviteModalShow: false,
      DeleteModalShow: false,
      isSendInviteLoading: false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.VendorDeleteResponse !== prevProps.VendorDeleteResponse &&this.props.VendorDeleteSuccess) {
      this.handleDeleteClose();   
      this.getData(); 
      // toast.update(1, {
      //   render: this.props.VendorDeleteResponse.message,
      //   type: toast.TYPE.SUCCESS,
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   onClose:() =>{
      //     this.getData();
      //   }
      // });
    }
    if (this.props.VendorDeleteResponse !== prevProps.VendorDeleteResponse &&this.props.VendorDeleteHasErrored) {
      this.handleDeleteClose(); 
      toast.error((this.props.VendorDeleteResponse.error == null) ? this.props.VendorDeleteResponse.message : this.props.VendorDeleteResponse.error);    
      // toast.update(1, {
      //   render:  (this.props.VendorDeleteResponse.error == null) ? this.props.VendorDeleteResponse.message : this.props.VendorDeleteResponse.error,
      //   type: toast.TYPE.ERROR,
      //   autoClose: 2000,
      //   hideProgressBar: false
      // });
    }
    if (
      prevProps.sendInviteSuccess !== this.props.sendInviteSuccess &&
      this.props.sendInviteSuccess
    ) {
      this.setState({
        InviteModalShow: false,
        selectedVendor: null,
        isSendInviteLoading: false
      },()=>{
       // toast.success("Sent Invite successfull!");
      });
    }
    if (
      prevProps.hasSendInviteErrored !== this.props.hasSendInviteErrored &&
      this.props.hasSendInviteErrored
    ) {
      this.setState({
        InviteModalShow: false,
        selectedVendor: null,
        isSendInviteLoading: false
      },()=>{
        toast.error("Sent Invite unsuccessfull!");        
      });
    }
  }

  private getData() {
    if (this.prevSort != "") {
      this.props.getAllVendors(
        this.state.limit,
        this.state.page,
        this.state.search,
        this.state.sort_type + "_" + this.state.sort
      );
    } else {
      this.props.getAllVendors(
        this.state.limit,
        this.state.page,
        this.state.search,
        ""
      );
    }
  }

  private setTextInput = <T extends keyof IUserGroupCreateState>(
    event: React.FormEvent<HTMLInputElement>
  ) => {
    this.setState(
      { [event.currentTarget.name]: event.currentTarget.value } as {
        [P in T]: IUserGroupCreateState[P];
      },
      () => {
        this.getData();
      }
    );
  };

  private setSelectInput = (event: React.FormEvent<HTMLSelectElement>) => {
    this.setState({ ["limit"]: parseInt(event.currentTarget.value) }, () => {
      this.setState({ ["page"]: 0 }, () => {
        this.getData();
      });
    });
  };
  private setPage = (value: any) => {
    let page = this.state.page;
    page = value == "inc" ? page + 1 : page - 1;
    this.setState({ ["page"]: page }, () => {
      this.getData();
    });
  };
  private setSort = (type: string, value: string) => {
    value = value == "asc" ? "desc" : "asc";
    if (this.prevSort != type) {
      this.prevSort = type;
      value = "asc";
    }
    this.setState({ ["sort_type"]: type }, () => {
      this.setState({ ["sort"]: value }, () => {
        this.getData();
      });
    });
  };
  getArrow = () => {
    return (((this.state.page+1) * this.state.limit) >= this.totalCount) ? arrowRightDis : arrowRight;
  }
  setCount = (count:number) => {
    this.totalCount = count;
  }
  getCountString = () => {
    var str = "";
    if(this.totalCount > ((this.state.page+1) * this.state.limit)){
       str = (this.state.page * this.state.limit)+1+"-"+((this.state.page+1) * this.state.limit) +" of "+ this.totalCount;
    }else{
       str = (this.state.page * this.state.limit)+1+"-"+this.totalCount +" of "+ this.totalCount;
    }
    return str;
  }
  handleChange(e: any) {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState(prevState => ({
      checkedItems: prevState.checkedItems.set(item, isChecked)
    }));
  }

  handleDelete = () => {
    this.props.deleteVendor(this.state.selectedVendor.documentID);
    this.handleClose();
    this.handleDeleteClose();
    this.getData();    
    hideLoading();
  };

  public handleClose = () => {
    this.setState({ InviteModalShow: false, selectedVendor: null });   
  };
  getRoles(profile:any){
    const groups = [];
    if(profile.GroupNames.includes("Administrators") === true ){
      groups.push("Administrators");
    }
    if(profile.GroupNames.includes("Festival Vendors") === true && profile.invitationStatus == "accepted"){
      groups.push("Festival Vendors");
    }
    if(profile.GroupNames.includes("Festival Owners") === true && profile.opeationalStatus == "accepted"){
      groups.push("Festival Owners");
    }
    return groups.length;
  }
  handleSendInvite = () => {
    this.setState({ isSendInviteLoading: true });
    const groupscount:number = this.getRoles(this.state.selectedVendor.document);
    this.props.sendInvite(
      this.state.selectedVendor.document.email as string,
      this.state.selectedVendor.document.contactEmail as string, 
      this.state.selectedVendor.document.firstName as string,
      groupscount as number
      );
  };

  public handleShow = (item: any) => {
    this.setState({ InviteModalShow: true, selectedVendor: item });
  };
  public handleDeleteClose = () => {
    this.setState({ DeleteModalShow: false, selectedVendor: null });
  };
  public handleDeleteShow = (item: any) => {
    this.setState({ DeleteModalShow: true, selectedVendor: item });
  };
  getLocation = () => {
    let location = [];
    if(this.state.selectedVendor.document.location != ''){
      location.push(this.state.selectedVendor.document.location);
    }
    if(this.state.selectedVendor.document.city != ''){
      location.push(this.state.selectedVendor.document.city);
    }
    
    if(this.state.selectedVendor.document.state != ''){
      var statedata:any = stateOptions.filter((option:any) => option.value === this.state.selectedVendor.document.state);
      location.push(statedata[0].label);
    }
    if(this.state.selectedVendor.document.zipcode != ''){
      if(this.state.selectedVendor.document.country != null){
        location.push(this.state.selectedVendor.document.zipcode+" "+this.state.selectedVendor.document.country);
      }else{
        location.push(this.state.selectedVendor.document.zipcode);
      }     
    }
    return location.join(", ");
  }
  public render() {
    var moment = require('moment');

    return (
        <div>
          <div className="inner-title">
            <h3>VIEW VENDORS</h3>
            <div className="path_navigation">
              <ul>
                <li>
                  <NavLink to="/FestivalVendorManagement" className="btn-addnew">
                    VENDOR Management
                  </NavLink>
                </li>
                <li>
                  <span>VIEW VENDORS</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="table_data">
            <div className="table_header">
              <div className="icon-input search-icon">
                <input
                  type="text"
                  name={"search"}
                  className="form-control"
                  placeholder="Search for Vendors"
                  value={this.state.search}
                  onChange={event => this.setTextInput(event)}
                />
              </div>
              <NavLink to="/FestivalVendorManagement/list/create" className="btn-addnew">
                Add New <span>+</span>
              </NavLink>
            </div>
            <table className="custom_table">
              <thead>
                <tr>
                  <th>
                    Business Name
                    <a
                      onClick={event =>
                        this.setSort("businessNameSort", this.state.sort)
                      }
                    >
                      {this.state.sort_type == "businessNameSort" ? (
                        <img src={this.state.sort == "asc" ? sortUpActive : sortDown} />
                      ) : (
                        <img src={sortUp} />
                      )}
                    </a>
                  </th>
                  <th>
                    First Name
                    <a
                      onClick={event =>
                        this.setSort("firstNameSort", this.state.sort)
                      }
                    >
                      {this.state.sort_type == "firstNameSort" ? (
                        <img src={this.state.sort == "asc" ? sortUpActive : sortDown} />
                      ) : (
                        <img src={sortUp} />
                      )}
                    </a>
                  </th>
                  <th>
                    Last Name
                    <a
                      onClick={event =>
                        this.setSort("lastNameSort", this.state.sort)
                      }
                    >
                      {this.state.sort_type == "lastNameSort" ? (
                        <img src={this.state.sort == "asc" ? sortUpActive : sortDown} />
                      ) : (
                        <img src={sortUp} />
                      )}
                    </a>
                  </th>
                  <th>
                    CATEGORY
                    <a
                      onClick={event =>
                        this.setSort("category", this.state.sort)
                      }
                    >
                      {this.state.sort_type == "category" ? (
                        <img src={this.state.sort == "asc" ? sortUpActive : sortDown} />
                      ) : (
                        <img src={sortUp} />
                      )}
                    </a>
                  </th>
                  <th>
                    Active Since
                    <a
                      onClick={event =>
                        this.setSort("activeSince", this.state.sort)
                      }
                    >
                      {this.state.sort_type == "activeSince" ? (
                        <img src={this.state.sort == "asc" ? sortUpActive : sortDown} />
                      ) : (
                        <img src={sortUp} />
                      )}
                    </a>
                  </th>
                  <th>
                    INVITATION STATUS
                    <a
                      onClick={event =>
                        this.setSort("invitationStatus", this.state.sort)
                      }
                    >
                      {this.state.sort_type == "invitationStatus" ? (
                        <img src={this.state.sort == "asc" ? sortUpActive : sortDown} />
                      ) : (
                        <img src={sortUp} />
                      )}
                    </a>
                  </th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
              {this.props.success.length == 0 && !this.props.isLoading ? (
                <tr className="">
                  <td colSpan={7} className="nodata">
                    No data found!
                  </td>
                </tr>
              ) : null}
                {this.props.success.map((item: any) => (
                  <tr key={item.documentID}>{this.setCount(item.count)}
                    <td  className="capitalize">{item.document.businessName}</td>
                    <td  className="capitalize">{item.document.firstName}</td>
                    <td  className="capitalize">{item.document.lastName}</td>
                    <td  className="capitalize">{ (item.document.category && item.document.category !== null) ?  item.document.category.join(", ") : null }</td>
                    <td>{ (item.document.activeSince != '' && item.document.activeSince != undefined && item.document.activeSince._seconds != undefined && item.document.invitationStatus == 'accepted') ?(
                        <span>{moment.tz( moment.unix(item.document.activeSince._seconds), "America/New_York").format("MM/DD/YY, hh:mm A")+" EDT" }</span>
                    ): null}</td>
                    <td className="capitalizeNew">
                      <span className={item.document.invitationStatus}>
                        {item.document.invitationStatus}
                      </span>
                    </td>
                    <td>
                      <Dropdown drop="left" className="table_menu">
                        <Dropdown.Toggle id="dropdown-basic">
                          <span className="more_icon"></span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>                          
                            {item.document.invitationStatus != 'accepted' ? (
                              <div role="presentation">
                            <a className="spinv" onClick={() => this.handleShow(item)}>
                              <FontAwesomeIcon icon={faEnvelopeOpenText} />
                              {item.document.invitationStatus == 'optout' || item.document.invitationStatus == 'pending' || item.document.invitationStatus == 'rejected' ? ("Resend Invite") : "Send Invite"}
                            </a>
                            </div>
                            ) : null}                         
                          <div role="presentation">
                            <Link
                              to={{
                                pathname: "/FestivalVendorManagement/list/edit",
                                state: {
                                  item: item
                                }
                              }}
                            >
                              <FontAwesomeIcon icon={faEdit} />{" "}
                              <i className="fa fa-eye"></i> View/Edit
                            </Link>
                          </div>
                          {/* <div role="presentation">
                          <Link
                              to={{
                                pathname: "/reviews"
                              }}
                            >
                              <FontAwesomeIcon icon={faStar} /> Reviews
                              </Link>
                          </div> */}
                          <div role="presentation">
                            <a onClick={e => this.handleDeleteShow(item)}>
                              {" "}
                              <FontAwesomeIcon icon={faTrashAlt} /> Delete
                            </a>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={7}>
                    <div className="d-flex flex-row-reverse bd-highlight">
                      <div className="p-2 bd-highlight">
                        <button
                          className="paginationbtn"
                          disabled={this.state.page == 0}
                          onClick={event => this.setPage("dec")}
                        >
                          {" "}
                          &nbsp;&nbsp;&nbsp;
                          <img style={{width:'15px'}} src={(this.state.page == 0) ? arrowLeftDis : arrowLeft }/>{" "}
                          &nbsp;&nbsp;&nbsp;
                        </button>
                        <button
                          className="paginationbtn"
                          disabled={(((this.state.page+1) * this.state.limit) >= this.totalCount)}
                          onClick={event => this.setPage("inc")}
                        >
                          {" "}
                          &nbsp;&nbsp;&nbsp;
                          <img style={{ width: "15px" }} src={this.getArrow()} />
                          &nbsp;&nbsp;&nbsp;
                        </button>
                      </div>
                      <div className="p-2 bd-highlight">{this.getCountString()}</div>
                      <div className="p-2 bd-highlight">
                        Rows per page &nbsp;&nbsp;&nbsp;
                        <select
                          name={"offset"}
                          onChange={event => this.setSelectInput(event)}
                          style={{ border: "0px" }}
                        > 
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <Modal
            className="custom_modal InviteModalShow"
            show={this.state.InviteModalShow}
            onHide={() => this.handleClose()}
          >
            <Modal.Body>
              <div className="invitationBox">
                
            <a className="modalClose" onClick={() => this.handleClose()}>
                <img src={closeBtn}></img>
              </a>
                <div className="user-deatils-box">
                  {this.state.selectedVendor && this.state.selectedVendor.document.profileImageURL != '' ? (
                                          <div className="user-img">

                          <img src={this.state.selectedVendor.document.profileImageURL} alt="" />
                          </div>

                  ) : null}
                  <div className="user-deatils">
                    <h4>
                      {this.state.selectedVendor &&
                        this.state.selectedVendor.document.firstName +" "+ this.state.selectedVendor.document.lastName }
                    </h4>
                    {/* <p>Britt’s Bragtastic Burger Em…</p> */}
                  </div>
                </div>
                <div className="form-group">
                  <label className="blue_label"> Business name </label>
                  <p>
                    {this.state.selectedVendor &&
                      this.state.selectedVendor.document.businessName}
                  </p>
                </div>
                {this.state.selectedVendor && this.state.selectedVendor.document.contactEmail != '' && this.state.selectedVendor.document.contactEmail != undefined ? (
                    <div className="form-group">
                      <label className="blue_label"> CONTACT EMAIL ADDRESS </label>
                      <p>
                        {this.state.selectedVendor &&
                          this.state.selectedVendor.document.contactEmail}
                      </p>
                    </div>
                ) : (
                  <div className="form-group">
                    <label className="blue_label"> EMAIL ADDRESS </label>
                    <p>
                      {this.state.selectedVendor &&
                        this.state.selectedVendor.document.email}
                    </p>
                  </div>
                )}
        
                <div className="form-group">
                  <label className="blue_label"> PHONE NUMBER </label>
                  <p>
                    {this.state.selectedVendor &&
                      this.state.selectedVendor.document.phoneNumber}
                  </p>
                </div>
                <div className="form-group">
                  <label className="blue_label"> MAILING ADDRESS </label>
                  <p>
                    {this.state.selectedVendor &&
                      this.getLocation()}
                  </p>
                </div>
                <div className="form-group">
                  <label className="blue_label"> ALERT NOTIFICATIONS ON</label>
                  <div>
                    <React.Fragment>
                      {checkboxes.map(item => (
                        <div className="checkBoxButton checkBoxButton_inline">
                          <CheckBox
                            id={item.key}
                            name={item.name}
                            checked={
                              this.state.selectedVendor &&
                              this.state.selectedVendor.document.Notifications.includes(
                                item.name
                              )
                            }
                            onChange={this.handleChange}
                          />
                          <label
                            className="checkboxlabel"
                            htmlFor={item.key}
                            key={item.key}
                          >
                            {item.label}
                          </label>
                        </div>
                      ))}
                    </React.Fragment>
                  </div>
                </div>
                <button
                  disabled={this.state.isSendInviteLoading}
                  className="btn-submit"
                  onClick={() => this.handleSendInvite()}
                  type="submit"
                >
                  {this.state.isSendInviteLoading
                    ? "Sending.."
                    : "Send Invite"}
                </button>
                <button
                  className="btn-bordered"
                  onClick={() => this.handleClose()}
                  type="submit"
                >
                  Cancel
                </button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            className="custom_modal"
            show={this.state.DeleteModalShow}
            onHide={() => this.handleDeleteClose()}
          >
            <Modal.Body>
              <div className="invitationBox">
              <a
                className="modalClose"
                onClick={() => this.handleDeleteClose()}
              >
                <img src={closeBtn}></img>
              </a>
                <div className="delete-msg">
                  <h4>Are you sure you want delete this vendor?</h4>
                </div>
                <div className="user-deatils-box">
                {this.state.selectedVendor && this.state.selectedVendor.document.profileImageURL !== undefined && this.state.selectedVendor.document.profileImageURL != '' ? (
                                          <div className="user-img">

                          <img src={this.state.selectedVendor.document.profileImageURL} alt="" />
                          </div>

                  ) : null}
                  <div className="user-deatils">
                    <h4>
                      {this.state.selectedVendor &&
                        this.state.selectedVendor.document.firstName +" "+ this.state.selectedVendor.document.lastName}
                    </h4>
                  </div>
                </div>
                <button
                  className="btn-submit"
                  onClick={() => this.handleDelete()}
                  type="submit"
                >
                  Delete
                </button>
                <button
                  className="btn-bordered"
                  onClick={() => this.handleDeleteClose()}
                  type="submit"
                >
                  Cancel
                </button>
                
              </div>
            </Modal.Body>
          </Modal>
        </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.vendors.VendorList.isLoading,
  hasErrored: state.vendors.VendorList.hasErrored,
  success: state.vendors.VendorList.success,
  VendorDeleteSuccess: state.vendors.VendorDelete.success,
  VendorDeleteResponse: state.vendors.VendorDelete.response,
  VendorDeleteHasErrored: state.vendors.VendorDelete.hasErrored,
  isSendInviteLoading: state.vendors.VendorSendInvite.isLoading,
  hasSendInviteErrored: state.vendors.VendorSendInvite.hasErrored,
  sendInviteSuccess: state.vendors.VendorSendInvite.success
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, Action>
): DispatchProps => ({
  getAllVendors: (limit, page, search, sort) =>
    dispatch(vendorListAll(limit, page, search, sort)),
  deleteVendor: (vendorId: string) => dispatch(vendorDelete(vendorId)),
  sendInvite: (email: string,contactEmail: string,name: string,groupscount:number) => dispatch(vendorSendInvite(email,contactEmail,name,groupscount))
});

export default connect(mapStateToProps, mapDispatchToProps)(VendorList);
