import * as ActionTypes from "./actionTypes";
import { ForgotPinEmailState, ForgotPinEmailActionTypes } from "./types";

const initialState: ForgotPinEmailState = {
  forgotPinEmail: {
    isLoading: false,
    success: null,
    hasErrored: false
  },
  resetPin: {
    isLoading: false,
    success: null,
    hasErrored: false
  }
};

export default (
  state = initialState,
  action: ForgotPinEmailActionTypes
): ForgotPinEmailState => {
  switch (action.type) {
    case ActionTypes.FORGOT_PIN_EMAIL:
      return {
        ...state,
        forgotPinEmail: {
          ...state.forgotPinEmail,
          isLoading: true,
          hasErrored: false,
          success: null
        }
      };

    case ActionTypes.FORGOT_PIN_EMAIL_SUCCESS:
      return {
        ...state,
        forgotPinEmail: {
          ...state.forgotPinEmail,
          isLoading: false,
          success: action.payload
        }
      };

    case ActionTypes.FORGOT_PIN_EMAIL_ERROR:
      return {
        ...state,
        forgotPinEmail: {
          ...state.forgotPinEmail,
          isLoading: false,
          hasErrored: true
        }
      };

    case ActionTypes.RESET_PIN:
      return {
        ...state,
        resetPin: {
          ...state.resetPin,
          isLoading: true,
          hasErrored: false,
          success: null
        }
      };

    case ActionTypes.RESET_PIN_SUCCESS:
      return {
        ...state,
        resetPin: {
          ...state.resetPin,
          isLoading: false,
          success: action.payload
        }
      };

    case ActionTypes.RESET_PIN_ERROR:
      return {
        ...state,
        resetPin: {
          ...state.resetPin,
          isLoading: false,
          hasErrored: true
        }
      };

    default:
      return state;
  }
};
