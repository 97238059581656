import * as ActionTypes from "./actionTypes";
import { Dispatch } from "redux";
import Api from "../../Api";
import { showLoading, hideLoading } from "react-redux-loading-bar";


const getAllUserFunctionsSuccess = (payload: Array<Object>) => ({
  type: ActionTypes.USER_GET_ALL_FUNCTIONS_SUCCESS,
  payload
});

const getAllUserFunctionsError = (payload: any) => ({
  type: ActionTypes.USER_GET_ALL_FUNCTIONS_ERROR,
  payload
});

export const deleteSingleUserFunction = (payload: string) => ({
  type: ActionTypes.USER_DELETE_SINGLE_USER_FUNCTION,
  payload
});

export const getAllFunctionsReset = () => ({
  type: ActionTypes.USER_GET_ALL_FUNCTIONS_RESET
});

export const getAllUserFunctions = () => async (dispatch: Dispatch) => {
  try {
    dispatch(showLoading());
    let userFunctionCreateResponse = await Api.getAllFunctions();
    dispatch(getAllUserFunctionsSuccess(userFunctionCreateResponse));
    dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    dispatch(hideLoading());
    dispatch(getAllUserFunctionsError(error));
  }
};
export const getAllUserFunctionsList = (limit:number,page:number,search:string,sort:string) => async (dispatch: Dispatch) => {
  try {
    dispatch(showLoading());
    let userFunctionCreateResponse = await Api.getAllUserFunctionsList(limit,page,search,sort);
    dispatch(getAllUserFunctionsSuccess(userFunctionCreateResponse));
    dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    dispatch(hideLoading());
    dispatch(getAllUserFunctionsError(error));
  }
};
