import * as ActionTypes from "./actionTypes";
import { Dispatch } from "redux";
import { emailSignInSuccess } from "../session/actions";
import Api from "../../Api";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { toast } from "react-toastify";

export const forgotPinEmailLoading = () => ({
  type: ActionTypes.FORGOT_PIN_EMAIL
});

export const forgotPinEmailSuccess = (payload: any) => ({
  type: ActionTypes.FORGOT_PIN_EMAIL_SUCCESS,
  payload
});

export const forgotPinEmalError = () => ({
  type: ActionTypes.FORGOT_PIN_EMAIL_SUCCESS
});

export const forgotPinEmail = (payload: string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(showLoading());
    dispatch(forgotPinEmailLoading());
    let forgotPinEmailResponse = await Api.forgotPassword({ email: payload });
    dispatch(forgotPinEmailSuccess(forgotPinEmailResponse));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch(forgotPinEmalError());
  }
};

export const resetPinLoading = () => ({
  type: ActionTypes.RESET_PIN
});

export const resetPinSuccess = (payload: any) => ({
  type: ActionTypes.RESET_PIN_SUCCESS,
  payload
});

export const resetPinError = () => ({
  type: ActionTypes.RESET_PIN_ERROR
});

export const resetPin = (payload: {
  token: string;
  password: string;
}) => async (dispatch: Dispatch) => {
  try {
    dispatch(showLoading());
    dispatch(resetPinLoading());
    let resetPinResponse = await Api.resetPassword({
      token: payload.token,
      password: payload.password 
    });
    dispatch(hideLoading());
    dispatch(resetPinSuccess(resetPinResponse));  
    dispatch(
      emailSignInSuccess({
        token: resetPinResponse.token,
        uid: resetPinResponse.uid,
        data: resetPinResponse.data
      })
    );
  } catch (error) {
    dispatch(resetPinError());
    dispatch(hideLoading());
    toast.error("Reset pin unsuccessfully.");
  }
};
