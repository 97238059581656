import React from "react";
import { CheckBox } from '../../styled-components/checkboxes/index';
import ProfileDefault from "../../assets/images/user.svg";
import InputMask from 'react-input-mask';

interface Props {   
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	checkedItems: Map<string, string>; 
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  setTextInput: (event: React.FormEvent<HTMLInputElement>) => void;
  setFileInput: (event: React.FormEvent<HTMLInputElement>) => void;
  deleteFileInput: (event: React.FormEvent) => void;
  profilePicbase: string;
  profilePic: any;
  setRadioButton: (event: React.ChangeEvent<HTMLInputElement>) => void;  isUpdate: boolean;

  firstName: string;
  lastName: string;
  email: string;
  contactEmail: string;
  phoneNumber: string;
 
  validator: any;
  userGroups: any;
  userGroup: any;
}



function UserManagementForm(props: Props): React.ReactElement {
  let $profilePreview = null;
  if (props.profilePicbase != "") {
    $profilePreview = (<img src={props.profilePicbase} />);
  }else{
    $profilePreview = (<img src={ProfileDefault} />);
  }
  let groupKeys = [...props.checkedItems.keys()];

  return (
    <form onSubmit={props.handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="blue_label">
              FIRST NAME <span className="required">*</span>
            </label>
            <input
              name={"firstName"}
              value={props.firstName}
              onChange={event => props.setTextInput(event)}
              type="text"
              className="form-control"
              maxLength={45} 
              placeholder="Enter First Name"
            />
            {props.validator.message("firstName", props.firstName, "required|min:3|max:45")}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="blue_label">
              LAST NAME <span className="required">*</span>
            </label>
            <input
              name={"lastName"}
              value={props.lastName}
              onChange={event => props.setTextInput(event)}
              type="text"
              className="form-control"
              placeholder="Enter Last Name"
              maxLength={45} 
            />
            {props.validator.message("lastName", props.lastName,"required|min:3|max:45")}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="blue_label">
              EMAIL ADDRESS (USERNAME) <span className="required">*</span>
            </label>
            <input
              name={"email"}
              value={props.email}
              onChange={event => props.setTextInput(event)}
              type="text"
              maxLength={45} 
              disabled={props.isUpdate}
              className="form-control"
              placeholder="Enter Email"
            />
            {props.validator.message("email", props.email, "required|email")}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="blue_label">
              PHONE NUMBER <span className="required">*</span>
            </label>
            <InputMask className="form-control" placeholder="Ex: (202) 456-1111"  mask="(999) 999-9999"  name={"phoneNumber"} value={props.phoneNumber} onChange={event => props.setTextInput(event)}  />

            {props.validator.message(
              "phoneNumber",
              props.phoneNumber,
              "required|phoneNumber"
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="blue_label">
              CONTACT EMAIL ADDRESS <span className="required">*</span>
            </label>
            <input
              name={"contactEmail"}
              value={props.contactEmail}
              onChange={event => props.setTextInput(event)}
              type="text"
              maxLength={45} 
              disabled={!props.isUpdate}
              className="form-control"
              placeholder="Enter Contact Email"
            />
            {props.validator.message("contactEmail", props.contactEmail, "required|email")}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="group_panel">
            <h4>
              USER GROUPS <span className="required">*</span>
            </h4>
            <div className="user_permissin_list row customCheckBoxs">
            <React.Fragment>
                {
                  props.userGroups.map((item:any) => (
                    <div className="groupBox col-md-3">
                      <div className="checkBoxButton ">
                        <CheckBox
                          required
                          id={item.documentID}
                          name={item.document.name}
                          checked={props.checkedItems.get(item.documentID)}
                          onChange={props.handleChange}
                          />
                        <label className="checkboxlabel capitalize" htmlFor={item.documentID} key={item.documentID}>  {item.document.name} </label>                      
                      </div>
                        <div className="permission_item">
                        {item.document._functions.map((itemX:any) => (
                          <p>{itemX.name}</p>
                        ))}
                    </div>
                  </div>
                  ))
                }
              </React.Fragment>
            </div>
            {props.validator.message("Groups",groupKeys,"required")} 

          </div>
      </div>
      </div>

      <div className="row">
        <div className="col-md-6">
        <label className="blue_label">PROFILE PICTURE</label>


          <div className="form-group">
            <label className="userFileUpload">
              Choose Picture
              <input 
              type="file"
              name={"profilePic"}
              onChange={event => props.setFileInput(event)} 
              className="form-control" />
              <span>Upload</span>
            </label>
            
          </div>
          <i className="smallhint"><span className="reduired">*</span> Aspect ratio: 1:1 | Min Width:150px, Height: 150px</i>

        </div>
        <div className="col-md-6">
          
            <div className="profileUploadedImg">
               
                <div className="profileUploadedImg_inner">
                {$profilePreview}
                </div>
                <a className="delete-icon"   onClick={event => props.deleteFileInput(event)} ></a>
            </div>
        </div>
      </div>
      <div className="text-right">
      <button className="btn-submit" type="submit">
        {props.isUpdate != true ? ("Save") : "Update"} 
      </button>
      </div>
    </form>
  );
}

export default UserManagementForm;
