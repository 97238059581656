import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import SimpleReactValidator from "simple-react-validator";
import UserMyProfile from "../../components/profile/UserMyProfile";
import Select, { ValueType } from "react-select";
import { CheckBox } from "../../styled-components/checkboxes/index";
import { AppState } from "../../state/types";
import { profileEdit } from "../../state/profiles/actions";
import { onUpdateTokenNone } from '../../state/session/actions';

import { toast } from "react-toastify";
import VendorForm from "../../components/vendor/vendorForm";
import VendorFormMyProfile from "../../components/vendor/vendorFormMyProfile";
import OwnerForm from "../../components/owner/ownerForm";
import { stateOptions } from "./../../constants/stateOptions";
import { countryOptions } from "./../../constants/countryOptions";
interface DispatchProps {
  profileEdit: (profile: {
    token: string;
    businessName: string;
    category: string;
    email: string;
    contactEmail: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    facebookURL: string;
    twitterURL: string;
    instagramURL: string;
    paymentType: string[];
    Notifications: string[];
    location: string;
    GroupNames:string,
    city: string;
    state: string;
    zipcode: string;
    aboutVendor: string;
    emailSystemEmbedCode: string;
    websiteLink: string;
    country: string;
    profileImageURL: any;
    bannerImageUrl:any;
  }) => void,  
  updateToken: () => void,
}

interface StateProps {
  isLoading: boolean;
  success: boolean;
  hasErrored: boolean;
  profile: any;
}

// interface Props {}
interface State {
  isUpdate: boolean;
  token: string;
  profilePic: any,
  businessName: string;
  category: ValueType<{ value: string; label: string }>[];
  payment: ValueType<{ value: string; label: string }>[];
  country: ValueType<{ value: string; label: string }>;
  state: ValueType<{ value: string; label: string }>;
  firstName: string;
  lastName: string;
  city: string;
  email: string;
  contactEmail: string;
  facebookURL: string;
  instagramURL: string;
  location: string;
  phoneNumber: string;
  twitterURL: string;
  websiteURL: string;
  zipcode: string;
  about: string;
  embed: string;
  profilePicbase:string;
  profileImageURL:string;
  bannerImageUrl:any;
  notifications: Map<string, string>;
}

type Props = DispatchProps & StateProps & RouteComponentProps;
class ProfileEdit extends Component<Props, State> {
  private validator: any;
  public profilePicbase = "";
  public isonlyOwner = false;
  public level = 0;
  private countrydata:any = countryOptions.filter((option:any) => option.value === this.props.profile.profile.country);
private statedata:any = stateOptions.filter((option:any) => option.value === this.props.profile.profile.state);

  state = {
    isUpdate: true,
    token: "",
    profilePic: null as any,
    businessName: "",
    category: null as any,
    payment: null as any,
    country: null as any,
    selectedCountry: null as any,
    state: null as any,
    selectedState: null as any,
    firstName: "",
    lastName: "",
    city: "",
    email: "",
    contactEmail: "",
    facebookURL: "",
    instagramURL: "",
    location: "",
    address: "",
    phoneNumber: "",
    twitterURL: "",
    websiteURL: "",
    zipcode: "",
    about: "",
    embed: "",
    profilePicbase:"",
    profileImageURL:"",
    bannerImageUrl:"",
    GroupNames:"",
    notifications: new Map()
  };
  
  getRoles(){
    const groups = [];
    if(this.props.profile.profile.GroupNames.includes("Administrators") === true ){
      groups.push("Administrators");
    }
    if(this.props.profile.profile.GroupNames.includes("Festival Vendors") === true && this.props.profile.profile.invitationStatus == "accepted"){
      groups.push("Festival Vendors");
    }
    if(this.props.profile.profile.GroupNames.includes("Festival Owners") === true && this.props.profile.profile.opeationalStatus == "accepted"){
      groups.push("Festival Owners");
    }
    return groups;
  }
  constructor(props: Props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this,  validators: {
      phoneNumber: {
        message: 'The :attribute must be a valid phone Number',
        rule: (val:any, params:any, validator:any) => {
          return validator.helpers.testRegex(val,/^((\([0-9]{3}\))|[0-9]{3})[\s\-][\0-9]{3}[-][0-9]{4}$/i) && params.indexOf(val) === -1
        },
        required: true
      }
    } });
    if(this.props.profile.profile.opeationalStatus === undefined){
      this.props.profile.profile.opeationalStatus = "";
    }
    if(this.props.profile.profile.invitationStatus === undefined){
      this.props.profile.profile.invitationStatus = "";
    }
    if(this.getRoles().includes("Administrators") === true && this.getRoles().includes("Festival Vendors") == false  &&  this.getRoles().includes("Festival Owners") == false){
      this.level = 1;
        this.state = {
            isUpdate: true,
            token: this.props.profile.token,
            profilePic: this.props.profile.profile.profileImageURL,
            profilePicbase: this.props.profile.profile.profileImageURL,
            firstName: this.props.profile.profile.firstName,
            lastName: this.props.profile.profile.lastName,
            email: this.props.profile.profile.email,
            contactEmail: this.props.profile.profile.contactEmail,
            phoneNumber: this.props.profile.profile.phoneNumber,
            GroupNames:this.props.profile.profile.GroupNames,
            businessName: "",
            category: [],
            payment: [],
            country: null as any,
            state: null as any,   
            selectedState: null as any,
            selectedCountry: null as any,   
            city: "",
            facebookURL: "",
            instagramURL: "",
            location: "",
            address: "",
            twitterURL: "",
            websiteURL: "",
            zipcode: "",
            about: "",
            embed: "",
            profileImageURL:"",
            bannerImageUrl:"",
            notifications: new Map()
          };
    }
    
    else if((this.getRoles().includes("Festival Vendors") == false  &&  this.getRoles().includes("Festival Owners") == true)){
      this.isonlyOwner = true;
      this.level = 2;
      this.state = {
          isUpdate: true,
          token: this.props.profile.token,
          profilePic: this.props.profile.profile.profileImageURL,
          profilePicbase: this.props.profile.profile.profileImageURL,
          firstName: this.props.profile.profile.firstName,
          lastName: this.props.profile.profile.lastName,
          email: this.props.profile.profile.email,
          contactEmail: this.props.profile.profile.contactEmail,
          phoneNumber: this.props.profile.profile.phoneNumber,
          GroupNames:this.props.profile.profile.GroupNames,
          selectedState: this.props.profile.profile.state != ''
          ? {
              value: this.props.profile.profile.state,
              label: (this.statedata.length > 0) ? this.statedata[0].label :this.props.profile.profile.state
            }
          : null,
          selectedCountry:  this.props.profile.profile.country != ''
          ? {
              value: this.props.profile.profile.country,
              label: (this.countrydata.length > 0) ? this.countrydata[0].label :this.props.profile.profile.country
            }
          : null, 
          businessName: "",
          category: [],
          payment: [],
          country:
          this.props.profile.profile.country != ''
            ? {
                value: this.props.profile.profile.country,
                label: (this.countrydata.length > 0) ? this.countrydata[0].label :this.props.profile.profile.country 
              }
            : null,
        state: this.props.profile.profile.state != ''
          ? {
              value: this.props.profile.profile.state,
              label: (this.statedata.length > 0) ? this.statedata[0].label :this.props.profile.profile.state
            }
          : null,  
          city: this.props.profile.profile.city,
          facebookURL: "",
          instagramURL: "",
          location: this.props.profile.profile.location == '' ? this.props.profile.profile.address : this.props.profile.profile.location ,
          address: this.props.profile.profile.address == '' ? this.props.profile.profile.location : this.props.profile.profile.address ,
          twitterURL: "",
          websiteURL: "",
          zipcode: this.props.profile.profile.zipcode,
          about: "",
          embed: "",
          profileImageURL:"",
          bannerImageUrl:"",
          notifications: new Map(
            this.props.profile.profile.Notifications.map((i: string) => [
              i,
              i
            ]) as Map<string, string>
          )
        };
  }
    else if(this.getRoles().includes("Festival Vendors") == true  &&  this.getRoles().includes("Festival Owners") == false || (this.getRoles().includes("Festival Vendors") == true  &&  this.getRoles().includes("Festival Owners") == true && this.getRoles().includes("Administrators") === false)){

      this.level = 3;
    this.state = {
        isUpdate: true,
        token: this.props.profile.token,
        profilePic: this.props.profile.profile.profileImageURL,
        profilePicbase: this.props.profile.profile.profileImageURL,
        businessName: this.props.profile.profile.businessName,
        selectedState: this.props.profile.profile.state != ''
        ? {
            value: this.props.profile.profile.state,
            label: (this.statedata.length > 0) ? this.statedata[0].label :this.props.profile.profile.state
          }
        : null,
        selectedCountry:  this.props.profile.profile.country != ''
        ? {
            value: this.props.profile.profile.country,
            label: (this.countrydata.length > 0) ? this.countrydata[0].label :this.props.profile.profile.country
          }
        : null, 
        category: this.props.profile.profile.category.map(
          (item: string) => ({
            value: item,
            label: item.charAt(0).toUpperCase() + item.slice(1)
          })
        ),
        payment: this.props.profile.profile.paymentType.map(
          (item: string) => ({
            value: item,
            label: item.charAt(0).toUpperCase() + item.slice(1)
          })
        ),
        country:
          this.props.profile.profile.country.length > 0
            ? {
                value: this.props.profile.profile.country,
                label: (this.countrydata.length > 0) ? this.countrydata[0].label :this.props.profile.profile.country
              }
            : null,
        state: this.props.profile.profile.state != ''
          ? {
              value: this.props.profile.profile.state,
              label: (this.statedata.length > 0) ? this.statedata[0].label :this.props.profile.profile.state
            }
          : null,
        firstName: this.props.profile.profile.firstName,
        lastName: this.props.profile.profile.lastName,
        GroupNames:this.props.profile.profile.GroupNames,
        city: this.props.profile.profile.city,
        email: this.props.profile.profile.email,
        contactEmail: this.props.profile.profile.contactEmail,
        facebookURL: this.props.profile.profile.facebookURL,
        instagramURL: this.props.profile.profile.instagramURL,
        location: this.props.profile.profile.location == '' ? this.props.profile.profile.address : this.props.profile.profile.location ,
        address: this.props.profile.profile.address == '' ? this.props.profile.profile.location : this.props.profile.profile.address ,
        phoneNumber: this.props.profile.profile.phoneNumber,
        twitterURL: this.props.profile.profile.twitterURL,
        websiteURL: this.props.profile.profile.websiteLink,
        zipcode: this.props.profile.profile.zipcode,
        about: this.props.profile.profile.aboutVendor,
        embed: this.props.profile.profile.emailSystemEmbedCode,
        profileImageURL: this.props.profile.profile.profileImageURL,
        bannerImageUrl: this.props.profile.profile.bannerImageUrl,
        notifications: new Map(
          this.props.profile.profile.Notifications.map((i: string) => [
            i,
            i
          ]) as Map<string, string>
        )
      };
    }
    else if(this.getRoles().includes("Administrators") === true && this.getRoles().includes("Festival Vendors") == true &&  this.getRoles().includes("Festival Owners") == true){
      this.level = 3;
    
    this.state = {
        isUpdate: true,
        token: this.props.profile.token,
        profilePic: this.props.profile.profile.profileImageURL,
        profilePicbase: this.props.profile.profile.profileImageURL,
        businessName: this.props.profile.profile.businessName,
        category: this.props.profile.profile.category.map(
          (item: string) => ({
            value: item,
            label: item.charAt(0).toUpperCase() + item.slice(1)
          })
        ),
        payment: this.props.profile.profile.paymentType.map(
          (item: string) => ({
            value: item,
            label: item.charAt(0).toUpperCase() + item.slice(1)
          })
        ),
        country:
          this.props.profile.profile.country != ''
            ? {
                value: this.props.profile.profile.country,
                label: (this.countrydata.length > 0) ? this.countrydata[0].label :this.props.profile.profile.country
              }
            : null,
        state: this.props.profile.profile.state
          ? {
              value: this.props.profile.profile.state,
              label: (this.statedata.length > 0) ? this.statedata[0].label :this.props.profile.profile.state
            }
          : null,
          selectedState: this.props.profile.profile.state != ''
          ? {
              value: this.props.profile.profile.state,
              label: (this.statedata.length > 0) ? this.statedata[0].label :this.props.profile.profile.state
            }
          : null,
          selectedCountry:  this.props.profile.profile.country != ''
          ? {
              value: this.props.profile.profile.country,
              label: (this.countrydata.length > 0) ? this.countrydata[0].label :this.props.profile.profile.country
            }
          : null,  
        firstName: this.props.profile.profile.firstName,
        lastName: this.props.profile.profile.lastName,
        GroupNames:this.props.profile.profile.GroupNames,
        city: this.props.profile.profile.city,
        email: this.props.profile.profile.email,
        contactEmail: this.props.profile.profile.contactEmail,
        facebookURL: this.props.profile.profile.facebookURL,
        instagramURL: this.props.profile.profile.instagramURL,
        location: this.props.profile.profile.location == '' ? this.props.profile.profile.address : this.props.profile.profile.location ,
        address: this.props.profile.profile.address == '' ? this.props.profile.profile.location : this.props.profile.profile.address ,
        phoneNumber: this.props.profile.profile.phoneNumber,
        twitterURL: this.props.profile.profile.twitterURL,
        websiteURL: this.props.profile.profile.websiteLink,
        zipcode: this.props.profile.profile.zipcode,
        about: this.props.profile.profile.aboutVendor,
        embed: this.props.profile.profile.emailSystemEmbedCode,
        profileImageURL: this.props.profile.profile.profileImageURL,
        bannerImageUrl: this.props.profile.profile.bannerImageUrl,
        notifications: new Map(
          this.props.profile.profile.Notifications.map((i: string) => [
            i,
            i
          ]) as Map<string, string>
        )
      };
    }

   
  }
 
  componentDidMount() {
    document.title = "Edit Myprofile | FestFrwrd - The Futr of FSTIVLS";
  }
  componentDidUpdate(prevProps: Props) {
    if (prevProps.success !== this.props.success && this.props.success) {     
      this.props.updateToken();      
    }
    if (
      prevProps.hasErrored !== this.props.hasErrored &&
      this.props.hasErrored
    ) {
      //this.props.history.goBack();
    }
    if (prevProps.profile.token !== this.props.profile.token) {
      this.props.history.push("/FestivalManagement");
    }
  }
  private deleteFileInput = (event: any) => {
    this.setState({ profilePicbase: "" });
  };
  getBase64(file:any, cb:any) {
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = function () {
          cb(reader.result)
      };
      reader.onerror = function (error) {
          console.log('Error: ', error);
      };
  }
  private setFileInput = (event: React.FormEvent<HTMLInputElement>) => { 
    let files = event.currentTarget.files;
    this.getBase64(files, (result:any) => {
        this.setState({ profileImageURL: result });
        this.setState({ profilePicbase: result });
    });
  };
  setTextInput = <T extends keyof State>(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const newState = {
      [event.currentTarget.name]: event.currentTarget.value
    };
    this.setState(newState as { [P in T]: State[P] });
  };

  handleSelectChange = <T extends keyof State>(
    selectedOption: ValueType<{ value: string; label: string }>,
    name: string
  ) => {
    const newState = {
      [name]: selectedOption
    };
    this.setState(newState as { [P in T]: State[P] }, () =>
      console.log(this.state)
    );
  };
  private setProfileFileInput = (value: any) => {
    this.setState({ profileImageURL: value });
  };
  private setBanerFileInput = (value: any) => {
    this.setState({ bannerImageUrl: value });
  };
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const item = e.target.id;
    const name = e.target.name;
    const isChecked = e.target.checked;
    if (!isChecked) {
      this.setState((state, props) => {
        state.notifications.delete(item);
        return {
          notifications: state.notifications
        };
      });
    } else {
      this.setState((state, props) => {
        state.notifications.set(item, name);
        return {
          notifications: state.notifications
        };
      });
    }
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.props.isLoading) {
      return;
    }
    if (this.validator.allValid()) {
      const paymentType = this.state.payment.map((item: any) => item["value"]);
      const category = this.state.category.map((item: any) => item["value"]);
      let profileImageURL = undefined;
      if(this.state.profileImageURL != this.props.profile.profile.profileImageURL){
        profileImageURL = this.state.profileImageURL;
      }
      let bannerImageUrl = undefined;
      if(this.state.bannerImageUrl != this.props.profile.profile.bannerImageUrl){
        bannerImageUrl = this.state.bannerImageUrl;
      }
      this.props.profileEdit({
        token: this.props.profile.token,
        category: category,
        paymentType: paymentType,
        aboutVendor: this.state.about,
        Notifications: [...this.state.notifications.values()],
        city: this.state.city,
        country: (this.level == 3 && this.state.country != null) ? this.state.country.value : this.state.selectedCountry != null ? this.state.selectedCountry.value : "",
        email: this.state.email,
        contactEmail: this.state.contactEmail,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phoneNumber: this.state.phoneNumber,
        state: (this.level == 3 && this.state.state != null) ? this.state.state.value : this.state.selectedState != null ? this.state.selectedState.value : "",
        businessName: this.state.businessName,
        facebookURL: this.state.facebookURL,
        instagramURL: this.state.instagramURL,
        GroupNames:this.state.GroupNames,
        location: (this.isonlyOwner == true) ? this.state.address : this.state.location,
        twitterURL: this.state.twitterURL,
        websiteLink: this.state.websiteURL,
        zipcode: this.state.zipcode,
        profileImageURL: profileImageURL,
        emailSystemEmbedCode: this.state.embed,        
        bannerImageUrl:bannerImageUrl,
      });
    } else {
      toast.dismiss(2);
      toast.warn("Please fill in all required fields!",{toastId:2});
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
        <div>
          <div className="inner-title">
            <h3>Edit profile</h3>
          </div>
            {this.getRoles().includes("Festival Vendors") == true &&  this.getRoles().includes("Festival Owners") == true ? (
            <VendorFormMyProfile
                isUpdate={this.state.isUpdate}
                businessName={this.state.businessName}
                category={this.state.category}
                firstName={this.state.firstName}
                lastName={this.state.lastName}
                city={this.state.city}
                email={this.state.email}
                contactEmail={this.state.contactEmail}
                facebookURL={this.state.facebookURL}
                instagramURL={this.state.instagramURL}
                location={this.state.location}
                phoneNumber={this.state.phoneNumber}
                twitterURL={this.state.twitterURL}
                websiteURL={this.state.websiteURL}
                zipcode={this.state.zipcode}
                payment={this.state.payment}
                country={this.state.country}
                about={this.state.about}
                embed={this.state.embed}
                profileImageURL= {this.state.profileImageURL}
                bannerImageUrl= {this.state.bannerImageUrl}
                state={this.state.state}
                notifications={this.state.notifications}
                setTextInput={this.setTextInput}
                handleSelectChange={this.handleSelectChange}
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}                             
                setProfileFileInput={this.setProfileFileInput}
                setBanerFileInput={this.setBanerFileInput}
                validator={this.validator}
                />
              ): null}
              {(this.getRoles().includes("Festival Vendors") == true  &&  this.getRoles().includes("Festival Owners") == false)  ? (
                 <div className="white_panel form_pading">
                  <VendorForm
                      isUpdate={this.state.isUpdate}
                      businessName={this.state.businessName}
                      category={this.state.category}
                      firstName={this.state.firstName}
                      lastName={this.state.lastName}
                      city={this.state.city}
                      email={this.state.email}
                      contactEmail={this.state.contactEmail}
                      facebookURL={this.state.facebookURL}
                      instagramURL={this.state.instagramURL}
                      location={this.state.location}
                      phoneNumber={this.state.phoneNumber}
                      twitterURL={this.state.twitterURL}
                      websiteURL={this.state.websiteURL}
                      zipcode={this.state.zipcode}
                      payment={this.state.payment}
                      country={this.state.country}
                      about={this.state.about}
                      embed={this.state.embed}
                      profileImageURL= {this.state.profileImageURL}
                      bannerImageUrl= {this.state.bannerImageUrl}
                      state={this.state.state}
                      notifications={this.state.notifications}
                      setTextInput={this.setTextInput}
                      handleSelectChange={this.handleSelectChange}
                      handleChange={this.handleChange}
                      handleSubmit={this.handleSubmit}                             
                      setProfileFileInput={this.setProfileFileInput}
                      setBanerFileInput={this.setBanerFileInput}
                      validator={this.validator}
                      />
                </div>
              ): null}
              {(this.getRoles().includes("Festival Vendors") == false &&  this.getRoles().includes("Administrators") == true   &&  this.getRoles().includes("Festival Owners") == false)
              ? (
                   <UserMyProfile                    
                    handleSubmit={e => this.handleSubmit(e)}
                    setTextInput={e => this.setTextInput(e)}
                    firstName={this.state.firstName}
                    lastName={this.state.lastName}
                    email={this.state.email}
                    contactEmail={this.state.contactEmail}
                    phoneNumber={this.state.phoneNumber}
                    validator={this.validator}
                    setFileInput={this.setFileInput} 
                    profilePic={this.state.profilePic}
                    deleteFileInput={this.deleteFileInput}         
                    profilePicbase={this.state.profilePicbase}
                    />
            ): null}
             {(this.getRoles().includes("Festival Vendors") == false  &&  this.getRoles().includes("Festival Owners") == true) ? (
            <OwnerForm
                   isUpdate={this.state.isUpdate}
                    firstName={this.state.firstName}
                    lastName={this.state.lastName}
                    email={this.state.email}
                    contactEmail={this.state.contactEmail}
                    phoneNumber={this.state.phoneNumber}
                    address={this.state.address}
                    city={this.state.city}
                    states={stateOptions}
                    selectedState={this.state.selectedState}
                    zipcode={this.state.zipcode}
                    countries={countryOptions}
                    selectedCountry={this.state.selectedCountry}
                    notifications={this.state.notifications}
                    setTextInput={this.setTextInput}
                    handleSelectChange={this.handleSelectChange}
                    setFileInput={this.setFileInput}
                    profilePic={this.state.profilePic}
                    deleteFileInput={this.deleteFileInput}
                    profilePicbase={this.state.profilePicbase}
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                    validator={this.validator}
                  />
            ): null}
          </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  profile: state.session,
  isLoading: state.profiles.ProfileEdit.isLoading,
  success: state.profiles.ProfileEdit.success,
  hasErrored: state.profiles.ProfileEdit.hasErrored
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, Action>
): DispatchProps => ({
  profileEdit: ({
    token,
    businessName,
    category,
    email,
    contactEmail,
    phoneNumber,
    firstName,
    lastName,
    facebookURL,
    twitterURL,
    GroupNames,
    instagramURL,
    paymentType,
    Notifications,
    location,
    city,
    state,
    zipcode,
    aboutVendor,
    emailSystemEmbedCode,
    websiteLink,
    country,
    profileImageURL,
    bannerImageUrl
  }) =>
    dispatch(
      profileEdit({
        token,
        businessName,
        category,
        email,
        contactEmail,
        phoneNumber,
        firstName,
        lastName,
        facebookURL,
        twitterURL,
        GroupNames,
        instagramURL,
        paymentType,
        Notifications,
        location,
        city,
        state,
        zipcode,
        aboutVendor,
        emailSystemEmbedCode,
        websiteLink,
        country,
        profileImageURL,
        bannerImageUrl
      })
    ),
    updateToken: () => dispatch(onUpdateTokenNone())
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit);
