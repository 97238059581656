import * as React from "react";
import { NavLink, Link } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { toast } from "react-toastify";

import { showLoading, hideLoading  } from "react-redux-loading-bar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faTrashAlt,
  faStar,
  faEnvelopeOpenText
} from "@fortawesome/free-solid-svg-icons";
import closeBtn from "../../assets/images/close-button.svg";
import userImg from "../../assets/images/user.png";
import { connect } from "react-redux";
import { AppState } from "../../state/types";
import {
  festivalListAll,
  festivalDelete
} from "../../state/festivals/actions";
import arrowRight from "../../assets/images/right-arrow.svg";
import arrowRightDis from "../../assets/images/right-arrow-dis.svg";
import arrowLeft from "../../assets/images/left-arrow.svg";
import arrowLeftDis from "../../assets/images/left-arrow-dis.svg";
import sortUp from "../../assets/images/sort_up.svg";
import sortUpActive from "../../assets/images/sort_up_active.svg";
import sortDown from "../../assets/images/sort_down.svg";
import moment from "moment";

interface DispatchProps {
  getAllFestivals: (
    limit: number,
    page: number,
    search: string,
    sort: string,
    filter: string,
    type: any,
  ) => void;
  deleteFestival: (festivalId: string) => void;
}

interface StateProps {
  isLoading: boolean;
  hasErrored: boolean;
  success: Array<any>;
  profile: any;
  session: any;
  successDelete: boolean;
  responseDelete: any;
  hasErroredDelete: boolean;
}

export interface IFestivalListProps {}
interface IUserGroupCreateState {
  limit: number;
  page: number;
  search: string;
  sort: string;
  sort_type: string;
  filter: string;
  selectedFestival: any;
  DeleteModalShow: any;
}
export interface UrlProps {
  match: {
    params: {
      type?: string
    }
  }
}
type Props = DispatchProps & StateProps & IFestivalListProps & UrlProps;

class FestivalList extends React.Component<Props, IUserGroupCreateState> {
  private prevSort = "startDate";
  public totalCount = 0;
  constructor(props: any) { 
    super(props);
   
    var hashval = window.location.hash;
    var f = 'active';
    if(hashval != ''){
      hashval = hashval.replace("#","");
      f = hashval;
    }
  
    this.state = {
      limit: 10,
      page: 0,
      search: "",
      sort: "asc",
      sort_type: "startDate",
      filter: f,
      selectedFestival: null as any,
      DeleteModalShow: false,
    };
    this.handleChange = this.handleChange.bind(this);
   
    setTimeout(() => {
      var hashval = window.location.hash;
      var f = 'active';
      if(hashval != ''){
        hashval = hashval.replace("#","");
        f = hashval;
      }
      this.setState({filter: f},()=>{
        this.getData();
      })
      
    });
  }
  componentDidMount(){
    var hashval = window.location.hash;
    var f = 'active';
    if(hashval != ''){
      hashval = hashval.replace("#","");
      f = hashval;
    }
    this.setState({ filter: f})
  }

  componentDidUpdate(prevProps: Props) {
    if(this.props.match.params.type != prevProps.match.params.type){
      this.getData();
    }
    if (prevProps.responseDelete !== this.props.responseDelete && this.props.successDelete) {
      this.getData(); 
      // toast.update(1, {
      //   render: this.props.responseDelete.data.message,
      //   type: toast.TYPE.SUCCESS,
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   onClose:() =>{
         
      //   }
      // });    
    }
    if (
      prevProps.responseDelete !== this.props.responseDelete &&
      this.props.hasErroredDelete
    ) { 
      toast.error((this.props.responseDelete.error == null) ? this.props.responseDelete.message : this.props.responseDelete.error);        
      // toast.update(1, {
      //   render: (this.props.responseDelete.error == null) ? this.props.responseDelete.message : this.props.responseDelete.error,
      //   type: toast.TYPE.ERROR,
      //   autoClose: 2000,
      //   hideProgressBar: false
      // });
    }
  }

  private getData() {
    this.totalCount = 0;
    if (this.prevSort != "") {
      let _type = this.props.match.params.type;
      if(_type === undefined){
        if(this.getRoles().includes("Administrators") === true){
          _type = 'all';
        }
        if(this.getRoles().includes("Festival Owners") === true){
          _type = 'owner';
        }
        if(this.getRoles().includes("Festival Vendors") === true){
          _type = 'vendor';
        } 
      }
      
      this.props.getAllFestivals(
        this.state.limit,
        this.state.page,
        this.state.search,
        this.state.sort_type + "_" + this.state.sort,
        this.state.filter,
        _type
      );
    } else {
      let _type = this.props.match.params.type;
      if(_type === undefined){
        if(this.getRoles().includes("Administrators") === true){
          _type = 'all';
        }
        if(this.getRoles().includes("Festival Owners") === true){
          _type = 'owner';
        }
        if(this.getRoles().includes("Festival Vendors") === true){
          _type = 'vendor';
        }
      }
      
      this.props.getAllFestivals(
        this.state.limit,
        this.state.page,
        this.state.search,
        "",
        this.state.filter,
        _type
      );
    }
  }

  private setTextInput = <T extends keyof IUserGroupCreateState>(
    event: React.FormEvent<HTMLInputElement>
  ) => {
    this.setState(
      { [event.currentTarget.name]: event.currentTarget.value } as {
        [P in T]: IUserGroupCreateState[P];
      },
      () => {
        this.getData();
      }
    );
  };

  private setSelectInput = (event: React.FormEvent<HTMLSelectElement>) => {
    this.setState({ ["limit"]: parseInt(event.currentTarget.value) }, () => {
      this.setState({ ["page"]: 0 }, () => {
        this.getData();
      });
    });
  };
  private setPage = (value: any) => {
    let page = this.state.page;
    page = value == "inc" ? page + 1 : page - 1;
    this.setState({ ["page"]: page }, () => {
      this.getData();
    });
  };
  private setSort = (type: string, value: string) => {
    value = value == "asc" ? "desc" : "asc";
    if (this.prevSort != type) {
      this.prevSort = type;
      value = "asc";
    }
    this.setState({ ["sort_type"]: type }, () => {
      this.setState({ ["sort"]: value }, () => {
        this.getData();
      });
    });
  };
  getArrow = () => {
    return (((this.state.page+1) * this.state.limit) >= this.totalCount) ? arrowRightDis : arrowRight;
  }
  setFilter = (value:any) => {

    this.setState({ ["limit"]: 10 });
    this.setState({ ["page"]: 0 });
    this.setState({ ["search"]: "" });
    if(value == 'past'){
      this.setState({ ["sort_type"]: "endDate" });
      this.setState({ ["sort"]: "desc" });
    }else{
      this.setState({ ["sort_type"]: "startDate" });
      this.setState({ ["sort"]: "asc" });
    }   

    this.setState({ ["filter"]: value }, () => {
      window.location.hash = this.state.filter;

      this.getData();   
    });
  }
  setCount = (count:number) => {
    this.totalCount = count;
  }
  getCountString = () => {
    var str = "";
    if(this.totalCount > ((this.state.page+1) * this.state.limit)){
       str = (this.state.page * this.state.limit)+1+"-"+((this.state.page+1) * this.state.limit) +" of "+ this.totalCount;
    }else{
       str = (this.state.page * this.state.limit)+1+"-"+this.totalCount +" of "+ this.totalCount;
    }
    return str;
  }
  handleChange(e: any) {
    const item = e.target.name;
    const isChecked = e.target.checked;
   
  }

  handleDelete = () => {
    this.props.deleteFestival(this.state.selectedFestival.documentID);
    this.handleDeleteClose();

  };
  getRoles(){
    const groups = [];
    if(this.props.profile.GroupNames.includes("Administrators") === true ){
      groups.push("Administrators");
    }
    if(this.props.profile.GroupNames.includes("Festival Vendors") === true && this.props.profile.invitationStatus == "accepted"){
      groups.push("Festival Vendors");
    }
    if(this.props.profile.GroupNames.includes("Festival Owners") === true && this.props.profile.opeationalStatus == "accepted"){
      groups.push("Festival Owners");
    }
    return groups;
  }
  public handleDeleteClose = () => {
    this.setState({ DeleteModalShow: false, selectedFestival: null });
    hideLoading();
  };
  public handleDeleteShow = (item: any) => {
    this.setState({ DeleteModalShow: true, selectedFestival: item });
  };
  public getDateDisplay(date:any){
    if(typeof date === 'string'){
      return moment(new Date(date)).format("MM-DD-YYYY");
    }else if(typeof date === 'object' && date !== null){
      return moment.unix(date._seconds).format("MM-DD-YYYY");
    }else{
      return '';
    }
  }
  public render() {
    var _type = this.props.match.params.type;
    if(_type === undefined){
      if(this.getRoles().includes("Administrators") === true){
        _type = 'Festival Management';
      }else{
        _type = (_type == '' || _type == undefined) ? "Your Festival Participation  as a vendor" : _type
      } 
    }else{ 
      _type = 'Your Festival Participation  as a '+_type;
    }
    document.title = _type+" | FestFrwrd - The Futr of FSTIVLS";

    return (
        <div>
          <div className="inner-title">
            <h3>{_type}</h3>
                <div className="festival_nav">
                  <a className={(this.state.filter == "active") ? 'active' : ""} 
                     onClick={event =>
                        this.setFilter("active")
                      }
                  >ACTIVE FESTIVALS</a>
                  <a className={(this.state.filter == "upcoming") ? 'active' : ""} 
                   onClick={event =>
                      this.setFilter("upcoming")
                    }
                  >UPCOMING FESTIVALS</a>
                  <a className={(this.state.filter == "past") ? 'active' : ""} 
                   onClick={event =>
                      this.setFilter("past")
                    }
                  >PAST FESTIVALS</a>
                  {this.getRoles().includes("Administrators") == true ||  this.getRoles().includes("Festival Owners") == true ? (
                  <a  className={(this.state.filter == "unpublished") ? 'active' : ""}
                   onClick={event =>
                      this.setFilter("unpublished")
                    }
                   >UNPUBLISHED FESTIVALS</a>
                  ):null}
                </div>
            </div>

          <div className="table_data">
            <div className="table_header">
              <div className="icon-input search-icon">
                <input
                  type="text"
                  name={"search"}
                  className="form-control"
                  placeholder="Search for Festivals"
                  value={this.state.search}
                  onChange={event => this.setTextInput(event)}
                />
              </div>
                {this.props.profile.GroupNames.includes("Administrators") === true ? (
                  <NavLink to={"/FestivalManagement/list/create#"+this.state.filter} className="btn-addnew">
                    Add New <span>+</span>
                  </NavLink>
                ) : null}
            </div>
            <table className="custom_table festval">
              <thead>
                <tr>
                  <th>
                    FESTIVAL NAME
                    <a
                      onClick={event =>
                        this.setSort("festivalNameSort", this.state.sort)
                      }
                    >
                      {this.state.sort_type == "festivalNameSort" ? (
                        <img src={this.state.sort == "asc" ? sortUpActive : sortDown} />
                      ) : (
                        <img src={sortUp} />
                      )}
                    </a>
                  </th>
                  <th>
                    FESTIVAL category
                    <a
                      onClick={event =>
                        this.setSort("category", this.state.sort)
                      }
                    >
                      { this.state.sort_type == "category" ? (
                        <img src={this.state.sort == "asc" ? sortUpActive : sortDown} />
                      ) : (
                        <img src={sortUp} />
                      )}
                    </a>
                  </th>
                  <th>
                  START DATE
                    <a
                      onClick={event =>
                        this.setSort("startDate", this.state.sort)
                      }
                    >
                      { this.state.sort_type == "startDate" ? (
                        <img src={this.state.sort == "asc" ? sortUpActive : sortDown} />
                      ) : (
                        <img src={sortUp} />
                      )}
                    </a>
                  </th>
                  <th>
                  END DATE
                    <a
                      onClick={event =>
                        this.setSort("endDate", this.state.sort)
                      }
                    >
                      { this.state.sort_type == "endDate" ? (
                        <img src={this.state.sort == "asc" ? sortUpActive : sortDown} />
                      ) : (
                        <img src={sortUp} />
                      )}
                    </a>
                  </th>
                  <th>
                  LOCATION
                    <a
                      onClick={event =>
                        this.setSort("addressSort", this.state.sort)
                      }
                    >
                      { this.state.sort_type == "addressSort" ? (
                        <img src={this.state.sort == "asc" ? sortUpActive : sortDown} />
                      ) : (
                        <img src={sortUp} />
                      )}
                    </a>
                  </th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
              {this.props.success.length == 0 && !this.props.isLoading ? (
                <tr className="">
                  <td colSpan={7} className="nodata">
                    No data found!
                  </td>
                </tr>
              ) : null}
                {this.props.success.map((item: any) => (
                  <tr key={item.documentID}>{this.setCount(item.count)}
                    <td  className="capitalize">{ (item.document._draft != null && item.document._draft != undefined  ) ? item.document._draft.festivalName :  item.document.festivalName }</td>
                    <td  className="capitalize">{ (item.document._draft != null && item.document._draft != undefined  ) ? (item.document._draft.category !== null)? item.document._draft.category.label:''  : (item.document.category !== null)? item.document.category.label:'' }</td>
                    <td  className="capitalize">{ (item.document._draft != null && item.document._draft != undefined  ) ? this.getDateDisplay(item.document._draft.startDate) : this.getDateDisplay(item.document.startDate) }</td>
                    <td  className="capitalize">{ (item.document._draft != null && item.document._draft != undefined  ) ? this.getDateDisplay(item.document._draft.endDate) : this.getDateDisplay(item.document.endDate) }</td>
                    <td  className="capitalize">{ (item.document._draft != null && item.document._draft != undefined  ) ? item.document._draft._location :  item.document._location }</td>
                    <td>
                      <Dropdown drop="left" className="table_menu">
                        <Dropdown.Toggle id="dropdown-basic">
                          <span className="more_icon"></span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>         
                        {this.state.filter === "past" || (this.props.match.params.type !== undefined && this.props.match.params.type === 'vendor') ||  (item.document.festivalOwner !== null && item.document.festivalOwner !== undefined && item.document.festivalOwner.value != this.props.session.uid && this.getRoles().includes("Administrators") == false ) ? ( 
                          <div role="presentation">
                            <Link
                              to={{
                                pathname: "/FestivalManagement/list/view",
                                state: {
                                  item: item,
                                  filter: this.state.filter,
                                }
                              }}
                            >
                              <FontAwesomeIcon icon={faEye} />{" "}
                              <i className="fa fa-pencel"></i> View
                            </Link>
                          </div>   
                        ):null}
                          {this.state.filter != "past" && (this.getRoles().includes("Administrators") == true  ||  (item.document.festivalOwner !== null && item.document.festivalOwner !== undefined && item.document.festivalOwner.value == this.props.session.uid) )  ? (
                          <div role="presentation">
                            <Link
                              to={{
                                pathname: "/FestivalManagement/list/edit",
                                state: {
                                  item: item,
                                  filter: this.state.filter,
                                }
                              }}
                            >
                              <FontAwesomeIcon icon={faEdit} />{" "}
                              <i className="fa fa-pencel"></i> <span>View/Edit</span>
                            </Link>
                          </div>  
                          ) : null}



                          {this.props.profile.GroupNames.includes("Administrators") === true ?   (                    
                          <div role="presentation">
                            <a onClick={e => this.handleDeleteShow(item)}>
                              {" "}
                              <FontAwesomeIcon icon={faTrashAlt} /> Delete
                            </a>
                          </div>
                          ):null}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={7}>
                    <div className="d-flex flex-row-reverse bd-highlight">
                      <div className="p-2 bd-highlight">
                        <button
                          className="paginationbtn"
                          disabled={this.state.page == 0}
                          onClick={event => this.setPage("dec")}
                        >
                          {" "}
                          &nbsp;&nbsp;&nbsp;
                          <img style={{width:'15px'}} src={(this.state.page == 0) ? arrowLeftDis : arrowLeft }/>{" "}
                          &nbsp;&nbsp;&nbsp;
                        </button>
                        <button
                          className="paginationbtn"
                          disabled={(((this.state.page+1) * this.state.limit) >= this.totalCount)}
                          onClick={event => this.setPage("inc")}
                        >
                          {" "}
                          &nbsp;&nbsp;&nbsp;
                          <img style={{ width: "15px" }} src={this.getArrow()} />
                          &nbsp;&nbsp;&nbsp;
                        </button>
                      </div>
                      <div className="p-2 bd-highlight">{this.getCountString()}</div>
                      <div className="p-2 bd-highlight">
                        Rows per page &nbsp;&nbsp;&nbsp;
                        <select
                          name={"offset"}
                          value={this.state.limit}
                          onChange={event => this.setSelectInput(event)}
                          style={{ border: "0px" }}
                        > 
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <Modal
            className="custom_modal"
            show={this.state.DeleteModalShow}
            onHide={() => this.handleDeleteClose()}
          >
            <Modal.Body>
              <div className="invitationBox">
              <a
                className="modalClose"
                onClick={() => this.handleDeleteClose()}
              >
                <img src={closeBtn}></img>
              </a>
                <div className="delete-msg">
                  <h4>Are you sure you want delete this festival?</h4>
                </div>
                <div className="user-deatils-box">
                  <div className="user-img">
                   
                  {this.state.selectedFestival && this.state.selectedFestival.document._festivalImages.length > 0 ? (
                          <img  src={this.state.selectedFestival.document._festivalImages[0].thmb} alt="" />
                  ) : (
                          <img src={userImg} alt="" />
                  )}
                  </div>
                  <div className="user-deatils">
                    <h4>
                      {this.state.selectedFestival &&
                        this.state.selectedFestival.document.festivalName}
                    </h4>
                  </div>
                </div>
                <button
                  className="btn-submit"
                  onClick={() => this.handleDelete()}
                  type="submit"
                >
                  Delete
                </button>
                <button
                  className="btn-bordered"
                  onClick={() => this.handleDeleteClose()}
                  type="submit"
                >
                  Cancel
                </button>
                
              </div>
            </Modal.Body>
          </Modal>
        </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  profile: state.session.profile,
  session: state.session,
  isLoading: state.festivals.FestivalList.isLoading,
  hasErrored: state.festivals.FestivalList.hasErrored,
  success: state.festivals.FestivalList.success,
  successDelete: state.festivals.FestivalDelete.success,
  responseDelete: state.festivals.FestivalDelete.response,
  hasErroredDelete: state.festivals.FestivalDelete.hasErrored,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, Action>
): DispatchProps => ({
    getAllFestivals: (limit, page, search, sort,filter,type) =>
    dispatch(festivalListAll(limit, page, search, sort,filter,type)),
    deleteFestival: (festivalId: string) => dispatch(festivalDelete(festivalId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FestivalList);
