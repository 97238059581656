import * as ActionTypes from "./actionTypes";
import { FestivalOwnerState, FestivalOwnerCreateActionTypes } from "./types";

const initialState: FestivalOwnerState = {
  FestivalOwnerCreate: {
    isLoading: false,
    hasErrored: false,
    success: false,
    response: null,
  },
  FestivalOwnerList: {
    isLoading: false,
    hasErrored: false,
    success: [],
    response: null,
  },
  FestivalOwnerEdit: {
    isLoading: false,
    hasErrored: false,
    success: false,
    response: null,
  },
  FestivalOwnerDelete: {
    isLoading: false,
    hasErrored: false,
    success: false,
    response: null,
  },
  FestivalOwnerSendInvite: {
    isLoading: false,
    hasErrored: false,
    success: false,
    response: null,
  }
};

export default (
  state = initialState,
  action: FestivalOwnerCreateActionTypes
): FestivalOwnerState => {
  switch (action.type) {
    case ActionTypes.FESTIVAL_OWNER_CREATE:
      return {
        ...state,
        FestivalOwnerCreate: {
          ...state.FestivalOwnerCreate,
          isLoading: true, success: false, hasErrored: false, response:null
        }
      };

    case ActionTypes.FESTIVAL_OWNER_CREATE_SUCCESS:
      return {
        ...state,
        FestivalOwnerCreate: {
          ...state.FestivalOwnerCreate,
          isLoading: false, success: true, hasErrored: false, response:action.payload
        }
      };

    case ActionTypes.FESTIVAL_OWNER_CREATE_ERROR:
      return {
        ...state,
        FestivalOwnerCreate: {
          ...state.FestivalOwnerCreate,
          isLoading: false, success: false, hasErrored: true, response:action.payload
        }
      };

    case ActionTypes.FESTIVAL_OWNER_EDIT:
      return {
        ...state,
        FestivalOwnerEdit: {
          ...state.FestivalOwnerEdit,
          isLoading: true,
          success: false,
          hasErrored: false,
          response:null
        }
      };

    case ActionTypes.FESTIVAL_OWNER_EDIT_SUCCESS:
      return {
        ...state,
        FestivalOwnerEdit: {
          ...state.FestivalOwnerEdit,
          isLoading: false, success: true, hasErrored: false, response:action.payload
        }
      };

    case ActionTypes.FESTIVAL_OWNER_EDIT_ERROR:
      return {
        ...state,
        FestivalOwnerEdit: {
          ...state.FestivalOwnerEdit,
          isLoading: false, success: false, hasErrored: true, response:action.payload
        }
      };

    case ActionTypes.FESTIVAL_OWNER_DELETE:
      return {
        ...state,
        FestivalOwnerDelete: {
          ...state.FestivalOwnerDelete,
          isLoading: true,
          success: false,
          hasErrored: false
        }
      };

    case ActionTypes.FESTIVAL_OWNER_DELETE_SINGLE:
      const success = state.FestivalOwnerList.success.filter(
        item => item.documentID !== action.payload
      );
      return {
        ...state,
        FestivalOwnerList: {
          ...state.FestivalOwnerList,
          success: success
        }
      };

    case ActionTypes.FESTIVAL_OWNER_DELETE_SUCCESS:
      return {
        ...state,
        FestivalOwnerDelete: {
          ...state.FestivalOwnerDelete,
          isLoading: false, success: true, hasErrored: false, response:action.payload
        }
      };

    case ActionTypes.FESTIVAL_OWNER_DELETE_ERROR:
      return {
        ...state,
        FestivalOwnerDelete: {
          ...state.FestivalOwnerDelete,
          isLoading: false, success: false, hasErrored: true, response:action.payload
        }
      };

    case ActionTypes.FESTIVAL_OWNER_LIST:
      return {
        ...state,
        FestivalOwnerList: {
          ...state.FestivalOwnerList,
          isLoading: true,
          success: [],
          hasErrored: false
        }
      };

    case ActionTypes.FESTIVAL_OWNER_LIST_SUCCESS:
      return {
        ...state,
        FestivalOwnerList: {
          ...state.FestivalOwnerList,
          success: action.payload,
          isLoading: false
        }
      };

    case ActionTypes.FESTIVAL_OWNER_LIST:
      return {
        ...state,
        FestivalOwnerList: {
          ...state.FestivalOwnerList,
          isLoading: false,
          hasErrored: true
        }
      };

    case ActionTypes.FESTIVAL_OWNER_SEND_INVITE:
      return {
        ...state,
        FestivalOwnerSendInvite: {
          ...state.FestivalOwnerSendInvite,
          isLoading: true,
          success: false,
          hasErrored: false
        }
      };

    case ActionTypes.FESTIVAL_OWNER_SEND_INVITE_SUCCESS:
      return {
        ...state,
        FestivalOwnerSendInvite: {
          ...state.FestivalOwnerSendInvite,
          isLoading: false,
          success: true,
          hasErrored: false
        }
      };

    case ActionTypes.FESTIVAL_OWNER_SEND_INVITE_ERROR:
      return {
        ...state,
        FestivalOwnerSendInvite: {
          ...state.FestivalOwnerSendInvite,
          isLoading: false,
          success: false,
          hasErrored: true
        }
      };

    case ActionTypes.FESTIVAL_OWNER_EDIT_SINGLE:
      const update = state.FestivalOwnerList.success.map(item => {
        if (item.document.email !== action.payload.email) {
          return item;
        } else {
          return {
            ...item,
            document: {
              ...item.document,
              ...action.payload
            }
          };
        }
      });

      return {
        ...state,
        FestivalOwnerList: {
          ...state.FestivalOwnerList,
          success: update
        }
      };

    default:
      return state;
  }
};
