import { toast } from "react-toastify";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import * as ActionTypes from "./actionTypes";
import { Dispatch } from "redux";
import Api from "../../Api";

export const profileEditSingle = (payload: any) => ({
  type: ActionTypes.PROFILE_EDIT_SINGLE,
  payload
});

const profileEditSuccess = (payload: any) => ({
  type: ActionTypes.PROFILE_EDIT_SUCCESS,
  payload
});

const profileEditError = (payload: any) => ({
  type: ActionTypes.PROFILE_EDIT_ERROR,
  payload
});

export const profileEdit = ({
  token,
  businessName,
  category,
  email,
  contactEmail,
  phoneNumber,
  firstName,
  lastName,
  facebookURL,
  twitterURL,
  GroupNames,
  instagramURL,
  paymentType,
  Notifications,
  location,
  city,
  state,
  zipcode,
  aboutVendor,
  emailSystemEmbedCode,
  websiteLink,
  country,
  profileImageURL,
  bannerImageUrl
}: 

{
  token: string;
  businessName: string;
  category: string;
  email: string;
  contactEmail: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  facebookURL: string;
  twitterURL: string;
  GroupNames:string;
  instagramURL: string;
  paymentType: string[];
  Notifications: string[];
  location: string;
  city: string;
  state: string;
  zipcode: string;
  aboutVendor: string;
  emailSystemEmbedCode: string;
  websiteLink: string;
  country: string;
  profileImageURL: any;
  bannerImageUrl: any;
}) => async (dispatch: Dispatch) => {
  try {
    dispatch(showLoading());
    let profileEditResponse = await Api.editProfile({
      token,
      businessName,
      category,
      email,
      contactEmail,
      phoneNumber,
      firstName,
      lastName,
      facebookURL,
      twitterURL,
      GroupNames,
      instagramURL,
      paymentType,
      Notifications,
      location,
      city,
      state,
      zipcode,
      aboutVendor,
      emailSystemEmbedCode,
      websiteLink,
      country,
      profileImageURL,      
      bannerImageUrl
    });
    dispatch(profileEditSuccess(profileEditResponse));
    dispatch(hideLoading());
   // toast.dismiss(1);
    //toast.success("Profile edited successfully");
  } catch (error) {
    dispatch(hideLoading());
    dispatch(profileEditError(error));
    toast.dismiss(1);
    toast.error(error.error);
  }
};
