import * as ActionTypes from "./actionTypes"
import { UserFunctionEditState, UserFunctionEditActionTypes } from "./types"

const initialState: UserFunctionEditState = {
	isLoading: false,
	hasErrored: false,
	success: false,
	response: null,
}

export default (state = initialState, action: UserFunctionEditActionTypes): UserFunctionEditState => {
	switch (action.type) {

		case ActionTypes.USER_FUNCTION_EDIT:
			return { ...state,  isLoading: true, success: false, hasErrored: false, response:null }

		case ActionTypes.USER_FUNCTION_EDIT_SUCCESS:
			return { ...state, isLoading: false, success: true, hasErrored: false, response:action.payload }

		case ActionTypes.USER_FUNCTION_EDIT_ERROR:
			return { ...state, isLoading: false, success: false,  hasErrored: true, response:action.payload }

		default:
			return state
	}
}
