import React from 'react'
import { CheckBox } from '../../styled-components/checkboxes/index';
import { UserFunction } from '../../state/userFunctionList/types';


interface Props {
	isUpdate: boolean;
	handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
	groupName: string;
	groupDescription: string;
	setTextInput: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	checkedItems: Map<string, string>;
	userFunctions: Array<UserFunction>;
	validator: any
}

export default function UserGroupForm(props: Props): React.ReactElement {
	let functionKeys = [...props.checkedItems.keys()];
	return (
		<form action="" onSubmit={props.handleSubmit}>

			<div className="row">

				<div className="col-md-6">
					<div className="form-group">
						<label className="blue_label">GROUP NAME  <span className="required">*</span></label>
						<input  maxLength={45} name={"groupName"} type="text" className="form-control" placeholder="Enter Group Name" value={props.groupName} onChange={props.setTextInput} />
						{props.validator.message(
							"groupName",
							props.groupName,
							"required|min:3|max:45"
						)}
					</div>
					<div className="form-group">
						<label className="blue_label">GROUP DESCRIPTION <span className="required">*</span></label>
						<textarea  maxLength={550} name={"groupDescription"} className="form-control" placeholder="Enter Description" value={props.groupDescription} onChange={props.setTextInput}></textarea>
						{props.validator.message(
							"description",
							props.groupDescription,
							"required|min:1|max:550"
						)}
					</div>
				</div>

				<div className="col-md-6">
					<label className="blue_label">SELECT FUNCTIONS  <span className="required">*</span></label>

					<React.Fragment>
						{
							props.userFunctions.map(item => (
								<div className="checkBoxButton">
									<CheckBox
										required
										id={item.documentID}
										name={item.document.name}
										checked={props.checkedItems.get(item.documentID)}
										onChange={props.handleChange} />
									<label className="checkboxlabel" htmlFor={item.documentID} key={item.documentID}>
										{item.document.name}
									</label>
								</div>
							))
						}
					</React.Fragment>
 						{props.validator.message("Functions",functionKeys,"required")} 
				</div>
			</div>
			<div className="text-right">
			<button className="btn-submit" type="submit"> {props.isUpdate != true ? ("Save") : "Update"}  </button>
			</div>
		</form>
	)
}
