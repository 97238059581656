import { showLoading, hideLoading } from "react-redux-loading-bar";
import * as ActionTypes from "./actionTypes";
import { Dispatch } from "redux";
import Api from "../../Api";

const vendorListSuccess = (payload: any) => ({
  type: ActionTypes.VENDOR_LIST_SUCCESS,
  payload
});

const vendorListError = (payload: any) => ({
  type: ActionTypes.VENDOR_LIST_ERROR,
  payload
});

export const vendorListAll = (
  limit: number,
  page: number,
  search: string,
  sort: string
) => async (dispatch: Dispatch) => {
  try {
    dispatch(showLoading());
    let vendorListResponse = await Api.getAllVendorsByList(
      limit,
      page,
      search,
      sort
    );
    dispatch(vendorListSuccess(vendorListResponse));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch(vendorListError(error));
  }
};

export const vendorList = () => async (dispatch: Dispatch) => {
  try {
    dispatch(showLoading());
    let vendorListResponse = await Api.getAllVendors();
    dispatch(vendorListSuccess(vendorListResponse));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch(vendorListError(error));
  }
};

const vendorCreateSuccess = (payload: any) => ({
  type: ActionTypes.VENDOR_CREATE_SUCCESS,
  payload
});

const userGroupCreateError = (payload: any) => ({
  type: ActionTypes.VENDOR_CREATE_ERROR,
  payload
});

export const vendorCreate = ({
  businessName,
  category,
  email,
  contactEmail,
  phoneNumber,
  GroupNames,
  firstName,
  lastName,
  facebookURL,
  twitterURL,
  instagramURL,
  paymentType,
  Notifications,
  location,
  city,
  state,
  zipcode,
  aboutVendor,
  emailSystemEmbedCode,
  websiteLink,
  country,
  profileImageURL,
  bannerImageUrl,
  tags,
}: {
  businessName: string;
  category: string;
  email: string;
  contactEmail: string;
  phoneNumber: string;
  GroupNames: string[];
  firstName: string;
  lastName: string;
  facebookURL: string;
  twitterURL: string;
  instagramURL: string;
  paymentType: string[];
  Notifications: string[];
  location: string;
  city: string;
  state: string;
  zipcode: string;
  aboutVendor: string;
  emailSystemEmbedCode: string;
  websiteLink: string;
  country: string;
  profileImageURL: any;
  bannerImageUrl: any;
  tags: any;
}) => async (dispatch: Dispatch) => {
  try {
    dispatch(showLoading());
    let vendorCreateResponse = await Api.createVendor({
      businessName,
      category,
      email,
      contactEmail,
      phoneNumber,
      GroupNames,
      firstName,
      lastName,
      facebookURL,
      twitterURL,
      instagramURL,
      paymentType,
      Notifications,
      location,
      city,
      state,
      zipcode,
      aboutVendor,
      emailSystemEmbedCode,
      websiteLink,
      country,
      profileImageURL,
      bannerImageUrl,
      tags,
    });
    dispatch(vendorCreateSuccess(vendorCreateResponse));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(userGroupCreateError(error));
    dispatch(hideLoading());
  }
};

export const vendorEditSingle = (payload: any) => ({
  type: ActionTypes.VENDOR_EDIT_SINGLE,
  payload
});

const vendorEditSuccess = (payload: any) => ({
  type: ActionTypes.VENDOR_EDIT_SUCCESS,
  payload
});

const vendorEditError = (payload: any) => ({
  type: ActionTypes.VENDOR_EDIT_ERROR,
  payload
});

export const vendorEdit = ({
  vendorId,
  tags,
  businessName,
  category,
  email,
  contactEmail,
  phoneNumber,
  GroupNames,
  firstName,
  lastName,
  facebookURL,
  twitterURL,
  instagramURL,
  paymentType,
  Notifications,
  location,
  city,
  state,
  zipcode,
  aboutVendor,
  emailSystemEmbedCode,
  websiteLink,
  country,      
  profileImageURL,
  bannerImageUrl
}:
{
  vendorId: string;
  tags: any;
  businessName: string;
  category: string;
  email: string;
  contactEmail: string;
  phoneNumber: string;
  GroupNames: string[];
  firstName: string;
  lastName: string;
  facebookURL: string;
  twitterURL: string;
  instagramURL: string;
  paymentType: string[];
  Notifications: string[];
  location: string;
  city: string;
  state: string;
  zipcode: string;
  aboutVendor: string;
  emailSystemEmbedCode: string;
  websiteLink: string;
  country: string;
  profileImageURL: any;
  bannerImageUrl: any;
}) => async (dispatch: Dispatch) => {
  try {
    dispatch(showLoading());
    let vendorEditResponse = await Api.editVendor({
      vendorId,
      tags,
      businessName,
      category,
      email,
      contactEmail,
      phoneNumber,
      GroupNames,
      firstName,
      lastName,
      facebookURL,
      twitterURL,
      instagramURL,
      paymentType,
      Notifications,
      location,
      city,
      state,
      zipcode,
      aboutVendor,
      emailSystemEmbedCode,
      websiteLink,
      country,      
      profileImageURL,
      bannerImageUrl
    });
    dispatch(vendorEditSuccess(vendorEditResponse));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch(vendorEditError(error));
  }
};

const vendorDeleteSingle = (groupId: string) => ({
  type: ActionTypes.VENDOR_DELETE_SINGLE,
  payload: groupId
});

const vendorDeleteSuccess = (payload: any) => ({
  type: ActionTypes.VENDOR_DELETE_SUCCESS,
  payload
});

const vendorDeleteError = (payload: any) => ({
  type: ActionTypes.VENDOR_DELETE_ERROR,
  payload
});

export const vendorDelete = (vendorId: string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(showLoading());
    let vendorEditResponse = await Api.deleteVendor({
      vendorId: vendorId
    });
    dispatch(vendorDeleteSuccess(vendorEditResponse));
    dispatch(vendorDeleteSingle(vendorId));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(vendorDeleteError(error));
    dispatch(hideLoading());
  }
};

export const vendorSendInviteLoading = () => ({
  type: ActionTypes.VENDOR_SEND_INVITE
});

export const vendorSendInviteSuccess = (payload: any) => ({
  type: ActionTypes.VENDOR_SEND_INVITE_SUCCESS,
  payload
});

export const vendorSendInviteError = (payload: any) => ({
  type: ActionTypes.VENDOR_SEND_INVITE_ERROR,
  payload
});

export const vendorSendInvite = (email: string,contactEmail:string,name: string,groupscount:number) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(vendorSendInviteLoading());
    dispatch(showLoading());
    let sendInviteResp = await Api.vendorsSendInvite({ email: email,contactEmail:contactEmail,name: name,groupscount:groupscount });
    dispatch(hideLoading());
    dispatch(vendorSendInviteSuccess(sendInviteResp));
    dispatch(vendorEditSingle({ invitationStatus: "Pending", email }));
  } catch (error) {
    dispatch(vendorSendInviteError(error));
    dispatch(hideLoading());
  }
};
