import { SessionState, SessionActionTypes } from "./types";
import * as actionTypes from "./actionTypes";

const initialState: SessionState = {
  uid: undefined,
  profile: undefined,
  token: undefined
};

export function reducers(
  state = initialState,
  action: SessionActionTypes
): SessionState {
  switch (action.type) {
    case actionTypes.LOGOUT:
      return initialState;
    case actionTypes.LOGIN_SUCCESS:
      return {
        token: action.payload.token,
        uid: action.payload.uid,
        profile: action.payload.data
      };
    case actionTypes.LOGIN_ERROR:
      return { error: action.payload };
    default:
      return state;
  }
}
