import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, RouteComponentProps,Link } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { getVendorsAndOwners } from "../../state/userManagement/actions";
import * as _ from "lodash";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import FestivalView from "../../components/Festivals/festivalView";
import { ValueType } from "react-select";
import { AppState } from "../../state/types";
import { festivalEdit } from "../../state/festivals/actions";
import { amenitieOptions } from "../../constants/amenitieOptions";
import moment from "moment";



interface DispatchProps {
  getVendorsAndOwners: () => void;
  festivalEdit: (vendro: {
    festivalId: string;
    festivalName: string;
    category: string;
    address: string;
    city: string;
    state: string;
    country: string;
    zipcode: string;
    startDate: string;
    endDate: string;
    price: string;
    festivalDescription: string;
    festivalOwner: string;
    contactNumber: string;
    websiteURL: string;
    facebookURL: string;
    twitterURL: string;
    instagramURL: string;
    otherSocialLink: string;
    Amenities : any;
    Vendors: any;
    Schedule: any;
    timeandSchedule: any;
    _festivalImages: any;
    publishingStatus: any;
  }) => void;
}
interface StateAmenities {
  checkedAmenities: Map<string, string>;
}
interface StateVendors{
  checkedVendors: Map<string, string>;
}
interface StateProps {
  vendorOwnersList:  any;
  checkedAmenities: Map<string, string>;
  checkedVendors: Map<string, string>;
  isLoading: boolean;
  success: boolean;
  hasErrored: boolean;
}

// interface Props {}
interface State {
  festivalName: string;
  category?: ValueType<{ value: string; label: string }>;
  payment?: ValueType<{ value: string; label: string }>;
  country?: ValueType<{ value: string; label: string }>;
  state?: ValueType<{ value: string; label: string }>;
  city: string;
  address: string;
  zipcode: string;
  startDate: string;
  endDate: string;
  price: string;
  festivalDescription: string;
  festivalOwner: ValueType<{ value: string; label: string }>;
  contactNumber: string;
  websiteURL: string;
  facebookURL: string;
  twitterURL: string;
  instagramURL: string;
  otherSocialLink: string;
  Amenities : any[];
  Vendors: any;
  Schedule: any,
  timeandSchedule: any,
  scheduleModal: any,  
  scheduleModalPopUp: any,  
  ScheduleFormIndex: any,
  ScheduleFormTitle: string,
  ScheduleFormStartTime: string,
  ScheduleFormEndTime: string,
  ScheduleFormDescription:string,
  ScheduleFormVenue: string,
  checkedAmenities:  Map<string, string>;
  checkedVendors:  Map<string, string>;
  bannerImageUrl: any;
  _festivalImages: string[];  
  activeSchedule: any;
  commonStartTime: any;
  commonEndTime: any;
}

type Props = DispatchProps & StateProps & StateAmenities & StateVendors& RouteComponentProps;
export class FestivalViews extends Component<Props, State> {
  private validator: any;
  private validatorSchedule: any;
  private _ownersList: any;
  public _owners:any = [];
  public _vendors:any = [];
  state = {
    festivalId: "",
    festivalName: "",
    category: null as any,
    address: "",
    city: "",
    state: null as any,
    country: null as any,
    zipcode: "",
    startDate: "",
    endDate:  "",
    price: "",
    festivalDescription: "",
    festivalOwner:  null as any,
    contactNumber: "",
    websiteURL: "",
    facebookURL: "",
    twitterURL: "",
    instagramURL: "",
    otherSocialLink: "",
    Amenities : [],
    Vendors: [],
    Schedule: [],
    timeandSchedule: {
      isPerDay:false,
      _dates:[]
    },
    checkedAmenities: new Map(),
    checkedVendors: new Map(),
    ScheduleFormIndex: -1,
    ScheduleFormTitle: "",
    ScheduleFormStartTime: "",
    ScheduleFormEndTime: "",
    ScheduleFormDescription:"",
    ScheduleFormVenue: "",
    scheduleModal: false,
    scheduleModalPopUp: false,
    bannerImageUrl: [],
    _festivalImages: [],
    activeSchedule: '',
    commonStartTime: '',
    commonEndTime: ''
  };

  constructor(props: Props) {
    super(props);  
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.validatorSchedule = new SimpleReactValidator({ autoForceUpdate: this });
    var commonStartTime = '',commonEndTime='';
    if(this.props.location.state.item.timeandSchedule !== '' && this.props.location.state.item.timeandSchedule !== undefined && this.props.location.state.item.timeandSchedule._dates != undefined && this.props.location.state.item.timeandSchedule._dates[moment(this.props.location.state.item.document.startDate,'MM/DD/YYYY').format("MM-DD-YYYY")] !== undefined){
      commonStartTime = this.props.location.state.item.timeandSchedule._dates[moment(this.props.location.state.item.documentstartDate,'MM/DD/YYYY').format("MM-DD-YYYY")].startTime;
      commonEndTime = this.props.location.state.item.timeandSchedule._dates[moment(this.props.location.state.item.documentstartDate,'MM/DD/YYYY').format("MM-DD-YYYY")].endTime;
    }

    this.state = {
      festivalId: this.props.location.state.item.documentID,
      festivalName:  this.props.location.state.item.document.festivalName,
      category:  this.props.location.state.item.document.category,
      address:  this.props.location.state.item.document.address,
      city: this.props.location.state.item.document.city,
      state: this.props.location.state.item.document.state,
      country:  this.props.location.state.item.document.country,
      zipcode: this.props.location.state.item.document.zipcode,
      startDate:  this.props.location.state.item.document.startDate,
      endDate:   this.props.location.state.item.document.endDate,
      price:  this.props.location.state.item.document.price,
      festivalDescription:  this.props.location.state.item.document.festivalDescription,
      festivalOwner:   this.props.location.state.item.document.festivalOwner,
      contactNumber: this.props.location.state.item.document.contactNumber,
      websiteURL:  this.props.location.state.item.document.websiteURL,
      facebookURL: this.props.location.state.item.document.facebookURL,
      twitterURL:  this.props.location.state.item.document.twitterURL,
      instagramURL:  this.props.location.state.item.document.instagramURL,
      otherSocialLink:  this.props.location.state.item.document.otherSocialLink,
      Amenities :  this.props.location.state.item.document.Amenities,
      Vendors:  this.props.location.state.item.document.Vendors,
      Schedule:  this.props.location.state.item.document.Schedule,
      timeandSchedule:  (this.props.location.state.item.document.timeandSchedule == undefined || this.props.location.state.item.document.timeandSchedule == '') ? {
        isPerDay:false,
        _dates:[]
      } : this.props.location.state.item.document.timeandSchedule,
      checkedAmenities: new Map(),
      checkedVendors: new Map(),
      ScheduleFormIndex: -1,
      ScheduleFormTitle: "",
      ScheduleFormStartTime: "",
      ScheduleFormEndTime: "",
      ScheduleFormDescription:"",
      ScheduleFormVenue: "",
      scheduleModal: false,
      scheduleModalPopUp: false,
      bannerImageUrl: [],
      _festivalImages:  this.props.location.state.item.document._festivalImages,
      activeSchedule: moment(this.props.location.state.item.document.startDate,'MM/DD/YYYY').format("MM-DD-YYYY"),
        commonStartTime: commonStartTime,
        commonEndTime: commonEndTime
    };

 


    this.handleAmenitiesChange = this.handleAmenitiesChange.bind(this);
    this.handleAmenitiesDelete = this.handleAmenitiesDelete.bind(this);
    this.pushToAmenities = this.pushToAmenities.bind(this);
    this.handleVendorsChange = this.handleVendorsChange.bind(this);
    this.handleVendorsDelete = this.handleVendorsDelete.bind(this);
    this.pushToVendors = this.pushToVendors.bind(this);
    this.setBanerFileInput = this.setBanerFileInput.bind(this);
    this._handleBannerImageChange = this._handleBannerImageChange.bind(this);
    this.handleImageDelete = this.handleImageDelete.bind(this);
    this.setIsPerDay = this.setIsPerDay.bind(this);
    this.handleShStartDateTimeSdlChange = this.handleShStartDateTimeSdlChange.bind(this);
    this.handleShendDateTimeSdlChange = this.handleShendDateTimeSdlChange.bind(this);
    this.handleShCommonDateTimeSdlChange = this.handleShCommonDateTimeSdlChange.bind(this);
    this.setSchedulesActive = this.setSchedulesActive.bind(this);
    this.handleScheduleSubClose = this.handleScheduleSubClose.bind(this);
    this.handleScheduleSubShow = this.handleScheduleSubShow.bind(this);
  }
  componentWillMount() {
    this.props.getVendorsAndOwners();
  }
  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.vendorOwnersList.length !== this.props.vendorOwnersList.length &&
      this.props.vendorOwnersList.length > 0
    ) { 
    this.setState((state, props) => {
        this.props.location.state.item.document.Amenities.map(
          (item: { id: string; name: string }) => {
            state.checkedAmenities.set(item.id, item.name);
          }
        );
        return {
          checkedAmenities: state.checkedAmenities
        };
      });
      this.setState((state, props) => {
        this.props.location.state.item.document.Vendors.map(
          (item: any) => {
            state.checkedVendors.set(item.documentID, item.businessName);
            this.pushToVendors();
          }
        );
        return {
          checkedVendors: state.checkedVendors
        };
      });
    }
    if (prevProps.success !== this.props.success && this.props.success) {
      toast.dismiss(1);
      this.props.history.goBack();
    }
  }
  setTextInput = <T extends keyof State>(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const newState = {
      [event.currentTarget.name]: event.currentTarget.value
    };
    this.setState(newState as { [P in T]: State[P] });
  };
  setStateFromChild  = <T extends keyof State> (name:string,value:any) => {
    const newState = {
      [name]: value
    };
    this.setState(newState as { [P in T]: State[P] });
  }
  handleSelectChange = <T extends keyof State>(
    selectedOption: ValueType<{ value: string; label: string }>,
    name: string
  ) => {
    const newState = {
      [name]: selectedOption
    };
    this.setState(newState as { [P in T]: State[P] }, () =>
      console.log(this.state)
    );
  };
  pushToAmenities = <T extends keyof State>() => {
    let _amenities:any = [];
    let keys = [...this.state.checkedAmenities.keys()];
    keys.map((key, index) => {    
      var n = _.find(amenitieOptions, {id:key});
      _amenities.push(n);
    });
    const newState = {
      Amenities: _amenities
    };
    this.setState(newState as { [P in T]: State[P] }, () =>
      console.log(this.state)
    );
  }
  handleAmenitiesChange(e: React.ChangeEvent<HTMLInputElement>) { 
    const item = e.target.id;
    const name = e.target.name;
    const isChecked = e.target.checked;
    if (!isChecked) {
      this.setState((state, props) => {
        state.checkedAmenities.delete(item);
        this.pushToAmenities();
        return {
          checkedAmenities: state.checkedAmenities
        };
      });
    } else {
      this.setState((state, props) => {
        state.checkedAmenities.set(item, name);
        this.pushToAmenities();
        return {
          checkedAmenities: state.checkedAmenities
        };
      });
    }

  }
  handleAmenitiesDelete = (item: any) =>{    
    this.setState((state, props) => {
      state.checkedAmenities.delete(item);
      this.pushToAmenities();
      return {
        checkedAmenities: state.checkedAmenities
      };
    });
  } 
  pushToVendors = <T extends keyof State>() => {
    if(this.props.vendorOwnersList.length > 0){
      this.props.vendorOwnersList.map((item:any) => {
        if(item.Type == "Festival Owners"){
          let obj = { value: item.documentID, label: item.document.firstName+" "+item.document.lastName };
           this._owners.push(obj);  
        }else{
          let obj = { 
            documentID: item.documentID,
            businessName: item.document.businessName,
            firstName: item.document.firstName,
            lastName: item.document.lastName,
            category: item.document.category,
            email: item.document.email,
            paymentType: item.document.paymentType,
            profileImageURL: item.document.profileImageURL
            };
            if(obj !== undefined)
          this._vendors.push(obj);  
        } 
      });
    }
    console.log( this._vendors);
    let _vendors:any = [];
    let keys = [...this.state.checkedVendors.keys()];
    keys.map((key, index) => {    
      var n = _.find(this._vendors, {documentID:key});
      if(n !== undefined)
      _vendors.push(n);
    });
    const newState = {
      Vendors: _vendors
    };
    this.setState(newState as { [P in T]: State[P] }, () =>
      console.log(this.state)
    );
  }
    
  getSchedulesActive = () => {
    return this.state.activeSchedule;
  }
  setSchedulesActive = (date:any) => {
     this.setState({ activeSchedule: date });
  }
  isValidSchedules = () => {
    try {  
      var obj:any =  this.state.timeandSchedule;
      var dates = this.state.timeandSchedule._dates;
      for (var key in dates) { 
        if (obj._dates.hasOwnProperty(key)) {
            if(obj._dates[key].startTime == '' || obj._dates[key].endTime == ''){
              return false;
            }            
        }
      }
      return true;
    } catch (error) {
      return false;
    }

  }
  handleShStartDateTimeSdlChange = (date:any,key:any) => {
    if(date != null){    
      var value = date.value.toLocaleTimeString('en-US',{ hour: '2-digit', minute: '2-digit' });
      var obj:any =  this.state.timeandSchedule;
      obj._dates[key].startTime = value;
      this.setState((state, props) => {
        return {
          timeandSchedule: obj
        };
      });
    }
  }
  handleShendDateTimeSdlChange = (date:any,key:any) => { 
    if(date != null){    
      var value = date.value.toLocaleTimeString('en-US',{ hour: '2-digit', minute: '2-digit' });
      var obj:any =  this.state.timeandSchedule;
      obj._dates[key].endTime = value;
      this.setState((state, props) => {
        return {
          timeandSchedule: obj
        };
      });
    }
  }
  handleShCommonDateTimeSdlChange = (date:any,type:any) => {
   
    if(type == "start"){
      if(date != null){    
        var value = date.value.toLocaleTimeString('en-US',{ hour: '2-digit', minute: '2-digit' });
        var obj:any =  this.state.timeandSchedule;
        var dates = Object.keys(this.state.timeandSchedule._dates).sort();
        for(let _d of dates){
          obj._dates[_d].startTime = value;
        }
        this.setState((state, props) => {          
          return {
            commonStartTime: value
          };
        });
        this.setState((state, props) => {
          
          return {
            timeandSchedule: obj
          };
        });
      }
    } else{
      var value = date.value.toLocaleTimeString('en-US',{ hour: '2-digit', minute: '2-digit' });
      var obj:any =  this.state.timeandSchedule;
      var dates = Object.keys(this.state.timeandSchedule._dates).sort();
      for(let _d of dates){
        obj._dates[_d].endTime = value;
      }
      this.setState((state, props) => {          
        return {
          commonEndTime: value
        };
      });
      this.setState((state, props) => {
        return {
          timeandSchedule: obj
        };
      });
    }
    
  }
  objectWithKeySorted(object:any) {
    var result:any = {};
    _.forEach(Object.keys(object).sort(), function(key) {
      result[key] = object[key];
    });
    return result;
  }
  setDatesArray = () => {
    if(this.state.startDate == '' || this.state.endDate == ''){
      return [];
    }
    var current = moment(this.state.startDate,'MM/DD/YYYY');
    var _current = moment(this.state.startDate,'MM/DD/YYYY');
    var dates = [];
    
    var obj:any = this.state.timeandSchedule;
    while (current.isBefore(this.state.endDate)) {
      if(!obj._dates.hasOwnProperty(current.format('MM-DD-YYYY'))){
        obj._dates[current.format('MM-DD-YYYY')] = {
          startTime:'',
          endTime:'',
          schedules:[]
        }
      }      
      current.add(1, 'days');
    }
    if(!obj._dates.hasOwnProperty(moment(this.state.endDate,'MM/DD/YYYY').format('MM-DD-YYYY'))){
      obj._dates[moment(this.state.endDate,'MM/DD/YYYY').format('MM-DD-YYYY')] = {
        startTime:'',
        endTime:'',
        schedules:[]
      }
    }


   
  this.setState((state, props) => {
    this.setSchedulesActive(_current.format('MM-DD-YYYY'));
    return {
      timeandSchedule: obj
    };
  });
  }
  isValidToChangePerDaya = () =>{
    var dates:any = Object.keys(this.state.timeandSchedule._dates).sort();
    var isValid = true;
    for(let _d of dates){
      if(_d.schedule !== undefined && _d.schedule.length > 0){
        isValid = false;
      }
    }
    return isValid;
  }
  setIsPerDay(e: React.ChangeEvent<HTMLInputElement>) {
    if(this.isValidToChangePerDaya() == false){
      toast.error("Please delete existing schedules before change"); 
      return false;
    }
    this.setDatesArray();
    const isChecked = e.target.checked;
    let obj:any = this.state.timeandSchedule;
    obj.isPerDay = isChecked;
    this.setState((state, props) => {
      return {
        timeandSchedule: obj
      };
    },()=>{
      if(isChecked === true){
            var obj:any =  this.state.timeandSchedule;
            var dates:any = Object.keys(this.state.timeandSchedule._dates).sort();
            var _startTime:any = this.state.timeandSchedule._dates[dates[0]];
            var startTime = _startTime.startTime;
            var _endTime:any = this.state.timeandSchedule._dates[dates[0]];
            var endTime = _endTime.endTime;
          
            for(let _d of dates){

              obj._dates[_d].startTime = startTime;
              obj._dates[_d].endTime = endTime;
            }
            this.setState((state, props) => {          
              return {
                commonStartTime: startTime
              };
            });
            this.setState((state, props) => {          
              return {
                commonStartTime: endTime
              };
            });
            this.setState((state, props) => {
              
              return {
                timeandSchedule: obj
              };
            });
        
      } 
      
      
    });
  }
  handleScheduleSubClose = () => {
    this.setState({ scheduleModalPopUp: false });
  };
  handleScheduleSubShow = () => {
    this.setState({ scheduleModal: false });
    this.setState({ scheduleModalPopUp: true });
  };
  handleVendorsChange(e: React.ChangeEvent<HTMLInputElement>) { 
    const item = e.target.id;
    const name = e.target.name;
    const isChecked = e.target.checked;
    if (!isChecked) {
      this.setState((state, props) => {
        state.checkedVendors.delete(item);
        this.pushToVendors();
        return {
          checkedVendors: state.checkedVendors
        };
      });
    } else {
      this.setState((state, props) => {
        state.checkedVendors.set(item, name);
        this.pushToVendors();
        return {
          checkedVendors: state.checkedVendors
        };
      });
    }

  }
  handleVendorsDelete = (item: any) =>{    
    this.setState((state, props) => {
      state.checkedVendors.delete(item);
      this.pushToVendors();
      return {
        checkedVendors: state.checkedVendors
      };
    });
  }
  setBanerFileInput = (item: any) =>{    
    // this.setState((state, props) => {
    //   state.checkedVendors.delete(item);
    //   this.pushToVendors();
    //   return {
    //     checkedVendors: state.checkedVendors
    //   };
    // });
  }
  handleSubmit = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (this.props.isLoading) {
      return;
    }
    if (this.validator.allValid()) {
      // const paymentType = this.state.payment.map((item: any) => item["value"]);
      // const category = this.state.category.map((item: any) => item["value"]);
      this.props.festivalEdit({
        festivalId: this.state.festivalId,
        festivalName: this.state.festivalName,
        category: this.state.category,
        address: this.state.address,
        city: this.state.city,
        state: this.state.state,
        country: this.state.country,
        zipcode: this.state.zipcode,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        price: this.state.price,
        festivalDescription: this.state.festivalDescription,
        festivalOwner: this.state.festivalOwner,
        contactNumber: this.state.contactNumber,
        websiteURL: this.state.websiteURL,
        facebookURL: this.state.facebookURL,
        twitterURL: this.state.twitterURL,
        instagramURL: this.state.instagramURL,
        otherSocialLink: this.state.otherSocialLink,
        Amenities : this.state.Amenities,
        Vendors: this.state.Vendors,
        Schedule: this.state.Schedule,
        timeandSchedule: this.state.timeandSchedule,
        _festivalImages: this.state._festivalImages,
        publishingStatus: 'published',
      });
    } else {
      toast.dismiss(2);
      toast.warn("Please fill in all required fields!",{toastId:2});
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  handleSave = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (this.props.isLoading) {
      return;
    }
    this.validator.hideMessages();
 
      this.props.festivalEdit({
        festivalId: this.state.festivalId,
        festivalName: this.state.festivalName,
        category: this.state.category,
        address: this.state.address,
        city: this.state.city,
        state: this.state.state,
        country: this.state.country,
        zipcode: this.state.zipcode,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        price: this.state.price,
        festivalDescription: this.state.festivalDescription,
        festivalOwner: this.state.festivalOwner,
        contactNumber: this.state.contactNumber,
        websiteURL: this.state.websiteURL,
        facebookURL: this.state.facebookURL,
        twitterURL: this.state.twitterURL,
        instagramURL: this.state.instagramURL,
        otherSocialLink: this.state.otherSocialLink,
        Amenities : this.state.Amenities,
        Vendors: this.state.Vendors,
        Schedule: this.state.Schedule,
        timeandSchedule: this.state.timeandSchedule,
        _festivalImages: this.state._festivalImages,
        publishingStatus: 'unpublished',
      });
  
  };
  handleScheduleSubmit = (e: React.FormEvent<HTMLFormElement>) => { 
    e.preventDefault();
    if (this.validatorSchedule.allValid()) { 
      let _list:any = this.state.Schedule;
      let index = this.state.Schedule.length;
      if(this.state.ScheduleFormIndex >= 0){
        index = this.state.ScheduleFormIndex;
      }
      let obj = { 
        index: index,
        title: this.state.ScheduleFormTitle,
        StartTime:this.state.ScheduleFormStartTime,
        EndTime: this.state.ScheduleFormEndTime,
        Description: this.state.ScheduleFormDescription,
        Venue: this.state.ScheduleFormVenue
        };
        if(this.state.ScheduleFormIndex >= 0){
          _list[this.state.ScheduleFormIndex] = obj;
        }else{
          _list.push(obj);
        }
      
      this.setState({ Schedule: _list},()=>{
        console.log(this.state)
        this.handleScheduleClose();
        this.handleScheduleReset();
      });
     }
    else {
      this.validatorSchedule.showMessages();
      this.forceUpdate();
    }
  }
  handleScheduleReset = () => {
    this.setState({ ScheduleFormTitle: "" },()=>{
      this.setState({ ScheduleFormIndex: -1});
      this.setState({ ScheduleFormStartTime: "" });
      this.setState({ ScheduleFormEndTime: "" });
      this.setState({ ScheduleFormDescription: "" });
      this.setState({ ScheduleFormVenue: "" });
      this.validatorSchedule.hideMessages();
    });
  }
  handleScheduleEdit = (item:any,index:any) => { 
    this.setState({ ScheduleFormTitle: item.title },()=>{
     
      this.setState({ ScheduleFormStartTime: item.StartTime });
      this.setState({ ScheduleFormEndTime: item.EndTime });
      this.setState({ ScheduleFormDescription: item.Description });
      this.setState({ ScheduleFormVenue: item.Venue });
      this.setState({ ScheduleFormIndex: item.index },()=>{
        this.setState({ scheduleModal: true });
      });
     
    });
    this.validatorSchedule.hideMessages();
  };
  handleScheduleDelete = (item:any,index:any) => { 
    let obj = this.state.Schedule;
    obj.splice(index, 1);
    this.setState({ Schedule: obj },()=>{
      console.log(this.state);
    });
  }

  handleScheduleClose = () => {
    this.setState({ scheduleModal: false });
    this.validatorSchedule.hideMessages();
  };
  handleScheduleShow = () => {
    this.handleScheduleReset();
    this.setState({ scheduleModal: true });
  };
  _handleBannerImageChange(e:any) {
    e.preventDefault();
    var files = e.target.files;  
    for (var i = 0; i < files.length; i++) {
        var file = files[i];
        if (!file.type.match('image')) continue;
        var picReader = new FileReader();
        picReader.addEventListener("load",  (event:any) => {
            var picFile = event.target;
            var _iObj:any = this.state._festivalImages;
            var obj = {
              thmb:picFile.result,
              main:'',
              isnew:true
            }
            _iObj.push(obj);
            this.setState({_festivalImages:_iObj});
        });
        picReader.readAsDataURL(file);
    }
    
  }
 
  handleImageDelete = (index:any) => { 
    let obj = this.state._festivalImages;
    obj.splice(index, 1);
    this.setState({ _festivalImages: obj },()=>{
      console.log(this.state);
    });
  }
  render() {
    if (this.props.vendorOwnersList.length === 0) {
      
      return <p>Loading......
      </p>;
    }
    return ( 
        <div> 
          <div className="white_panel form_pading festival_module">
            <div className="festival_header">
                  <div className="row">
                      <div className="col-md-4">
                        <Link onClick={()=> window.history.go(-1)} to={""} className="btn-back">Back </Link>
                      </div>
                      <div className="col-md-4">
                        <h3>FESTIVAL DETAILS</h3>
                      </div>
                      
                  </div>
            </div>

            <FestivalView
              festivalName= {this.state.festivalName}
              category={this.state.category}
              address={this.state.address}
              city={this.state.city}
              state={this.state.state}
              country={this.state.country}
              zipcode={this.state.zipcode}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              price={this.state.price}
              festivalDescription={this.state.festivalDescription}
              festivalOwner={this.state.festivalOwner}
              vendorOwnersList={this.props.vendorOwnersList}
              contactNumber={this.state.contactNumber}
              websiteURL={this.state.websiteURL}
              facebookURL={this.state.facebookURL}
              twitterURL={this.state.twitterURL}
              instagramURL={this.state.instagramURL}
              otherSocialLink={this.state.otherSocialLink}
              setTextInput={this.setTextInput}
              handleSelectChange={this.handleSelectChange}        
              handleSubmit={this.handleSubmit}
              handleSave={this.handleSave}
              amenitieOptions={amenitieOptions}
              checkedAmenities={this.state.checkedAmenities}
              handleAmenitiesChange={this.handleAmenitiesChange}
              Amenities={this.state.Amenities}
              handleAmenitiesDelete={this.handleAmenitiesDelete}
              checkedVendors={this.state.checkedVendors}
              handleVendorsChange={this.handleVendorsChange}
              Vendors={this.state.Vendors}
              handleVendorsDelete={this.handleVendorsDelete}
              validator={this.validator}
              ScheduleFormIndex={this.state.ScheduleFormIndex}
              ScheduleFormTitle={this.state.ScheduleFormTitle}
              ScheduleFormStartTime={this.state.ScheduleFormStartTime}
              ScheduleFormEndTime={this.state.ScheduleFormEndTime}
              ScheduleFormDescription={this.state.ScheduleFormDescription}
              ScheduleFormVenue={this.state.ScheduleFormVenue}
              Schedule={this.state.Schedule}
              timeandSchedule={this.state.timeandSchedule}
              scheduleModal={this.state.scheduleModal}
              handleScheduleShow={this.handleScheduleShow}
              handleScheduleClose={this.handleScheduleClose}
              handleScheduleEdit={this.handleScheduleEdit}
              handleScheduleDelete={this.handleScheduleDelete}
              validatorSchedule={this.validatorSchedule}
              handleScheduleSubmit={this.handleScheduleSubmit}
              bannerImageUrl = {this.state.bannerImageUrl}
              setBanerFileInput = {this.setBanerFileInput}
              setStateFromChild={this.setStateFromChild}
              _festivalImages={this.state._festivalImages}
              _handleBannerImageChange={this._handleBannerImageChange}
              handleImageDelete={this.handleImageDelete}
            />
        </div>
        </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  vendorOwnersList: state.userManagement.GetVendorsAndOwners.success,
  isLoading: state.festivals.FestivalEdit.isLoading,
  success: state.festivals.FestivalEdit.success,
  hasErrored: state.festivals.FestivalEdit.hasErrored
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, Action>
): DispatchProps => ({
  getVendorsAndOwners: () => dispatch(getVendorsAndOwners()),
  festivalEdit: ({
    festivalId,
    festivalName,
    category,
    address,
    city,
    state,
    country,
    zipcode,
    startDate,
    endDate,
    price,
    festivalDescription,
    festivalOwner,
    contactNumber,
    websiteURL,
    facebookURL,
    twitterURL,
    instagramURL,
    otherSocialLink,
    Amenities,
    Vendors,
    Schedule,
    timeandSchedule,
    _festivalImages,
    publishingStatus,
  }) =>
    dispatch(
      festivalEdit({
        festivalId,
        festivalName,
        category,
        address,
        city,
        state,
        country,
        zipcode,
        startDate,
        endDate,
        price,
        festivalDescription,
        festivalOwner,
        contactNumber,
        websiteURL,
        facebookURL,
        twitterURL,
        instagramURL,
        otherSocialLink,
        Amenities,
        Vendors,
        Schedule,
        timeandSchedule,
        _festivalImages,
        publishingStatus,
      })
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(FestivalViews);
