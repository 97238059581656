import React, { Component } from "react";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { ValueType } from "react-select";
import OwnerForm from "../../components/owner/ownerForm";
import { AppState } from "../../state/types";
import { festivalOwnerEdit } from "../../state/festivalOwners/actions";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { onUpdateTokenNone } from '../../state/session/actions';

import { stateOptions } from "./../../constants/stateOptions";
import { countryOptions } from "./../../constants/countryOptions";
import moment from 'moment-timezone';

interface DispatchProps {
  festivalOwnerEdit: (festivalOwner: {
    ownerId: string;
    tags: any;
    firstName: string;
    lastName: string;
    email: string;
    contactEmail: string;
    phoneNumber: string;
    GroupNames: string[];
    Notifications: string[];
    address: string;
    city: string;
    country: string;
    state: string;
    zipcode: string;    
    profileImageURL: string
  }) => void
  ,  
  updateToken: () => void,
}

interface StateProps {
  isLoading: boolean;
  success: boolean;
  hasErrored: boolean;
  response: any;
  profile: any;
}

interface ComponentProps {}
interface State {
  firstName: string;
  lastName: string;
  email: string;
  contactEmail: string;
  phoneNumber: string;
  address: string;
  city: string;
  selectedState?: ValueType<{ value: string; label: string }>;
  zipcode: string;
  selectedCountry?: ValueType<{ value: string; label: string }>;
  notifications: Map<string, string>;
  profilePicbase: any;
}



type Props = DispatchProps &
  ComponentProps &
  StateProps &
  State &
  RouteComponentProps;

export class OwnerEdit extends Component<Props, State> {
  private validator: any;
  private countrydata:any = countryOptions.filter((option:any) => option.value === this.props.location.state.item.document.country);
  private statedata:any = stateOptions.filter((option:any) => option.value === this.props.location.state.item.document.state);
  state = {
    isUpdate:true,
    firstName: this.props.location.state.item.document.firstName,
    lastName: this.props.location.state.item.document.lastName,
    email: this.props.location.state.item.document.email,
    contactEmail: this.props.location.state.item.document.contactEmail,
    phoneNumber: this.props.location.state.item.document.phoneNumber,
    location: this.props.location.state.item.document.location == '' ? this.props.location.state.item.document.address : this.props.location.state.item.document.location ,
    address: this.props.location.state.item.document.address == '' ? this.props.location.state.item.document.location : this.props.location.state.item.document.address ,
    city: this.props.location.state.item.document.city,
    selectedState: {
      value: this.props.location.state.item.document.state,
      label: (this.statedata.length > 0) ? this.statedata[0].label :this.props.location.state.item.document.state
    },
    selectedCountry:{
      value: this.props.location.state.item.document.country,
      label: (this.countrydata.length > 0) ? this.countrydata[0].label :this.props.location.state.item.document.country
    },
    zipcode: this.props.location.state.item.document.zipcode,
   
    notifications: new Map(
      this.props.location.state.item.document.Notifications.map((i: string) => [
        i,
        i
      ]) as Map<string, string>
    ),
    profileImageURL: this.props.location.state.item.document.profileImageURL,
    profilePic: this.props.location.state.item.document.profileImageURL,
    profilePicbase: this.props.location.state.item.document.profileImageURL,
    opeationalStatus: this.props.location.state.item.document.opeationalStatus,
    activeSinceOwner: (this.props.location.state.item.document.activeSinceOwner !== undefined) ? this.props.location.state.item.document.activeSinceOwner : '',
    opeationalStatusOn: (this.props.location.state.item.document.opeationalStatusOn !== undefined) ? this.props.location.state.item.document.opeationalStatusOn : '',
  };

  constructor(props: Props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this,  validators: {
      phoneNumber: {
        message: 'The :attribute must be a valid phone Number',
        rule: (val:any, params:any, validator:any) => {
          return validator.helpers.testRegex(val,/^((\([0-9]{3}\))|[0-9]{3})[\s\-][\0-9]{3}[-][0-9]{4}$/i) && params.indexOf(val) === -1
        },
        required: true
      }
    } });
  }
  componentDidMount() {
    document.title = "Edit Owner | FestFrwrd - The Futr of FSTIVLS";

  }
  componentDidUpdate(prevProps: Props) {
    if (prevProps.response !== this.props.response && this.props.success) { 
      if(this.props.profile.profile.email == this.state.email){
        this.props.updateToken();
      }else{
        this.props.history.goBack();
      }
      // toast.update(1, {
      //   render: this.props.response.data.message,
      //   type: toast.TYPE.SUCCESS,
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   onClose:() =>{
      //     this.props.history.goBack();
      //   }
      // });    
    }
    if (prevProps.profile.token !== this.props.profile.token) {
      this.props.history.goBack();
    }
    if (
      prevProps.response !== this.props.response &&
      this.props.hasErrored
    ) { 
      toast.error((this.props.response.error == null) ? this.props.response.message : this.props.response.error);      
      // toast.update(1, {
      //   render: (this.props.response.error == null) ? this.props.response.message : this.props.response.error,
      //   type: toast.TYPE.ERROR,
      //   autoClose: 2000,
      //   hideProgressBar: false
      // });
    }
  }
  getCountryLabel = () => {    
      let cot =  countryOptions.filter((option:any) => option.value === this.props.location.state.item.document.country);
      if(cot.length > 0){
        return cot[0].label;
      }
      return this.props.location.state.item.document.country;    
  }
  private deleteFileInput = (event: any) => {
    this.setState({ profilePicbase: "" });
  };
  getBase64(file:any, cb:any) {
    let reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}
  private setFileInput = (event: React.FormEvent<HTMLInputElement>) => {
    console.log("ggg",event.currentTarget.files);
    let files = event.currentTarget.files;
    this.getBase64(files, (result:any) => {
        this.setState({ profilePicbase: result });
    });
  };
  setTextInput = <T extends keyof State>(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const newState = {
      [event.currentTarget.name]: event.currentTarget.value
    };
    this.setState(newState as { [P in T]: State[P] });
  };

  handleSelectChange = <T extends keyof State>(
    selectedOption: ValueType<{ value: string; label: string }>,
    name: string
  ) => {
    const newState = {
      [name]: selectedOption
    };
    this.setState(newState as { [P in T]: State[P] }, () =>
      console.log(this.state)
    );
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const item = e.target.id;
    const name = e.target.name;
    const isChecked = e.target.checked;
    if (!isChecked) {
      this.setState((state, props) => {
        state.notifications.delete(item);
        return {
          notifications: state.notifications
        };
      });
    } else {
      this.setState((state, props) => {
        state.notifications.set(item, name);
        return {
          notifications: state.notifications
        };
      });
    }
  };
 
  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let profileImageURL = undefined;
      if(this.state.profileImageURL != this.props.location.state.item.document.profileImageURL){
        profileImageURL = this.state.profileImageURL;
      }
      this.props.festivalOwnerEdit({
        Notifications: [...this.state.notifications.values()],
        address: this.state.address,
        city: this.state.city,
        country: this.state.selectedCountry.value,
        email: this.state.email,
        contactEmail: this.state.contactEmail,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        ownerId: this.props.location.state.item.documentID,
        phoneNumber: this.state.phoneNumber,
        GroupNames: ["Festival Owners"], 
        state: this.state.selectedState.value,
        zipcode: this.state.zipcode,
        profileImageURL: this.state.profilePicbase,
        tags: {
          opeationalStatus: this.props.location.state.item.opeationalStatus,
          invitationStatus: this.props.location.state.item.invitationStatus,
          businessName: this.props.location.state.item.businessName,
          category: this.props.location.state.item.category,
          activeSince: this.props.location.state.item.activeSince,
        },
      });
    } else {
      toast.dismiss(2);
      toast.warn("Please fill in all required fields!",{toastId:2});
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
        <div>
          <div className="inner-title">
            <h3>EDIT FESTIVAL OWNER</h3>
            <div className="path_navigation">
              <ul>
                <li>
                  <NavLink to="/FestivalOwnerManagement">FESTIVAL OWNER Management</NavLink>
                </li>
                <li>
                  <NavLink to="/FestivalOwnerManagement/list">VIEW FESTIVAL OWNER</NavLink>
                </li>
                <li>
                  <span>Edit FESTIVAL OWNER</span>
                </li>
              </ul>
            </div>
          </div>
          <OwnerForm
           isUpdate={this.state.isUpdate}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            email={this.state.email}
            contactEmail={this.state.contactEmail}
            phoneNumber={this.state.phoneNumber}
            address={this.state.address}
            city={this.state.city}
            states={stateOptions}
            selectedState={this.state.selectedState}
            zipcode={this.state.zipcode}
            countries={countryOptions}
            selectedCountry={this.state.selectedCountry}
            notifications={this.state.notifications}
            setTextInput={this.setTextInput}
            handleSelectChange={this.handleSelectChange}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            setFileInput={this.setFileInput}
            profilePic={this.state.profilePic}
            deleteFileInput={this.deleteFileInput}
            profilePicbase={this.state.profilePicbase}
            validator={this.validator}
          />
            <div>
            <div className="invitation_history white_panel form_pading">
                <h3>INVITATION HISTORY</h3>
                <div className="invitation_history_inner">
                   <div className="history_item">
                      <label>Last Invitation Sent</label>
                      <p>{
                      (this.state.opeationalStatusOn !== undefined && this.state.opeationalStatusOn._seconds != undefined ) ?
                      moment.tz( moment.unix(this.state.opeationalStatusOn._seconds), "America/New_York").format("MM/DD/YY, hh:mm A")+" EDT"
                      : "-"}</p>
                   </div>
                   <div className="history_item">
                      <label>Current Status</label>
                      <p className="history_status">
                      {this.state.activeSinceOwner != '' && this.state.activeSinceOwner != undefined && this.state.activeSinceOwner._seconds != undefined ? (
                        <span className="capitalizeNew">
                          <span className={this.state.opeationalStatus}>{this.state.opeationalStatus}</span> - {" "} 
                          {moment.tz( moment.unix(this.state.activeSinceOwner._seconds), "America/New_York").format("MM/DD/YY, hh:mm A")+" EDT" }
                          
                          
                       </span>
                      ): (
                      <span className="capitalizeNew">
                         <span className={this.state.opeationalStatus}>{this.state.opeationalStatus != '' ? this.state.opeationalStatus : '-'}</span>
                       </span>

                      )
                      }
                      </p>
                   </div>
                </div>
            </div> 
            <div className="emailSubscribe d-none">
               <p>EMAIL SUBSCRIBERS</p>
               <div className="count">
                 27
               </div>
               <div className="arrow">
               </div>
             </div>             <br/>
             <br/>

             </div>
        </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  profile: state.session,
  response: state.festivalOwner.FestivalOwnerEdit.response,
  isLoading: state.festivalOwner.FestivalOwnerEdit.isLoading,
  success: state.festivalOwner.FestivalOwnerEdit.success,
  hasErrored: state.festivalOwner.FestivalOwnerEdit.hasErrored
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, Action>
): DispatchProps => ({
  festivalOwnerEdit: ({
    ownerId,
    firstName,
    lastName,
    email,
    contactEmail,
    phoneNumber,
    GroupNames,
    Notifications,
    address,
    city,
    country,
    state,
    zipcode,
    profileImageURL,
    tags
  }) =>
    dispatch(
      festivalOwnerEdit({
        ownerId,
        firstName,
        lastName,
        email,
        contactEmail,
        phoneNumber,
        GroupNames,
        Notifications,
        address,
        city,
        country,
        state,
        zipcode,
        profileImageURL,
        tags
      })
    ),
    updateToken: () => dispatch(onUpdateTokenNone())
});

export default connect(mapStateToProps, mapDispatchToProps)(OwnerEdit);
