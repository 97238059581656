export const festivalsCategory:any = [
    { value: "Animals", label: "Animals" },
    { value: "Arts", label: "Arts" },
    { value: "Business", label: "Business" },
    { value: "Music", label: "Music" },
    { value: "FoodBeverages", label: "Food & Beverages" },
    { value: "FlowersGardens", label: "Flowers & Gardens" },
    { value: "LGBTQ", label: "LGBTQ" },
    { value: "LiteraryBooks", label: "Literary & Books" },
    { value: "Dance", label: "Dance" },
    { value: "Sports", label: "Sports" },
    { value: "Religious", label: "Religious" },
    { value: "Film", label: "Film" },
    { value: "Theatre", label: "Theatre" },
    { value: "FolkCultural", label: "Folk & Cultural" },
    { value: "TrainsPlanesAutomobiles", label: "Trains, Planes, and Automobiles" },
    { value: "BalloonsLanterns", label: "Balloons & Lanterns" },
    { value: "Historical", label: "Historical" },
    { value: "Comedy", label: "Comedy" },
    { value: "FairsCarnivals", label: "Fairs & Carnivals" },
    { value: "HealthWellness", label: "Health & Wellness" },
    { value: "Science", label: "Science" },
    { value: "SeasonalHoliday", label: "Seasonal & Holiday" },
    { value: "Fashion", label: "Fashion" },    
    { value: "Unknown Category", label: "Unknown Category" }   
]