import * as React from "react";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { showLoading, hideLoading  } from "react-redux-loading-bar";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt,faRedo } from "@fortawesome/free-solid-svg-icons";
import { AppState } from "../../state/types";
import { Dropdown, Modal } from 'react-bootstrap';
import closeBtn from "../../assets/images/close-button.svg";
import resetBtn from "../../assets/images/reset.png";

import sortUp from "../../assets/images/sort_up.svg";
import sortDown from "../../assets/images/sort_down.svg";

import sortUpActive from "../../assets/images/sort_up_active.svg";

import arrowRight from "../../assets/images/right-arrow.svg";
import arrowRightDis from "../../assets/images/right-arrow-dis.svg";
import arrowLeft from "../../assets/images/left-arrow.svg";
import arrowLeftDis from "../../assets/images/left-arrow-dis.svg";

import {
  userManagementListAll,
  userManagementDelete,
  userresetPinCreate,
} from "../../state/userManagement/actions";

export interface IUserManagementProps {}
interface StateProps {
  profile: any;
  isLoading: boolean;
  hasErrored: boolean;
  success: Array<any>;  
  isDeleteLoading: boolean;
  hasDeleteErrored: boolean;
  deleteSuccess: any;
  isRestLoading: boolean;
  hasResetErrored: boolean;
  resetSuccess: any;
  resetPinModal: any;
}
interface DispatchProps {
  getAllUsers: (limit:number,page:number,search:string,sort:string) => void;
  deleteUser: (userId: string) => void;
  userresetPinCreate: (newPin: any, email:string) => void;
}

type Props = IUserManagementProps & DispatchProps & StateProps;

class UserManagement extends React.Component<Props> {
  private prevSort = "firstNameSort";
  private validator: any;
  public totalCount = 0;
	private prevSortInit = "asc";
	public state = {
      limit: 10,
      page: 0,
		  search: "",
		  sort: "asc",
      sort_type: "firstNameSort",      
      deleteModalShow: false,      
      selecteduserId: null as any,
      resetPinModal: false,
      newPin: "",
      confirmPin: "",
      email: "",
    }
    constructor(props: Props) {
      super(props);  
      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }
    componentDidUpdate(prevProps: Props) {
      if (this.props.resetSuccess !== prevProps.resetSuccess && this.props.resetSuccess) {
        this.handleResetPinClose();    
        //toast.success("PIN updated successfully");
      }
      
      if (this.props.resetSuccess !== prevProps.resetSuccess && this.props.hasResetErrored) {
        this.handleResetPinClose();    
        toast.error("PIN update was unsuccessful");
      }

      if (this.props.deleteSuccess !== prevProps.deleteSuccess &&this.props.deleteSuccess) {
        this.handleDeleteClose();  
        this.getData();  
        // toast.update(1, {
        //   render: this.props.deleteSuccess.data.message,
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   onClose:() =>{
        //     this.getData();
        //   }
        // });
      }
      
      if (this.props.deleteSuccess !== prevProps.deleteSuccess &&this.props.hasDeleteErrored) {
        this.handleDeleteClose(); 
        toast.error((this.props.deleteSuccess.error == null) ? this.props.deleteSuccess.message : this.props.deleteSuccess.error);    
        // toast.update(1, {
        //   render: (this.props.deleteSuccess.error == null) ? this.props.deleteSuccess.message : this.props.deleteSuccess.error,
        //   type: toast.TYPE.ERROR,
        //   autoClose: 2000, 
        //   hideProgressBar: false
        // });
      }
    }
  componentDidMount() {
    document.title = "User Management | FestFrwrd - The Futr of FSTIVLS";
    this.getData();
  }
	private getData(){
		if(this.prevSort != ""){
		  this.props.getAllUsers(this.state.limit,this.state.page,this.state.search,this.state.sort_type+"_"+this.state.sort);
		}else{
		  this.props.getAllUsers(this.state.limit,this.state.page,this.state.search,"");	
	
		}
  }
  handleResetPinClose = () => { 
    this.setState({ resetPinModal: false },()=>{
      this.validator.hideMessages();
      this.handleResetPinFormReset();
    });
   

  };
  handleResetPinShow = (id:string) => {
    this.setState({ resetPinModal: true });
    this.setState({ email: id });
  };
  handleResetPinFormReset = () => {
    this.setState({ newPin: "" },()=>{
      this.setState({ confirmPin: ""});
      this.setState({ email: "" });
    });
  }
  handleResetPinSubmit = (e: React.FormEvent<HTMLFormElement>) => { 
     e.preventDefault();
    if (this.validator.allValid()) { 
      this.props.userresetPinCreate(
        this.state.newPin,
        this.state.email 
      );
     }
    else {
      toast.dismiss(2);
      toast.warn("Please fill in all required fields!",{toastId:2});
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  private setTextInputPop = (event: React.FormEvent<HTMLInputElement>) => {		
		this.setState({ [event.currentTarget.name]: event.currentTarget.value }, () => {
		});
    }
  private setTextInput = (event: React.FormEvent<HTMLInputElement>) => {		
		this.setState({ [event.currentTarget.name]: event.currentTarget.value }, () => {
			this.getData();
		});
    }
    private setSelectInput = (event: React.FormEvent<HTMLSelectElement>) => {		
      this.setState({ ["limit"]: event.currentTarget.value }, () => {
        this.setState({ ["page"]: 0 }, () => {
        this.getData();
        })
      });
     }
     private setPage = (value:any) => {
      let page = this.state.page;
      page = (value == 'inc') ? page+1 : (page-1);
      this.setState({ ["page"]: page }, () => {
        this.getData();
      });
    }
    private setSort = (type:string,value:string) => {	
      value = (value == "asc") ? "desc" : "asc";
      if(this.prevSort != type){
        this.prevSort = type;
        value = "asc";
      }
      this.setState({ ["sort_type"]: type }, () => {
        this.setState({ ["sort"]: value }, () => {
          this.getData();
        });
      });
      
    }
    getArrow = () => {
      return (((this.state.page+1) * this.state.limit) >= this.totalCount) ? arrowRightDis : arrowRight;
    }
    setCount = (count:number) => {
      this.totalCount = count;
    }
    getCountString = () => {
      var str = "";
      if(this.totalCount > ((this.state.page+1) * this.state.limit)){
         str = (this.state.page * this.state.limit)+1+"-"+((this.state.page+1) * this.state.limit) +" of "+ this.totalCount;
      }else{
         str = (this.state.page * this.state.limit)+1+"-"+this.totalCount +" of "+ this.totalCount;
      }
      return str;
    }
    handleDelete = () => {
      this.props.deleteUser(this.state.selecteduserId.documentID);
      this.handleDeleteClose();
    };    
    handleDeleteClose = () => {
      this.setState({ deleteModalShow: false, selecteduserId: null });
      hideLoading();
    };

    handleDeleteShow = (groupId: any) => {
      this.setState({ deleteModalShow: true, selecteduserId: groupId });
    };


  public render() {
    if (this.props.isLoading) {
      return <p>Loading</p>;
    }
    if (this.props.hasErrored) {
      return <p>Failed to fetch users</p>;
    }
    return (
      <div>
        <div className="inner-title">
          <h3>USER MANAGEMENT</h3>
          <div className="path_navigation">
            <ul>
              <li>
                <NavLink to="/security">SECURITY Management</NavLink>
              </li>
              <li>
                <span>USER MANAGEMENT</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="table_data">
          <div className="table_header">
            <div className="icon-input search-icon">
              <input
                type="text"
                className="form-control"
                placeholder="Search User"
                name={"search"} value={this.state.search} onChange={(event) => this.setTextInput(event)}
              />
            </div>
            <NavLink to="/security/UserManagement/new" className="btn-addnew">
              Add New <span>+</span>
            </NavLink>
          </div>
          <table className="custom_table">
            <thead>
              <tr>
                <th>First Name  <a onClick={(event) => this.setSort("firstNameSort",this.state.sort)} >
									{(this.state.sort_type == "firstNameSort") ? (  <img src={this.state.sort == "asc" ? sortUpActive : sortDown} /> ) : ( <img src={sortUp}/> )}  
									</a></th>
                <th>Last Name  <a onClick={(event) => this.setSort("lastNameSort",this.state.sort)} >
									{(this.state.sort_type == "lastNameSort") ? (  <img src={this.state.sort == "asc" ? sortUpActive : sortDown} /> ) : ( <img src={sortUp}/> )}  
									</a></th>
                <th>Email  <a onClick={(event) => this.setSort("emailSort",this.state.sort)} >
									{(this.state.sort_type == "emailSort") ? (  <img src={this.state.sort == "asc" ? sortUpActive : sortDown} /> ) : ( <img src={sortUp}/> )}  
									</a></th>
                <th>Phone contact  <a onClick={(event) => this.setSort("phoneNumber",this.state.sort)} >
									{(this.state.sort_type == "phoneNumber") ? (  <img src={this.state.sort == "asc" ? sortUpActive : sortDown} /> ) : ( <img src={sortUp}/> )}  
									</a></th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
            {(this.props.success.length == 0 && !this.props.isLoading)? ( <tr className=""><td colSpan={5} className="nodata">No data found!</td></tr>) : null}

              {this.props.success.map(user => (
                <tr>{this.setCount(user.count)}
                  <td  className="capitalize">{user.document.firstName}</td>
                  <td  className="capitalize">{user.document.lastName}</td>
                  <td>{user.document.email}</td>
                  <td>{user.document.phoneNumber}</td>
                  <td>
                  <Dropdown  drop="left" className="table_menu">
                    <Dropdown.Toggle id="dropdown-basic">
                      <span className="more_icon"></span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                       <div role="presentation">
                       <Link
                      to={{
                        pathname: "/security/UserManagement/view_edit",
                        state: {
                          firstName: user.document.firstName,
                          lastName: user.document.lastName,
                          email: user.document.email,
                          contactEmail: user.document.contactEmail,
                          phoneNumber: user.document.phoneNumber,
                          id: user.documentID,
                          profilePic: user.document.profileImageURL,
                          groupId: user.document._groupId
                        }
                      }}
                    >
                         <FontAwesomeIcon icon={faEdit} /> <i className="fa fa-eye"></i> View/Edit
                        </Link>
                        </div>
                       <div role="presentation" >
                         
                         <a  onClick={e => this.handleDeleteShow(user)} > <FontAwesomeIcon  icon={faTrashAlt} /> Delete</a>
                        </div> 
                        <div role="presentation" >
                         
                         <a  onClick={e => this.handleResetPinShow(user.documentID)} > <FontAwesomeIcon  icon={faRedo} /> Reset Pin</a>
                        </div>    
                    </Dropdown.Menu>
                  </Dropdown>
                    
                  </td>
                </tr>
              ))}
              	<tr>
                <td colSpan={5}>
                <div className="d-flex flex-row-reverse bd-highlight">
                <div className="p-2 bd-highlight"> 							
                  <button className="paginationbtn" disabled={this.state.page == 0} onClick={(event) => this.setPage("dec")} >  &nbsp;&nbsp;&nbsp;
                  <img style={{width:'15px'}} src={(this.state.page == 0) ? arrowLeftDis : arrowLeft }/> &nbsp;&nbsp;&nbsp;
                  </button>
                  <button className="paginationbtn" disabled={(((this.state.page+1) * this.state.limit) >= this.totalCount)} 
                  onClick={(event) => this.setPage("inc")}> &nbsp;&nbsp;&nbsp;<img style={{width:'15px'}} src={this.getArrow()}/>&nbsp;&nbsp;&nbsp;</button>										
                </div>
                    <div className="p-2 bd-highlight">{this.getCountString()}</div>
                  <div className="p-2 bd-highlight">Rows per page &nbsp;&nbsp;&nbsp;
                    <select name={"offset"}  onChange={(event) => this.setSelectInput(event)} style={{border:'0px'}}>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>										
                    </select>
                  </div>
                </div>										
                </td>
                </tr>
            </tbody>
          </table>
        </div>
        <Modal
          className="custom_modal"
          show={this.state.deleteModalShow}
          onHide={() => this.handleDeleteClose()}
        >
          <Modal.Body>
            <div className="invitationBox">
              <a
                className="modalClose"
                onClick={() => this.handleDeleteClose()}
              >
                <img src={closeBtn}></img>
              </a>
              <div className="delete-msg">
                <h4>Are you sure you want delete this User?</h4>
              </div>
              <div className="user-deatils-box">
              {this.state.selecteduserId && this.state.selecteduserId.document.profileImageURL !== undefined && this.state.selecteduserId.document.profileImageURL != '' ? (
                                          <div className="user-img">

                          <img src={this.state.selecteduserId.document.profileImageURL} alt="" />
                          </div>

                  ) : null}
                  <div className="user-deatils">
                    <h4>
                      {this.state.selecteduserId &&
                        this.state.selecteduserId.document.firstName +" "+ this.state.selecteduserId.document.lastName}
                    </h4>
                  </div>
                </div>
              <button
                className="btn-submit"
                onClick={() => this.handleDelete()}
                type="submit"
              >
                Delete
              </button> 
              <button
                className="btn-bordered"
                onClick={() => this.handleDeleteClose()}
                type="submit"
              >
                Cancel
              </button>    
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          className="custom_modal"
          show={this.state.resetPinModal}
          onHide={() => this.handleResetPinClose()}
        >
		        <Modal.Body>
                      
            
            <div className="invitationBox">
              <a
                className="modalClose"
                onClick={() => this.handleResetPinClose()}
              >
                <img src={closeBtn}></img>
              </a>
              <div className="delete-msg">
                <h4>Reset PIN</h4>
              </div>
              <form action="" onSubmit={this.handleResetPinSubmit}>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<label className="blue_label">NEW PIN </label>
									<div className="new_pin">
									<input name={"newPin"} maxLength={6} value={this.state.newPin} onChange={this.setTextInputPop} type="password" className="form-control" placeholder="OOOOOO"  />
									{this.validator.message(
										"newPin",
										this.state.newPin,
										"required|integer|min:6|max:6"
									)}
									</div>
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<label className="blue_label">CONFIRM NEW PIN </label>
									<div className="new_pin">
									<input name={"confirmPin"} 
									maxLength={6} value={this.state.confirmPin} onChange={this.setTextInputPop} type="password" className="form-control" placeholder="OOOOOO"  />
									{this.validator.message(
										"confirmPin",
										this.state.confirmPin,
										`required|integer|min:6|max:6|in:${this.state.newPin}`
									)}
									
									</div>
								</div>
							</div>
						</div>
						<button className="btn-submit" type="submit">Submit</button>
					</form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  profile: state.session,
  isLoading: state.userManagement.UserManagementList.isLoading,
  hasErrored: state.userManagement.UserManagementList.hasErrored,
  success: state.userManagement.UserManagementList.success,  
  isDeleteLoading: state.userManagement.UserManagementDelete.isLoading,
  hasDeleteErrored: state.userManagement.UserManagementDelete.hasErrored,
  deleteSuccess: state.userManagement.UserManagementDelete.response,
  isRestLoading: state.userManagement.UserManagementResetPIN.isLoading,
  hasResetErrored: state.userManagement.UserManagementResetPIN.hasErrored,
  resetSuccess: state.userManagement.UserManagementResetPIN.success
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, Action>
): DispatchProps => ({
  getAllUsers: (limit,page,search,sort) => dispatch(userManagementListAll(limit,page,search,sort)),
  deleteUser: (userId: string) => dispatch(userManagementDelete(userId)),
  userresetPinCreate: (newPin: any, email:string) => dispatch(userresetPinCreate(newPin,email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
