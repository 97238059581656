import { toast } from "react-toastify";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import * as ActionTypes from "./actionTypes";
import { Dispatch } from "redux";
import Api from "../../Api";

const userManagementListSuccess = (payload: any) => ({
  type: ActionTypes.USER_MANAGEMENT_LIST_SUCCESS,
  payload
});

const userManagementListError = (payload: any) => ({
  type: ActionTypes.USER_MANAGEMENT_LIST_ERROR,
  payload
});
export const userManagementListAll = (
  limit: number,
  page: number,
  search: string,
  sort: string
) => async (dispatch: Dispatch) => {
  try {
    dispatch(showLoading());
    let userGroupListResponse = await Api.getAllUsersList(
      limit,
      page,
      search,
      sort
    );
    dispatch(userManagementListSuccess(userGroupListResponse));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch(userManagementListError(error));
  }
};

export const userManagementList = () => async (dispatch: Dispatch) => {
  try {
    dispatch(showLoading());
    let userGroupListResponse = await Api.getAllUsers();
    dispatch(userManagementListSuccess(userGroupListResponse));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(userManagementListError(error));
    dispatch(hideLoading());
  }
};

const userManagementCreateSuccess = (payload: any) => ({
  type: ActionTypes.USER_MANAGEMENT_CREATE_SUCCESS,
  payload
});

const userManagementCreateError = (payload: any) => ({
  type: ActionTypes.USER_MANAGEMENT_CREATE_ERROR,
  payload
});

export const userManagementCreate = (
  email: string,
  contactEmail: string,
  phoneNumber: string,
  firstName: string,
  lastName: string,
  _groupId: any[],
  profilePicbase: string,
) => async (dispatch: Dispatch) => {
  try {
   dispatch(showLoading());
    let userGroupCreateResponse = await Api.createUser({
      email,
      contactEmail,
      phoneNumber,
      firstName,
      lastName,
      _groupId,
      profilePicbase,
    });
    dispatch(userManagementCreateSuccess(userGroupCreateResponse));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch(userManagementCreateError(error));
  }
};

const userManagementEditSuccess = (payload: any) => ({
  type: ActionTypes.USER_MANAGEMENT_EDIT_SUCCESS,
  payload
});

const userManagementEditError = (payload: any) => ({
  type: ActionTypes.USER_MANAGEMENT_EDIT_ERROR,
  payload
});

export const userManagementEdit = (
  email: string,
  contactEmail: string,
  phoneNumber: string,
  firstName: string,
  lastName: string,
  _groupId: any[],
  profilePicbase: string,
  userId: string,
  tags: any,
) => async (dispatch: Dispatch) => {
  try {
    dispatch(showLoading());
    let userManagementEditResponse = await Api.editUser({
      email,
      contactEmail,
      phoneNumber,
      firstName,
      lastName,
      _groupId,
      profilePicbase,
      userId,
      tags
    });
    dispatch(userManagementEditSuccess(userManagementEditResponse));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(userManagementEditError(error));
    dispatch(hideLoading());
  }
};

const userManageMentListSingleDelete = (userId: string) => ({
  type: ActionTypes.USER_MANAGEMENT_LIST_SINGLE_DELETE,
  payload: userId
});

const userManagementDeleteSuccess = (payload: any) => ({
  type: ActionTypes.USER_MANAGEMENT_DELETE_SUCCESS,
  payload
});

const userManagementDeleteError = (payload: any) => ({
  type: ActionTypes.USER_MANAGEMENT_DELETE_ERROR,
  payload
});

export const userManagementDelete = (userId: string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(showLoading());
    let userManagementDeleteResponse = await Api.deleteUser({
      userId
    });
    dispatch(userManagementDeleteSuccess(userManagementDeleteResponse));
    dispatch(userManageMentListSingleDelete(userId));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(userManagementDeleteError(error));
    dispatch(hideLoading());
  }
};
const getVendorsAndOwnersSuccess = (payload: any) => ({
  type: ActionTypes.GET_VENDOR_OWNER_LIST_SUCCESS,
  payload
});

const getVendorsAndOwnersError = (payload: any) => ({
  type: ActionTypes.GET_VENDOR_OWNER_LIST_ERROR,
  payload
});
export const getVendorsAndOwners = () => async (dispatch: Dispatch) => {
  try {
    dispatch(showLoading());
    let list = await Api.getVendorsAndOwners();
    dispatch(getVendorsAndOwnersSuccess(list));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch(getVendorsAndOwnersError(error));
  }
};

const resetPINSuccess = (payload: any) => ({
  type: ActionTypes.USER_MANAGEMENT_RESETPIN_SUCCESS,
  payload
});

const resetPINError = (payload: any) => ({
  type: ActionTypes.USER_MANAGEMENT_RESETPIN_ERROR,
  payload
});

export const userresetPinCreate = (newPin: any, email:string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(showLoading());
    let resetPINResponse = await Api.resetPIN({
      newPin, email
    });
    dispatch(resetPINSuccess(resetPINResponse));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(resetPINError(error));
    dispatch(hideLoading());
  }
};

