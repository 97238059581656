import * as React from "react";
import { Link } from "react-router-dom";
import owner from "../../assets/images/businessmen2.svg";
import ownerC from "../../assets/images/businessmenc.svg";

export interface IOwnerDashboardProps {}

export default class OwnerDashboard extends React.Component<
  IOwnerDashboardProps
> {
  componentDidMount() {
    document.title = "Owner Management | FestFrwrd - The Futr of FSTIVLS";
  }
  public render() {
    return (
        <div>
          <div className="inner-title">
            <h3>Festival Owner Management</h3>
          </div>

          <div className="icon_boxes_list">
            <Link to="/FestivalOwnerManagement/list" className="icon_box">
              <img className="icon_color" src={ownerC} alt="" />
              <img className="icon_white" src={owner} alt="" />
              <p>
                View Festival <br /> Owners
              </p>
            </Link>
          </div>
        </div>
    );
  }
}
