import * as React from "react";
import { NavLink, Link } from "react-router-dom";
import Select, { ValueType } from "react-select";
import { CheckBox } from "../../styled-components/checkboxes/index";
import BannerDefault from "../../assets/images/upload.svg";
import ProfileDefault from "../../assets/images/user.svg";
import Attachment from "../../assets/images/attachment.svg";
import feature1 from "../../assets/images/icon-onsitenav.png";
import { Dropdown, Modal, Tabs, Tab } from "react-bootstrap";
import StarRatingComponent from 'react-star-rating-component';
import facebook from "../../assets/images/facebook.svg";
import facebookDis from "../../assets/images/facebook_dis.svg";
import twitter from "../../assets/images/twitter.svg";
import twitterDis from "../../assets/images/twitter_dis.svg";
import instagram from "../../assets/images/instagram.svg";
import instagramDis from "../../assets/images/instagram_dis.svg";
import chat from "../../assets/images/chat.png";
import email from "../../assets/images/email.png";
import phone from "../../assets/images/phone.png";
import fest from "../../assets/images/fest.png";
import ratings from "../../assets/images/ratings.png";
import icoonsite from "../../assets/images/ico-onsite.png";
import topicons from "../../assets/images/top-icons.png";
import InputMask from 'react-input-mask';

import { festivalsCategory } from "./../../constants/festivalsCategory";
import { VendorCategories } from "../../constants/vendorCategories";
import { paymentOptions } from "./../../constants/paymentOptions";
import { stateOptions } from "./../../constants/stateOptions";
import { countryOptions } from "./../../constants/countryOptions";
export interface IUserFunctionCreateProps {
  isUpdate: boolean;
  businessName: string;
  category?: ValueType<{ value: string; label: string }>;
  payment?: ValueType<{ value: string; label: string }>;
  country?: ValueType<{ value: string; label: string }>;
  state?: ValueType<{ value: string; label: string }>;
  firstName: string;
  lastName: string;
  email: string;
  contactEmail: string;
  phoneNumber: string;
  facebookURL: string;
  twitterURL: string;
  instagramURL: string;
  websiteURL: string;
  location: string;
  city: string;
  zipcode: string;
  about: string;
  embed: any;
  profileImageURL: any;
  bannerImageUrl: any;
  notifications: Map<string, string>;
  setTextInput: (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleSelectChange: (
    selectedOption: ValueType<{ value: string; label: string }>,
    name: string
  ) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  setProfileFileInput: (e: any) => void;
  setBanerFileInput: (e: any) => void;
  validator: any;
}

interface IUserGroupCreateState {
  checkedItems: Map<string, string>;
  categorySelected: any;
  paymentSelect: any;
  stateSelected: any;
  countrySelected: any;
  profileImage: any;
  profileImageURL: any;
  bannerImage: any;
  bannerImageUrl: any;
}
interface options { value: string; label: string; } 
const checkboxes = [
  {
    name: "sms",
    key: "sms",
    label: "SMS"
  },
  {
    name: "email",
    key: "email",
    label: "Email"
  }
];
export default class VendorForm extends React.Component<
  IUserFunctionCreateProps,
  IUserGroupCreateState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      checkedItems: new Map(),
      categorySelected: null,
      paymentSelect: null,
      stateSelected: null,
      countrySelected: null,
      profileImage: '',
      profileImageURL: '',
      bannerImage: '',
      bannerImageUrl: ''
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e: any) {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState(prevState => ({
      checkedItems: prevState.checkedItems.set(item, isChecked)
    }));
  }

  handlePaymentChange = (paymentSelect: any) => {
    this.setState({ paymentSelect });
  };
  handleStateChange = (stateSelected: any) => {
    this.setState({ stateSelected });
  };
  handleCountryChange = (countrySelected: any) => {
    this.setState({ countrySelected });
  };
  handleCategoryChange = (categorySelected: any) => {
    this.setState({ categorySelected });
  };
  getLocation = () => {
    let location = [];
    if(this.props.location != ''){
      location.push(this.props.location);
    }
    if(this.props.city != ''){
      location.push(this.props.city);
    }
    if(this.props.zipcode != ''){
      location.push(this.props.zipcode);
    }
    if(this.state.stateSelected != null){
      location.push(this.state.stateSelected.label);
    }
    if(this.state.countrySelected != null){
      location.push(this.state.countrySelected.label);
    }

    return location.join(", ");
  }
  createMarkup() {
    return {__html: this.props.embed};
  }
  _handleProfileImageChange(e:any) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        profileImage: file,
        profileImageURL: reader.result
      }, () => {
        this.props.setProfileFileInput(this.state.profileImageURL);
    });
    }

    reader.readAsDataURL(file)
  }

  _handleBannerImageChange(e:any) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        bannerImage: file,
        bannerImageUrl: reader.result
      }, () => {
        this.props.setBanerFileInput(this.state.bannerImageUrl);
    });
    }
    reader.readAsDataURL(file)
  }

  public render() {
    let categories:any = this.props.category;   
    let profileImageURL = "";
    if(this.state.profileImageURL && this.state.profileImageURL != ''){
      profileImageURL= this.state.profileImageURL;
    }else if(this.props.profileImageURL && this.props.profileImageURL != ''){
      profileImageURL = this.props.profileImageURL;
    }
    let $profilePreview = null;
    if (profileImageURL) {
      $profilePreview = (<img src={profileImageURL} />);
    } else {
      $profilePreview = (<div className="no_pofile_img"><img src={ProfileDefault} /></div>);
    }

    let bannerImageUrl = "";
    if(this.state.bannerImageUrl && this.state.bannerImageUrl != ''){
      bannerImageUrl= this.state.bannerImageUrl;
    }else if(this.props.bannerImageUrl && this.props.bannerImageUrl != ''){
      bannerImageUrl = this.props.bannerImageUrl;
    }
    let $bannerPreview = null;
    let $mbannerPreview = null;
    if (bannerImageUrl) {
      $bannerPreview = $mbannerPreview = (<img src={bannerImageUrl} />);
    } else {
      $bannerPreview = (<div className="no_banner_img"><img src={BannerDefault} /><span>Upload</span></div>);
      $mbannerPreview = (<div className="no_banner_img"><img  /></div>);
    }

    
    return (
      <form action="" onSubmit={this.props.handleSubmit}>
        
        <div className="action_buttons text-right ">
          <Link onClick={()=> window.history.go(-1)} to={""} className="btn-back">Back </Link>
          <button className="btn-submit" type="submit"> {this.props.isUpdate != true ? ("Submit") : "Update"}  </button>
        </div>
        <div className="row">
          <div className="col-md-7">
            <div className="form-group">
              <label className="blue_label">BUSINESS NAME <span className="text-danger">*</span></label>
              <input
                  type="text" maxLength={45}
                className="form-control"
                placeholder="Enter Business Name"
                name={"businessName"}
                value={this.props.businessName}
                onChange={this.props.setTextInput}
              />
              {this.props.validator.message(
                "businessName",
                this.props.businessName,
                "required|min:3|max:45"
              )}
            </div>
            <div className="form-group select-multi">
              <label className="blue_label">
                CATEGORY <span className="text-danger">*</span>
              </label>
              <Select
                name={"category"}
                value={this.props.category}
                onChange={(
                  selectedOption: ValueType<{ value: string; label: string }>
                ) => this.props.handleSelectChange(selectedOption, "category")}
                options={VendorCategories}
                isMulti
              />
              {this.props.validator.message(
                "category",
                this.props.category,
                "required|array"
              )}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="blue_label">
                    FIRST NAME <span className="text-danger">*</span>
                  </label>
                  <input
                      type="text" maxLength={45}
                    className="form-control"
                    placeholder="Enter First Name"
                    name={"firstName"}
                    value={this.props.firstName}
                    onChange={this.props.setTextInput}
                  />
                  {this.props.validator.message(
                    "firstName",
                    this.props.firstName,
                    "required|min:3|max:45"
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="blue_label">
                    LAST NAME <span className="text-danger">*</span>
                  </label>
                  <input
                      type="text" maxLength={45}
                    className="form-control"
                    placeholder="Enter Last Name"
                    name={"lastName"}
                    value={this.props.lastName}
                    onChange={this.props.setTextInput}
                  />
                  {this.props.validator.message(
                    "lastName",
                    this.props.lastName,
                    "required|min:3|max:45"
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="blue_label">
                    EMAIL ADDRESS <span className="text-danger">*</span>
                  </label>
                  <input
                      type="text" maxLength={45}
                    className="form-control"
                    placeholder="Enter Email Address"
                    name={"email"}
                    disabled={this.props.isUpdate}
                    value={this.props.email}
                    onChange={this.props.setTextInput}
                  />
                  {this.props.validator.message(
                    "email",
                    this.props.email,
                    "required|email|min:3|max:45"
                  )}
                </div>
                <div className="form-group">
                  <label className="blue_label">
                    CONTACT EMAIL ADDRESS <span className="text-danger">*</span>
                  </label>
                  <input
                      type="text" maxLength={45}
                    className="form-control"
                    placeholder="Enter Contact Email Address"
                    name={"contactEmail"}
                    disabled={!this.props.isUpdate}
                    value={this.props.contactEmail}
                    onChange={this.props.setTextInput}
                  />
                  {this.props.validator.message(
                    "contactEmail",
                    this.props.contactEmail,
                    "required|email|min:3|max:45"
                  )}
                </div>
                <div className="form-group">
                  <label className="blue_label">
                    PHONE NUMBER <span className="text-danger">*</span>
                  </label>
                  <InputMask placeholder="Ex: (202) 456-1111"  className="form-control" mask="(999) 999-9999"  name={"phoneNumber"} value={this.props.phoneNumber} onChange={this.props.setTextInput}  />
                  {this.props.validator.message(
                    "phoneNumber",
                    this.props.phoneNumber,
                    "required|phoneNumber"
                  )}
                </div>

                <div className="form-group select-multi">
                  <label className="blue_label">
                    PAYMENT TYPE <span className="text-danger">*</span>
                  </label>
                  <Select
                    value={this.props.payment}
                    onChange={(
                      selectedOption: ValueType<{
                        value: string;
                        label: string;
                      }>
                    ) =>
                      this.props.handleSelectChange(selectedOption, "payment")
                    }
                    options={paymentOptions}
                    isMulti
                  />
                   {this.props.validator.message(
                      "payment",
                      this.props.payment,
                      "required"
                    )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="blue_label">FACEBOOK </label>
                  <input
                      type="text" maxLength={45}
                    className="form-control"
                    placeholder="Enter Facebook URL"
                    name={"facebookURL"}
                    value={this.props.facebookURL}
                    onChange={this.props.setTextInput}
                  />
                    {this.props.validator.message(
                      "facebookUrl",
                      this.props.facebookURL,
                      "url"
                    )}
                </div>
              
                <div className="form-group">
                  <label className="blue_label">TWITTER </label>
                  <input
                      type="text" maxLength={45}
                    className="form-control"
                    placeholder="Enter Twitter URL"
                    name={"twitterURL"}
                    value={this.props.twitterURL}
                    onChange={this.props.setTextInput}
                  />
                  {this.props.validator.message(
                      "twitterUrl",
                      this.props.twitterURL,
                      "url"
                    )}
                </div>

                <div className="form-group">
                  <label className="blue_label">INSTAGRAM </label>
                  <input
                      type="text" maxLength={45}
                    className="form-control"
                    placeholder="Enter Instagram URL"
                    name={"instagramURL"}
                    value={this.props.instagramURL}
                    onChange={this.props.setTextInput}
                  />
                   {this.props.validator.message(
                      "instagramUrl",
                      this.props.instagramURL,
                      "url"
                    )}
                </div>
                <div className="form-group">
              <label className="blue_label">WEBSITE LINK </label>
              <input
                  type="text" maxLength={45}
                className="form-control"
                placeholder="Enter Website URL"
                name={"websiteURL"}
                value={this.props.websiteURL}
                onChange={this.props.setTextInput}
              />
               {this.props.validator.message(
                      "websiteUrl",
                      this.props.websiteURL,
                      "url"
                    )}
            </div>
              </div>
            </div>

         

            <label className="blue_label">MAILING ADDRESS</label>
            <div className="form-group">
              <label className="blue_label">LOCATION </label>
              <input
                  type="text" maxLength={45}
                className="form-control"
                placeholder="Enter Address"
                name={"location"}
                value={this.props.location}
                onChange={this.props.setTextInput}
              />
            </div>

            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label className="blue_label">CITY </label>
                  <input
                      type="text" maxLength={45}
                    className="form-control"
                    placeholder="Enter City"
                    name={"city"}
                    value={this.props.city}
                    onChange={this.props.setTextInput}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group select-group">
                  <label className="blue_label">STATE </label>
                  <Select
                    options={stateOptions}
                    value={this.props.state}
                    onChange={(
                      selectedOption: ValueType<{
                        value: string;
                        label: string;
                      }>
                    ) => this.props.handleSelectChange(selectedOption, "state")}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="blue_label">ZIP CODE </label>
                  <input
                      type="text" maxLength={45}
                    className="form-control"
                    placeholder="Enter Zip code"
                    name={"zipcode"}
                    value={this.props.zipcode}
                    onChange={this.props.setTextInput}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3">
                <div className="form-group select-group">
                  <label className="blue_label">COUNTRY </label>
                  <Select
                    value={this.props.country}
                    onChange={(
                      selectedOption: ValueType<{
                        value: string;
                        label: string;
                      }>
                    ) =>
                      this.props.handleSelectChange(selectedOption, "country")
                    }
                    options={countryOptions}
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="blue_label">
                ABOUT THIS VENDOR<span className="text-danger">*</span>
              </label>
              <textarea
                className="form-control"
                placeholder="Enter Vendor description"
                name={"about"}
                value={this.props.about}
                onChange={this.props.setTextInput}
              ></textarea>
               {this.props.validator.message(
                      "aboutVendor",
                      this.props.about,
                      "required"
                    )}
            </div>

            <div className="form-group">
              <label className="blue_label">
                EMAIL SYSTEM - EMBED CODE
              </label>
              <textarea
                className="form-control yellow-bg"
                placeholder="Paste Email Form Embed Code here"
                name={"embed"}
                value={this.props.embed}
                onChange={this.props.setTextInput}
              ></textarea>
            </div>

            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label className="blue_label">ALERT NOTIFICATIONS ON </label>
                  <div>
                    <React.Fragment>
                      {checkboxes.map(item => (
                        <div className="checkBoxButton checkBoxButton_inline">
                          <CheckBox
                            id={item.key}
                            name={item.name}
                            checked={this.props.notifications.get(item.name)}
                            onChange={this.props.handleChange}
                          />
                          <label
                            className="checkboxlabel"
                            htmlFor={item.key}
                            key={item.key}
                          >
                            {item.label}
                          </label>
                        </div>
                      ))}
                    </React.Fragment>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5">
            <label className="blue_label">ADD PICTURES</label>
            <div className="form-group">
              <label className="blue_label">PROFILE PICTURE<span className="text-danger">*</span> </label><br/>
              <div className="profile_upoad">
                 <div className="profieimg">
                 {$profilePreview}
                 </div>
                <label>
                    <input
                    type="file"
                    className="form-control"
                    placeholder="UPLOAD PICTURE" onChange={(e)=>this._handleProfileImageChange(e)}
                  />
                  
                  <div className="attach">
                    <img src={Attachment} alt=""/>
                  </div>
                  UPLOAD PICTURE
                </label>
             
              </div>
              <i className="smallhint"><span className="reduired">*</span> Aspect ratio: 1:1 | Min Width:150px, Height: 150px</i>

              {this.props.validator.message(
                      "profileImage",
                      this.props.profileImageURL,
                      "required"
                    )}
              
            </div>
            <div className="form-group">
              <label className="blue_label">BANNER IMAGE<span className="text-danger">*</span> </label><br/>
              <i className="smallhint"><span className="reduired">*</span> Aspect ratio: 1.6:1 | Min Width:480px, Height: 300px</i>
              <label className="banner_upload">
                <input
                  type="file"
                  className="form-control"
                  placeholder="Upload"  onChange={(e)=>this._handleBannerImageChange(e)}
                />
                <div className="banner_img">
                  {$bannerPreview}
                </div>
              </label>
            </div>
            {this.props.validator.message(
                      "bannerImage",
                      this.props.bannerImageUrl,
                      "required"
                    )}
            <div className="mobile_preview vendor_mobile_preview">
                             <p className="preview_title">MOBILE PREVIEW</p>
                             
                            <div className="mobile_preview_inner">
                            <img className="topicons" src={topicons}/>

                                  <div className="mobile_banner" >
                                  {$mbannerPreview}
                                   
                                    <div className="mobile_banner_title">
                                      <div className="profile_pic">
                                        {$profilePreview}
                                      </div>
                                      <h2>{this.props.businessName}</h2>
                                      <div className="rating">
                                      <StarRatingComponent 
                                      name="rate1" 
                                      starCount={5}
                                      value={4}
                                      starColor={'#58B5C9'}
                                      emptyStarColor={'#e2e2e2'}
                                      />
                                      <p>97 Ratings</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mobile_tabs">
                                    <Tabs id="mobile_tabs" defaultActiveKey="information" >
                                      <Tab eventKey="information" title="Information">
                                        <div className="form-group border_bottom_preview">
                                          <label className="blue_label">Type </label>
                                          {(categories != null) ? (
                                            <div>{(categories.map((item: any) => item['label'])).join(", ")} </div>
                                          ):null }
                                        </div>
                                         <div className="overview_footer border_bottom_preview row">
                                            <Link className="col-md-3" to={this.props.facebookURL} >
                                                <img src={icoonsite} />
                                            </Link>
                                            <Link className="col-md-3" to={this.props.facebookURL} >
                                              <img src={phone} />
                                            </Link>
                                            <Link className="col-md-3" to={""} >
                                              <img src={email} />
                                            </Link>
                                            <Link className="col-md-3" to={""} >
                                              <img src={chat} />
                                            </Link>
                                        </div>
                                        <div className="form-group border_bottom_preview nospace">
                                          <label className="blue_label">About this vendor </label>
                                          <p className="truncate-overflow2">{this.props.about}</p>
                                        </div>
                                        <div className="overview_footer social">
                                        <button className={(this.props.websiteURL == '') ? 'disabled-link social btn-submit' : 'btn-submit'}> 
                                              <Link className="buttonlink" to={this.props.websiteURL} >
                                              Visit Website
                                                </Link>
                                              </button>
                                             <Link className={(this.props.facebookURL == '') ? 'disabled-link' : ''} to={this.props.facebookURL} >
                                                <img src={(this.props.facebookURL == '') ? facebookDis : facebook} />
                                              </Link>
                                              <Link className={(this.props.twitterURL == '') ? 'disabled-link' : ''} to={this.props.twitterURL} >
                                              <img src={(this.props.twitterURL == '') ? twitterDis : twitter} />
                                              </Link>
                                              <Link className={(this.props.instagramURL == '') ? 'disabled-link' : ''} to={this.props.instagramURL} >
                                                <img src={(this.props.instagramURL == '') ? instagramDis : instagram} />
                                              </Link>
                                        </div>
                                        {this.props.embed !== '' ? (
                                                <div dangerouslySetInnerHTML={this.createMarkup()} />

                                        ):null}
                                       

                                       
                                      </Tab>
                                      <Tab eventKey="festivals" title="Festivals">
                                      <img src={fest} />
                                      </Tab>
                                      <Tab eventKey="ratings" title="Ratings">
                                      <img src={ratings} />
                                      </Tab>
                                    </Tabs>
                                  </div>
                            </div>
                        </div>


            
          </div>
        </div>
        <div className="action_buttons text-right ">
        <Link onClick={()=> window.history.go(-1)} to={""} className="btn-back">Back </Link>
        <button className="btn-submit" type="submit"> {this.props.isUpdate != true ? ("Submit") : "Update"}  </button>
        </div>
      </form>
    );
  }
}
