import * as React from 'react';
import { Link } from 'react-router-dom'

import user from "../../assets/images/growth.svg";
import userWhite from "../../assets/images/growth_white.svg";

import group from "../../assets/images/group.svg";
import groupWhite from "../../assets/images/group__white.svg";

import functions from "../../assets/images/cms.svg";
import fcuntionWhite from "../../assets/images/cms_white.svg";

export interface ISettingsProps {
}

export default class Settings extends React.Component<ISettingsProps> {
  public render() {
    return (
      <div>
        <div className="inner-title">
                <h3>Settings</h3>
            </div>

            <div className="icon_boxes_list">

                <Link to="/app-settings" className="icon_box" href="user-management.html">
                    <img className="icon_color" src={user} alt="" />
                    <img className="icon_white" src={userWhite} alt="" />
                    <p>App <br /> Settings</p>
                </Link>

                <Link to="/sms-settings" className="icon_box" href="user-groups.html">
                    <img className="icon_color" src={group} alt="" />
                    <img className="icon_white" src={groupWhite} alt="" />
                    <p>SMS <br /> Settings</p>
                </Link>

                <Link to="/email-settings" className="icon_box">
                    <img className="icon_color" src={functions} alt="" />
                    <img className="icon_white" src={fcuntionWhite} alt="" />
                    <p>Email <br /> Settings</p>
                </Link>

            </div>
      </div>
    );
  }
}
