import * as ActionTypes from "./actionTypes";
import { VendorState, VendorCreateActionTypes } from "./types";

const initialState: VendorState = {
  VendorCreate: {
    isLoading: false,
    hasErrored: false,
    success: false,
    response: null
  },
  VendorList: {
    isLoading: false,
    hasErrored: false,
    success: [],
    response: null
  },
  VendorEdit: {
    isLoading: false,
    hasErrored: false,
    success: false,
    response: null
  },
  VendorDelete: {
    isLoading: false,
    hasErrored: false,
    success: false,
    response: null
  },
  VendorSendInvite: {
    isLoading: false,
    hasErrored: false,
    success: false,
    response: null
  }
};

export default (
  state = initialState,
  action: VendorCreateActionTypes
): VendorState => {
  switch (action.type) {
    case ActionTypes.VENDOR_CREATE:
      return {
        ...state,
        VendorCreate: {
          ...state.VendorCreate,
          isLoading: true,
          success: false,
          hasErrored: false
        }
      };

    case ActionTypes.VENDOR_CREATE_SUCCESS:
      return {
        ...state,
        VendorCreate: {
          ...state.VendorCreate,
          isLoading: false, success: true, hasErrored: false, response:action.payload
        }
      };

    case ActionTypes.VENDOR_CREATE_ERROR:
      return {
        ...state,
        VendorCreate: {
          ...state.VendorCreate,
          isLoading: false, success: false, hasErrored: true, response:action.payload
        }
      };

    case ActionTypes.VENDOR_EDIT:
      return {
        ...state,
        VendorEdit: {
          ...state.VendorEdit,
          isLoading: true,
          success: false,
          hasErrored: false
        }
      };

    case ActionTypes.VENDOR_EDIT_SUCCESS:
      return {
        ...state,
        VendorEdit: {
          ...state.VendorEdit,
          isLoading: false, success: true, hasErrored: false, response:action.payload
        }
      };

    case ActionTypes.VENDOR_EDIT_ERROR:
      return {
        ...state,
        VendorEdit: {
          ...state.VendorEdit,
          isLoading: false, success: false, hasErrored: true, response:action.payload
        }
      };

    case ActionTypes.VENDOR_DELETE:
      return {
        ...state,
        VendorDelete: {
          ...state.VendorDelete,
          isLoading: true,
          success: false,
          hasErrored: false
        }
      };

    case ActionTypes.VENDOR_DELETE_SINGLE:
      const success = state.VendorList.success.filter(
        item => item.documentID !== action.payload
      );
      return {
        ...state,
        VendorList: {
          ...state.VendorList,
          success: success
        }
      };

    case ActionTypes.VENDOR_DELETE_SUCCESS:
      return {
        ...state,
        VendorDelete: {
          ...state.VendorDelete,
          isLoading: false, success: true, hasErrored: false, response:action.payload
        }
      };

    case ActionTypes.VENDOR_DELETE_ERROR:
      return {
        ...state,
        VendorDelete: {
          ...state.VendorDelete,
          isLoading: false, success: false, hasErrored: true, response:action.payload
        }
      };

    case ActionTypes.VENDOR_LIST:
      return {
        ...state,
        VendorList: {
          ...state.VendorList,
          isLoading: true,
          success: [],
          hasErrored: false
        }
      };

    case ActionTypes.VENDOR_LIST_SUCCESS:
      return {
        ...state,
        VendorList: {
          ...state.VendorList,
          success: action.payload,
          isLoading: false
        }
      };

    case ActionTypes.VENDOR_LIST:
      return {
        ...state,
        VendorList: {
          ...state.VendorList,
          isLoading: false,
          hasErrored: true
        }
      };

    case ActionTypes.VENDOR_SEND_INVITE:
      return {
        ...state,
        VendorSendInvite: {
          ...state.VendorSendInvite,
          isLoading: true,
          success: false,
          hasErrored: false
        }
      };

    case ActionTypes.VENDOR_SEND_INVITE_SUCCESS:
      return {
        ...state,
        VendorSendInvite: {
          ...state.VendorSendInvite,
          isLoading: false,
          success: true,
          hasErrored: false
        }
      };

    case ActionTypes.VENDOR_SEND_INVITE_ERROR:
      return {
        ...state,
        VendorSendInvite: {
          ...state.VendorSendInvite,
          isLoading: false,
          success: false,
          hasErrored: true
        }
      };

    case ActionTypes.VENDOR_EDIT_SINGLE:
      const update = state.VendorList.success.map(item => {
        if (item.document.email !== action.payload.email) {
          return item;
        } else {
          return {
            ...item,
            document: {
              ...item.document,
              ...action.payload
            }
          };
        }
      });

      return {
        ...state,
        VendorList: {
          ...state.VendorList,
          success: update
        }
      };

    default:
      return state;
  }
};
