import * as React from "react";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { showLoading, hideLoading  } from "react-redux-loading-bar";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { AppState } from "../../state/types";
import {
  userGroupListAll,
  userGroupDelete
} from "../../state/userGroups/actions";

import { Dropdown, Modal } from "react-bootstrap";
import closeBtn from "../../assets/images/close-button.svg";
import sortUp from "../../assets/images/sort_up.svg";
import sortDown from "../../assets/images/sort_down.svg";
import sortUpActive from "../../assets/images/sort_up_active.svg";

import arrowRight from "../../assets/images/right-arrow.svg";
import arrowRightDis from "../../assets/images/right-arrow-dis.svg";
import arrowLeft from "../../assets/images/left-arrow.svg";
import arrowLeftDis from "../../assets/images/left-arrow-dis.svg";
export interface IUserGroupProps {}

interface DispatchProps {
  getAllGroups: (
    limit: number,
    page: number,
    search: string,
    sort: string
  ) => void;
  deleteGroup: (groupId: string) => void;
}

interface StateProps {
  isLoading: boolean;
  hasErrored: boolean;
  success: Array<any>;
  isDeleteLoading: boolean;
  hasDeleteErrored: boolean;
  deleteSuccess: boolean;
  deleteresponse: any;
}

type Props = StateProps & IUserGroupProps & DispatchProps;

class UserGroup extends React.Component<Props> {
  private prevSort = "nameSort";
  public totalCount = 0;
  private prevSortInit = "asc";
  public state = {
    limit: 10,
    page: 0,
    search: "",
    sort: "asc",
    sort_type: "nameSort",
    deleteModalShow: false,
    selectedGroup: null as any
  };

  constructor(props: Props) {
    super(props);
    this.getData();
  }
  componentDidMount() {
    document.title = "User groups | FestFrwrd - The Futr of FSTIVLS";
  }
  componentDidUpdate(prevProps: Props) {
    if (this.props.deleteresponse !== prevProps.deleteresponse &&this.props.deleteSuccess) {
      this.handleDeleteClose(); 
      this.getData();   
      // toast.update(1, {
      //   render: this.props.deleteresponse.data.message,
      //   type: toast.TYPE.SUCCESS,
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   onClose:() =>{
      //     this.getData();
      //   }
      // });
    }
    
    if (this.props.deleteresponse !== prevProps.deleteresponse &&this.props.hasDeleteErrored) {
      this.handleDeleteClose();   
      toast.error((this.props.deleteresponse.error == null) ? this.props.deleteresponse.message : this.props.deleteresponse.error);   
      // toast.update(1, {
      //   render: (this.props.deleteresponse.error == null) ? this.props.deleteresponse.message : this.props.deleteresponse.error,
      //   type: toast.TYPE.ERROR,
      //   autoClose: 2000, 
      //   hideProgressBar: false
      // });
    }
  }

  private getData() {
    if (this.prevSort != "") {
      this.props.getAllGroups(
        this.state.limit,
        this.state.page,
        this.state.search,
        this.state.sort_type + "_" + this.state.sort
      );
    } else {
      this.props.getAllGroups(
        this.state.limit,
        this.state.page,
        this.state.search,
        ""
      );
    }
  }

  private setTextInput = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState(
      { [event.currentTarget.name]: event.currentTarget.value },
      () => {
        this.getData();
      }
    );
  };
  private setSelectInput = (event: React.FormEvent<HTMLSelectElement>) => {
    this.setState({ ["limit"]: event.currentTarget.value }, () => {
      this.setState({ ["page"]: 0 }, () => {
        this.getData();
      });
    });
  };
  private setPage = (value: any) => {
    let page = this.state.page;
    page = value == "inc" ? page + 1 : page - 1;
    this.setState({ ["page"]: page }, () => {
      this.getData();
    });
  };
  private setSort = (type: string, value: string) => {
    value = value == "asc" ? "desc" : "asc";
    if (this.prevSort != type) {
      this.prevSort = type;
      value = "asc";
    }
    this.setState({ ["sort_type"]: type }, () => {
      this.setState({ ["sort"]: value }, () => {
        this.getData();
      });
    });
  };
  getArrow = () => {
    return (((this.state.page+1) * this.state.limit) >= this.totalCount) ? arrowRightDis : arrowRight;
  }
  setCount = (count:number) => {
    this.totalCount = count;
  }
  getCountString = () => {
    var str = "";
    if(this.totalCount > ((this.state.page+1) * this.state.limit)){
       str = (this.state.page * this.state.limit)+1+"-"+((this.state.page+1) * this.state.limit) +" of "+ this.totalCount;
    }else{
       str = (this.state.page * this.state.limit)+1+"-"+this.totalCount +" of "+ this.totalCount;
    }
    return str;
  }
  handleDelete = () => {
    this.props.deleteGroup(this.state.selectedGroup);
    this.handleDeleteClose();
    this.getData();    
    hideLoading();
  };

  handleDeleteClose = () => {
    this.setState({ deleteModalShow: false, selectedGroup: null });
    
  };

  handleDeleteShow = (groupId: string) => {
    this.setState({ deleteModalShow: true, selectedGroup: groupId });
  };

  public render() {
    if (this.props.isLoading) {
      return <p>Loading</p>;
    }

    if (this.props.hasErrored) {
      return <p>error</p>;
    }

    return (
      <div>
        <div className="inner-title">
          <h3>VIEW USER GROUPS</h3>
          <div className="path_navigation">
            <ul>
              <li>
                <NavLink to="/security">SECURITY Management</NavLink>
              </li>
              <li>
                <span>VIEW USER GROUPS</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="table_data">
          <div className="table_header">
            <div className="icon-input search-icon">
              <input
                type="text"
                className="form-control"
                name={"search"}
                value={this.state.search}
                onChange={event => this.setTextInput(event)}
                placeholder="Search for User Groups"
              />
            </div>
            <NavLink to="/security/UserGroupsManagement/new" className="btn-addnew">
              Add New <span>+</span>
            </NavLink>
          </div>
          <table className="custom_table">
            <thead>
              <tr>
                <th>
                  Group NAME{" "}
                  <a onClick={event => this.setSort("nameSort", this.state.sort)}>
                  { this.state.sort_type == "nameSort" ? (
                      <img src={this.state.sort == "asc" ? sortUpActive : sortDown} />
                    ) : (
                      <img src={sortUp} />
                    )}
                  </a>
                </th>
                <th>
                  DESCRIPTION{" "}
                  <a
                    onClick={event =>
                      this.setSort("descriptionSort", this.state.sort)
                    }
                  >
                   { this.state.sort_type == "descriptionSort" ? (
                      <img src={this.state.sort == "asc" ? sortUpActive : sortDown} />
                    ) : (
                      <img src={sortUp} />
                    )}
                  </a>
                </th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {this.props.success.length == 0 && !this.props.isLoading ? (
                <tr className="">
                  <td colSpan={5} className="nodata">
                    No data found!
                  </td>
                </tr>
              ) : null}
              {this.props.success.map(item => (
                <tr key={item.documentID}>{this.setCount(item.count)}
                  <td  className="capitalize">{item.document.name}</td>
                  <td>{item.document.description}</td>
                  <td>
                    <Dropdown drop="left" className="table_menu">
                      <Dropdown.Toggle id="dropdown-basic">
                        <span className="more_icon"></span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div role="presentation">
                          <Link
                            to={{
                              pathname: "/security/UserGroupsManagement/view_edit",
                              state: {
                                name: item.document.name,
                                description: item.document.description,
                                id: item.documentID,
                                functions: item.document._functions
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faEdit} />{" "}
                            <i className="fa fa-eye"></i> View/Edit
                          </Link>
                        </div>
                        <div role="presentation">
                          <a
                            onClick={e =>
                              this.handleDeleteShow(item.documentID)
                            }
                          >
                            {" "}
                            <FontAwesomeIcon icon={faTrashAlt} /> Delete
                          </a>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={3}>
                  <div className="d-flex flex-row-reverse bd-highlight">
                    <div className="p-2 bd-highlight">
                      <button
                        className="paginationbtn"
                        disabled={this.state.page == 0}
                        onClick={event => this.setPage("dec")}
                      >
                        {" "}
                        &nbsp;&nbsp;&nbsp;
                        <img style={{width:'15px'}} src={(this.state.page == 0) ? arrowLeftDis : arrowLeft }/>{" "}
                        &nbsp;&nbsp;&nbsp;
                      </button>
                      <button
                        className="paginationbtn"
                        disabled={(((this.state.page+1) * this.state.limit) >= this.totalCount)}
                        onClick={event => this.setPage("inc")}
                      >
                        {" "}
                        &nbsp;&nbsp;&nbsp;
                        <img style={{ width: "15px" }} src={this.getArrow()} />
                        &nbsp;&nbsp;&nbsp;
                      </button>
                    </div>
                    <div className="p-2 bd-highlight">{this.getCountString()}</div>
                    <div className="p-2 bd-highlight">
                      Rows per page &nbsp;&nbsp;&nbsp;
                      <select
                        name={"offset"}
                        onChange={event => this.setSelectInput(event)}
                        style={{ border: "0px" }}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <Modal
          className="custom_modal"
          show={this.state.deleteModalShow}
          onHide={() => this.handleDeleteClose()}
        >
          <Modal.Body>
            <div className="invitationBox">
              <a
                className="modalClose"
                onClick={() => this.handleDeleteClose()}
              >
                <img src={closeBtn}></img>
              </a>
              <div className="delete-msg">
                <h4>Are you sure you want delete this function?</h4>
              </div>
              <button
                className="btn-submit"
                onClick={() => this.handleDelete()}
                type="submit"
              >
                Delete
              </button> 
              <button
                className="btn-bordered"
                onClick={() => this.handleDeleteClose()}
                type="submit"
              >
                Cancel
              </button>
                
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.userGroups.UserGroupList.isLoading,
  hasErrored: state.userGroups.UserGroupList.hasErrored,
  success: state.userGroups.UserGroupList.success,
  isDeleteLoading: state.userGroups.UserGroupDelete.isLoading,
  hasDeleteErrored: state.userGroups.UserGroupDelete.hasErrored,
  deleteSuccess: state.userGroups.UserGroupDelete.success,
  deleteresponse: state.userGroups.UserGroupDelete.response,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, Action>
): DispatchProps => ({
  getAllGroups: (limit, page, search, sort) =>
    dispatch(userGroupListAll(limit, page, search, sort)),
  deleteGroup: (groupId: string) => dispatch(userGroupDelete(groupId))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserGroup);
