import * as React from "react";
import { NavLink, Link } from "react-router-dom";
import Select, { ValueType, OptionsType, ActionMeta } from "react-select";
import { CheckBox } from "../../styled-components/checkboxes/index";
import ProfileDefault from "../../assets/images/user.svg";
import InputMask from 'react-input-mask';

import { stateOptions } from "./../../constants/stateOptions";
import { countryOptions } from "./../../constants/countryOptions";

export interface IOwnerFunctionCreateProps {
  isUpdate: boolean;
  firstName: string;
  lastName: string;
  email: string;
  contactEmail: string;
  phoneNumber: string;
  address: string;
  city: string;
  selectedState?: ValueType<{ value: string; label: string; }>;
  zipcode: string;
  selectedCountry?: ValueType<{ value: string; label: string; }>;
  notifications: Map<string, string>;
  states?: OptionsType<{ value: string; label: string; }>
  countries?: OptionsType<{ value: string; label: string; }>
  setTextInput: (e: React.FormEvent<HTMLInputElement>) => void;
  handleSelectChange: (selectedOption: ValueType<{ value: string; label: string; }>, name: string) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  setFileInput: (event: React.FormEvent<HTMLInputElement>) => void;
  deleteFileInput: (event: React.FormEvent) => void;
  profilePicbase: string;
  profilePic: any;
  validator: any
}

interface IUserGroupCreateState {
  checkedItems: Map<string, string>;
  stateSelected: any;
  countrySelected: any;
  profileImage: any;
  profileImageURL: any;
}

const checkboxes = [
  {
    name: "sms",
    key: "sms",
    label: "SMS"
  },
  {
    name: "email",
    key: "email",
    label: "Email"
  }
];

export default class OwnerCreation extends React.Component<
  IOwnerFunctionCreateProps,
  IUserGroupCreateState
  > {
  constructor(props: any) {
    super(props);

    this.state = {
      checkedItems: new Map(),
      stateSelected: null,
      countrySelected: null,      
      profileImage: '',
      profileImageURL: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e: any) {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState(prevState => ({
      checkedItems: prevState.checkedItems.set(item, isChecked)
    }));
  }
  public render() {
    let $profilePreview = null;
    if (this.props.profilePicbase != "") {
      $profilePreview = (<img src={this.props.profilePicbase} />);
    }else{
      $profilePreview = (<img src={ProfileDefault} />);
    }
  
    return (
      <div className="white_panel form_pading">
        <form action="" onSubmit={this.props.handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="blue_label">
                      FIRST NAME <span className="text-danger">*</span>
                    </label>
                    <input
                      name={"firstName"}
                      maxLength={45} 
                      value={this.props.firstName}
                      onChange={this.props.setTextInput}
                       type="text" 
                      className="form-control"
                      placeholder="Enter First Name"
                    />
                    {this.props.validator.message(
                      "firstName",
                      this.props.firstName,
                      "required|min:3|max:45"
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="blue_label">
                      LAST NAME <span className="text-danger">*</span>
                    </label>
                    <input
                      name={"lastName"}
                      value={this.props.lastName}
                      onChange={this.props.setTextInput}
                       type="text" 
                       maxLength={45}
                      className="form-control"
                      placeholder="Enter Last Name"
                    />
                    {this.props.validator.message(
                      "lastName",
                      this.props.lastName,
                      "required|min:3|max:45"
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="blue_label">
                      EMAIL ADDRESS <span className="text-danger">*</span>
                    </label>
                    <input
                      name={"email"}
                      value={this.props.email}
                      onChange={this.props.setTextInput}
                       type="text" maxLength={45}
                      disabled={this.props.isUpdate}
                      className="form-control"
                      placeholder="Enter Email Address"
                    />
                    {this.props.validator.message(
                      "email",
                      this.props.email,
                      "required|min:3|max:45"
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="blue_label">
                      PHONE NUMBER <span className="text-danger">*</span>
                    </label>
                    <InputMask className="form-control" placeholder="Ex: (202) 456-1111" mask="(999) 999-9999"  name={"phoneNumber"} value={this.props.phoneNumber} onChange={this.props.setTextInput}  />

                   
                    {this.props.validator.message(
                      "phoneNumber",
                      this.props.phoneNumber,
                      "required|phoneNumber"
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                  <label className="blue_label">
                      CONTACT EMAIL ADDRESS <span className="required">*</span>
                    </label>
                    <input
                      name={"contactEmail"}
                      value={this.props.contactEmail}
                      onChange={event => this.props.setTextInput(event)}
                      type="text"
                      maxLength={45} 
                      disabled={!this.props.isUpdate}
                      className="form-control"
                      placeholder="Enter Contact Email"
                    />
                    {this.props.validator.message("contactEmail", this.props.contactEmail, "required|email")}
                  </div>
                </div>
                </div>
              <label className="blue_label">MAILING ADDRESS</label>
              <div className="form-group">
                <label className="blue_label">Address </label>
                <input
                  name={"address"}
                  value={this.props.address}
                  onChange={this.props.setTextInput}
                   type="text" maxLength={45}
                  className="form-control"
                  placeholder="Enter Address"
                />
                
              </div>

              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="blue_label">CITY </label>
                    <input
                      name={"city"}
                      value={this.props.city}
                      onChange={this.props.setTextInput}
                       type="text" 
                       maxLength={45}
                      className="form-control"
                      placeholder="Enter City"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group select-group">
                    <label className="blue_label">STATE </label>
                    <Select
                      name={"state"}
                      value={this.props.selectedState}
                      onChange={(selectedOption: ValueType<{ value: string; label: string; }>) => this.props.handleSelectChange(selectedOption, "selectedState")}
                      options={stateOptions}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="blue_label">ZIP CODE </label>
                    <input
                      name={"zipcode"}
                      value={this.props.zipcode}
                      onChange={this.props.setTextInput}
                       type="text" 
                       maxLength={45}
                      className="form-control"
                      placeholder="Enter Zip code"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group select-group">
                    <label className="blue_label">COUNTRY </label>
                    <Select
                      name={"country"}
                      value={this.props.selectedCountry}
                      onChange={(selectedOption: ValueType<{ value: string; label: string; }>) => this.props.handleSelectChange(selectedOption, "selectedCountry")}
                      options={countryOptions}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="blue_label">
                      ALERT NOTIFICATIONS ON{" "}
                    </label>
                    <div>
                      <React.Fragment>
                        {checkboxes.map(item => (
                          <div className="checkBoxButton checkBoxButton_inline">
                            <CheckBox
                              id={item.key}
                              name={item.name}
                              checked={this.props.notifications.get(item.name)}
                              onChange={this.props.handleChange}
                            />
                            <label
                              className="checkboxlabel"
                              htmlFor={item.key}
                              key={item.key}
                            >
                              {item.label}
                            </label>
                          </div>
                        ))}
                      </React.Fragment>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="blue_label">PROFILE PICTURE</label>
            <label className="userFileUpload">
              Choose Picture
              <input 
              type="file"
              name={"profilePic"}
              onChange={event => this.props.setFileInput(event)} 
              className="form-control" />
              <span>Upload</span>
            </label>
            
          </div>
          <i className="smallhint"><span className="reduired">*</span> Aspect ratio: 1:1 | Min Width:150px, Height: 150px</i>

        </div>
        <div className="col-md-6">
          
            <div className="profileUploadedImg">
               
                <div className="profileUploadedImg_inner">
                {$profilePreview}
                </div>
                <a className="delete-icon"   onClick={event => this.props.deleteFileInput(event)} ></a>
            </div>
        </div>
      </div>
      
            </div>
          </div>
          <div className="action_buttons text-right ">
          <Link onClick={()=> window.history.go(-1)} to={""} className="btn-back">Back </Link>
          <button className="btn-submit" type="submit"> {this.props.isUpdate != true ? ("Submit") : "Update"}  </button>
          </div>
        </form>
      </div>
    );
  }
}
