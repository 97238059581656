export const USER_MANAGEMENT_CREATE = "USER_MANAGEMENT_CREATE";
export const USER_MANAGEMENT_CREATE_SUCCESS = "USER_MANAGEMENT_CREATE_SUCCESS";
export const USER_MANAGEMENT_CREATE_ERROR = "USER_MANAGEMENT_CREATE_ERROR";
export const USER_MANAGEMENT_EDIT = "USER_MANAGEMENT_EDIT";
export const USER_MANAGEMENT_EDIT_SUCCESS = "USER_MANAGEMENT_EDIT_SUCCESS";
export const USER_MANAGEMENT_EDIT_ERROR = "USER_MANAGEMENT_EDIT_ERROR";
export const USER_MANAGEMENT_DELETE = "USER_MANAGEMENT_DELETE";
export const USER_MANAGEMENT_DELETE_SUCCESS = "USER_MANAGEMENT_DELETE_SUCCESS";
export const USER_MANAGEMENT_DELETE_ERROR = "USER_MANAGEMENT_DELETE_ERROR";
export const USER_MANAGEMENT_LIST_SINGLE_DELETE =  "USER_MANAGEMENT_LIST_SINGLE_DELETE";
export const USER_MANAGEMENT_LIST = "USER_MANAGEMENT_LIST";
export const USER_MANAGEMENT_LIST_SUCCESS = "USER_MANAGEMENT_LIST_SUCCESS";
export const USER_MANAGEMENT_LIST_ERROR = "USER_MANAGEMENT_LIST_ERROR";
export const GET_VENDOR_OWNER_LIST = "GET_VENDOR_OWNER_LIST";
export const GET_VENDOR_OWNER_LIST_SUCCESS = "GET_VENDOR_OWNER_LIST_SUCCESS";
export const GET_VENDOR_OWNER_LIST_ERROR = "GET_VENDOR_OWNER_LIST_ERROR"; 
export const USER_MANAGEMENT_RESETPIN = "USER_MANAGEMENT_RESETPIN";
export const USER_MANAGEMENT_RESETPIN_SUCCESS = "USER_MANAGEMENT_RESETPIN_SUCCESS";
export const USER_MANAGEMENT_RESETPIN_ERROR = "USER_MANAGEMENT_RESETPIN_ERROR";
