import * as actionTypes from "./actionTypes";
import { ThunkPromise } from "../types";
import { Dispatch } from "redux";
import Api from "../../Api";
import { showLoading, hideLoading } from "react-redux-loading-bar";

export const emailSignInSuccess = ({
  token,
  uid,
  data
}: {
  token?: string;
  uid?: string;
  data?: Object;
}) => ({
  type: actionTypes.LOGIN_SUCCESS,
  payload: {
    token,
    uid, 
    data
  }
});

export const emailSignInError = (error: any) => ({
  type: actionTypes.LOGIN_ERROR,
  payload: error
});

export const emailSignIn = (email: string, password: string) => async (
  dispatch: Dispatch
) => {
  let response ;
  try {
    dispatch(showLoading());
    response = await Api.signIn({ email, password });
    dispatch(hideLoading());
    dispatch(emailSignInSuccess(response));   
  } catch (error) {
    dispatch(hideLoading());
    dispatch(emailSignInError(error));
  }
};

export const onLogout = () => ({
  type: actionTypes.LOGOUT
});

export const onUpdateToken = () => async (
  dispatch: Dispatch
) => {
  try {
    let response = await Api.updateToken();
    dispatch(emailSignInSuccess(response));  
  } catch (error) {
    dispatch(emailSignInError(true));
  }
};

export const onUpdateTokenNone = () => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(showLoading());
    let response = await Api.updateToken();
    dispatch(emailSignInSuccess(response));  
    dispatch(hideLoading());
  } catch (error) {
    dispatch(emailSignInError(true));
    dispatch(hideLoading());
  }
};