import React, { useState, useEffect } from "react";
import { NavLink, useHistory, useLocation, useParams } from "react-router-dom";
import logo from "../../assets/images/logo_2x.png";
import { toast } from "react-toastify";

export const TempPin = () => {
  let tem = '';
  const params: { token?: string; temppin?: string } = useParams();
  const history = useHistory();
  useEffect(() => {
    if (!params.token || !params.temppin) {
      history.push("/login");
    }
    return () => {};
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    toast.dismiss(1);
    event.preventDefault();
    if( params.temppin == tem){
      history.push("/reset-pin", {
        password: params.temppin,
        token: params.token
      });
    }else{
      toast.error("Please enter valid Temporary PIN!",{toastId:1,hideProgressBar:true});
    }
  };
  return (
    <div>
      <div className="non--login">
        <div className="login-form">
          <div className="logo_img">
            <img src={logo} alt="F" />
            <h1>
              <span>FEST</span>FRWRD
            </h1>
          </div>

          <div className="login-form--inner">
            <h2>Reset Pin</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>TEMPORARY PIN <span className="required">*</span></label>
                <div className="tempInfo">
                  <div className="cs_tooltip">
                     <h4>Temporary PIN</h4>
                     <p>Please check your email, where we have provided you with a Temporary PIN in order to login and set a new Permanent PIN.</p>
                  </div>
                </div>
                <div className="icon-input password-icon">
                  <input
                    type="password"
                    className="form-control"
                    onChange={(e:any) => {tem = e.target.value}}
                    placeholder="&#8728;&#8728;&#8728;&#8728;&#8728;&#8728;"
                  />
                 
                </div>
              </div>
              <button className="login_btn">Next</button>
            </form>

            <div className="row">
              <div className="col-12 text-center">
                <NavLink to="/login" className="link-text-small">
                  Login?
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">Copyright. All rights reserved 2019</div>
    </div>
  );
};
