import * as React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import logo from "../../assets/images/logo_2x.png";
import home from "../../assets/images/home.svg";
import security from "../../assets/images/surface1.svg";
import vendor from "../../assets/images/stall.svg";
import owner from "../../assets/images/businessmen.svg";
import { AppState } from "../../state/types";
import * as _ from "lodash";
import { routesConfig } from "./../../constants/routes";

export interface ISidebarProps {}

interface StateProps {
  token?: string;
  profile?: any;
}

type Props = ISidebarProps & StateProps;

class Sidebar extends React.Component<Props> {
  public allRoutes: string[] =[];
  componentWillMount() {
    this.renderAdminRoutes();
  }
  componentWillUpdate(){
    console.log(document.location);
  }
  renderAdminRoutes = () => {
    if(this.props.profile){
      if(this.props.profile.group){
      this.props.profile.group.map((item:any)=>{
      if(item._functions){
       item._functions.map((func: { name: any }) =>{
          if(routesConfig[func.name]){
            for(let i of routesConfig[func.name]){
              this.allRoutes.push(i);
            }       
          }
        
        this.allRoutes = _.uniq(this.allRoutes);
      }
    )
    }
  })
}
}
};

getRoles(){
  const groups = [];
  if(this.props.profile === undefined)
  return [];
  if(this.props.profile.GroupNames.includes("Administrators") === true ){
    groups.push("Administrators");
  }
  if(this.props.profile.GroupNames.includes("Festival Vendors") === true && this.props.profile.invitationStatus == "accepted"){
    groups.push("Festival Vendors");
  }
  if(this.props.profile.GroupNames.includes("Festival Owners") === true && this.props.profile.opeationalStatus == "accepted"){
    groups.push("Festival Owners");
  }
  return groups;
}
  protected hasPermission = (link:string[]) => {
    return  _.find(this.allRoutes, function(rout) {
          return link.includes(rout);
      });
  }
  public render() {
    
    return (
        <nav className="main_nav">
            <div className="nav-logo">
                <img src={logo} alt="F" />
            </div>

            <div className="nav-menu">
                <ul>
                
                
                  {this.getRoles().includes("Administrators") === true ? (
                    <li>
                        <NavLink to="/FestivalManagement">
                            <img src={home} alt="Home" />
                        </NavLink>
                        <span>Manage Festivals</span>
                    </li>
                    ) : null}
                  {this.getRoles().includes("Administrators") === false && this.getRoles().includes("Festival Vendors") === true ? (
                    <li>
                        <NavLink to="/FestivalManagement/vendor">
                            <img src={vendor} alt="vendor" />
                        </NavLink>
                        <span className="long">Your Festival Participation as a Vendor</span>
                    </li>
                    ) : null}

                  {this.getRoles().includes("Administrators") === false && this.getRoles().includes("Festival Owners") === true ? (
                    <li>
                        <NavLink to="/FestivalManagement/owner">
                            <img src={home} alt="Home" />
                        </NavLink>
                        <span className="long">Your Festival Participation as a Owner</span>
                    </li>
                    ) : null}

                     {this.hasPermission(["/UserManagement","/UserGroupsManagement","/UserFunctionsManagement"]) ? (
                    <li>
                        <NavLink to="/security">
                          <img src={security} alt="Home" />
                        </NavLink>
                        <span>Manage Security</span>
                    </li>
                    ) : null}
                    {this.hasPermission(["/FestivalVendorManagement"]) ? (
                    <li>
                        <NavLink to="/FestivalVendorManagement">
                            <img src={vendor} alt="Vendor" />
                        </NavLink>
                        <span>Manage Vendor</span>
                    </li>
                      ) : null}
                       {this.hasPermission(["/FestivalOwnerManagement"]) ? (
                    <li>
                        <NavLink to="/FestivalOwnerManagement">
                            <img src={owner} alt="Owner" />
                        </NavLink>
                        <span>Manage Owner</span>
                    </li>  
                    ) : null}                 
                </ul>
            </div>

            <div className="nav-footer">
                <p><span>©</span> 2019</p>
            </div>
        </nav>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  token: state.session.token,
  profile: state.session.profile
});

export default connect(mapStateToProps)(Sidebar);
