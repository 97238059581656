import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { forgotPinEmail } from "../../state/forgotPin/actions";
import { AppState } from "../../state/types";
import logo from "../../assets/images/logo_2x.png";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";

export const ForgotPin = () => {
  let history = useHistory();
  let states = true;
  const [userName, setUserName] = useState("");
  const dispatch = useDispatch();
  const validator = new SimpleReactValidator();
  const isLoading = useSelector(
    (state: AppState) => state.forgotPin.forgotPinEmail.isLoading
  );
  const success = useSelector(
    (state: AppState) => state.forgotPin.forgotPinEmail.success
  );
  const hasErrored = useSelector(
    (state: AppState) => state.forgotPin.forgotPinEmail.hasErrored
  );

  useEffect(() => {
    if (success) {
      history.push("/forgot-pin-confirm");
    }
    return () => {};
  }, [success, history]);

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); 
    if (validator.allValid()) {
      dispatch(forgotPinEmail(userName));
    } else { 
      toast.error("Please enter valid email!",{toastId:1,hideProgressBar:true});
      validator.showMessages(); 
    }
  };
  return (
    <div>
      <div className="non--login">
        <div className="login-form">
          <div className="logo_img">
            <img src={logo} alt="F" />
            <h1>
              <span>FEST</span>FRWRD
            </h1>
          </div>

          <div className="login-form--inner">
            <h2>Reset Pin</h2>
            <form onSubmit={handleFormSubmit}>
              <div className="form-group">
                <label>EMAIL ADDRESS (USERNAME) <span className="required">*</span></label>
                <div className="icon-input user-icon">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email Address"
                    value={userName}
                    onChange={event => setUserName(event.target.value)}
                  />
                  {!(validator.allValid()) ? (
                    <div className="srv-validation-message">The email field is not valid email.</div>
                  ): null}
                </div>
              </div>
              <button className="login_btn" type="submit">
                Submit
              </button>
            </form>

            <div className="row">
              <div className="col-12 text-center">
                <NavLink to="/login" className="link-text-small">
                  Login?
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">Copyright. All rights reserved 2019</div>
    </div>
  );
};
