import React from 'react'

interface Props {
	isUpdate: boolean,
	handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void,
	functionName: string,
	functionDescription: string,
	setTextInput: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void
	validator: any
}

export default function UserFunctionForm(props: Props) {
	return (
		<form onSubmit={(e) => props.handleSubmit(e)}>
			<div className="row">
				<div className="col-md-6">
					<div className="form-group">
						<label className="blue_label">FUNCTION NAME <span className="required">*</span></label>
						<input name={"functionName"}  maxLength={45} value={props.functionName} onChange={props.setTextInput} type="text" className="form-control" placeholder="Enter Function Name" />
						{props.validator.message(
							"functionName",
							props.functionName,
							"required|min:3|max:45"
						)}
					</div>
					<div className="form-group">
						<label className="blue_label">FUNCTION DESCRIPTION <span className="required">*</span></label>
						<textarea name={"functionDescription"}  maxLength={550} value={props.functionDescription} onChange={props.setTextInput} className="form-control" placeholder="Enter Description"></textarea>
						{props.validator.message(
							"functionDescription",
							props.functionDescription,
							"required|min:1|max:550"
						)}
					</div>
				</div>
			</div>
			<div className="row">
			<div className="col-md-6 text-right">
			<button className="btn-submit" type="submit"> {props.isUpdate != true ? ("Save") : "Update"}  </button>
			</div>
			</div>
		</form>
	)
}
