import * as ActionTypes from "./actionTypes";
import { UserManagementState, UserManagementCreateActionTypes } from "./types";

const initialState: UserManagementState = {
  UserManagementCreate: {
    isLoading: false,
    hasErrored: false,
    success: false,
    response:null
  },
  UserManagementEdit: {
    isLoading: false,
    hasErrored: false,
    success: false,
    response:null,
    rand:null,
  },
  UserManagementDelete: {
    isLoading: false,
    hasErrored: false,
    success: false,
    response:null
  },
  UserManagementResetPIN: {
    isLoading: false,
    hasErrored: false,
    success: false,
    response:null
  },
  UserManagementList: {
    isLoading: false,
    hasErrored: false,
    success: [],
    response:null
  },
  GetVendorsAndOwners: {
    isLoading: false,
    hasErrored: false,
    success: [],
    response:null
  }
};

export default (
  state = initialState,
  action: UserManagementCreateActionTypes
): UserManagementState => {
  switch (action.type) {
    case ActionTypes.USER_MANAGEMENT_CREATE:
      return {
        ...state,
        UserManagementCreate: {
          ...state.UserManagementCreate,
          isLoading: true,
          success: false,
          hasErrored: false,
          response:null
        }
      };

    case ActionTypes.USER_MANAGEMENT_CREATE_SUCCESS:
      return {
        ...state,
        UserManagementCreate: {
          ...state.UserManagementCreate,
          success: true,
          isLoading: false,
          hasErrored: false,
          response:action.payload
        }
      };

    case ActionTypes.USER_MANAGEMENT_CREATE_ERROR:
      return {
        ...state,
        UserManagementCreate: {
          ...state.UserManagementCreate,
          success: false,
          isLoading: false,
          hasErrored: true,
          response:action.payload
        }
      };

    case ActionTypes.USER_MANAGEMENT_EDIT:
      return {
        ...state,
        UserManagementEdit: {
          ...state.UserManagementEdit,
          isLoading: true,
          success: false,
          hasErrored: false,
          response:null,
          rand:Math.random(),
        }
      };

    case ActionTypes.USER_MANAGEMENT_EDIT_SUCCESS:
      return {
        ...state,
        UserManagementEdit: {
          ...state.UserManagementEdit,
          isLoading: false,
          success: true,
          hasErrored: false,
          response:action.payload
        }
      };

    case ActionTypes.USER_MANAGEMENT_EDIT_ERROR:
      return {
        ...state,
        UserManagementEdit: {
          ...state.UserManagementEdit,
          isLoading: false,
          success: false,
          hasErrored: true,
          response:action.payload
        }
      };

    case ActionTypes.USER_MANAGEMENT_LIST_SINGLE_DELETE:
      let success = state.UserManagementList.success.filter(
        item => item.documentID !== action.payload
      );
      return {
        ...state,
        UserManagementList: {
          ...state.UserManagementList,
          success: success
        }
      };

    case ActionTypes.USER_MANAGEMENT_DELETE:
      return {
        ...state,
        UserManagementDelete: {
          ...state.UserManagementDelete,
          isLoading: true,
          success: false,
          hasErrored: false,
          response:null
        }
      };

    case ActionTypes.USER_MANAGEMENT_DELETE_SUCCESS:
      return {
        ...state,
        UserManagementDelete: {
          ...state.UserManagementDelete,
          isLoading: false,
          success: true,
          hasErrored: false,
          response:action.payload
        }
      };

    case ActionTypes.USER_MANAGEMENT_DELETE_ERROR:
      return {
        ...state,
        UserManagementDelete: {
          ...state.UserManagementDelete,
          isLoading: false,
          success: false,
          hasErrored: true,
          response:action.payload
        }
      };
      
      case ActionTypes.USER_MANAGEMENT_RESETPIN:
      return {
        ...state,
        UserManagementResetPIN: {
          ...state.UserManagementResetPIN,
          isLoading: true,
          success: false,
          hasErrored: false,
          response:null
        }
      };

    case ActionTypes.USER_MANAGEMENT_RESETPIN_SUCCESS:
      return {
        ...state,
        UserManagementResetPIN: {
          ...state.UserManagementResetPIN,
          isLoading: false,
          success: true,
          hasErrored: false,
          response:action.payload
        }
      };

    case ActionTypes.USER_MANAGEMENT_RESETPIN_ERROR:
      return {
        ...state,
        UserManagementResetPIN: {
          ...state.UserManagementResetPIN,
          isLoading: false,
          success: false,
          hasErrored: true,
          response:action.payload
        }
      };
    case ActionTypes.USER_MANAGEMENT_LIST:
      return {
        ...state,
        UserManagementList: {
          ...state.UserManagementList,
          isLoading: true,
          success: [],
          hasErrored: false
        }
      };

    case ActionTypes.USER_MANAGEMENT_LIST_SUCCESS:
      return {
        ...state,
        UserManagementList: {
          ...state.UserManagementList,
          success: action.payload,
          isLoading: false
        }
      };

    case ActionTypes.USER_MANAGEMENT_LIST_ERROR:
      return {
        ...state,
        UserManagementList: {
          ...state.UserManagementList,
          isLoading: false,
          hasErrored: true
        }
      };
      case ActionTypes.GET_VENDOR_OWNER_LIST:
      return {
        ...state,
        GetVendorsAndOwners: {
          ...state.GetVendorsAndOwners,
          isLoading: true,
          success: [],
          hasErrored: false
        }
      };

    case ActionTypes.GET_VENDOR_OWNER_LIST_SUCCESS:
      return {
        ...state,
        GetVendorsAndOwners: {
          ...state.GetVendorsAndOwners,
          success: action.payload,
          isLoading: false
        }
      };

    case ActionTypes.GET_VENDOR_OWNER_LIST_ERROR:
      return {
        ...state,
        GetVendorsAndOwners: {
          ...state.GetVendorsAndOwners,
          isLoading: false,
          hasErrored: true
        }
      };

    default:
      return state;
  }
};
