import { showLoading, hideLoading } from "react-redux-loading-bar";
import * as ActionTypes from "./actionTypes";
import { Dispatch } from "redux";
import Api from "../../Api";

const festivalOwnerListSuccess = (payload: any) => ({
  type: ActionTypes.FESTIVAL_OWNER_LIST_SUCCESS,
  payload
});

const festivalOwnerListError = (payload: any) => ({
  type: ActionTypes.FESTIVAL_OWNER_LIST_ERROR,
  payload
});
export const festivalOwnerListAll = (
  limit: number,
  page: number,
  search: string,
  sort: string
) => async (dispatch: Dispatch) => {
  try {
    dispatch(showLoading());
    let festivalOwnerListResponse = await Api.getAllOwnersByList(
      limit,
      page,
      search,
      sort
    );
    dispatch(festivalOwnerListSuccess(festivalOwnerListResponse));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch(festivalOwnerListError(error));
  }
};

export const festivalOwnerList = () => async (dispatch: Dispatch) => {
  try {
    dispatch(showLoading());
    let festivalOwnerListResponse = await Api.getAllOwners();
    dispatch(festivalOwnerListSuccess(festivalOwnerListResponse));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch(festivalOwnerListError(error));
  }
};

export const festivalOwnerEditSingle = (payload: any) => ({
  type: ActionTypes.FESTIVAL_OWNER_EDIT_SINGLE,
  payload
});

const festivalOwnerCreateLoading = () => ({
  type: ActionTypes.FESTIVAL_OWNER_CREATE
});

const festivalOwnerCreateSuccess = (payload: any) => ({
  type: ActionTypes.FESTIVAL_OWNER_CREATE_SUCCESS,
  payload
});

const userGroupCreateError = (payload: any) => ({
  type: ActionTypes.FESTIVAL_OWNER_CREATE_ERROR,
  payload
});

export const festivalOwnerCreate = ({
  firstName,
  lastName,
  email,
  contactEmail,
  phoneNumber,
  GroupNames,
  Notifications,
  address,
  city,
  country,
  state,
  zipcode,
  profileImageURL,
  tags
}: {
  firstName: string;
  lastName: string;
  email: string;
  contactEmail: string;
  phoneNumber: string;
  GroupNames: string[];
  Notifications: string[];
  address: string;
  city: string;
  country: string;
  state: string;
  zipcode: string;
  profileImageURL: any;
  tags: any;
}) => async (dispatch: Dispatch) => {
  try {
    dispatch(showLoading());
    dispatch(festivalOwnerCreateLoading());
    let festivalOwnerCreateResponse = await Api.createFestivalOwner({
      firstName,
      lastName,
      email,
      contactEmail,
      phoneNumber,
      GroupNames,
      Notifications,
      address,
      city,
      country,
      state,
      zipcode,
      profileImageURL,
      tags
    });
    dispatch(festivalOwnerCreateSuccess(festivalOwnerCreateResponse));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(userGroupCreateError(error));
    dispatch(hideLoading());
  }
};

const festivalOwnerEditLoading = () => ({
  type: ActionTypes.FESTIVAL_OWNER_EDIT
});

const festivalOwnerEditSuccess = (payload: any) => ({
  type: ActionTypes.FESTIVAL_OWNER_EDIT_SUCCESS,
  payload
});

const festivalOwnerEditError = (payload: any) => ({
  type: ActionTypes.FESTIVAL_OWNER_EDIT_ERROR,
  payload
});

export const festivalOwnerEdit = ({
  ownerId,
  firstName,
  lastName,
  email,
  contactEmail,
  phoneNumber,
  GroupNames,
  Notifications,
  address,
  city,
  country,
  state,
  zipcode,
  profileImageURL,
  tags
}: {
  ownerId: string;
  firstName: string;
  lastName: string;
  email: string;
  contactEmail: string;
  phoneNumber: string;
  GroupNames: string[];
  Notifications: string[];
  address: string;
  city: string;
  country: string;
  state: string;
  zipcode: string;
  profileImageURL: any;
  tags: any;
}) => async (dispatch: Dispatch) => {
  try {
    dispatch(showLoading());
    dispatch(festivalOwnerEditLoading());
    let festivalOwnerEditResponse = await Api.editFestivalOwner({
      ownerId,
      firstName,
      lastName,
      email,
      contactEmail,
      phoneNumber,
      GroupNames,
      Notifications,
      address,
      city,
      country,
      state,
      zipcode,
      profileImageURL,
      tags
    });
    dispatch(festivalOwnerEditSuccess(festivalOwnerEditResponse));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch(festivalOwnerEditError(error));
  }
};

const festivalOwnerDeleteSingle = (groupId: string) => ({
  type: ActionTypes.FESTIVAL_OWNER_DELETE_SINGLE,
  payload: groupId
});

const festivalOwnerDeleteSuccess = (payload: any) => ({
  type: ActionTypes.FESTIVAL_OWNER_DELETE_SUCCESS,
  payload
});

const festivalOwnerDeleteError = (payload: any) => ({
  type: ActionTypes.FESTIVAL_OWNER_DELETE_ERROR,
  payload
});

export const festivalOwnerDelete = (ownerId: string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(showLoading());
    let festivalOwnerEditResponse = await Api.deleteFestivalOwner({
      ownerId: ownerId
    });
    dispatch(festivalOwnerDeleteSuccess(festivalOwnerEditResponse));
    dispatch(festivalOwnerDeleteSingle(ownerId));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch(festivalOwnerDeleteError(error));
  }
};

export const festivalOwnerSendInviteLoading = () => ({
  type: ActionTypes.FESTIVAL_OWNER_SEND_INVITE
});

export const festivalOwnerSendInviteSuccess = (payload: any) => ({
  type: ActionTypes.FESTIVAL_OWNER_SEND_INVITE_SUCCESS,
  payload
});

export const festivalOwnerSendInviteError = (payload: any) => ({
  type: ActionTypes.FESTIVAL_OWNER_SEND_INVITE_ERROR,
  payload
});

export const festivalOwnerSendInvite = (email: string,contactEmail:string,name:string, groupscount:number) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(showLoading());
    dispatch(festivalOwnerSendInviteLoading());
    let sendInviteResp = await Api.ownersSendInvite({ email: email,contactEmail:contactEmail,name: name,groupscount:groupscount });
    dispatch(festivalOwnerSendInviteSuccess(sendInviteResp));
    dispatch(festivalOwnerEditSingle({ opeationalStatus: "Pending", email }));
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch(festivalOwnerSendInviteError(error));
  }
};
