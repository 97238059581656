import * as ActionTypes from "./actionTypes"
import { Dispatch } from 'redux'
import Api from "../../Api"
import { showLoading, hideLoading } from "react-redux-loading-bar";

const userChangePinCreateSuccess = (payload: any) => ({
	type: ActionTypes.USER_PIN_CREATE_SUCCESS,
	payload
})

const userChangePinCreateError = (payload: any) => ({
	type: ActionTypes.USER_PIN_CREATE_ERROR,
	payload
})



export const userChangePinCreate = (oldpin: string, newpin: string,confirmpin: string,email:string) => async (dispatch: Dispatch) => {
	try {
		dispatch(showLoading());
		let userChangePinCreateResponse = await Api.updateUserPin({oldpin, newpin,confirmpin,email })
		dispatch(userChangePinCreateSuccess(userChangePinCreateResponse))
		dispatch(hideLoading());
	} catch (error) {
		dispatch(hideLoading());
		dispatch(userChangePinCreateError(error))
	}
}

