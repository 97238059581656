import * as ActionTypes from "./actionTypes";
import { Dispatch } from "redux";
import Api from "../../Api";
import { showLoading, hideLoading } from "react-redux-loading-bar";

import { deleteSingleUserFunction } from "../userFunctionList/actions";

const userFunctionDeleteLoading = () => ({
  type: ActionTypes.USER_FUNCTION_DELETE
});

const userFunctionDeleteSuccess = (payload: any) => ({
  type: ActionTypes.USER_FUNCTION_DELETE_SUCCESS,
  payload
});

const userFunctionDeleteError = (payload: any) => ({
  type: ActionTypes.USER_FUNCTION_DELETE_ERROR,
  payload
});

export const userFunctionDelete = (functionId: string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(userFunctionDeleteLoading());
    let userFunctionCreateResponse = await Api.deleteUserFunction(functionId);
    dispatch(userFunctionDeleteSuccess(userFunctionCreateResponse));
    dispatch(deleteSingleUserFunction(functionId));
  } catch (error) {
    dispatch(userFunctionDeleteError(error));
  }
};
