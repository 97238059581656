import "./checkbox.css";
import * as React from "react";



export const CheckBox = (props: any) => {
  return (
    <input id={props.id} name={props.name} onChange={props.onChange} value={props.value} type="checkbox" checked={props.checked} />
  );
}
