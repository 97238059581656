import * as React from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { showLoading, hideLoading,ImmutableLoadingBar as LoadingBar  } from "react-redux-loading-bar";
import {store} from './../state/store';
export const Axios = axios.create({ baseURL: process.env.REACT_APP_API_URL  });
  //Axios.defaults.timeout = 100000;
  var excludeurls = ["vendors/updateinvite","auth/userResetPin","auth/updateToken","auth/login","auth/updateUserPRofile","auth/changePassword/","auth/resetPin","owners/sendinvite","auth/resetPin","owners/updateinvite","auth/forgetpassword","vendors/sendinvite"];

  Axios.defaults.onUploadProgress = function(progressEvent) {
    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    
    // toast.update(1,{
    //   progress:percentCompleted
    // })
    //console.log("percentCompleted",percentCompleted)
  }
  interface Profile {
     GroupNames: string;
     group: string;
  }
  /************Authorization Token*********/
  Axios.interceptors.request.use(function (config) {
    const session = store.getState().session;
    try {
      if(session !== undefined){
        const profile: Profile | any = session.profile;    
        config.headers.Authorization =  session.token;
        config.headers.uid =  session.uid;
        if(profile !== undefined && profile.GroupNames != undefined){
          config.headers.GroupNames =  profile.GroupNames;
          const _functions = [];
          if(profile.group.length > 0){
            for(let _g of profile.group){
                if(_g._functions){
                  for(let _f of _g._functions){
                    if(_functions.includes(_f.name) === false)
                    _functions.push(_f.name);
                  }
              }
            }
            config.headers._functions =  _functions;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
   
    return config;
  }, function (error) {  
    return Promise.reject(error);
  });

/************401 403*********/
  Axios.interceptors.response.use((response) => {
    // if(response.status === 401 || response.status === 500) {
    //     toast.dismiss(1);
    //     toast.error("You are not authorized");
    // }
    return response;
}, (error) => {
  
    if(error.response !== undefined){
      // if(error.response.status === 401 ) {  
      //   toast.dismiss(1);      
      //   toast.warn("Too many unsuccessful login attempts. Temp PIN sent to your Email.",{toastId:1,onClose: () => {
      //     window.location.href = "/login";
      //   }});
       
      // }
      if(error.response.status === 403 ) {  
        toast.dismiss(1);      
        toast.error("Access forbidden for current account / Session Expired!",{toastId:1,onClose: () => {
          window.location.href = "/login";
        }});
        window.location.href = "/login";
      }
      if(error.response.status === 404 ) {
        toast.dismiss(1);
        toast.error("ERROR: The requested URL is not found!",{toastId:1,onClose: () => {
          window.location.href = "/login";
        }});
      }
      if(error.response.status === 402 ) {
        toast.dismiss(1);
        toast.error("ERROR: The requested data is invalid!",{toastId:1});
      }
    }else{
      toast.dismiss(1);
      toast.error("ERROR: "+error.message+", Try Again! " ,{toastId:1});
    }
    if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
});



// Add interceptors
Axios.interceptors.request.use(
  request => requestHandler(request)
)

Axios.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
)
const isHandlerEnabled = (config={}) => {
  return  true;
}
const requestHandler = (request:any) => {
  if(request.url != "auth/updateToken")
  showLoading();
  if (isHandlerEnabled(request)) { 
    if(excludeurls.indexOf(request.url) == -1){
      if((request.method == "put" || request.method == "post" || request.method == "delete")){
        toast.dismiss(2);
       // toast.info(`Please wait`,{toastId:1,hideProgressBar:true,autoClose:false})
      } 
    } 
  }
  return request
}

const errorHandler = (error:any) => {
  if (isHandlerEnabled(error.config)) {
       hideLoading();
      // toast.dismiss(1);
  }
  return Promise.reject({ ...error })
}

const successHandler = (response:any) => {
  //console.log(response);
  if (isHandlerEnabled(response.config)) {
    //  toast.dismiss(1);
     hideLoading();
  }
  return response
}
