import React, { Component } from "react";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { ValueType } from "react-select";
import OwnerForm from "../../components/owner/ownerForm";
import { AppState } from "../../state/types";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { festivalOwnerCreate } from "../../state/festivalOwners/actions";

import { stateOptions } from "./../../constants/stateOptions";
import { countryOptions } from "./../../constants/countryOptions";

interface DispatchProps {
  festivalOwnerCreate: (festivalOwner: {
    firstName: string;
    lastName: string;
    email: string;
    contactEmail: string;
    phoneNumber: string;
    GroupNames: string[];
    Notifications: string[];
    address: string;
    city: string;
    country: string;
    state: string;
    zipcode: string;
    profileImageURL: string,
    tags: any,
  }) => void;
}

interface StateProps {
  isLoading: boolean;
  success: boolean;
  hasErrored: boolean;
  response: any;
}

interface ComponentProps {}
interface State {
  firstName: string;
  lastName: string;
  email: string;
  contactEmail: string;
  phoneNumber: string;
  address: string;
  city: string;
  selectedState: ValueType<{ value: string; label: string }>;
  zipcode: string;
  selectedCountry: ValueType<{ value: string; label: string }>;
  notifications: Map<string, string>;
  profilePicbase:any;
  profilePic: any;  
}


type Props = DispatchProps &
  ComponentProps &
  StateProps &
  State &
  RouteComponentProps;

export class OwnerCreate extends Component<Props, State> {
  private validator: any;
  public profileImageURL = "";
  state = {
    isUpdate:false,
    firstName: "",
    lastName: "",
    email: "",
    contactEmail: "",
    phoneNumber: "",
    address: "",
    city: "",
    selectedState: null as any,
    zipcode: "",
    selectedCountry: null as any,
    notifications: new Map(),
    profilePicbase:"",
    profilePic: null as any,
  };
  constructor(props: Props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this,  validators: {
      phoneNumber: {
        message: 'The :attribute must be a valid phone Number',
        rule: (val:any, params:any, validator:any) => {
          return validator.helpers.testRegex(val,/^((\([0-9]{3}\))|[0-9]{3})[\s\-][\0-9]{3}[-][0-9]{4}$/i) && params.indexOf(val) === -1
        },
        required: true
      }
    } });
  }
  getBase64(file:any, cb:any) {
    let reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
  }
  private setFileInput = (event: React.FormEvent<HTMLInputElement>) => { 
    console.log("ggg",event.currentTarget.files);
    let files = event.currentTarget.files;
    this.getBase64(files, (result:any) => {
      this.setState({ profilePicbase: result });
    });
  };
  private deleteFileInput = (event: any) => {
    this.setState({ profilePicbase: "" });
  };
  componentDidMount() {
    document.title = "New Owner | FestFrwrd - The Futr of FSTIVLS";
  }
  componentDidUpdate(prevProps: Props) {
    if (prevProps.response !== this.props.response && this.props.success) { 
      this.props.history.goBack();
      // toast.update(1, {
      //   render: this.props.response.data.message,
      //   type: toast.TYPE.SUCCESS,
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   onClose:() =>{
      //     this.props.history.goBack();
      //   }
      // });    
    }
    if (
      prevProps.response !== this.props.response &&
      this.props.hasErrored
    ) {  
      toast.error((this.props.response.error == null) ? this.props.response.message : this.props.response.error);     
      // toast.update(1, {
      //   render: (this.props.response.error == null) ? this.props.response.message : this.props.response.error,
      //   type: toast.TYPE.ERROR,
      //   autoClose: 2000,
      //   hideProgressBar: false
      // });
    }
  }

  setTextInput = <T extends keyof State>(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const newState = {
      [event.currentTarget.name]: event.currentTarget.value
    };
    this.setState(newState as { [P in T]: State[P] });
    if(event.currentTarget.name === 'email'){
      this.setState({
        ['contactEmail']: event.currentTarget.value
      } as Pick<State, keyof State>);
    }
  };

  handleSelectChange = <T extends keyof State>(
    selectedOption: ValueType<{ value: string; label: string }>,
    name: string
  ) => {
    const newState = {
      [name]: selectedOption
    };
    this.setState(newState as { [P in T]: State[P] }, () =>
      console.log(this.state)
    );
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const item = e.target.id;
    const name = e.target.name;
    const isChecked = e.target.checked;
    if (!isChecked) {
      this.setState((state, props) => {
        state.notifications.delete(item);
        return {
          notifications: state.notifications
        };
      });
    } else {
      this.setState((state, props) => {
        state.notifications.set(item, name);
        return {
          notifications: state.notifications
        };
      });
    }
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.props.festivalOwnerCreate({
        Notifications: [...this.state.notifications.values()],
        address: this.state.address,
        city: this.state.city,
        country: this.state.selectedCountry
          ? this.state.selectedCountry.value
          : "",
        email: this.state.email,
        contactEmail: this.state.contactEmail,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phoneNumber: this.state.phoneNumber,
        GroupNames: ["Festival Owners"],
        state: this.state.selectedState ? this.state.selectedState.value : "",
        zipcode: this.state.zipcode,        
        profileImageURL: this.state.profilePicbase,
        tags: {
          opeationalStatus: '',
          invitationStatus: '',
          businessName: '',
          category: '',
          activeSince: '',
        }
      });
    } else {
      toast.dismiss(2);
      toast.warn("Please fill in all required fields!",{toastId:2});
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
        <div>
          <div className="inner-title">
            <h3>ADD FESTIVAL OWNER</h3>
            <div className="path_navigation">
              <ul>
                <li>
                  <NavLink to="/FestivalOwnerManagement">FESTIVAL OWNER Management</NavLink>
                </li>
                <li>
                  <NavLink to="/FestivalOwnerManagement/list">VIEW FESTIVAL OWNER</NavLink>
                </li>
                <li>
                  <span>ADD FESTIVAL OWNER</span>
                </li>
              </ul>
            </div>
          </div>
          <OwnerForm
            isUpdate={this.state.isUpdate}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            email={this.state.email}
            contactEmail={this.state.contactEmail}
            phoneNumber={this.state.phoneNumber}
            address={this.state.address}
            city={this.state.city}
            states={stateOptions}
            selectedState={this.state.selectedState}
            zipcode={this.state.zipcode}
            countries={countryOptions}
            selectedCountry={this.state.selectedCountry}
            notifications={this.state.notifications}
            setTextInput={this.setTextInput}
            handleSelectChange={this.handleSelectChange}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}  
            profilePic={this.state.profilePic}          
            setFileInput={this.setFileInput}   
            deleteFileInput={this.deleteFileInput}         
            profilePicbase={this.state.profilePicbase}
            validator={this.validator}
          />
        </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  response: state.festivalOwner.FestivalOwnerCreate.response,
  isLoading: state.festivalOwner.FestivalOwnerCreate.isLoading,
  success: state.festivalOwner.FestivalOwnerCreate.success,
  hasErrored: state.festivalOwner.FestivalOwnerCreate.hasErrored
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, Action>
): DispatchProps => ({
  festivalOwnerCreate: ({
    Notifications,
    address,
    city,
    country,
    email,
    contactEmail,
    firstName,
    lastName,
    phoneNumber,
    GroupNames,
    state,
    zipcode,
    profileImageURL,
    tags,
  }) =>
    dispatch(
      festivalOwnerCreate({
        Notifications,
        address,
        city,
        country,
        email,
        contactEmail,
        firstName,
        lastName,
        phoneNumber,
        GroupNames,
        state,
        zipcode,
        profileImageURL,
        tags
      })
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(OwnerCreate);
