import * as ActionTypes from "./actionTypes"
import { UserChangePinCreateState, UserChangePinCreateActionTypes } from "./types"

const initialState: UserChangePinCreateState = {
	isLoading: false,
	hasErrored: false,
	success: Math.random()
}

export default (state = initialState, action: UserChangePinCreateActionTypes): UserChangePinCreateState => {
	switch (action.type) {

		case ActionTypes.USER_PIN_CREATE:
			return { ...state, isLoading: true, success: Math.random(), hasErrored: false }

		case ActionTypes.USER_PIN_CREATE_SUCCESS:
			return { ...state, success: Math.random(), isLoading: false }

		case ActionTypes.USER_PIN_CREATE_ERROR:
			return { ...state, isLoading: false, hasErrored: true }

		default:
			return state
	}
}
