import * as ActionTypes from "./actionTypes"
import { UserFunctionDeleteState, UserFunctionDeleteActionTypes } from "./types"

const initialState: UserFunctionDeleteState = {
	isLoading: false,
	hasErrored: false,
	success: false,
	response: null
}

export default (state = initialState, action: UserFunctionDeleteActionTypes): UserFunctionDeleteState => {
	switch (action.type) {

		case ActionTypes.USER_FUNCTION_DELETE:
			return { ...state, isLoading: true, success: false, hasErrored: false, response:null}

		case ActionTypes.USER_FUNCTION_DELETE_SUCCESS:
			return { ...state, isLoading: false, success: true, hasErrored: false, response:action.payload  }

		case ActionTypes.USER_FUNCTION_DELETE_ERROR:
			return { ...state,  isLoading: false, success: false,  hasErrored: true, response:action.payload }

		default:
			return state
	}
}
